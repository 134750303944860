import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useMemo } from "react";
import styled from "styled-components";
import PhonePerfix from "./phonePerfix";
import { useTranslation } from "react-i18next";

// Container to hold the input and dropdown with proper spacing and alignment
const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

// Styled input with consistent height, font size, and border radius
const StyledInput = styled(InputText)`
  flex-grow: 1;

  border-radius: 0.375rem; /* about 6px */
  /* Adjust border and background if needed */
  text-align: right;
  border: 1px solid var(--border-color);
`;

// Styled dropdown with a fixed width and similar sizing as the input
const StyledDropdown = styled(Dropdown)`
  width: 160px;
  font-size: 14px;

  border-radius: 0.375rem;
  border: 1px solid var(--border-color);
  /* Remove right border if you prefer a connected look */
  /* border-right: none; */

  .p-dropdown-label {
  }
`;

interface Props {
  value?: string;
  onChange: (v: string) => void;
  error?: string;
}

const PhoneSelector: React.FC<Props> = ({ value, onChange, error }) => {
  const { t } = useTranslation();

  // Find the matching phone prefix based on the current value
  const start = useMemo(() => {
    return PhonePerfix.find((p) => value?.startsWith(p.dial_code));
  }, [value]);

  // Extract the remaining part of the phone number
  const end = useMemo(
    () => value?.substring(start?.dial_code.length ?? 0) || "",
    [start?.dial_code, value]
  );

  const handlePhoneChange = (newLocalNumber: string) => {
    const newFullNumber = `${start?.dial_code || ""}${newLocalNumber}`;
    onChange(newFullNumber);
  };

  const handleDialCodeChange = (newDialCode: string) => {
    const newFullNumber = `${newDialCode}${end}`;
    onChange(newFullNumber);
  };

  return (
    <div>
      <Container>
        <StyledInput
          className={error ? "p-invalid" : ""}
          value={end}
          onChange={(e) => {
            const newLocalNumber = e.target.value;
            handlePhoneChange(newLocalNumber);
          }}
          placeholder={"נא להכניס מספר טלפון"}
          style={{ direction: "ltr" }}
        />
        <StyledDropdown
          className={error ? "p-invalid" : ""}
          value={start?.dial_code}
          onChange={(e: DropdownChangeEvent) => {
            const newDialCode = e.value || "";
            handleDialCodeChange(newDialCode);
          }}
          options={PhonePerfix}
          optionLabel="fullName"
          optionValue="dial_code"
          placeholder={t("form.phone.select_country")}
          filter
          filterBy="name"
        />
      </Container>
      {error && <small className="p-error">{error}</small>}
    </div>
  );
};

export default PhoneSelector;
