import { Dictionary } from "lodash";
import InstagramCaptionBlock from "./blocks/InstagramCaptionBlock";
import FacebookPostCaptionBlock from "./blocks/FacebookPostCaptionBlock";
import { BlockProps } from "./blocks/types/dictionaryProps";
import { ForwardRefExoticComponent, RefAttributes } from "react";
import { RefProps } from "./blocks/types/refProps";
import LinkedInCaptionBlock from "./blocks/LinkedinCaptionBlock";
import InstagramReelIdeaBlock from "./blocks/InstagramReelIdeaBlock";
import InstagramStoryIdeaBlock from "./blocks/InstagramStoryIdeaBlock";
import InstagramPostIdeaBlock from "./blocks/InstagramPostIdeaBlock";
import ThreadsTwitBlock from "./blocks/ThreadsTwitBlock";
import EmailContentBlock from "./blocks/EmailContentBlock";
import SmsContentBlock from "./blocks/SmsContentBlock";
import XTwitBlock from "./blocks/XTwitBlock";
import WebsiteFaqBlock from "./blocks/WebsiteFaqBlock";
import WebsiteAboutBlock from "./blocks/WebsiteAboutBlock";
import WebsiteProductDescriptionBlock from "./blocks/WebsiteProductDescriptionBlock";
import WebsiteTestmonialBlock from "./blocks/WebsiteTestmonialBlock";
import FacebookPageAboutBlock from "./blocks/FacebookPageAboutBlock";
import FacebookPostIdeaBlock from "./blocks/FacebookPostIdeaBlock";
import InstagramBioDescriptionBlock from "./blocks/InstagramBioDescriptionBlock";

import GoogleAdsKeywordsBlock from "./blocks/GoogleAdsKeywordsBlock";
import LinkedinPageAboutBlock from "./blocks/LinkedinPageAboutBlock";
import LinkedInPostIdeaBlock from "./blocks/LinkedinPostIdeaBlock";

import { useDefaultAccordions } from "./forms/EmptyForm";

import { useBlogPostOpinionAccordions } from "./forms/BlogPostOpinionForm";
import { AccordionProps } from "./forms/types/AccordionProps";
import { DocumentEntity } from "neword-core";
import ArticleBlock from "./blocks/ArticleBlock";
import { useBlogPostTipsAccordions } from "./forms/BlogPostTipsForm";
import { useBlogPostComparisonAccordions } from "./forms/BlogPostComparisonForm";
import { useBlogPostCaseStudyAccordions } from "./forms/BlogPostCaseStudyForm";
import { useCartAbandonmentMailAccordions } from "./forms/CartAbandonmentMailForm";
import { useTrafficEmailAccordions } from "./forms/TrafficEmailForm";
import { useNewsLetterEmailAccordions } from "./forms/NewsLetterEmailForm";
import { useProductUpdateEmailAccordions } from "./forms/ProductUpdateEmailForm";
import { useEmailAccordions } from "./forms/EmailForm";
import { useWelcomeEmailAccordions } from "./forms/WelcomEmailForm";
import { useFlashSmsAccordions } from "./forms/FlashSmsForm";
import { useNotificationSmsAccordions } from "./forms/NotificationSmsForm";
import { useSmsAccordions } from "./forms/SmsForm";
import PushNotificationBlock from "./blocks/PushNotificationBlock";
import { usePushNotificationAccordions } from "./forms/PushNotificationForm";
import { useBioDescriptionAccordions } from "./forms/BioDescriptionForm";
import { usePostAccordions } from "./forms/PostForm";
import InstagramSponsoredAdBlock from "./blocks/InstagramSponsoredAdBlock";
import { useInstagramSponsoredAdAccordions } from "./forms/InstagramSponsoredAdForm";
import LinkedInSponsoredAdBlock from "./blocks/LinkedInSponsoredAdBlock";
import FacebookSponsoredAdBlock from "./blocks/FacebookSponsoredAdBlock";
import SearchAdBlock from "./blocks/SearchAdBlock";
import { useSearchAdAccordions } from "./forms/SearchAdForm";
import BlogPostSharedSettings from "./forms/advencedSettings/BlogPostSharedSettings";
import SmsSharedSettings from "./forms/advencedSettings/SmsSharedSettings";
import SocialSharedSettings from "./forms/advencedSettings/SocialSharedSettings";
import BioSharedSettings from "./forms/advencedSettings/BioSharedSettings";
import { MultiDocBlock } from "./blocks/MultiDocBlock";

interface DocumentsDictionaryProps {
  block: ForwardRefExoticComponent<
    Omit<BlockProps, "ref"> & RefAttributes<RefProps>
  >;
  form: (() => AccordionProps[]);
  columns?: number;
  disableBaseForm?: boolean;
  AdvencedSettings?: React.FC;
}
const documentsDictionary: Dictionary<DocumentsDictionaryProps> = {
  INSTAGRAM_CAPTION: {
    block: InstagramCaptionBlock,
    form: usePostAccordions,
    AdvencedSettings: SocialSharedSettings,
  },
  INSTAGRAM_REEL_IDEA: {
    block: InstagramReelIdeaBlock,
    form: useDefaultAccordions,
    AdvencedSettings: SocialSharedSettings,
  },
  INSTAGRAM_STORY_IDEA: {
    block: InstagramStoryIdeaBlock,
    form: useDefaultAccordions,
    AdvencedSettings: SocialSharedSettings,
  },
  INSTAGRAM_POST_IDEA: {
    block: InstagramPostIdeaBlock,
    form: useDefaultAccordions,
    AdvencedSettings: SocialSharedSettings,
  },
  INSTAGRAM_BIO_DESCRIPTION: {
    block: InstagramBioDescriptionBlock,
    form: useBioDescriptionAccordions,
    AdvencedSettings: BioSharedSettings,
  },
  FACEBOOK_POST_CAPTION: {
    block: FacebookPostCaptionBlock,
    form: usePostAccordions,
    AdvencedSettings: SocialSharedSettings,
  },
  FACEBOOK_POST_IDEA: {
    block: FacebookPostIdeaBlock,
    form: useDefaultAccordions,
    AdvencedSettings: SocialSharedSettings,
  },
  FACEBOOK_PAGE_ABOUT: {
    block: FacebookPageAboutBlock,
    form: useBioDescriptionAccordions,
    AdvencedSettings: BioSharedSettings,
  },
  LINKEDIN_POST_CAPTION: {
    block: LinkedInCaptionBlock,
    form: usePostAccordions,
    AdvencedSettings: SocialSharedSettings,
  },
  LINKEDIN_POST_IDEA: {
    block: LinkedInPostIdeaBlock,
    form: useDefaultAccordions,
    AdvencedSettings: SocialSharedSettings,
  },
  LINKEDIN_PAGE_ABOUT: {
    block: LinkedinPageAboutBlock,
    form: useBioDescriptionAccordions,
    AdvencedSettings: BioSharedSettings,
  },
  THREADS_TWIT: {
    block: ThreadsTwitBlock,
    form: useDefaultAccordions,
    columns: 2,
    AdvencedSettings: SocialSharedSettings,
  },
  EMAIL_CONTENT: {
    block: EmailContentBlock,
    form: useEmailAccordions,
    columns: 2,
  },
  CART_ABANDONMENT_EMAIL: {
    block: EmailContentBlock,
    form: useCartAbandonmentMailAccordions,
    columns: 2,
  },
  TRAFFIC_EMAIL: {
    block: EmailContentBlock,
    form: useTrafficEmailAccordions,
    columns: 2,
  },
  NEWSLETTER_EMAIL: {
    block: EmailContentBlock,
    form: useNewsLetterEmailAccordions,
    columns: 2,
  },
  PRODUCT_UPDATE_EMAIL: {
    block: EmailContentBlock,
    form: useProductUpdateEmailAccordions,
    columns: 2,
  },
  WELCOME_EMAIL: {
    block: EmailContentBlock,
    form: useWelcomeEmailAccordions,
    columns: 2,
  },
  PUSH_NOTIFICATION: {
    block: PushNotificationBlock,
    form: usePushNotificationAccordions,
  },
  SMS_CONTENT: {
    block: SmsContentBlock,
    form: useSmsAccordions,
    AdvencedSettings: SmsSharedSettings,
  },
  FLASH_SALE_SMS: {
    block: SmsContentBlock,
    form: useFlashSmsAccordions,
    AdvencedSettings: SmsSharedSettings,
  },
  NOTIFICATION_SMS: {
    block: SmsContentBlock,
    form: useNotificationSmsAccordions,
    AdvencedSettings: SmsSharedSettings,
  },

  TWITTER_TWIT: {
    block: XTwitBlock,
    form: useDefaultAccordions,
    columns: 2,
  },
  WEBSITE_FAQ: {
    block: WebsiteFaqBlock,
    form: useDefaultAccordions,
    columns: 2,
  },
  WEBSITE_ABOUT: {
    block: WebsiteAboutBlock,
    form: useDefaultAccordions,
    columns: 2,
  },
  WEBSITE_PRODUCT_DESCRIPTION: {
    block: WebsiteProductDescriptionBlock,
    form: useDefaultAccordions,
  },
  WEBSITE_TESTEMONIAL: {
    block: WebsiteTestmonialBlock,
    form: useDefaultAccordions,
  },
  GOOGLE_ADS_KEYWORDS: {
    block: GoogleAdsKeywordsBlock,
    form: useDefaultAccordions,
    columns: 2,
  },
  ARTICLE: {
    block: ArticleBlock,
    form: useDefaultAccordions,
    columns: 1,
    disableBaseForm: true,
    AdvencedSettings: BlogPostSharedSettings,
  },
  BLOG_POST_OPINION: {
    block: ArticleBlock,
    form: useBlogPostOpinionAccordions,
    columns: 1,
    AdvencedSettings: BlogPostSharedSettings,
  },
  BLOG_POST_TIPS: {
    block: ArticleBlock,
    form: useBlogPostTipsAccordions,
    columns: 1,
    AdvencedSettings: BlogPostSharedSettings,
  },
  BLOG_POST_GUIDE: {
    block: ArticleBlock,
    form: useBlogPostTipsAccordions,
    columns: 1,
    AdvencedSettings: BlogPostSharedSettings,
  },
  BLOG_POST_COMPARISON: {
    block: ArticleBlock,
    form: useBlogPostComparisonAccordions,
    columns: 1,
    AdvencedSettings: BlogPostSharedSettings,
  },
  BLOG_POST_CASE_STUDY: {
    block: ArticleBlock,
    form: useBlogPostCaseStudyAccordions,
    columns: 1,
    AdvencedSettings: BlogPostSharedSettings,
  },
  INSTAGRAM_SPONSORED_AD: {
    block: InstagramSponsoredAdBlock,
    form: useInstagramSponsoredAdAccordions,
  },
  LINKEDIN_SPONSORED_AD: {
    block: LinkedInSponsoredAdBlock,
    form: useInstagramSponsoredAdAccordions,
  },
  FACEBOOK_SPONSORED_AD: {
    block: FacebookSponsoredAdBlock,
    form: useInstagramSponsoredAdAccordions,
  },
  SEARCH_AD: {
    block: SearchAdBlock,
    form: useSearchAdAccordions,
    columns: 2,
  },
  GENERIC_CAMPAIGN: {
    block: MultiDocBlock,
    form: usePostAccordions,
    columns: 3,
    AdvencedSettings: SocialSharedSettings,
  },
};

export default documentsDictionary;
