import { ReactComponent as NewordTextLogo } from "../../assets/Icons/NewordTextLogo.svg";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const Title = styled.h1`
  color: var(--main-title-color, #0a2540);
  text-align: right;
  font-family: "Noto Sans Hebrew", sans-serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 3rem */
  letter-spacing: -0.12rem;
  text-transform: capitalize;
  /* margin: 60px 0; */
  margin-bottom: 50px;
  margin-top:30px;
  width: 37.625rem;
  & span {
    color: var(--main-purple, #a960ee);
  }
  @media only screen and (max-width: 1000px) {
    margin-bottom: 30px !important  ;
  margin-top:10px !important;
  }
`;

const LogoWrapper = styled.div`
  /* position: absolute;
  right: 50px;
  top: 30px; */

  svg {
    width: 120px;
    height: 30px;
  }

  @media only screen and (max-width: 1000px) {
    position: relative;
    /* right: auto;
    top: auto; */
  }
`;

const AuthHeader: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-column w-full">
      <LogoWrapper>
        <NewordTextLogo />
      </LogoWrapper>

      <Title>{t("auth.connect_prompt")}</Title>
    </div>
  );
};
export default AuthHeader;
