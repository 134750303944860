// ViewToggle.tsx
import React from "react";
import styled from "styled-components";
import Button from "../../common/form/Button";

interface ViewToggleProps {
  currentView: "month" | "week" | "day";
  setCurrentView: React.Dispatch<
    React.SetStateAction<"month" | "week" | "day">
  >;
}

const ViewButtons = styled.div`
  display: flex;
  background-color: var(--light-bg);
  border-radius: 8px;
  padding: 5px;
  gap: 5px;
`;

const ViewButton = styled(Button)<{ active?: boolean }>`
  background-color: ${(props) =>
    props.active ? "var(--purple-opacity) !important" : "transparent"};
  color: ${(props) => (props.active ? "var(--primary-purple)" : "inherit")};

  &:hover {
    background-color: var(--bg-dark);
  }
  height: max-content;
  padding: 8px 14px;
`;

const ViewToggle: React.FC<ViewToggleProps> = ({
  currentView,
  setCurrentView,
}) => {
  return (
    <ViewButtons>
      <ViewButton
        className="right"
        onClick={() => setCurrentView("month")}
        active={currentView === "month"}
      >
        חודש
      </ViewButton>
      <ViewButton
        className="left"
        onClick={() => setCurrentView("week")}
        active={currentView === "week"}
      >
        שבוע
      </ViewButton>
      {/* Uncomment if you want day view:
      <ViewButton
        onClick={() => setCurrentView("day")}
        active={currentView === "day"}
      >
        יום
      </ViewButton> */}
    </ViewButtons>
  );
};

export default ViewToggle;
