import styled from "styled-components";
import { FieldValues, Path } from "react-hook-form";
import RadioGroup from "../../common/form/RadioGroup";
import { ReactComponent as ToneDark } from "../../../assets/Icons/tone/ToneDark.svg";
import { Tooltip } from "primereact/tooltip";
import { ReactComponent as InfoIcon } from "../../../assets/Icons/Info.svg";
import { useTonePromptFactory } from "../factory/tonePromptFactory";
import { useRecoilValue } from "recoil";
import { toneState } from "../../../state/toneState";
import { useParams } from "react-router";
import { useMemo, useState } from "react";
import { ReactComponent as PlusIcon } from "../../../assets/Icons/PlusBorder.svg";
import CreateToneModal from "../../common/form/createToneModal";
import { useTranslation } from "react-i18next";
import { Tooltip as MUITooltip } from "@mui/material";

interface Props<T> {
  threeColumn?: boolean;
  fieldName: Path<T>;
}

const TooltipTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  svg {
    width: 20px;
    height: 20px;
  }
  span {
    font-weight: 600;
    color: var(--title-color);
  }
`;

const ToneWrapperEmoji = styled.div``;

const InsideRadio = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  width: 100%;

  .infoicon {
    width: 14px;
    height: 14px;
    right: -27px;
    top: 0;
    path {
      fill: #bdc7ca !important;
    }
  }
`;

const RadioWrapper = styled.div`
  .singleradio {
    background: white;
  }
  span {
    color: var(--title-color);
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const IconWrapper = styled.div`
  position: absolute;
  bottom: 12px;
  left: 17px;
  opacity: 0;
  transition-duration: 0.1s;
`;

const AudienceCard = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
  cursor: pointer;

  &.add-audience {
    border: 1px dashed #ddd;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      width: 20px;
      height: 20px;
    }
  }

  &:hover {
    border-color: var(--title-color);

    ${IconWrapper} {
      opacity: 1;
    }
  }
`;

const TooltipStyled = styled(Tooltip)``;

function DocumentTone<FormDataType extends FieldValues>(
  props: Props<FormDataType>
) {
  const { t } = useTranslation();
  const { websiteId } = useParams();
  const tonePromptFactory = useTonePromptFactory();
  const [createToneModal, showCreateToneModal] = useState(false);

  const tones = useRecoilValue(toneState(websiteId as string));

  const websiteTones = useMemo(
    () =>
      tones.map((tone) => {
        return {
          value: tone.summerizedToneText,
          render: (
            <>
              <InsideRadio className="insideradio">
                <ToneDark style={{ width: "1.5rem", height: "1.5rem" }} />
                <span>{tone.name}</span>

                {/* Only the icon is wrapped with the tooltip */}
                <MUITooltip
                  title={
                    <div className="p-4">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <span>{tone.summerizedToneText}</span>
                      </div>
                    </div>
                  }
                  placement="top"
                  arrow
                >
                  <InfoIcon className="infoicon" />
                </MUITooltip>
              </InsideRadio>
            </>
          ),
        };
      }),
    [tones]
  );

  return (
    <>
      <Wrapper>
        <RadioWrapper>
          <RadioGroup<FormDataType, string>
            ColumnNumber={"2"}
            fieldName={props.fieldName}
            options={[
              {
                // Marketing => 🛍️
                value: tonePromptFactory["MARKETING"],
                render: (
                  <>
                    <TooltipStyled target=".infoicon.marketing">
                      <div className="p-4">
                        <TooltipTitle>
                          <ToneWrapperEmoji>
                            <span>🛍️</span>
                          </ToneWrapperEmoji>
                          <span>
                            {t("document_tone.marketing.description")}
                          </span>
                        </TooltipTitle>
                        <small>{t("document_tone.marketing.example")}</small>
                      </div>
                    </TooltipStyled>
                    <InsideRadio className="insideradio">
                      <ToneWrapperEmoji>
                        <span>🛍️</span>
                      </ToneWrapperEmoji>
                      <span>{t("document_tone.marketing.title")}</span>
                      <InfoIcon
                        className="infoicon marketing"
                        data-pr-position="top"
                      />
                    </InsideRadio>
                  </>
                ),
              },
              {
                // Persuasive => 🤝
                value: tonePromptFactory["PERSUASIVE"],
                render: (
                  <>
                    <TooltipStyled target=".infoicon.persuasive">
                      <div className="p-4">
                        <TooltipTitle>
                          <ToneWrapperEmoji>
                            <span>🤝</span>
                          </ToneWrapperEmoji>
                          <span>
                            {t("document_tone.persuasive.description")}
                          </span>
                        </TooltipTitle>
                        <small>{t("document_tone.persuasive.example")}</small>
                      </div>
                    </TooltipStyled>
                    <InsideRadio className="insideradio">
                      <ToneWrapperEmoji>
                        <span>🤝</span>
                      </ToneWrapperEmoji>
                      <span>{t("document_tone.persuasive.title")}</span>
                      <InfoIcon
                        className="infoicon persuasive"
                        data-pr-position="top"
                      />
                    </InsideRadio>
                  </>
                ),
              },
              {
                // Informative => ℹ️
                value: tonePromptFactory["INFORMATIVE"],
                render: (
                  <>
                    <TooltipStyled target=".infoicon.informative">
                      <div className="p-4">
                        <TooltipTitle>
                          <ToneWrapperEmoji>
                            <span>ℹ️</span>
                          </ToneWrapperEmoji>
                          <span>
                            {t("document_tone.informative.description")}
                          </span>
                        </TooltipTitle>
                        <small>{t("document_tone.informative.example")}</small>
                      </div>
                    </TooltipStyled>
                    <InsideRadio className="insideradio">
                      <ToneWrapperEmoji>
                        <span>ℹ️</span>
                      </ToneWrapperEmoji>
                      <span>{t("document_tone.informative.title")}</span>
                      <InfoIcon
                        className="infoicon informative"
                        data-pr-position="top"
                      />
                    </InsideRadio>
                  </>
                ),
              },
              {
                // Friendly => 🤗
                value: tonePromptFactory["FRIENDLY"],
                render: (
                  <>
                    <TooltipStyled target=".infoicon.friendly">
                      <div className="p-4">
                        <TooltipTitle>
                          <ToneWrapperEmoji>
                            <span>🤗</span>
                          </ToneWrapperEmoji>
                          <span>{t("document_tone.friendly.description")}</span>
                        </TooltipTitle>
                        <small>{t("document_tone.friendly.example")}</small>
                      </div>
                    </TooltipStyled>
                    <InsideRadio className="insideradio">
                      <ToneWrapperEmoji>
                        <span>🤗</span>
                      </ToneWrapperEmoji>
                      <span>{t("document_tone.friendly.title")}</span>
                      <InfoIcon
                        className="infoicon friendly"
                        data-pr-position="top"
                      />
                    </InsideRadio>
                  </>
                ),
              },
              {
                // Trendy/Provocative => 🔥
                value: tonePromptFactory["TRENDY_PROVOCATIVE"],
                render: (
                  <>
                    <TooltipStyled target=".infoicon.trendy">
                      <div className="p-4">
                        <TooltipTitle>
                          <ToneWrapperEmoji>
                            <span>🔥</span>
                          </ToneWrapperEmoji>
                          <span>
                            {t("document_tone.trendy_provocative.description")}
                          </span>
                        </TooltipTitle>
                        <small>
                          {t("document_tone.trendy_provocative.example")}
                        </small>
                      </div>
                    </TooltipStyled>
                    <InsideRadio className="insideradio">
                      <ToneWrapperEmoji>
                        <span>🔥</span>
                      </ToneWrapperEmoji>
                      <span>{t("document_tone.trendy_provocative.title")}</span>
                      <InfoIcon
                        className="infoicon trendy"
                        data-pr-position="top"
                      />
                    </InsideRadio>
                  </>
                ),
              },
              {
                // Humorous => 🤣
                value: tonePromptFactory["HUMOROUS"],
                render: (
                  <>
                    <TooltipStyled target=".infoicon.humorous">
                      <div className="p-4">
                        <TooltipTitle>
                          <ToneWrapperEmoji>
                            <span>🤣</span>
                          </ToneWrapperEmoji>
                          <span>{t("document_tone.humorous.description")}</span>
                        </TooltipTitle>
                        <small>{t("document_tone.humorous.example")}</small>
                      </div>
                    </TooltipStyled>
                    <InsideRadio className="insideradio">
                      <ToneWrapperEmoji>
                        <span>🤣</span>
                      </ToneWrapperEmoji>
                      <span>{t("document_tone.humorous.title")}</span>
                      <InfoIcon
                        className="infoicon humorous"
                        data-pr-position="top"
                      />
                    </InsideRadio>
                  </>
                ),
              },
              {
                // Formal => 💼
                value: tonePromptFactory["FORMAL"],
                render: (
                  <>
                    <TooltipStyled target=".infoicon.formal">
                      <div className="p-4">
                        <TooltipTitle>
                          <ToneWrapperEmoji>
                            <span>💼</span>
                          </ToneWrapperEmoji>
                          <span>{t("document_tone.formal.description")}</span>
                        </TooltipTitle>
                        <small>{t("document_tone.formal.example")}</small>
                      </div>
                    </TooltipStyled>
                    <InsideRadio className="insideradio">
                      <ToneWrapperEmoji>
                        <span>💼</span>
                      </ToneWrapperEmoji>
                      <span>{t("document_tone.formal.title")}</span>
                      <InfoIcon
                        className="infoicon formal"
                        data-pr-position="top"
                      />
                    </InsideRadio>
                  </>
                ),
              },
              ...websiteTones,
            ]}
          />
          <div className="col-12">
            <AudienceCard
              className="p-2 add-audience"
              onClick={() => showCreateToneModal(true)}
            >
              <PlusIcon className="icon" />
              {t("document_tone.add_writing_style")}
            </AudienceCard>
          </div>
        </RadioWrapper>
      </Wrapper>

      {createToneModal && (
        <CreateToneModal onHide={() => showCreateToneModal(false)} />
      )}
    </>
  );
}

export default DocumentTone;
