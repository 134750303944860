import { ReactComponent as IgIcon } from "../../assets/Icons/InstagramIcon.svg";
import { ReactComponent as Facebook } from "../../assets/Icons/facebook/FacebookLogo.svg";
import { ReactComponent as Linkedin } from "../../assets/Icons/linkedin/LinkedInLogo.svg";
import { ReactComponent as Twitter } from "../../assets/Icons/XLogo.svg";
import { ReactComponent as Threads } from "../../assets/Icons/ThreadsLogo.svg";
import { ReactComponent as Email } from "../../assets/Icons/EmailLogo.svg";
import { ReactComponent as Sms } from "../../assets/Icons/SmsIcon.svg";
import { ReactComponent as WebsiteLogo } from "../../assets/Icons/WebsiteLogo.svg";
import { ReactComponent as Google } from "../../assets/Icons/ColoredGoogle.svg";
import { ReactComponent as FacebookFlat } from "../../assets/Icons/FacebookIconFlat.svg";
import { ReactComponent as EmailFlat } from "../../assets/Icons/EmailIconFlat.svg";
import { ReactComponent as LinkedinFlat } from "../../assets/Icons/LinkedinIconFlat.svg";
import { ReactComponent as IgIconPlain } from "../../assets/Icons/PlainSocialIcons/Instagram.svg";
import { ReactComponent as FacebookPlain } from "../../assets/Icons/PlainSocialIcons/Facebook.svg";
import { ReactComponent as TiktokLogo } from "../../assets/Icons/TiktokLogo.svg";
import { ReactComponent as ReelsIcon } from "../../assets/Icons/ReelsIcon.svg";
import { ReactComponent as NoteIcon } from "../../assets/Icons/Idea.svg";

import styled from "styled-components";
import { PostEntityType } from "neword-core";
import { ReactComponent as LinkedinPlain } from "../../assets/Icons/PlainSocialIcons/Linkedin.svg";

const GoogleStyled = styled(Google)`
  width: 20px;
  height: 20px;
`;

// Define a type for the icons and their corresponding colors
export type ContentCardIcon = {
  icon: React.ReactElement;
  color: string;
  flatIcon?: React.ComponentType<any>;
  iconPlain?: React.ComponentType<any>;
  socialPostingText?: string;
  name?: string;
  scheduleAble?: boolean;
  platform: string;
  disableAnalytics?: boolean;
};
export const PostCardIcons: Record<PostEntityType, ContentCardIcon> = {
  [PostEntityType.INSTAGRAM_POST]: {
    icon: <IgIcon />,
    color: "#e1306c",
    iconPlain: IgIconPlain,
    socialPostingText: "פרסם באינסטגרם",
    name: "אינסטגרם",
    scheduleAble: true,
    platform: "INSTAGRAM",
  },
  [PostEntityType.INSTAGRAM_REEL]: {
    icon: <ReelsIcon />,
    color: "#e1306c",
    iconPlain: ReelsIcon,
    socialPostingText: "פרסם רילס באינסטגרם",
    name: "רילס אינסטגרם",
    scheduleAble: true,
    platform: "INSTAGRAM",
  },
  [PostEntityType.FACEBOOK_POST]: {
    icon: <Facebook />,
    color: "#1877F2",
    flatIcon: FacebookFlat,
    iconPlain: FacebookPlain,
    socialPostingText: "פרסם בפייסבוק",
    name: "פייסבוק",
    scheduleAble: true,
    platform: "FACEBOOK",
  },
  [PostEntityType.FACEBOOK_REEL]: {
    icon: <ReelsIcon />,
    color: "#1877F2",
    flatIcon: ReelsIcon,
    iconPlain: ReelsIcon,
    socialPostingText: "פרסם רילס בפייסבוק",
    name: "רילס פייסבוק",
    scheduleAble: true,
    platform: "FACEBOOK",
  },
  [PostEntityType.TWITTER_TWIT]: {
    icon: <Twitter />,
    color: "#1DA1F2", // Twitter's official blue color
    scheduleAble: true,
    socialPostingText: "פרסם בטוויטר",
    name: "טוויטר",
    platform: "TWITTER",
    disableAnalytics: true,
  },
  [PostEntityType.LINKEDIN_POST]: {
    icon: <Linkedin />,
    color: "#0077B5", // LinkedIn's official blue color
    flatIcon: LinkedinFlat,
    iconPlain: LinkedinPlain,
    socialPostingText: "פרסם בלינקדאין",
    name: "לינקדאין",
    scheduleAble: true,
    platform: "LINKEDIN",
    disableAnalytics: true,
  },
  [PostEntityType.LINKEDIN_COMPANY_POST]: {
    icon: <Linkedin />,
    color: "#0077B5", // LinkedIn's official blue color
    flatIcon: LinkedinFlat,
    iconPlain: LinkedinPlain,
    socialPostingText: "פרסם בעמוד לינקדאין",
    name: "עמוד לינקדאין",
    scheduleAble: true,
    platform: "LINKEDIN_COMPANY",
    disableAnalytics: true,
  },
  [PostEntityType.TIKTOK_POST]: {
    icon: <TiktokLogo />,
    color: "#000000",
    iconPlain: TiktokLogo,
    socialPostingText: "פרסם בטיקטוק",
    name: "טיקטוק",
    scheduleAble: true,
    platform: "TIKTOK",
    disableAnalytics: true,
  },
  [PostEntityType.THREADS_TWIT]: {
    icon: <Threads />,
    color: "#000000", // Threads' official black color
    socialPostingText: "פרסם בThreads",
    name: "Threads",
    scheduleAble: false,
    platform: "THREADS",
    disableAnalytics: true,
  },
  [PostEntityType.EMAIL]: {
    icon: <Email />,
    color: "#D44638", // Gmail's official red color
    flatIcon: EmailFlat,
    socialPostingText: "שלח מייל",
    name: "אימייל",
    platform: "EMAIL",
    disableAnalytics: true,
  },
  [PostEntityType.SMS]: {
    icon: <Sms />,
    color: "#4CAF50", // SMS/Android Messages green color
    socialPostingText: "שלח SMS",
    name: "SMS",
    platform: "SMS",
    disableAnalytics: true,
  },
  [PostEntityType.WEBSITE]: {
    icon: <WebsiteLogo />,
    color: "#F7931E", // Generic web orange color
    socialPostingText: "פרסם באתר",
    name: "אתר",
    platform: "WEBSITE",
    disableAnalytics: true,
  },
  [PostEntityType.ARTICLE]: {
    icon: <WebsiteLogo />,
    color: "#F7931E",
    socialPostingText: "פרסם כתבה",
    name: "כתבה",
    platform: "ARTICLE",
    disableAnalytics: true,
  },
  [PostEntityType.INSTAGRAM_STORY]: {
    icon: <WebsiteLogo />,
    color: "#F7931E",
    socialPostingText: "פרסם סטורי",
    name: "סטורי",
    platform: "INSTAGRAM",
    disableAnalytics: true,
  },
  [PostEntityType.NOTE]: {
    icon: <NoteIcon />,
    color: "#4CAF50", // Green color for note
    iconPlain: NoteIcon,
    socialPostingText: "צור Note",
    name: "Note",
    scheduleAble: true,
    platform: "NOTE",
    disableAnalytics: true,
  },
} as const;
