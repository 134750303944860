import { PostEntityType } from "neword-core";
import InstagramPostForm from "./InstagramPostForm";
import FacebookPostForm from "./FacebookPostForm";
import LinkedinPostForm from "./LinkedinPostForm";
import TiktokPostForm from "./TiktokPostForm";
import InstagramReelPostForm from "./InstagramReelPostForm";
import FacebookReelForm from "./FacebookReelForm";
import InstagramStoryForm from "./InstagramStoryForm";
import NoteForm from "./NoteForm";

export const postTypeToForm: {
  [key in PostEntityType]: React.ElementType<{ name: string; index?: number }>;
} = {
  [PostEntityType.INSTAGRAM_POST]: InstagramPostForm, // Pass the component type, not JSX
  [PostEntityType.INSTAGRAM_STORY]: InstagramStoryForm, // Pass the component type, not JSX
  [PostEntityType.FACEBOOK_POST]: FacebookPostForm,
  [PostEntityType.FACEBOOK_REEL]: FacebookReelForm,
  [PostEntityType.LINKEDIN_POST]: LinkedinPostForm,
  [PostEntityType.TWITTER_TWIT]: FacebookPostForm,
  [PostEntityType.THREADS_TWIT]: FacebookPostForm,
  [PostEntityType.LINKEDIN_COMPANY_POST]: LinkedinPostForm,
  [PostEntityType.TIKTOK_POST]: TiktokPostForm,
  [PostEntityType.INSTAGRAM_REEL]: InstagramReelPostForm, // Pass the component type, not JSX
  [PostEntityType.EMAIL]: FacebookPostForm,
  [PostEntityType.SMS]: FacebookPostForm,
  [PostEntityType.ARTICLE]: FacebookPostForm,
  [PostEntityType.WEBSITE]: FacebookPostForm,
  [PostEntityType.NOTE]: NoteForm,
};
