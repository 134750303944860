import { InputText } from "primereact/inputtext";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import userService from "../core/services/user.service";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useSetRecoilState,
} from "recoil";
import { userState } from "../state/userState";
import styled from "styled-components";
import { SubmitHandler, useForm } from "react-hook-form";
import { Message } from "primereact/message";
import FormStyle from "../components/common/form/FormStyle";
import Button from "../components/common/form/Button";
import { ReactComponent as Logo } from "../assets/Logo/ColoredLogo.svg";
import Link from "../components/common/Link";
import { CompleteSignupRequestData } from "../core/services/requests/signup/completeSignupRequestData";
import { ValidatePhoneCodeRequest } from "../core/services/requests/signup/validatePhoneCodeRequest";
import ErrorMessage from "../components/common/ErrorMessage";
import { toast } from "react-toastify";
import AuthHeader from "../components/auth/AuthHeader";
import MinimalTopMenu from "../components/website/createWebsite/MinimalTopMenu";
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import bgCircle from "../../../assets/images/bgcircle.png";
import { useTranslation } from "react-i18next";

const LoginPageWrapper = styled.div`
  height: calc(100vh - 75px);
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const CenteredForm = styled(FormStyle)`
  margin-top: 60px;
  padding: 0px 200px;
  display: flex;
  flex-direction: column;
  place-content: center;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  align-items: center;
`;

const SubTitle = styled.h2`
  margin-top: 20px;
  color: #425466;
  text-align: right;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 1.25rem */
  letter-spacing: -0.0375rem;
  text-align: center;
`;

const Title = styled.h1`
  color: #0a2540;
  font-size: 2.5rem;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 2.5rem */
  letter-spacing: -0.075rem;
`;

const CustomInputMask = styled(InputText)`
  font-size: 32px !important;
  text-align: center;
  width: 300px !important;
  height: 100px !important;
  &::placeholder {
    font-size: 32px !important;
  }
`;

const VerifyEmailPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ValidatePhoneCodeRequest>();
  const [user, setUserState] = useRecoilState(userState);
  const refreshUserSelectorState = useRecoilRefresher_UNSTABLE(userState);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoadingResend, setIsLoadingResend] = useState(false);

  const onSubmit: SubmitHandler<ValidatePhoneCodeRequest> = async (data) => {
    try {
      setIsLoading(true);
      const res = await userService.verifyCode(data);
      user &&
        setUserState({
          ...user,
          isActive: true,
        });

      refreshUserSelectorState();

      navigate("/websites/new");
    } catch (err) {
      console.log(err);
      setError("root", {});
    } finally {
      setIsLoading(false);
    }
  };

  //   useEffect(() => {
  //     const supportModal = (window as any).Beacon as any;
  //     supportModal("show-message", "7bac219c-d0d8-48c7-ac14-3fee464b97cc");
  //   }, []);

  const resendCode = useCallback(async () => {
    setIsLoadingResend(true);
    try {
      await userService.resendEmailVerificationCode();
    } catch (err) {
    } finally {
      setIsLoadingResend(false);
      toast(t("email_verification.toast_code_resent"));
    }
  }, []);

  return (
    <>
      <MinimalTopMenu />
      <LoginPageWrapper>
        <CenteredForm autoComplete="off">
          <Title>{t("email_verification.title")}</Title>
          <SubTitle>{t("email_verification.subtitle")}</SubTitle>
          <div className="flex flex-column mt-5">
            <div className="flex flex-column align-items-center">
              <Message
                className="mb-4"
                severity="info"
                text={t("email_verification.message", {
                  email: user?.email.toLowerCase(),
                })}
              />

              {/* <CustomInputMask className="custom_numbers" value={value} onChange={(e) => setValue("5")} mask="******" placeholder="123456"/> */}

              <CustomInputMask
                {...register("code", {
                  required: true,
                  maxLength: {
                    value: 6,
                    message: t("email_verification.code_error_length"),
                  },
                  minLength: {
                    value: 6,
                    message: t("email_verification.code_error_length"),
                  },
                })}
                placeholder="******"
                className={errors.code ? "p-invalid" : ""}
              />
              <ErrorMessage>{errors.code?.message}</ErrorMessage>
              <Button
                loading={isLoadingResend}
                className="mb-5"
                onClick={resendCode}
              >
                {t("email_verification.resend_button")}
              </Button>
            </div>
          </div>

          <Button
            className="mb-4"
            onClick={handleSubmit(onSubmit)}
            loading={isLoading}
            bgColor="purple"
            primary
            arrowPlacement="right"
          >
            {t("email_verification.submit_button")}
          </Button>
        </CenteredForm>
      </LoginPageWrapper>
    </>
  );
};

export default VerifyEmailPage;
