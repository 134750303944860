import React, { useEffect, useState } from "react";
import Route from "../../core/types/route";
import Protected from "../../middleware/protected";
import Preloader from "../common/Preloader";
import PrivateLayout from "./PrivateLayout";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { globalLoadingState } from "../../state/globalLoadingState";
import IsMobile from "./IsMobile";

const PageWrapper: React.FC<{ Route: Route }> = ({ Route }) => {
  const { t } = useTranslation();
  const isLoading = useRecoilValue(globalLoadingState);

  useEffect(() => {
    const pageTitle =
      (t(Route.displayName || "") || t("page_wrapper.default_title")) +
      t("page_wrapper.suffix");
    document.title = pageTitle;
  }, [Route.displayName]);

  if (Route.isProtected) {
    return (
      <Protected neededRole={Route.role}>
        {isLoading && <Preloader />}
        {Route.hideGlobalLayout ? (
          <React.Suspense fallback={<Preloader />}>{Route.Page}</React.Suspense>
        ) : (
          <PrivateLayout
            hideSideBar={Route.hideLeftSideBar}
            subMenu={Route.subMenu}
          >
            {Route.Page}
          </PrivateLayout>
        )}
      </Protected>
    );
  } else
    return (
      <React.Suspense fallback={<Preloader />}>{Route.Page}</React.Suspense>
    );
};

export default PageWrapper;
