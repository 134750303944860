import styled from "styled-components";
import { ReactComponent as LogoIcon } from "../assets/Logo/ColoredLogo.svg";
import { useParams } from "react-router";
import { PaymentStatusType } from "../core/types/paymentStatusType";
import AppConfig from "../config/appConfig";
import Button from "../components/common/form/Button";
import { useTranslation } from "react-i18next";
import Confetti from "react-confetti";
import { useEffect, useState } from "react";
import { Theme } from "../core/theme/theme";

// Layout
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  min-height: 100vh;
  background-color: #f9f9f9;
`;

const Card = styled.div<{ status: PaymentStatusType }>`
  background: white;
  border-radius: 20px;
  padding: 40px;
  max-width: 600px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  border:solid 1px ${({ status }) =>
      status === PaymentStatusType.SUCCESS
        ? Theme.colors.success
        : status === PaymentStatusType.PENDING
        ? Theme.colors.yellowOpacity
        : Theme.colors.danger};
  border-bottom: 6px solid
    ${({ status }) =>
     status === PaymentStatusType.SUCCESS
        ? Theme.colors.success
        : status === PaymentStatusType.PENDING
        ? Theme.colors.yellowOpacity
        : Theme.colors.danger};

  @media (max-width: 600px) {
    padding: 30px 20px;
  }
`;

const StyledLogo = styled(LogoIcon)`
  height: 40px;
  width: auto;
  margin-bottom: 20px;
`;

const StatusIcon = styled.div<{ status: PaymentStatusType }>`
  font-size: 48px;
  margin-bottom: 16px;
  color: ${({ status }) =>
    status === PaymentStatusType.SUCCESS
      ? "#4BB543"
      : status === PaymentStatusType.PENDING
      ? "#FFD700"
      : "#FF4C4C"};
`;

const Title = styled.h1`
  font-size: 28px;
  margin-bottom: 15px;
  color: #333;
`;

const Message = styled.p`
  font-size: 18px;
  color: #555;
  margin-bottom: 25px;
  line-height: 1.6;
`;

const SummaryBox = styled.div`
  background: #f2f2f2;
  border-radius: 10px;
  padding: 15px 20px;
  text-align: left;
  margin-bottom: 25px;
  font-size: 16px;
`;

const FooterLinks = styled.div`
  margin-top: 30px;
  font-size: 14px;
  a {
    color: #555;
    margin: 0 10px;
    text-decoration: none;
  }
`;

const AfterPaymentPage: React.FC = () => {
  const { status } = useParams();
  const { t } = useTranslation();
  const [showConfetti, setShowConfetti] = useState(false);

  const currentStatus = (status as PaymentStatusType) || PaymentStatusType.FAILED;
  const textObj = messagePerStatusFactory[currentStatus];

  const handleContinue = () => {
    window.location.href = AppConfig.appUrl;
  };

  const handleRetry = () => {
    window.location.href = "/pricing"; // or retry endpoint
  };

  useEffect(() => {
    if (currentStatus === PaymentStatusType.SUCCESS) {
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 7000);
    }
  }, [currentStatus]);

  return (
    <PageWrapper>
      {showConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} />}
      <Card status={currentStatus}>
      <StatusIcon status={currentStatus}>
          {currentStatus === PaymentStatusType.SUCCESS
            ? "✅"
            : currentStatus === PaymentStatusType.PENDING
            ? "⏳"
            : "❌"}
        </StatusIcon>
        <StyledLogo />
       
        <Title>{t(textObj.title)}</Title>
        <Message>{t(textObj.message)}</Message>

        {/* Example Order Summary */}
        {/* <SummaryBox>
          <p><strong>Plan:</strong> Premium Monthly</p>
          <p><strong>Email:</strong> user@example.com</p>
          <p><strong>Total:</strong> $19.99</p>
        </SummaryBox> */}

        {/* Next Steps */}
        {currentStatus === PaymentStatusType.SUCCESS && (
          <>
            <p>כעת תוכל להתחיל להשתמש במוצר עם כל האפשרויות פתוחות! 🚀</p>
            <Button className="mt-4" primary onClick={handleContinue}>מעבר למערכת</Button>
          </>
        )}

        {currentStatus === PaymentStatusType.PENDING && (
          <>
            <p>We’re currently verifying your payment. You’ll get an email update soon.</p>
            <p>Need help? <a href="mailto:support@example.com">Contact Support</a></p>
          </>
        )}

        {currentStatus === PaymentStatusType.FAILED && (
          <>
            <p>Something went wrong with your payment. You can try again or reach out.</p>
            <Button primary onClick={handleRetry}>Retry Payment</Button>
          </>
        )}

        {/* <FooterLinks>
          <a href="/">ארגונית תוכן</a>
          <a href="/">תבניות קופי</a>
        </FooterLinks> */}
      </Card>
    </PageWrapper>
  );
};

export default AfterPaymentPage;

// Localized messages
const messagePerStatusFactory: Record<PaymentStatusType, { message: string; title: string }> = {
  [PaymentStatusType.SUCCESS]: {
    title: "payment_status_messages.success.title",
    message: "payment_status_messages.success.message",
  },
  [PaymentStatusType.PENDING]: {
    title: "payment_status_messages.pending.title",
    message: "payment_status_messages.pending.message",
  },
  [PaymentStatusType.FAILED]: {
    title: "payment_status_messages.failed.title",
    message: "payment_status_messages.failed.message",
  },
};
