// Column.tsx
import React from "react";
import styled from "styled-components";
import TaskCard from "./TaskCard";
import { useDrop } from "react-dnd";
import { Theme } from "../../core/theme/theme";
import { Task } from "neword-core";

const StatusCircle = styled.div<{ status: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 8px;
  background-color: ${({ status }) => {
    switch (status) {
      case "backlog":
        return Theme.colors.border; // Gray for draft
      case "inprogress":
        return Theme.colors.yellowOpacity; // Yellow for pending
      case "review":
        return Theme.colors.lightBlueOpacity; // Blue for waiting for approval
      case "done":
        return Theme.colors.success; // Green for completed
      default:
        return Theme.colors.text;
    }
  }};
`;

const ColumnContainer = styled.div`
  border-radius: 4px;
  min-width: 25%;
  max-width: 25%;
  /* margin-right: 1rem; */
  display: flex;
  flex-direction: column;
`;

const ColumnHeader = styled.h2`
  padding: 0.75rem;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
`;

const TasksWrapper = styled.div`
  padding: 0.5rem;
  flex-grow: 1;
  min-height: 100px;
`;

const AddTaskButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: #0052cc;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin: 0.75rem;
`;

interface ColumnProps {
  columnId: string;
  title: string;
  tasks: Task[];
  onAddTask?: () => void;
  onTaskClick?: (task: Task) => void;
  moveTask: any;
}

const Column: React.FC<ColumnProps> = ({
  columnId,
  title,
  tasks,
  onAddTask,
  onTaskClick,
  moveTask,
}) => {
  /**
   * The drop target logic:
   * - We accept items of type 'TASK'.
   * - `hover` or `drop` can calculate where in the column to insert the task.
   *   (In this simplified example, we just drop at the end.)
   */
  const [{ isOver }, dropRef] = useDrop<
    { taskId: string; fromColumnId: string; index: number },
    void,
    { isOver: boolean }
  >({
    accept: "TASK",
    drop: (item, monitor) => {
      if (!monitor.didDrop()) {
        // Only allow dropping if the item is from a different column
        if (item.fromColumnId !== columnId) {
          // Drop the task at the end of this column
          moveTask(item.taskId, item.fromColumnId, columnId, item.index);
        }
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
    // Only allow dropping if the item is from a different column
    canDrop: (item) => item.fromColumnId !== columnId,
  });

  return (
    <ColumnContainer>
      <ColumnHeader>
        <StatusCircle status={columnId} />
        {title}
      </ColumnHeader>
      <TasksWrapper
        ref={dropRef}
        style={{
          backgroundColor: isOver ? "#f0f5ff" : "inherit",
          transition: "background-color 0.2s ease",
          padding: isOver ? "8px" : "0.5rem",
          border: isOver ? "1px dashed #3880ff" : "none",
          borderRadius: "4px",
        }}
      >
        {tasks.map((task, index) => (
          <TaskCard
            key={task.id}
            task={task}
            onClick={onTaskClick}
            columnId={columnId}
            index={index}
            moveTask={moveTask}
          />
        ))}
        {isOver && tasks.length === 0 && (
          <div
            style={{
              border: "2px dashed #3880ff",
              borderRadius: "4px",
              padding: "1rem",
              color: "#3880ff",
              textAlign: "center",
              fontWeight: 500,
              backgroundColor: "rgba(56, 128, 255, 0.05)",
            }}
          >
            תניח כאן
          </div>
        )}
      </TasksWrapper>
      {/* <AddTaskButton onClick={onAddTask}>+ Add Task</AddTaskButton> */}
    </ColumnContainer>
  );
};

export default Column;
