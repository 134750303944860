import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import styled from "styled-components";
import ModalHeader from "../ModalHeader";
import { Message } from "primereact/message";
import Button from "../../common/form/Button";
import { Skeleton } from "primereact/skeleton";
import {
  FieldValues,
  Path,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import SelectKeywordsInput from "../../keywords/SelectKeywordsInput";

interface Props {
  onHide: () => void;
  name: Path<FieldValues>;
}

const Wrapper = styled.div`
  background: var(--light-bg);
  padding: 10px;
  position: relative; /* So the dropdown can be absolutely positioned relative to this container */

  .p-inputtext {
    width: 100%;
    padding: 10px 5px;
  }

  .dropdown {
    position: absolute;
    top: 60px; /* adjust as needed */
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 999;

    .dropdown-item {
      padding: 8px 12px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5;
      }

      img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        margin-right: 8px;
      }
    }
  }
`;

const CollabPeopleModal: React.FC<Props> = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);

    // Show the dropdown if there's any input
    if (value.trim().length > 0) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const handleDropdownClick = () => {
    // For now, just log the chosen "username"
    console.log("Chosen username:", inputValue);
    // Hide dropdown if you want:
    setShowDropdown(false);
  };

  return (
    <Dialog
      style={{
        width: "500px",
      }}
      closable={false}
      modal
      visible
      header={<ModalHeader OnClose={props.onHide} />}
      onHide={props.onHide}
    >
      <Wrapper>
        <div className="mb-2">הוספת שיתוף פעולה:</div>
        <div className="flex gap-10 align-items-center">
          <SelectKeywordsInput<any>
            showAddButton={true}
            blackBadge
            fieldName={`${props.name}.collaborators`}
            placeholder="הזן את המשתמש ולחץ Enter"
          />
        </div>
        <small>על מנת להוסיף משתמש יש להלקיד את שם המשתמש וללחוץ Enter</small>

        {/* Mimic a dropdown. Shows whatever the user typed + placeholder image */}
        {showDropdown && (
          <div className="dropdown">
            <div className="dropdown-item gap-10" onClick={handleDropdownClick}>
              <Skeleton shape="circle" size="3rem" className="mr-2"></Skeleton>
              <span>{inputValue}</span>
            </div>
          </div>
        )}
      </Wrapper>

      <Message
        className="mt-5 mb-5"
        severity="info"
        text="על מנת להוסיף שיתוף פעולה יש לרשום את שם המשתמש המלא "
      />
      <Button primary bgColor="purple" onClick={() => console.log(inputValue)}>
        הוספת תיוג
      </Button>
    </Dialog>
  );
};

export default CollabPeopleModal;
