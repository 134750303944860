import React, { useState } from 'react';
import { Container, Input, Button, List } from './styles';
import TodoItem from './TodoItem';
import InputStyle from '../common/form/InputStyle';
import {useTranslation} from "react-i18next";

interface Todo {
  id: number;
  text: string;
  completed: boolean;
}

const TodoList: React.FC = () => {
  const [todos, setTodos] = useState<Todo[]>([]);
  const [input, setInput] = useState<string>('');
  const { t } = useTranslation();

  const addTodo = () => {
    if (!input.trim()) return;
    const newTodo: Todo = {
      id: Date.now(),
      text: input,
      completed: false,
    };
    setTodos([...todos, newTodo]);
    setInput('');
  };

  const toggleComplete = (id: number) => {
    setTodos(
      todos.map((todo) =>
        todo.id === id ? { ...todo, completed: !todo.completed } : todo
      )
    );
  };

  const deleteTodo = (id: number) => {
    setTodos(todos.filter((todo) => todo.id !== id));
  };

  return (
    <Container>
      <List>
        {todos.map((todo) => (
          <TodoItem
            key={todo.id}
            todo={todo}
            toggleComplete={toggleComplete}
            deleteTodo={deleteTodo}
          />
        ))}
      </List>
      <div className="flex">
        <InputStyle>
          <Input
            type="text"
            placeholder={t("common.add_new_task")}
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
        </InputStyle>
        <Button onClick={addTodo}>{t("common.add")}</Button>
      </div>
    </Container>
  );
};

export default TodoList;
