import React, {
  RefAttributes,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { ReactComponent as InstagramEdit } from "../../../assets/Icons/InstagramEdit.svg";
import { ReactComponent as SendIcon } from "../../../assets/Icons/Send.svg";
import { ReactComponent as CopyIcon } from "../../../assets/Icons/Copy.svg";
import { ReactComponent as DeletIcon } from "../../../assets/Icons/InstagramDelet.svg";
import { ReactComponent as RocketIcon } from "../../../assets/Icons/Rocket.svg";
import Button from "../../common/form/Button";
import { documentState } from "../../../state/documentState";
import { RefProps } from "../blocks/types/refProps";
import documentsDictionary from "../documentsDictionary";
import Card from "../../common/Card";
import { Tooltip } from "primereact/tooltip";
import PublishPostModal from "../../post/PublishPostModal";
import { convertDocToPostParams } from "../../post/convertDocToPostParams";
import { UseFieldArrayUpdate, useFormContext, useWatch } from "react-hook-form";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import RewriteModal from "../modal/RewriteModal";
import useClickOutside from "../hooks/useClickOutside";
import Preloader from "../../common/Preloader";
import { attachmentsState } from "../../../state/attachmentsState";
import {useTranslation} from "react-i18next";

const Wrapper = styled(Card)`
  padding: 0;
  position: relative;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid var(--Input-BorderColor, #e6e6e6);
  padding: 10px 10px;

  .send path {
    fill: var(--primary-purple);
  }
`;

const IconButton = styled.button`
  &.til svg {
    stroke: black;
    stroke-width: 6px;
  }
  cursor: pointer;

  background: white;
  color: white;
  align-items: center;
  border-radius: 8px;
  border: none;
  justify-content: center;
  height: 35px;
  width: 35px;
  svg {
    margin-top: 2px;
    width: 15px;
    height: 15px;
  }
  &:hover {
    svg path {
      fill: var(--primary-purple);
    }
    svg {
      fill: var(--primary-purple);
    }
    background: var(--light-bg);
    /* border: 1px solid var(--title-color, #e6e6e6); */
  }
`;

const IconWrapper = styled.div`
  z-index: 99;
  border-radius: 4px;
  margin-right: auto;
  background: #ffffffac;
  width: 25px;
  height: 15px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  svg {
    width: 15px;
    height: 15px;
  }
`;

export const BlockWrapper: React.FC<{
  index: number;
  onDelet: (index: number) => void;
  update: UseFieldArrayUpdate<DocumentEntity>;
}> = ({ index, onDelet, update }) => {
  const { websiteId } = useParams<{
    documentId: string;
    websiteId: string;
  }>();
  const { t } = useTranslation();

  const { getValues } = useFormContext<DocumentEntity>();
  const doc = getValues();

  const attachments = useRecoilValue(attachmentsState(websiteId as string));
  const childRef = useRef<RefProps | null>(null);

  const [editState, setEditState] = useState<boolean>(false);

  const [showPublishPostModal, setShowPublishPostModal] = useState(false);

  const [showRewriteModal, setShowRewriteModal] = useState(false);

  const blockType = useMemo(() => {
    if (doc.type === DocumentEntityType.GENERIC_CAMPAIGN && doc.output[index]) {
      return doc.output[index].type;
    }
    return doc.type;
  }, [doc.output, doc.type, index]);

  const BlockComponent = useMemo(
    () => documentsDictionary[blockType].block,
    [blockType]
  );

  const { control } = useFormContext<DocumentEntity>();

  const block = useWatch({ control, name: `output.${index}` });

  const onEdit = useCallback(() => {
    setEditState((prevState) => !prevState);
  }, [editState]);

  const onCopy = (copyText: string) => {
    navigator.clipboard.writeText(copyText);
  };

  const handleBlur = (blockIndex: number) => {
    setEditState(false);
  };

  const postParams = useMemo(() => {
    return convertDocToPostParams(doc, index, attachments);
  }, [block]);

  const wrapperRef = useClickOutside({ handleBlur, blockIndex: index });

  return (
    <div ref={wrapperRef}>
      <Wrapper>
        <BlockComponent
          isEditing={!!editState}
          handleBlur={handleBlur}
          blockIndex={index}
          ref={childRef}
        />
        <ButtonsWrapper>
          {/* <IconWrapper>
          <InstagramLogo />
        </IconWrapper> */}
          <Button
            onClick={() => setShowPublishPostModal(true)}
            primary={false}
            textColor="purple"
            icon={<SendIcon className="send" />}
            className="p-0 pr-3"
          >
            {t("block_wrapper.publish_post")}
          </Button>

          <span className="mr-2 mb-1">|</span>
          <div>
            <Tooltip target=".deleteicon" />

            <IconButton
              data-pr-tooltip={t("block_wrapper.delete_block")}
              data-pr-position="top"
              type="button"
              className="deleteicon"
              onClick={() => onDelet(index)}
            >
              <DeletIcon />
            </IconButton>
            <Tooltip target=".copyicon" />
            <IconButton
              data-pr-tooltip={t("block_wrapper.copy_text")}
              data-pr-position="top"
              className="mr-1 copyicon"
              type="button"
              onClick={() => {
                if (childRef.current?.onCopyCustom) {
                  const text = childRef.current.onCopyCustom();
                  onCopy(text);
                }
              }}
            >
              <CopyIcon />
            </IconButton>
            <Tooltip target=".editicon" />

            <IconButton
              data-pr-tooltip={t("block_wrapper.edit_text")}
              data-pr-position="top"
              disabled={editState}
              onClick={() => onEdit()}
              className="mr-1 editicon"
              type="button"
            >
              <InstagramEdit />
            </IconButton>
            <Tooltip target=".til" />
            <IconButton
              data-pr-tooltip={t("block_wrapper.upgrade_text")}
              data-pr-position="top"
              className="mr-1 til"
              type="button"
              onClick={() => {
                setShowRewriteModal(true);
              }}
            >
              <RocketIcon />
            </IconButton>
          </div>
        </ButtonsWrapper>
      </Wrapper>
      {showPublishPostModal && (
        <React.Suspense fallback={<Preloader />}>
          <PublishPostModal
            postEntityType={postParams.type}
            postDefaults={postParams}
            onHide={() => setShowPublishPostModal(false)}
            scheduleDate={
              doc.defaultPublishDate && new Date(doc.defaultPublishDate)
            }
          />
        </React.Suspense>
      )}
      {showRewriteModal && (
        <RewriteModal
          update={update}
          index={index}
          onHide={() => setShowRewriteModal(false)}
        />
      )}
    </div>
  );
};
