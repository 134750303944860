import { DocumentToneType } from "neword-core";
import { useTranslation } from "react-i18next";

export const useTonePromptFactory = () => {
  const { t } = useTranslation();

  return {
    MARKETING: t("tone_prompt_factory.marketing"),
    PERSUASIVE: t("tone_prompt_factory.persuasive"),
    INFORMATIVE: t("tone_prompt_factory.informative"),
    FRIENDLY: t("tone_prompt_factory.friendly"),
    INSPIRED: t("tone_prompt_factory.inspired"),
    HUMOROUS: t("tone_prompt_factory.humorous"),
    TRENDY_PROVOCATIVE: t("tone_prompt_factory.trendy_provocative"),
    MYSTERIOUS: t("tone_prompt_factory.mysterious"),
    FORMAL: t("tone_prompt_factory.formal"),
  } as Record<DocumentToneType, string>;
};
