import { Audience } from "../entities/audience";
import { WebsiteTone } from "../entities/tone";
import { Writer } from "../entities/writer";
import { ServiceBase } from "./service.base";

class WrtierService extends ServiceBase {
  prefix = "/writer";

  getWriterByWebsiteId(websiteId: string) {
    return this.get<Writer>(`/${websiteId}`);
  }

  updateWriter(writerId: string, data: Partial<Writer>) {
    return this.patch<Writer>(`/${writerId}`, data);
  }

  getTonesByWebsiteId(websiteId: string) {
    return this.get<WebsiteTone[]>(`/${websiteId}/tone`);
  }

  createTone(
    data: Omit<
      WebsiteTone,
      "websiteId" | "id" | "companyId" | "summerizedToneText"
    >,
    websiteId: string
  ) {
    return this.post<WebsiteTone>(`/${websiteId}/tone`, data);
  }

  updateTone(toneId: string, data: Partial<WebsiteTone>, websiteId: string) {
    return this.patch<WebsiteTone>(`/${websiteId}/tone/${toneId}`, data);
  }

  getAudiencesByWebsiteId(websiteId: string) {
    return this.get<Audience[]>(`/${websiteId}/audiences`);
  }

  createAudience(
    data: Omit<Audience, "id" | "websiteId" | "companyId">,
    websiteId: string
  ) {
    return this.post<Audience>(`/${websiteId}/audience`, data);
  }

  updateAudience(
    audienceId: string,
    data: Partial<Audience>,
    websiteId: string
  ) {
    return this.patch<Audience>(`/${websiteId}/audience/${audienceId}`, data);
  }

  deleteAudience(audienceId: string, websiteId: string) {
    return this.delete<void>(`/${websiteId}/audience/${audienceId}`);
  }
}
const writerService = new WrtierService();
export default writerService;
