import React from "react";
import styled from "styled-components";

interface StepCardProps {
  title?: string;
  description: string;
  icon?: React.ReactNode;
}

export const StepCard: React.FC<StepCardProps> = ({ title, description, icon }) => {
  return (
    <CardContainer>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {title && <Title>{title}</Title>}
      <Description>{description}</Description>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  padding: 16px;
  max-width: 320px;
  font-family: "Assistant", sans-serif;
`;

const Title = styled.h3`
  margin: 0 0 8px 0;
  font-size: 18px;
  color: #1f2937; /* neutral-800 */
`;

const Description = styled.p`
  margin: 0;
  font-size: 15px;
  color: #4b5563; /* neutral-600 */
`;

const IconWrapper = styled.div`
  font-size: 20px;
  margin-bottom: 8px;
`;
