import React from "react";
import styled from "styled-components";
import { Editor } from "@tinymce/tinymce-react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import {useTranslation} from "react-i18next";

const EditorContainer = styled.div`
  height: calc(100vh - 200px);
  overflow: auto;
`;

const rtlStyles = `
  .tox-tbtn {
    direction: rtl;
    text-align: right;
  }
  .tox-toolbar {
    direction: rtl;
    text-align: right;
  }
  .tox-toolbar__primary {
    direction: rtl;
    text-align: right;
  }
  .tox-toolbar__primary .tox-toolbar__group {
    direction: rtl;
    text-align: right;
  }
  .tox-toolbar__primary .tox-toolbar__group .tox-tbtn {
    margin-left: 0 !important;
    margin-right: 5px !important; /* Adjust spacing for RTL */
  }

  
  /* Adjust any additional toolbar styling if needed */
`;

interface Props {
  blockIndex: number;
}

function ArticleBlockEditor({ blockIndex }: Props) {
  const { getValues, setValue } = useFormContext<
    DocumentEntity & { type: DocumentEntityType.ARTICLE }
  >();
  const { t } = useTranslation();
  const editorRef = React.useRef<any>();
  const selectedElement = React.useRef<Element>();
  const value = getValues(`output.${blockIndex}.blogHtml`);
  const { articleId } = useParams();

  const handler = (e: any) => {
    selectedElement.current?.append(e.detail);
  };

  const endStreamHandler = React.useCallback(() => {
    if (selectedElement.current?.textContent) {
      setValue(`output.${blockIndex}.blogHtml`, editorRef.current.getContent());
    }
  }, [setValue, blockIndex]);

  React.useEffect(() => {
    document.removeEventListener("stream", handler);
    document.addEventListener("stream", handler);
    document.removeEventListener("end-stream", endStreamHandler);
    document.addEventListener("end-stream", endStreamHandler);
  }, [endStreamHandler]);

  const refraseWidget = () => {
    // Implementation for regenerating widget if needed
  };

  const toolbarOptions = {
    undo: t("article_editor.editor.toolbar.undo"),
    redo: t("article_editor.editor.toolbar.redo"),
    bold: t("article_editor.editor.toolbar.bold"),
    blocks: t("article_editor.editor.toolbar.blocks"),
    italic: t("article_editor.editor.toolbar.italic"),
    forecolor: t("article_editor.editor.toolbar.forecolor"),
    alignLeft: t("article_editor.editor.toolbar.align_left"),
    alignCenter: t("article_editor.editor.toolbar.align_center"),
    alignRight: t("article_editor.editor.toolbar.align_right"),
    alignjustify: t("article_editor.editor.toolbar.align_justify"),
    bullist: t("article_editor.editor.toolbar.bullet_list"),
    numlist: t("article_editor.editor.toolbar.number_list"),
    outdent: t("article_editor.editor.toolbar.outdent"),
    indent: t("article_editor.editor.toolbar.indent"),
    removeformat: t("article_editor.editor.toolbar.remove_format"),
  };

  return (
    <EditorContainer className="overflow-auto">
      <style>{rtlStyles}</style>
      <Editor
        apiKey="c6b2eufyb1kx5rbc8jt19dx2gzf74hsovsd0nfpr6kfwdujz"
        onInit={(evt, editor) => {
          editorRef.current = editor as any;

          editor.ui.registry.addMenuItem("paragraph", {
            icon: "text",
            text: "שכתוב טקסט מחדש",
            onAction: function () {
              refraseWidget();
            },
          });

          editor.ui.registry.addMenuItem("label", {
            icon: "text",
            text: "Regenerate title",
            onAction: function () {
              refraseWidget();
            },
          });

          editor.ui.registry.addContextMenu("test", {
            update: function (element: Element) {
              selectedElement.current = element;
              console.log(element.tagName);
              if (
                element.tagName === "H1" ||
                element.tagName === "H2" ||
                element.tagName === "H3"
              ) {
                return "label";
              } else if (element.tagName === "P") return "paragraph";
              return "";
            },
          });

          return { language: "he_IL" };
        }}
        onEditorChange={(v) => setValue(`output.${blockIndex}.blogHtml`, v)}
        value={value}
        init={{
          height: "100%",
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "wordcount",
          ],
          toolbar:
            `${toolbarOptions.undo} ${toolbarOptions.redo} | ${toolbarOptions.blocks} | ` +
            `${toolbarOptions.bold} ${toolbarOptions.italic} ${toolbarOptions.forecolor} | ${toolbarOptions.alignLeft} ${toolbarOptions.alignCenter} ` +
            `${toolbarOptions.alignRight} ${toolbarOptions.alignjustify} | ${toolbarOptions.bullist} ${toolbarOptions.numlist} ${toolbarOptions.outdent} ${toolbarOptions.indent} | ` +
            `${toolbarOptions.removeformat}`,
          content_style: `
            @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&display=swap');
            body { font-family: open sans hebrew, sans-serif; font-size: 16px; padding: 20px 120px; direction: rtl; }
            .header img { border-radius: 12px; border: solid 1px rgb(226, 232, 240); width: 100%; height: 400px; object-fit: cover; }
            .header h1 { color: #1C244B; font-size: 30px; font-weight: bold; text-transform: none; font-style: normal; text-decoration: none; line-height: 1.3em; letter-spacing: -1.2px; }
            li { margin-bottom: 6px; }
            p { color: #0A2540; font-size: 20px; font-weight: 300; text-transform: none; font-style: normal; text-decoration: none; line-height: 1.6em; letter-spacing: -0.2px; }
            h3 { font-size: 18px; font-weight: 600; }
            h2 { font-size: 24px; font-weight: bold; }
            a { box-shadow: none; text-decoration: none; color: #A960EE !important; }
            .visual-helper{
              background: #a960ee23;
              padding: 10px 30px;
              color: #6300C1;
              border:dashed 1px #6300C1;
              width: fit-content;
            }
          `,
          contextmenu: "image link test table", // add test menu later
          contextmenu_never_use_native: true,
          directionality: "rtl", // Add RTL directionality
        }}
      />
    </EditorContainer>
  );
}

export default React.memo(ArticleBlockEditor);
