import { PostEntity, PostStatusType } from "neword-core";
import React from "react";
import { ReactComponent as ApproveIcon } from "../../../../assets/Icons/Approved.svg";
import { ReactComponent as RejectIcon } from "../../../../assets/Icons/Rejected.svg";
import { ReactComponent as Comment } from "../../../../assets/Icons/Send.svg";
import styled from "styled-components";
import Button from "../../../common/form/Button";

interface ContentProps {
  post: PostEntity;
  onApprove: () => void;
  onReject: () => void;
}

const ButtonStyled = styled(Button)`
  padding: 0.5rem 1rem;
  font-weight: 500;
  font-size: 1.4rem;
  &.comment {
    svg {
      path {
        fill: black;
      }
    }
  }
  svg {
    width: 0.7rem;
    height: 0.7rem;
    fill: var(--title-color);
  }

  .viewicon {
    fill: black;
  }

  &.viewbutton {
    &:hover {
      background-color: #ceceff !important;
    }
  }
`;

const SliderTileFooter: React.FC<ContentProps> = (props) => {
  // Wrap the onClick handlers to stop propagation

  return (
    <>
      <div
        className="flex mt-3 w-full gap-10"
        style={{ justifyContent: "space-between" }}
      >
        <ButtonStyled
          disabled={props.post.status === PostStatusType.APPROVED}
          fullWidth
          onClick={props.onApprove}
          primary
          bgColor="success"
          hoverColor="success"
          textColor="title"
          icon={<ApproveIcon />}
        >
          אישור
        </ButtonStyled>
        <ButtonStyled
          disabled={props.post.status === PostStatusType.REJECTED}
          fullWidth
          onClick={props.onReject}
          primary
          bgColor="danger"
          textColor="title"
          hoverColor="danger"
          icon={<RejectIcon />}
        >
          דחייה
        </ButtonStyled>
        {/* <ButtonStyled 
        className="comment"
        fullWidth 
        onClick={props.onReject} 
        primary 
        bgColor="bg" 
        icon={<Comment />}
        textColor="title"
      >
        תגובה
      </ButtonStyled> */}
      </div>
      <div className="flex w-full justify-content-center mt-2 gap-10">
        {/* 
    <ButtonStyled onClick={props.openPreview} className="viewbutton" bgColor="bg" fullWidth textColor="title" icon={<ViewIcon className="viewicon" />}>
צפייה בפוסט   </ButtonStyled>
<ButtonStyled onClick={props.openPreview} className="viewbutton" bgColor="bg" fullWidth textColor="title" icon={<ViewIcon className="viewicon" />}>
הוסף הערה   </ButtonStyled> */}
      </div>
    </>
  );
};

export default SliderTileFooter;
