import { atomFamily } from "recoil";

import appIntegrationService from "../core/services/appIntegration.service";
import { LinkedInCompany } from "../core/entities/linkedinCompany";

export const linkedinCompanyState = atomFamily<LinkedInCompany[], string>({
  key: "linkedinCompanyState",
  default: (websiteId: string) =>
    appIntegrationService.getLinkedCompanyPages(websiteId),
});
