import { useEffect, useState } from "react";

const useIsMobile = (breakpoint = 1200) => {
  const getViewportWidth = () =>
    typeof window !== "undefined"
      ? window.visualViewport?.width || window.innerWidth
      : 0;

  const [isMobile, setIsMobile] = useState(getViewportWidth() < breakpoint);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const checkIsMobile = () => {
      const width = getViewportWidth();
      const mobile = width < breakpoint;
      console.log({
        innerWidth: window.innerWidth,
        visualViewport: window.visualViewport?.width,
        devicePixelRatio: window.devicePixelRatio,
      });
      setIsMobile(mobile);
    };

    checkIsMobile();

    let timeoutId: ReturnType<typeof setTimeout>;
    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        checkIsMobile();
      }, 150);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(timeoutId);
    };
  }, [breakpoint]);

  return isMobile;
};

export default useIsMobile;
