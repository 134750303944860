import React from "react";
import styled from "styled-components";
import { addWeeks, addMonths, format, startOfWeek, endOfWeek } from "date-fns";
import { he } from "date-fns/locale"; // Hebrew locale (v2+)
import { Button } from "antd";

interface DateNavigatorProps {
  value: Date;
  setValue: React.Dispatch<React.SetStateAction<Date>>;
  currentView: "month" | "week" | "day";
}

const NavRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

const NavLabel = styled.div`
  font-size: 14px;
`;

const NavButton = styled.div`
  user-select: none;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 12px;
  background: var(--light-bg);
  &:hover {
    background: var(--border-color);
  }
`;

const DateNavigator: React.FC<DateNavigatorProps> = ({
  value,
  setValue,
  currentView,
}) => {
  // Navigation
  const goNext = () => {
    if (currentView === "month") {
      setValue((prev) => addMonths(prev, 1));
    } else {
      setValue((prev) => addWeeks(prev, 1));
    }
  };

  const goPrev = () => {
    if (currentView === "month") {
      setValue((prev) => addMonths(prev, -1));
    } else {
      setValue((prev) => addWeeks(prev, -1));
    }
  };

  // Format label in Hebrew
  let label = "";
  if (currentView === "month") {
    label = format(value, "MMMM yyyy", { locale: he });
  } else {
    const start = startOfWeek(value, { weekStartsOn: 0 });
    const end = endOfWeek(value, { weekStartsOn: 0 });
    label = `${format(start, "MMM d", { locale: he })} - ${format(
      end,
      "MMM d, yyyy",
      { locale: he }
    )}`;
  }

  return (
    <NavRow className="">
      <NavButton onClick={goPrev}>הקודם</NavButton>
      <NavLabel>{label}</NavLabel>
      <NavButton onClick={goNext}>הבא</NavButton>
      {/* Uncomment if you need a "Today" button:
      <NavButton onClick={() => setValue(new Date())}>היום</NavButton>
      */}
    </NavRow>
  );
};

export default DateNavigator;
