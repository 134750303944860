import AudienceSelect from "../../common/form/AudienceSelect";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import DocumentTone from "../components/DocumentTone";
import { AccordionProps } from "./types/AccordionProps";
import GenericDescriptionInput from "./formComponentes/GenericDescriptionInput";
import {useTranslation} from "react-i18next";
import React from "react";

export const useTrafficEmailAccordions = (): AccordionProps[] => {
  const { t } = useTranslation();
  return [
    {
      title: t("traffic_email_accordions.content_description_title"),
      content: (
        <div className="w-full step-2-wt">
          <GenericDescriptionInput<
              DocumentEntity & { type: DocumentEntityType.TRAFFIC_EMAIL }
            >
            name="inputParams.contentTopic"
            label={t("traffic_email_accordions.content_topic_label")}
            placeholder={t("traffic_email_accordions.content_topic_placeholder")}
            inputStyle={{ height: 45 }}
          />

          <GenericDescriptionInput<
              DocumentEntity & { type: DocumentEntityType.TRAFFIC_EMAIL }
            >
            name="inputParams.cta"
            label={t("traffic_email_accordions.cta_label")}
            placeholder={t("traffic_email_accordions.cta_placeholder")}
            hideRephrase
            inputStyle={{ height: 60 }}
          />
        </div>
      ),
      fieldNames: [
        "inputParams.cartItems",
        "inputParams.supportContact",
        "inputParams.incentives",
      ],
    },

    {
      title: t("traffic_email_accordions.target_audience"),
      content: (
        <AudienceSelect<
            DocumentEntity & { type: DocumentEntityType.CART_ABANDONMENT_EMAIL }
          > fieldName="inputParams.audienceId" />
      ),
      fieldNames: ["inputParams.audienceId"],
    },
    {
      title: t("traffic_email_accordions.writing_style"),
      content: (
        <DocumentTone<
            DocumentEntity & { type: DocumentEntityType.CART_ABANDONMENT_EMAIL }
          >
          fieldName="inputParams.tone"
          threeColumn={true}
        />
      ),
      fieldNames: ["inputParams.tone"],
    },
  ];
};
