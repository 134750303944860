import React, { useState, useCallback, useMemo } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Header from "../../components/taskManager/Header/Header";
import Column from "../../components/taskManager/Column";
import ListView from "../../components/taskManager/ListView";
import CreateTaskDialog from "../../components/taskManager/CreateTaskDialog";
import { useRecoilState, useRecoilValue } from "recoil";
import { taskState } from "../../state/taskState";
import { Task, TaskPriority, TaskStatus } from "neword-core";
import styled from "styled-components";
import tasksService from "../../core/services/tasks.service";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ViewToggleContainer = styled.div`
  padding: 0.5rem 1rem;
  background-color: #e1e4e8;
  display: flex;
  justify-content: flex-end;
`;

const ToggleButton = styled.button<{ active: boolean }>`
  padding: 0.5rem 1rem;
  background-color: ${({ active }) => (active ? "#0052cc" : "#fff")};
  color: ${({ active }) => (active ? "#fff" : "#0052cc")};
  border: 1px solid #0052cc;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 0.5rem;
`;

export const ContentWrapper = styled.div`
  min-height: calc(100vh - 165px);
  max-height: calc(100vh - 165px);
  overflow-y: auto;
`;

export const BoardContainer = styled.div`
  flex: 1;
  display: flex;
  overflow-x: auto;
  padding: 1rem;
  background-color: #f4f5f7;
`;

export interface ColumnType {
  id: TaskStatus;
  title: string;
  tasks: Task[];
}

// Initial columns
export const TaskManager: React.FC = () => {
  const [tasks, setTasks] = useRecoilState(taskState);
  const columns: ColumnType[] = useMemo(
    () =>
      [
        {
          id: "PENDING",
          title: "ממתין לביצוע", // PENDING
          tasks: tasks.filter((task) => task.status === "PENDING"),
        },
        {
          id: "IN_REVIEW",
          title: "בבדיקה", // IN_REVIEW
          tasks: tasks.filter((task) => task.status === "IN_REVIEW"),
        },
        {
          id: "COMPLETED",
          title: "הושלם", // COMPLETED
          tasks: tasks.filter((task) => task.status === "COMPLETED"),
        },
        {
          id: "CANCELLED",
          title: "בוטל", // CANCELLED
          tasks: tasks.filter((task) => task.status === "CANCELLED"),
        },
      ] as ColumnType[],
    [tasks]
  );

  const [view, setView] = useState<"kanban" | "list">("kanban");

  const [filter, setFilter] = useState<string>("");
  const [priorityFilter, setPriorityFilter] = useState<TaskPriority | "">("");
  const [sortBy, setSortBy] = useState<string>("date");

  const handleAddTask = (columnId: string) => {
    console.log("Add Task clicked in column:", columnId);
  };

  const handleTaskClick = (task: Task) => {
    console.log("Task clicked:", task);
  };

  const openCreateTaskDialog = () => {
    setOpenCreateDialog(true);
  };

  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  /**
   * Move a task from one column to another (or within the same column).
   * @param taskId - The ID of the dragged task.
   * @param fromColumnId - The column ID we dragged from.
   * @param toColumnId - The column ID we dropped into.
   * @param toIndex - The position within the target column’s task list.
   */
  const moveTask = useCallback(
    async (
      taskId: string,
      fromColumnId: TaskStatus,
      toColumnId: TaskStatus,
      toIndex: number
    ) => {
      if (fromColumnId !== toColumnId) {
        // Move task to another column
        const task = tasks.find((task) => task.id === taskId);
        if (!task) {
          return;
        }

        setTasks((prevTasks) => {
          const updatedTasks = prevTasks.map((task) => {
            if (task.id === taskId) {
              return {
                ...task,
                status: toColumnId,
              };
            }
            return task;
          });

          return updatedTasks;
        });

        try {
          await tasksService.updateTask(taskId, { status: toColumnId });
        } catch (err) {
          console.error("Failed to update task status:", err);
        }
      }
    },
    [tasks]
  );

  // Filter tasks based on search term and priority, then sort them
  const getFilteredColumns = useCallback(() => {
    return columns.map((column) => {
      // Filter tasks first
      let filteredTasks = column.tasks.filter((task) => {
        // Check if the task title or description contains the filter text
        const matchesFilter =
          filter === "" ||
          task.title.toLowerCase().includes(filter.toLowerCase()) ||
          task.description.toLowerCase().includes(filter.toLowerCase());

        // Check if the task has the selected priority
        const matchesPriority =
          priorityFilter === "" || task.priority === priorityFilter;

        return matchesFilter && matchesPriority;
      });

      // Then sort the filtered tasks
      filteredTasks = [...filteredTasks].sort((a, b) => {
        switch (sortBy) {
          case "date":
            // Sort by date (newest first)
            return a.createdAt && a.createdAt
              ? new Date(a.createdAt).getTime() -
                  new Date(a.createdAt).getTime()
              : 0;

          case "priority":
            // Map priorities to numeric values for sorting
            const priorityOrder: Record<TaskPriority, number> = {
              HIGH: 3,
              MEDIUM: 2,
              LOW: 1,
            };
            return priorityOrder[b.priority] - priorityOrder[a.priority];

          case "title":
            // Sort alphabetically by title
            return a.title.localeCompare(b.title);

          default:
            return 0;
        }
      });

      return {
        ...column,
        tasks: filteredTasks,
      };
    });
  }, [columns, filter, priorityFilter, sortBy]);

  return (
    <PageContainer>
      <Header
        onCreateContent={openCreateTaskDialog}
        view={view}
        setView={setView}
        onFilterChange={setFilter}
        onPriorityFilterChange={setPriorityFilter}
        onSortChange={setSortBy}
        sortBy={sortBy}
      />

      <ContentWrapper style={{ display: "flex", flex: 1 }}>
        <DndProvider backend={HTML5Backend}>
          {view === "kanban" ? (
            <BoardContainer>
              {getFilteredColumns().map((column) => (
                <Column
                  key={column.id}
                  columnId={column.id}
                  title={column.title}
                  tasks={column.tasks}
                  onAddTask={() => handleAddTask(column.id)}
                  onTaskClick={handleTaskClick}
                  moveTask={moveTask}
                />
              ))}
            </BoardContainer>
          ) : (
            <ListView
              columns={getFilteredColumns()}
              moveTask={moveTask}
              onTaskClick={handleTaskClick}
            />
          )}
        </DndProvider>
        {/* <Sidebar /> */}
      </ContentWrapper>
      {openCreateDialog && (
        <CreateTaskDialog onClose={() => setOpenCreateDialog(false)} />
      )}
    </PageContainer>
  );
};
