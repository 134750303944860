import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useFormContext, Controller } from "react-hook-form";
import styled from "styled-components";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { websiteState } from "../../../state/websitesState";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import { ReactComponent as Neword } from "../../../assets/Icons/Neword.svg";
import { ReactComponent as LinkedInLogo } from "../../../assets/Icons/linkedin/LinkedInLogo.svg";
import { ReactComponent as LinkedInRepost } from "../../../assets/Icons/linkedin/LinkedInRepost.svg";

import { ReactComponent as LinkedInComment } from "../../../assets/Icons/linkedin/LinkedInComment.svg"; // Add this
import { ReactComponent as LinkedInShare } from "../../../assets/Icons/linkedin/LinkedInShare.svg"; // Add this
import LinkedInPreview from "../../../assets/images/LinkedInPreview.png";
import { ReactComponent as LinkedInLike } from "../../../assets/Icons/linkedin/LinkedInLike.svg";
import { BlockProps } from "./types/dictionaryProps";
import { RefProps } from "./types/refProps";
import EditableTextArea from "../components/EditableTextArea";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  padding: 20px;
  width: 100%;
  background: white;
`;

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px;
  justify-content: space-between;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AcountText = styled.div`
  font-weight: 700;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  font-family: "Open Sans", "open sans Hebrew";
  letter-spacing: -0.2px;
`;

const ContentWrapper = styled.div`
  font-size: 12.21px;
  display: flex;
`;

const LeftTopMenu = styled.div`
  display: flex;
  align-items: center;
`;

const LinkedInPreviewWrapper = styled.div`
  border-radius: 19.576px;
  margin-top: 10px;
  width: 100%;
  background-color: #f6f9fc;
  height: 216px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const PurpelText = styled.div`
  color: var(--main-hebrew, #6300c1);
  text-align: center;
  font-family: "Noto Sans Hebrew";
  font-size: 16px;
  font-weight: 700;
`;
const ImageText = styled.div`
  color: "";
  text-align: center;
  font-family: "Noto Sans Hebrew";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 13.432px */
  letter-spacing: -0.366px;
`;

const LinkedInPostIdeaBlock = forwardRef<RefProps, BlockProps>(
  ({ isEditing, blockIndex, handleBlur }, ref) => {
    const { websiteId } = useParams();
    const { t } = useTranslation();
    const website = useRecoilValue(websiteState(websiteId as string));
    const { getValues } = useFormContext<
      DocumentEntity & { type: DocumentEntityType.LINKEDIN_POST_IDEA }
    >();

    const imageText = getValues(`output.${blockIndex}.imageDescription`);

    useImperativeHandle(ref, () => ({
      onCopyCustom: () => {
        const output = getValues(`output.${blockIndex}`);
        return output.postIdea;
      },
    }));

    return (
      <Wrapper className="col-4">
        <TopSection>
          <LinkedInLogo />
          <LeftTopMenu>
            <AcountText className="ml-1">{website?.name}</AcountText>
            <Neword />
          </LeftTopMenu>
        </TopSection>

        <ContentWrapper className="mt-1">
          <EditableTextArea<DocumentEntityType.LINKEDIN_POST_IDEA>
            fieldName={`output.${blockIndex}.postIdea`}
            handleBlur={handleBlur}
            blockIndex={blockIndex}
            isEditing={isEditing}
          />
        </ContentWrapper>

        <LinkedInPreviewWrapper>
          <PurpelText>{t("common.example_image")}</PurpelText>
          <ImageText>{imageText}</ImageText>
        </LinkedInPreviewWrapper>
        <IconsWrapper>
          <LinkedInShare />
          <LinkedInRepost />
          <LinkedInComment />
          <LinkedInLike />
          <Neword />
        </IconsWrapper>
      </Wrapper>
    );
  }
);

export default LinkedInPostIdeaBlock;
