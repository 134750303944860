import React, { useState } from "react";
import Joyride, { CallBackProps } from "react-joyride";
import { useTour } from "../../hooks/useTour";
import { useTranslation } from "react-i18next";
import UpgradePackage from "../modals/UpgradePackage";
import { TourNames } from "../../core/types/tourNames";
import Preloader from "../common/Preloader";

export const TourProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { tour, stopTour } = useTour();
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [upgradePackageModal, setUpgradePackageModal] = useState(false);

  const handleJoyrideCallback = (data: CallBackProps) => {
    // Update current step
    if (data.index !== undefined) {
      setCurrentStep(data.index);
    }

    if (["finished", "skipped"].includes(data.status)) {
      if (tour.name === TourNames.WelcomeTour) {
        setUpgradePackageModal(true);
      }
      stopTour(tour.name);
      setCurrentStep(0); // Reset when ends
    }
  };

  return (
    <>
      <Joyride
        steps={tour.steps}
        run={tour.isRunning}
        continuous={true}
        showSkipButton={true}
        showProgress={true}
        callback={handleJoyrideCallback}
        locale={{
          back: t("tour.back"),
          close: t("tour.close"),
          last: t("tour.last"),
          next: t("tour.next"),
          nextLabelWithProgress: t("tour.next_label_with_progress", {
            currentStep: currentStep + 1,
            totalSteps: tour.steps.length,
          }),
          skip: t("tour.skip"),
        }}
        styles={{
          tooltipContainer: {
            direction: "rtl",
          },
          options: {
            zIndex: 10000,
          },
          buttonNext: {
            backgroundColor: "#6300c1",
            color: "#fff",
            borderRadius: "8px",
            padding: "10px 20px",
            fontFamily:"Assistant",
            letterSpacing: "-0.1px;", 
          },
        }}
      />
      {children}
      {upgradePackageModal && (
        <React.Suspense fallback={<Preloader />}>
          <UpgradePackage onHide={() => setUpgradePackageModal(false)} />
        </React.Suspense>
      )}
    </>
  );
};
