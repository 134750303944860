// utils/permissionUtils.ts

import { Features, Package, Subscription } from "neword-core";

// Check if a specific feature flag is enabled
export const hasFeature = (
  userSubscription: Subscription,
  featureKey: Features
): boolean => {
  return userSubscription.features?.includes(featureKey) ?? false;
};

// Check if user can create another business based on maxBusinesses
export const canCreateBusiness = (
  userPackage: Subscription,
  currentBusinessCount: number
): boolean => {
  return currentBusinessCount < userPackage.maxBusinesses;
};
