// BlogPostSharedSettings.tsx
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { Chips } from "primereact/chips";
import CustomChip from "../../components/CustomChip";
import GenericDescriptionInput from "../formComponentes/GenericDescriptionInput";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import DocChipInput from "../formComponentes/DocChipInput";
import {useTranslation} from "react-i18next";

function SocialSharedSettings() {
  const { t } = useTranslation();
  return (
    <div className="w-full step-2-wt">
      <GenericDescriptionInput<
        DocumentEntity & { type: DocumentEntityType.INSTAGRAM_CAPTION }
      >
        name="inputParams.cta"
        label={t("social_shared_settings.cta_label")}
        placeholder={t("social_shared_settings.cta_placeholder")}
        inputStyle={{ height: 60 }}
      />
      <DocChipInput<
        DocumentEntity & { type: DocumentEntityType.INSTAGRAM_CAPTION }
      >
        name="inputParams.hashtags"
        label={t("social_shared_settings.hashtags_label")}
        placeholder={t("social_shared_settings.hashtags_placeholder")}
      />
      <GenericDescriptionInput<
        DocumentEntity & { type: DocumentEntityType.INSTAGRAM_CAPTION }
      >
        name="inputParams.additionalInformation"
        label={t("social_shared_settings.additional_information_label")}
        placeholder={t("social_shared_settings.additional_information_placeholder")}
        hideRephrase
      />
    </div>
  );
}

export default SocialSharedSettings;
