import React, { forwardRef, HTMLAttributes } from "react";
import styled from "styled-components";

interface Props extends HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  className?: string;
}

const CardWrapper = styled.div`
  background: white;
  padding: 1.5rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;
`;

const Card = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { children, className, ...rest } = props;
  return (
    <CardWrapper ref={ref} className={className} {...rest}>
      {children}
    </CardWrapper>
  );
});

export default Card;
