import { AttachmentMedia } from "neword-core";
import { Website } from "../entities/website";
import { CreateWebsiteRequestData } from "./requests/createWebsite/createWebsiteRequestData";
import { ServiceBase } from "./service.base";
import imageCompression from "browser-image-compression";

class WebsiteService extends ServiceBase {
  prefix = "/website";

  listWebsites() {
    return this.get<Website[]>("/");
  }

  getWebsiteById(websiteId: string) {
    return this.get<Website>(`/${websiteId}`);
  }

  createWebsiteWithWorker(data: CreateWebsiteRequestData) {
    return this.post<Website>("/", data);
  }

  deleteWebsiteById(websiteId: string) {
    return this.delete(`/${websiteId}`);
  }

  updateWebsiteById(websiteId: string, data: Partial<Website>) {
    return this.patch<Website>(`/${websiteId}`, data);
  }

  uploadAttachment(websiteId: string, fileName: string, fileBuffer: string) {
    return this.post<AttachmentMedia>("/attachment", {
      fileName,
      fileBuffer,
      websiteId,
    });
  }

  finalizeAttachmentUpload(fileName: string, websiteId?: string) {
    return this.post<AttachmentMedia>("/finalize/attachment", {
      fileName,
      websiteId,
    });
  }

  uploadAttachmentChunk(
    fileName: string,
    chunk: string,
    index: number,
    totalChunks: number,
    websiteId?: string
  ) {
    return this.post<AttachmentMedia>("/attachment/chunk", {
      fileName,
      chunk,
      index,
      websiteId,
      totalChunks,
    });
  }

  async compressFileIfNeeded(file: File) {
    const isVideo = file.type.startsWith("video/");
    if (!isVideo) {
      if (file.size >= 8 * 1024 * 1024) {
        console.log("Compressing file...");
        const options = {
          maxSizeMB: 7.9,
          useWebWorker: true,
        };

        try {
          return await imageCompression(file, options);
        } catch (err) {
          return file;
        }
      }
    }
    return file;
  }

  async uploadAttachmentInChunks(file: File, websiteId?: string) {
    file = await this.compressFileIfNeeded(file);
    const chunkSize = 2 * 1024 * 1024; // 2 MB
    const chunks = splitFileIntoChunks(file, chunkSize);
    const fileName = file.name;

    for (let i = 0; i < chunks.length; i++) {
      const chunk = chunks[i];

      // Convert chunk to Base64
      const reader = new FileReader();
      const base64Promise = new Promise<string>((resolve, reject) => {
        reader.onload = () =>
          resolve(reader.result?.toString().split(",")[1] || "");
        reader.onerror = reject;
      });

      reader.readAsDataURL(chunk);
      const base64Chunk = await base64Promise;

      // Upload chunk to server
      await this.uploadAttachmentChunk(
        fileName,
        base64Chunk,
        i,
        chunks.length,
        websiteId
      );

      console.log(`Uploaded chunk ${i + 1}/${chunks.length}`);
    }

    // Combine chunks on server (trigger a finalize endpoint)
    const attachmentMedia = await this.finalizeAttachmentUpload(
      fileName,
      websiteId
    );

    return attachmentMedia;
  }

  getAttachments(websiteId?: string) {
    return this.post<AttachmentMedia[]>(`/getattachment`, {
      websiteId,
    });
  }

  saveAttachmentToGallery(websiteId: string, url: string) {
    return this.post<AttachmentMedia>(`/attachment/byurl`, {
      websiteId,
      url,
    });
  }
}

const splitFileIntoChunks = (file: File, chunkSize = 5 * 1024 * 1024) => {
  const chunks = [];
  let offset = 0;

  while (offset < file.size) {
    const chunk = file.slice(offset, offset + chunkSize);
    chunks.push(chunk);
    offset += chunkSize;
  }

  return chunks;
};

export const websiteService = new WebsiteService();
