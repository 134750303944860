import React, { useState } from "react";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { pageInsightsState } from "../../state/postInsightsState";
import {
  PageMetricsInsights,
  Metric,
  PostEntityType,
  AppIntegrationApplicationType,
} from "neword-core";
import styled from "styled-components";
import { useForm, FormProvider } from "react-hook-form";
import { Line } from "react-chartjs-2";
import DateRangePicker from "./DateRange";
import {
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import Card from "../common/Card";
import { ReactComponent as Followers } from "../../assets/Icons/Analytics/Followers.svg";
import { ReactComponent as Engagment } from "../../assets/Icons/Analytics/Engagment.svg";
import { ReactComponent as Impressions } from "../../assets/Icons/Analytics/Impressions.svg";
import { ReactComponent as Posts } from "../../assets/Icons/Analytics/Posts.svg";
import { ReactComponent as Reach } from "../../assets/Icons/Analytics/Reach.svg";

const PageWrapper = styled(Card)`
  margin: 0 auto;
`;

const FiltersWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 30px;
`;

const CountersRow = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: start;
  margin-bottom: 20px;
`;

/**
 * The `isActive` prop is used to decide whether to show the active border.
 */
const CounterWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: start;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid
    ${({ isActive }) =>
      isActive ? "var(--primary-purple)" : "var(--border-color)"};
  box-shadow: ${({ isActive }) =>
    isActive ? "0px 2px 2px 2px rgba(170, 0, 255, 0.05)" : "none"};
  font-size: 28px;
  letter-spacing: 0.5px;
  font-family: "Space Grotesk", sans-serif;
  font-weight: bold;
  width: 18%;
  color: var(--title-color);
  text-align: center;
  cursor: pointer;

  &:hover {
    /* If active, keep the active border; otherwise, show hover border */
    border: 1px solid
      ${({ isActive }) =>
        isActive ? "var(--primary-purple)" : "var(--text-color)"};
  }
`;

const CounterTitle = styled.h3`
  font-family: "Assistant";
  font-size: 14px;
  font-weight: 400;
  color: var(--title-color);
  margin-bottom: 5px;
`;

const ChartWrapper = styled.div`
  margin-top: 20px;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  text-align: center;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 10px;
  svg {
    width: 15px;
    height: 15px;
    path {
      fill: var(--text-color);
    }
  }
`;

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

interface Props {
  platform: AppIntegrationApplicationType;
}

const PagesInsightsPage: React.FC<Props> = ({ platform }) => {
  const { websiteId } = useParams();

  const methods = useForm({
    defaultValues: {
      startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
      endDate: new Date(),
    },
  });

  const { startDate, endDate } = methods.watch();
  const pageInsights = useRecoilValue(
    pageInsightsState({
      websiteId: websiteId as string,
      platform,
      startDate: startDate.toISOString().split("T")[0],
      endDate: endDate.toISOString().split("T")[0],
    })
  );
  const metrics = [
    {
      key: "followers",
      label: "עוקבים",
      color: "#1c7ed6",
      icon: <Followers />,
      displayType: "last",
    },
    {
      key: "posts",
      label: "פוסטים",
      color: "#ff6b6b",
      icon: <Posts />,
      displayType: "last",
    },
    {
      key: "impressions",
      label: "Impressions",
      color: "#ffa94d",
      icon: <Impressions />,
      displayType: "sum",
    },
    {
      key: "engagement",
      label: "Engagement",
      color: "#51cf66",
      icon: <Engagment />,
      displayType: "sum",
    },
    {
      key: "pageViews",
      label: "צפיות בעמוד",
      color: "#845ef7",
      icon: <Engagment />,
      displayType: "sum",
    },
  ];

  // Track which metric’s chart is currently visible
  const [activeMetricKey, setActiveMetricKey] = useState<string | null>(
    "impressions"
  );
  const getDisplayValue = (metricData: Metric, displayType: "last" | "sum") => {
    if (metricData.type === "chart" && Array.isArray(metricData.data)) {
      if (displayType === "last") {
        const lastPoint = metricData.data[metricData.data.length - 1];
        return lastPoint?.value ?? 0;
      } else if (displayType === "sum") {
        return metricData.data.reduce((sum, point) => sum + point.value, 0);
      }
    }
    return metricData.data;
  };

  // Toggle the active metric on click
  const handleCounterClick = (key: string, isChart: boolean) => {
    if (activeMetricKey === key) {
      // If already active, hide the chart
      setActiveMetricKey(null);
    } else {
      // If there's no chart, just hide any existing chart
      if (!isChart) {
        setActiveMetricKey(null);
      } else {
        // Otherwise, show this metric’s chart
        setActiveMetricKey(key);
      }
    }
  };

  return (
    <PageWrapper>
      <FormProvider {...methods}>
        <FiltersWrapper>
          <DateRangePicker />
        </FiltersWrapper>

        <CountersRow>
          {metrics.map(({ key, label, color, icon, displayType }) => {
            const metricData = pageInsights[
              key as keyof PageMetricsInsights
            ] as Metric;
            if (!metricData) return null;

            const isChart = metricData.type === "chart";
            const displayValue = getDisplayValue(
              metricData,
              displayType as any
            );
            const isActive = activeMetricKey === key;

            return (
              <CounterWrapper
                key={key}
                isActive={isActive}
                onClick={() => handleCounterClick(key, isChart)}
              >
                {icon && <IconWrapper>{icon}</IconWrapper>}
                <CounterTitle>{label}</CounterTitle>
                {displayValue.toLocaleString()}
              </CounterWrapper>
            );
          })}
        </CountersRow>

        {activeMetricKey &&
          (() => {
            const metricData = pageInsights[
              activeMetricKey as keyof PageMetricsInsights
            ] as Metric;
            const metric = metrics.find((m) => m.key === activeMetricKey);

            if (!metricData || metricData.type !== "chart" || !metric)
              return null;

            return (
              <ChartWrapper>
                <h3>{metric.label}</h3>
                <Line
                  data={{
                    labels: metricData.data.map((item: any) =>
                      new Date(item.date).toDateString()
                    ),
                    datasets: [
                      {
                        label: metric.label,
                        data: metricData.data.map((item: any) => item.value),
                        borderColor: metric.color,
                        backgroundColor: `${metric.color}33`,
                        fill: true,
                      },
                    ],
                  }}
                />
              </ChartWrapper>
            );
          })()}
      </FormProvider>
    </PageWrapper>
  );
};

export default PagesInsightsPage;
