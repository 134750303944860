import React, { useEffect, useState } from "react";
import { ContentCardIcon, PostCardIcons } from "../post/postIconMappings";
import styled from "styled-components";
import { ReactComponent as CheckIcon } from "../../assets/Icons/Approved.svg";
import { ReactComponent as CalendarIcon } from "../../assets/Icons/Calendar.svg";
import { ReactComponent as FeedIcon } from "../../assets/Icons/Feed.svg";
import { ReactComponent as ExportIcon } from "../../assets/Icons/Share.svg";
import { ReactComponent as AddArticle } from "../../assets/Icons/AddArticle.svg";

import DropDownMenu from "../common/form/DropDownMenu";
import Button from "../common/form/Button";
import { AppIntegrationApplicationType, PostEntityType } from "neword-core";
import { useTranslation } from "react-i18next";
import { MultiPlatformSelector } from "../common/MultiPlatformSelector";
import { useParams } from "react-router";
import ShareGantLinkModal from "../modals/shareGantLinkModal";

interface DropDownOption {
  label: string;
  icon?: React.ReactNode | JSX.Element;
  customTemplate?: React.ReactNode;
  link?: string;
  corners?: boolean;
}

interface IntegrationFactoryType {
  icon: React.ComponentType<any>;
  label: string;
  text: string;
}

interface CalendarMenuProps {
  setFilterPostType: React.Dispatch<
    React.SetStateAction<(AppIntegrationApplicationType | null)[]>
  >;
  filterPostType: (AppIntegrationApplicationType | null)[];
  toggleModal: () => void;
  notCalendar?: boolean;
  hideToggle?: boolean;
  // New prop: if true, only a single filter can be selected at a time.
  singleFilterSelect?: boolean;
}

interface ExtendContentCardIcon extends ContentCardIcon {
  type: string;
}

const Wrapper = styled.div<{ notCalendar?: boolean }>`
  width: 100%;
  z-index: 9;
  position: sticky;
  top: 0;
  background-color: white;
  border-radius: ${(props) => (props.notCalendar ? "12px" : "12px 12px 0 0 ")};
  border-bottom: ${(props) =>
    props.notCalendar ? "solid 1px var(--border-color)" : "none"};
  border: solid 1px var(--border-color);
  gap: 10px;
`;

const TopMenu = styled.div`
  z-index: 9999999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const IconCheckBoxWrapper = styled.div<{ active?: boolean }>`
  position: relative;
  width: 45px;
  height: 45px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px;
  border-color: ${(props) =>
    props.active ? "var(--border-color)" : "var(--border-color)"};
  svg {
    width: 15px;
    height: 15px;
  }
  cursor: pointer;
  &:hover {
    border-color: var(--primary-purple);
  }
`;

const CheckWrapper = styled.div<{ active?: boolean }>`
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.active ? "var(--primary-purple)" : "transparent"};
  right: -5px;
  top: -5px;
  svg {
    fill: white;
    width: 6px;
    height: 6px;
  }
`;

const ButtonStyled = styled(Button)`
  height: 100%;
  padding: 10px 15px;
`;

const CalendarMenu: React.FC<CalendarMenuProps> = ({
  filterPostType,
  setFilterPostType,
  toggleModal,
  hideToggle,
  singleFilterSelect,
}) => {
  const SocialIcons = PostCardIcons;
  const { t } = useTranslation();
  const [showGantModal, setShowGantModal] = useState(false);

  const [selectedOption, setSelectedOption] = useState<DropDownOption | null>(
    null
  );
  // Options to pass into DropDownMenu
  const options: DropDownOption[] = [
    {
      label: t("calendar.menu.calendar_view"),
      icon: <CalendarIcon />,
      link: "/calendar",
    },
    {
      label: t("calendar.menu.feed_view"),
      icon: <FeedIcon />,
      link: "/feed",
    },
    {
      label: t("calendar.menu.grid_view"),
      icon: <FeedIcon />,
      link: "/grid",
    },
  ];

  // Match URL to default selection
  useEffect(() => {
    const currentPath = window.location.pathname.split("/").pop();
    const matchedOption = options.find((option) => {
      const path = option.link?.split("/").pop();
      return currentPath === path;
    });
    if (matchedOption) {
      setSelectedOption(matchedOption);
    }
  }, []);

  const handleSelect = (option: DropDownOption) => {
    setSelectedOption(option);
  };

  return (
    <>
      <Wrapper notCalendar>
        <div className="grid m-0">
          <div className="col-3 m-auto">
            {hideToggle || (
              <div className="flex gap-10">
                <ButtonStyled
                  fullWidth={false}
                  icon={<AddArticle />}
                  primary
                  bgColor="pink"
                  onClick={toggleModal}
                >
                  {t("calendar.menu.create_manual_content")}
                </ButtonStyled>
                <ButtonStyled
                  onClick={() => {
                    setShowGantModal(true);
                  }}
                  icon={<ExportIcon />}
                  bgColor="transparent"
                >
                  {t("calendar.menu.export_gantt")}
                </ButtonStyled>
              </div>
            )}
          </div>
          <div className="col-6 m-auto">
            <MultiPlatformSelector
              setFilterPostType={setFilterPostType}
              filterPostType={filterPostType}
            />
          </div>
          <div className="col-3 m-auto">
            {hideToggle || (
              <DropDownMenu
                options={options}
                onSelect={handleSelect}
                selectedValue={selectedOption}
              />
            )}
          </div>
        </div>
        {showGantModal && (
          <ShareGantLinkModal onHide={() => setShowGantModal(false)} />
        )}
      </Wrapper>
    </>
  );
};

export default CalendarMenu;
