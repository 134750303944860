// CalendarContainer.tsx
import React, { useState } from "react";
import { useParams } from "react-router";
import { useRecoilState } from "recoil";

import { PostEntityType } from "neword-core";

import { postsSummariesState } from "../../state/postState";
import MobileSliderView from "../calendar/MobileSliderView";

const MobileViewClient: React.FC = () => {
  const { websiteId } = useParams<{ websiteId: string }>();

  // Posts state from Recoil
  const [posts, setPosts] = useRecoilState(
    postsSummariesState(websiteId as string)
  );

  // Post type filter state
  const [filterPostType] = useState<PostEntityType[]>(
    Object.values(PostEntityType) as PostEntityType[]
  );

  return (
    <>
      <MobileSliderView
        // date={new Date(value as Date)}
        posts={posts}
        filterPostType={filterPostType}
      />
    </>
  );
};

export default MobileViewClient;
