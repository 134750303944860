import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useFormContext, Controller } from "react-hook-form";
import styled from "styled-components";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { websiteState } from "../../../state/websitesState";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import { ReactComponent as Neword } from "../../../assets/Icons/BlueIcon.svg";
import { ReactComponent as FacebookLogo } from "../../../assets/Icons/facebook/FacebookLogo.svg"; // Add Facebook logo
import FacebookPreview from "../../../assets/images/facebookPreview.png"; // Add Facebook preview image
import { ReactComponent as FacebookLike } from "../../../assets/Icons/facebook/FacebookLike.svg"; // Add Facebook like icon
import { ReactComponent as FacebookComment } from "../../../assets/Icons/facebook/FacebookComment.svg"; // Add Facebook like icon
import { ReactComponent as FacebookShare } from "../../../assets/Icons/facebook/FacebookShare.svg"; // Add Facebook like icon

import { BlockProps } from "./types/dictionaryProps";
import { RefProps } from "./types/refProps";
import EditableTextArea from "../components/EditableTextArea";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  padding: 10px;
  width: 100%;
`;

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px;
  justify-content: space-between;
  font-size: 14px;
  svg {
    width: 15px;
  }
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
`;

const AccountText = styled.div`
  font-weight: 700;
  font-size: 12.21px;
  display: flex;
  flex-direction: row-reverse;
`;

const RephraseButtonWrapper = styled.div<{ isEditing: boolean }>`
  border-radius: 8px;
  margin-top: ${({ isEditing }) => (isEditing ? "10px" : "-16px")};
  display: flex;
  cursor: pointer;
  opacity: ${({ isEditing }) => (isEditing ? "1" : "0")};
  pointer-events: none;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: 0.15s ease-in-out;
  span {
    color: var(--primary-purple);
  }
  svg {
    width: 10px;
  }
  svg path {
    width: 10px;
    fill: var(--primary-purple);
  }
`;

const ContentWrapper = styled.div<{ isEditing: boolean }>`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 8px;
  transition: 0.1s ease-in-out;
  align-items: flex-start;

  /* Fixed border and padding logic */
  border: ${({ isEditing }) =>
    isEditing ? "1px solid #e6e6e6" : "1px solid white"};
  padding: ${({ isEditing }) => (isEditing ? "15px" : "0")};
  background: ${({ isEditing }) => (isEditing ? "#f9f9f9" : "transparent")};

  &:hover {
    ${RephraseButtonWrapper} {
      opacity: 1;
      pointer-events: all;
      margin-top: 10px;
    }
  }
`;

const LeftTopMenu = styled.div`
  display: flex;
  align-items: center;

  opacity: 0.3;
`;

const FacebookPreviewWrapper = styled.img`
  border-radius: 6px;
  /* width: 100%; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fills the container */
`;

const EditableInput = styled.textarea<{ isEditing: boolean }>`
  font-size: 12.21px;
  border: ${({ isEditing }) =>
    isEditing ? "1px solid var(--Input-BorderColor, #e6e6e6)" : "none"};
  background: transparent;
  outline: none;
  width: 100%;
  height: auto;
  font-family: inherit;
  resize: none;
  &:focus {
    outline: none;
  }
`;

const ImageWrapper = styled.div`
  margin-top: 10px;
  width: 100%; /* Full width of the container */
  padding-top: 90%; /* This creates the 1:1 aspect ratio */
  position: relative;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 12px;
  bottom: 19px;
  z-index: 99;
  border-radius: 4px;
  margin-right: auto;
  background: #ffffffac;
  width: 25px;
  height: 15px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const FacebookCaptionBlock = forwardRef<RefProps, BlockProps>(
  ({ isEditing, blockIndex, handleBlur }, ref) => {
    const { websiteId } = useParams();
    const { t } = useTranslation();
    const website = useRecoilValue(websiteState(websiteId as string));

    const { getValues } = useFormContext<
      DocumentEntity & { type: DocumentEntityType.FACEBOOK_POST_CAPTION }
    >();
    const imageUrl = getValues("inputParams.imageUrl") ?? FacebookPreview;

    useImperativeHandle(ref, () => ({
      onCopyCustom: () => {
        const output = getValues(`output.${blockIndex}`);
        return output.postCaption;
      },
    }));

    return (
      <Wrapper className="col-4 mb-2">
        <TopSection>
          {/* <LeftTopMenu>
            <AccountText className="ml-1">{website?.name}</AccountText>
            <Neword />
          </LeftTopMenu> */}
          <IconWrapper>
            <FacebookLogo />
          </IconWrapper>
        </TopSection>

        <ContentWrapper className="mt-1" isEditing={isEditing}>
          <EditableTextArea<DocumentEntityType.FACEBOOK_POST_CAPTION>
            fieldName={`output.${blockIndex}.postCaption`}
            handleBlur={handleBlur}
            blockIndex={blockIndex}
            isEditing={isEditing}
          />
        </ContentWrapper>
        <ImageWrapper>
          <FacebookPreviewWrapper src={imageUrl} />
        </ImageWrapper>

        <IconsWrapper>
          <div className="flex">
            <FacebookShare className="ml-2" />
            {t("facebook_caption_block.share")}
          </div>
          <div className="flex">
            <FacebookComment className="ml-2" />
            {t("facebook_caption_block.comment")}
          </div>

          <div className="flex">
            <FacebookLike className="ml-2" />
            {t("facebook_caption_block.like")}
          </div>
        </IconsWrapper>
      </Wrapper>
    );
  }
);

export default FacebookCaptionBlock;
