// CalendarControls.tsx
import React from "react";
import { PostEntityType } from "neword-core";
import CalendarMenu from "./CalendarMenu";
import AddManualContent from "../modals/AddManualContent";
import MainCalendarMenu from "./calendarMenu/MainCalendarMenu";

interface CalendarControlsProps {
  filterPostType: PostEntityType[];
  setFilterPostType: React.Dispatch<React.SetStateAction<PostEntityType[]>>;
  toggleModal: () => void;
  showModal: boolean;
  currentView: "month" | "week" | "day";
  setCurrentView: React.Dispatch<React.SetStateAction<"month" | "week" | "day">>;
  clientView?: boolean;

  // ADD THESE:
  value: Date;  
  setValue: React.Dispatch<React.SetStateAction<Date>>;
}

const CalendarControls: React.FC<CalendarControlsProps> = ({
  filterPostType,
  setFilterPostType,
  toggleModal,
  showModal,
  currentView,
  setCurrentView,
  value,
  setValue,
  clientView
}) => {
  return (
    <>
      <MainCalendarMenu
        filterPostType={filterPostType}
        setFilterPostType={setFilterPostType}
        toggleModal={toggleModal}
        // If you have a hideToggle or other props, pass them here as well
        currentView={currentView}
        setCurrentView={setCurrentView}
        value={value}
        setValue={setValue}
        clientView={clientView}
      />
    {showModal && <AddManualContent onHide={toggleModal} />}
    </>
  );
};

export default CalendarControls;
