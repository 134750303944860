import React, { useRef, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import styled from "styled-components";
import { addLocale } from "primereact/api";
import Button from "../common/form/Button";

const CalendarWrapperStyled = styled.div`
  background: var(--light-bg);
  border: solid 1px var(--border-color);
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 10px;
  align-items: center;
  &:hover {
    background: var(--light-bg);
    border-color: var(--title-color);
  }
  border-radius: 8px;
  transition-duration: 0.2s;
`;

const CalendarStyled = styled(Calendar)`
  width: 100%;
  &:hover {
    background: transparent;
  }
  cursor: pointer;
  input {
    background: transparent;
    cursor: pointer;
    border: none;
    &:focus {
      outline: none;
      box-shadow: none !important;
    }
  }
`;

const DateRangePicker: React.FC = () => {
  const calendarRef = useRef<any>(null);
  const { control } = useFormContext<{ startDate: Date; endDate: Date }>();
  const startDate = useController({ control, name: "startDate" });
  const endDate = useController({ control, name: "endDate" });
  const [rangeOption, setRangeOption] = useState<string | null>(null);
  const [tempRange, setTempRange] = useState<Date[]>([]);

  const rangeOptions = [
    { label: "שבוע אחורה", value: "week" },
    { label: "חודש אחורה", value: "month" },
    { label: "שנה אחורה", value: "year" },
  ];

  const handleRangeChange = (value: string) => {
    setRangeOption(value);
    const today = new Date();
    let newStartDate = new Date();
    if (value === "week") {
      newStartDate.setDate(today.getDate() - 7);
    } else if (value === "month") {
      newStartDate.setMonth(today.getMonth() - 1);
    } else if (value === "year") {
      newStartDate.setFullYear(today.getFullYear() - 1);
    }
    setTempRange([newStartDate, today]);
  };

  const confirmRange = () => {
    if (tempRange.length === 2) {
      startDate.field.onChange(tempRange[0]);
      endDate.field.onChange(tempRange[1]);
    }
  };

  addLocale("he", {
    firstDayOfWeek: 0,
    dayNames: ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שבת"],
    dateFormat: "dd/mm/yy",
    dayNamesShort: ["א'", "ב'", "ג'", "ד'", "ה'", "ו'", "ש'"],
    dayNamesMin: ["א", "ב", "ג", "ד", "ה", "ו", "ש"],
    monthNames: [
      "ינואר",
      "פברואר",
      "מרץ",
      "אפריל",
      "מאי",
      "יוני",
      "יולי",
      "אוגוסט",
      "ספטמבר",
      "אוקטובר",
      "נובמבר",
      "דצמבר",
    ],
    monthNamesShort: [
      "ינו'",
      "פבר'",
      "מרץ",
      "אפר'",
      "מאי",
      "יוני",
      "יולי",
      "אוג'",
      "ספט'",
      "אוק'",
      "נוב'",
      "דצמ'",
    ],
    today: "היום",
    clear: "נקה",
  });

  return (
    <CalendarWrapperStyled>
      <Dropdown
        value={rangeOption}
        options={rangeOptions}
        onChange={(e) => handleRangeChange(e.value)}
        placeholder="בחר טווח"
      />
      <CalendarStyled
        locale="he"
        placeholder="בחר טווח תאריכים"
        ref={calendarRef}
        selectionMode="range"
        value={tempRange.length === 2 ? tempRange : null}
        onChange={(e) => {
          if (Array.isArray(e.value) && e.value.length === 2) {
            setTempRange([e.value[0] as Date, e.value[1] as Date]);
          }
        }}
        dateFormat="dd/mm/yy"
      />
      <Button onClick={confirmRange}>אישור</Button>
    </CalendarWrapperStyled>
  );
};

export default DateRangePicker;
