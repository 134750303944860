// PostEventContent.tsx
import React from "react";
import styled from "styled-components";
import { Galleria } from "primereact/galleria";
import { AttachmentMedia, PostEntity } from "neword-core";

const PostText = styled.div`
  font-size: 13px;
  color: var(--text-color);
  margin-right: 10px;
  text-align: start;
  letter-spacing: -0.2px;
`;

const PostImage = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
`;

const GalleriaStyled = styled(Galleria)`
  width: 60px;
  ul {
    line-height: 0;
  }
  .p-galleria-indicator button {
    width: 0.5rem !important;
    height: 0.1rem !important;
  }
  .p-galleria-indicators {
    padding: 0 !important;
  }
  .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: var(--primary-purple);
  }
`;

interface ContentProps {
  post: PostEntity;
}

const NoteEventContent: React.FC<ContentProps> = ({ post }) => {
  return (
    <div className="flex w-full">
      {post.data.imageUrls && (
        <GalleriaStyled
          changeItemOnIndicatorHover
          value={post.data.imageUrls}
          numVisible={1}
          autoPlay
          showThumbnails={false}
          showIndicators
          item={(item: AttachmentMedia) =>
            item.metadata.type === "VIDEO" ? (
              <video
                src={item.url}
                autoPlay
                loop
                muted
                style={{
                  width: "60px",
                  aspectRatio: "9/16",
                  backgroundColor: "black",
                  borderRadius: "8px",
                }}
              />
            ) : (
              <PostImage src={item.url} />
            )
          }
        />
      )}
      <PostText>
        {post.data.message && post.data.message.length > 52
          ? post.data.message.substring(0, 52) + "..."
          : post.data.message || ""}
      </PostText>
    </div>
  );
};

export default NoteEventContent;
