import { useRef } from "react";
import { Dialog } from "primereact/dialog";
import { plugin_trim, plugin_trim_locale_en_gb } from "@pqina/pintura-video";
import {
  createDefaultImageWriter,
  getEditorDefaults,
  PinturaEditorDefaultOptions,
  setPlugins,
} from "@pqina/pintura";
import { PinturaEditor } from "@pqina/react-pintura";
import { websiteService } from "../../core/services/website.service";
import { useParams } from "react-router";

setPlugins(plugin_trim);

export default function VideoFrameImagePicker({
  url,
  onClose,
  onProcess,
  imageSettings,
  hideCloseButton,
}: {
  url: string;
  onProcess: (media: any) => void;
  onClose: () => void;
  imageSettings?: PinturaEditorDefaultOptions;
  hideCloseButton?: boolean;
}) {
  const { websiteId } = useParams();

  const editorRef = useRef<any>(null);
  const processingRef = useRef(false);

  const handleDone = async () => {
    if (processingRef.current) return;
    processingRef.current = true;

    try {
      const result = await editorRef.current.processImage({
        imageWriter: createDefaultImageWriter({
          mimeType: "image/jpeg",
          quality: 0.95,
        }),
      });

      if (result && result.dest) {
        const blob = result.dest;

        const timestamp = new Date().getTime();
        const filename = `frame_${timestamp}.jpg`;

        const file = new File([blob], filename, {
          type: "image/jpeg",
        });

        const reader = new FileReader();
        reader.onload = async () => {
          const base64String = reader.result?.toString().split(",")[1];
          if (base64String) {
            // Ensure the upload filename has .jpg extension
            url.split(".");
            const uploadFilename = `frameFrom_${url.split(".")[0]}.jpg`;
            const attachmentUrl = await websiteService.uploadAttachment(
              websiteId as string,
              uploadFilename,
              base64String
            );
            onClose();
            onProcess(attachmentUrl);
          }
        };
        reader.onerror = (error) => {
          console.error("Error converting file to Base64:", error);
        };

        reader.readAsDataURL(file);
      }
    } catch (err) {
      console.error("Export error:", err);
    } finally {
      processingRef.current = false;
    }
  };

  return (
    <Dialog
      style={{
        width: "60vw",
        margin: "0",
        boxShadow: "none",
        height: "94vh",
        borderLeft: "solid 1px var(--border-color)",
        borderRadius: "0px",
        maxHeight: "100%",
        padding: "0",
      }}
      closable={!hideCloseButton}
      visible
      onHide={onClose}
      modal
    >
      <h3 className="text-center">
        לבחירת תמונת קאבר יש לבחור את הזמן בו מופיעה התמונה המתאימה וללחוץ על
        done
      </h3>

      <PinturaEditor
        onInit={(editor) => {
          editorRef.current = editor;
        }}
        src={url}
        onProcess={handleDone}
        imageWriter={createDefaultImageWriter({
          mimeType: "image/jpeg",
          quality: 0.95,
        })}
        {...{
          ...getEditorDefaults({
            ...imageSettings,
            utils: ["trim"],
            locale: {
              ...plugin_trim_locale_en_gb,
            },
          }),
        }}
      />
    </Dialog>
  );
}
