import { useDrag } from "react-dnd";
import PostCalendarEvent from "./calendarEventTile/PostCalendarEvent";

export const DraggablePost = ({
  post,
  clientView,
  isPast,
}: {
  post: any;
  clientView?: boolean;
  isPast: boolean;
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "POST",
    item: { post },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      className="w-full"
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <PostCalendarEvent isPast={isPast} post={post} clientView={clientView} />
    </div>
  );
};
