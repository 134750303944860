import React, { useMemo, useRef } from "react";
import styled from "styled-components";
import { useDrag } from "react-dnd";
import Card from "../common/Card";
import Badge from "../common/Badge";
import { Theme } from "../../core/theme/theme";
import { ReactComponent as ScheduleIcon } from "../../assets/Icons/Schedule.svg";
import { ReactComponent as TagsIcon } from "../../assets/Icons/Tags.svg";
import { ReactComponent as UrgentIcon } from "../../assets/Icons/Urgent.svg";
import { ReactComponent as HighIcon } from "../../assets/Icons/PriorityHigh.svg";
import { ReactComponent as MediumIcon } from "../../assets/Icons/PriorityMedium.svg";
import { ReactComponent as LowIcon } from "../../assets/Icons/PriorityLow.svg";
import { Avatar, AvatarGroup } from "@mui/material";
import { Direction } from "react-toastify/dist/utils";
import { Task, TaskPriority } from "neword-core";
import { useRecoilValue } from "recoil";
import { usersState } from "../../state/userState";

// A wrapper for icons to enforce consistent sizing and spacing.
const IconWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  margin-right: 4px;
  svg {
    width: 16px;
    height: 16px;
  }
`;

const CardStyled = styled(Card)`
  background-color: #fff;
  margin-bottom: 0.75rem;
  padding: 1rem;
  border: 1px solid #dfe1e6;
  border-radius: 4px;
  cursor: pointer;
  transition:
    box-shadow 0.2s ease,
    border-color 0.2s ease,
    transform 0.1s ease;

  &:hover {
    border-color: var(--title-color);
  }
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const Title = styled.h3`
  font-size: 1.125rem;
  color: var(--title-color, #172b4d);
  margin: 0;
`;

const MetaData = styled.div`
  font-size: 0.875rem;
  color: #5e6c84;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Description = styled.p`
  font-size: 0.9375rem;
  color: #42526e;
  margin-bottom: 0.75rem;
`;

const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const BadgeStyled = styled(Badge)`
  border: 1px solid var(--border-color);
  border-radius: 4px;
  svg {
    width: 16px;
    height: 16px;
  }
`;

const Footer = styled.div`
  border-top: 1px solid var(--border-color);
  padding-top: 0.75rem;
  margin-top: 0.75rem;
`;

interface TaskCardProps {
  task: Task;
  onClick?: (task: Task) => void;
  columnId?: string;
  index: number;
  moveTask?: (
    taskId: string,
    fromColumnId: string,
    toColumnId: string,
    fromIndex: number,
    toIndex: number
  ) => void;
}

// Create a lookup object mapping each priority to its color and icon.
const priorityMapping: Record<
  TaskPriority,
  { color: "red" | "orange" | "yellow" | "green" | "text"; icon: JSX.Element }
> = {
  HIGH: { color: "orange", icon: <HighIcon /> },
  MEDIUM: { color: "yellow", icon: <MediumIcon /> },
  LOW: { color: "green", icon: <LowIcon /> },
};

const TaskCard: React.FC<TaskCardProps> = ({
  task,
  onClick,
  columnId,
  index,
  moveTask,
}) => {
  const users = useRecoilValue(usersState);
  const assignee = useMemo(() => {
    return users.find((user) => user.id === task.assigneeUserId);
  }, [users]);

  const [{ isDragging }, dragRef] = useDrag({
    type: "TASK",
    item: { taskId: task.id, fromColumnId: columnId, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // Combine local ref with drag ref.
  const localRef = useRef<HTMLDivElement | null>(null);
  const combinedRef = (node: HTMLDivElement | null) => {
    localRef.current = node;
    dragRef(node);
  };

  const priorityData = priorityMapping[task.priority] || {
    color: "text" as const,
    icon: <div />,
  };

  return (
    <CardStyled
      ref={combinedRef}
      style={{
        opacity: isDragging ? 0.3 : 1,
        transform: isDragging ? "scale(1.02)" : "scale(1)",
        boxShadow: isDragging ? "0 5px 10px rgba(0, 0, 0, 0.15)" : "none",
      }}
      onClick={() => onClick?.(task)}
    >
      <TitleRow>
        <Title>{task.title}</Title>
        <div style={{ display: "flex", alignItems: "center" }}>
          <BadgeStyled
            large
            textColor={priorityData.color}
            icon={priorityData.icon}
            bgColor="transparent"
          >
            <></>
            {/* {task.priority} */}
          </BadgeStyled>
        </div>
      </TitleRow>
      <MetaData>
        <IconWrapper>
          <ScheduleIcon />
        </IconWrapper>
        {new Date(task.deadline).toLocaleDateString()}
      </MetaData>
      <Description>{task.description}</Description>
      {/* <TagsContainer>
        <IconWrapper>
          <TagsIcon />
        </IconWrapper>
        {task.tags.map((tag, idx) => (
          <Badge textColor="title" bgColor="bg" key={idx}>
            {tag}
          </Badge>
        ))}
      </TagsContainer> */}
      <Footer>
        {assignee && (
          <AvatarGroup style={{ direction: "ltr" }}>
            <Avatar
              sx={{ width: 24, height: 24 }}
              alt={assignee.firstName[0]}
              src="/static/images/avatar/1.jpg"
            />
          </AvatarGroup>
        )}
      </Footer>
    </CardStyled>
  );
};

export default TaskCard;
