import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { globalModalState } from "../state/globalModalState";
import { appIntegrationState } from "../state/appIntegrationState";
import { AppIntegrationApplicationType } from "neword-core";
import { useParams } from "react-router";
import appIntegrationService from "../core/services/appIntegration.service";
import { FacebookPage } from "../core/entities/facebookPage";
import { AppIntegration } from "../core/entities/appIntegration";
import useGa4Auth from "./analytics/useGa4Auth";
import AppConfig from "../config/appConfig";
import { v4 } from "uuid";
import { globalLoadingState } from "../state/globalLoadingState";

const useIntegrations = (websiteIdParam?: string) => {
  let { websiteId } = useParams();

  if (!websiteId) {
    websiteId = websiteIdParam;
  }

  if (!websiteId) throw new Error("websiteId is required");

  const [appIntegrations, setAppIntegrations] = useRecoilState(
    appIntegrationState(websiteId as string)
  );
  const { onGoogleAnalyticsLogin } = useGa4Auth(websiteId as string);

  const [, setIsLoading] = useRecoilState(globalLoadingState);

  const [showFacebookModal, setShowFacebookModal] = useState({
    open: false,
    type: AppIntegrationApplicationType.FACEBOOK,
  });

  const [showLinkedinCompanyModal, setShowLinkedinCompanyModal] =
    useState(false);
  const [facebookPages, setFacebookPages] = useState<FacebookPage[]>([]);

  const facebookLogin = async (metaType: AppIntegrationApplicationType) => {
    const windowAny: any = window;
    const FB = windowAny.FB;
    FB.login(
      function (response: any) {
        if (response.authResponse) {
          const token = response.authResponse.accessToken;
          setIsLoading(true);
          appIntegrationService
            .getFacebookPages(
              token,
              metaType === AppIntegrationApplicationType.INSTAGRAM
            )
            .then(async (pages) => {
              setIsLoading(false);
              setFacebookPages(pages);
              setShowFacebookModal({
                open: true,
                type: metaType,
              });
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        config_id: "1259532178653389",
        auth_type: "reauthenticate", // Replace with your actual config_id)
      }
    );
  };

  const facebookToggle = async (value: boolean) => {
    const facebookIntegration = appIntegrations.find(
      (i) =>
        i.appIntegrationApplicationType ===
        AppIntegrationApplicationType.FACEBOOK
    );

    if (
      !facebookIntegration ||
      (facebookIntegration && !facebookIntegration.isEnabled)
    ) {
      facebookLogin(AppIntegrationApplicationType.FACEBOOK);
      return;
    } else {
      appIntegrationService
        .updateAppIntegrationByWebsiteId(facebookIntegration.id, {
          isEnabled: value,
        })
        .then(() => {
          setAppIntegrations(
            appIntegrations.map((i) =>
              i.id === facebookIntegration.id ? { ...i, isEnabled: value } : i
            )
          );
        });
    }
  };

  const instagramWithToggleToggle = async (value: boolean) => {
    const instagramIntegration = appIntegrations.find(
      (i) =>
        i.appIntegrationApplicationType ===
        AppIntegrationApplicationType.INSTAGRAM
    );

    if (
      !instagramIntegration ||
      (instagramIntegration && !instagramIntegration.isEnabled)
    ) {
      facebookLogin(AppIntegrationApplicationType.INSTAGRAM);
      return;
    } else {
      appIntegrationService
        .updateAppIntegrationByWebsiteId(instagramIntegration.id, {
          isEnabled: value,
        })
        .then(() => {
          console.log("");
          setAppIntegrations(
            appIntegrations.map((i) =>
              i.id === instagramIntegration.id ? { ...i, isEnabled: value } : i
            )
          );
        });
    }
  };

  const onToggleAppIntegration = async (value: boolean) => {
    const updatedAppIntegration = appIntegrations.find(
      (a) => a.id === AppIntegrationApplicationType.EMAIL
    ) as AppIntegration;
    const filteredAppIntegrations = appIntegrations.filter(
      (a) => a.id !== updatedAppIntegration.id
    );

    setAppIntegrations([
      ...filteredAppIntegrations,
      {
        ...updatedAppIntegration,
        isEnabled: value,
      },
    ]);

    try {
      await appIntegrationService.updateAppIntegrationByWebsiteId(
        updatedAppIntegration.id,
        {
          isEnabled: value,
        }
      );
    } catch (err) {}
  };

  const linkedinToggle = async (value: boolean) => {
    const linkedinIntegration = appIntegrations.find(
      (i) =>
        i.appIntegrationApplicationType ===
        AppIntegrationApplicationType.LINKEDIN
    );

    if (
      !linkedinIntegration ||
      (linkedinIntegration && !linkedinIntegration.isEnabled)
    ) {
      const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77gvdtjx20456n&redirect_uri=${AppConfig.serverUrl}/app-integration%2Flinkedin%2Fauth&state=${websiteId}&scope=openid%20profile%20w_member_social%20email`;

      handleIntegrationUrl(url);

      return;
    } else {
      appIntegrationService
        .updateAppIntegrationByWebsiteId(linkedinIntegration.id, {
          isEnabled: value,
        })
        .then(() => {
          setAppIntegrations(
            appIntegrations.map((i) =>
              i.id === linkedinIntegration.id ? { ...i, isEnabled: value } : i
            )
          );
        });
    }
  };

  const linkedinCompanyToggle = async (value: boolean) => {
    const linkedinIntegration = appIntegrations.find(
      (i) =>
        i.appIntegrationApplicationType ===
        AppIntegrationApplicationType.LINKEDIN_COMPANY
    );

    if (
      !linkedinIntegration ||
      (linkedinIntegration && !linkedinIntegration.isEnabled)
    ) {
      const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=777i5x86cmgha5&redirect_uri=${AppConfig.serverUrl}/app-integration%2Flinkedin-company%2Fauth&state=${websiteId}&scope=w_member_social%20r_organization_social_feed%20w_member_social%20r_organization_followers%20r_basicprofile%20rw_organization_admin%20r_organization_social%20w_organization_social`;

      handleIntegrationUrl(url, AppIntegrationApplicationType.LINKEDIN_COMPANY);

      return;
    } else {
      appIntegrationService
        .updateAppIntegrationByWebsiteId(linkedinIntegration.id, {
          isEnabled: value,
          externalId: "",
          name: "",
          imageUrl: "",
        })
        .then(() => {
          setAppIntegrations(
            appIntegrations.map((i) =>
              i.id === linkedinIntegration.id
                ? {
                    ...i,
                    isEnabled: value,
                    externalId: "",
                    name: "",
                    imageUrl: "",
                  }
                : i
            )
          );
        });
    }
  };

  const analyticsToggle = async (value: boolean) => {
    const updatedAppIntegration = appIntegrations.find(
      (a) =>
        a.appIntegrationApplicationType ===
        AppIntegrationApplicationType.GOOGLEANALYTICS
    ) as AppIntegration;

    const filteredAppIntegrations = appIntegrations.filter(
      (a) => a.id !== updatedAppIntegration.id
    );

    setAppIntegrations([
      ...filteredAppIntegrations,
      {
        ...updatedAppIntegration,
        isEnabled: value,
      },
    ]);

    try {
      if (value) {
        onGoogleAnalyticsLogin();
      } else {
        await appIntegrationService.updateAppIntegrationByWebsiteId(
          updatedAppIntegration.id,
          {
            isEnabled: value,
          }
        );
      }
    } catch (err) {}
  };

  const xToggle = async (value: boolean) => {
    const xIntegration = appIntegrations.find(
      (i) => i.appIntegrationApplicationType === AppIntegrationApplicationType.X
    );

    if (!xIntegration || !xIntegration.isEnabled) {
      const url = `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=X0EwVExrSHprYW4tSms5a1V5enQ6MTpjaQ&redirect_uri=${AppConfig.serverUrl}/app-integration%2Fx%2Fauth&scope=tweet.read%20users.read%20offline.access%20tweet.read&state=${websiteId}&code_challenge=challenge&code_challenge_method=plain`;
      handleIntegrationUrl(url);
      return;
    } else {
      appIntegrationService
        .updateAppIntegrationByWebsiteId(xIntegration.id, {
          isEnabled: value,
        })
        .then(() => {
          setAppIntegrations(
            appIntegrations.map((i) =>
              i.id === xIntegration.id ? { ...i, isEnabled: value } : i
            )
          );
        });
    }
  };
  const tiktokToggle = async (value: boolean) => {
    const tiktokIntegration = appIntegrations.find(
      (i) =>
        i.appIntegrationApplicationType === AppIntegrationApplicationType.TIKTOK
    );

    if (!tiktokIntegration || !tiktokIntegration.isEnabled) {
      const url = `https://www.tiktok.com/v2/auth/authorize?client_key=awpmogtazz6lh4b2&disable_auto_auth=1&response_type=code&scope=user.info.basic,video.upload,video.publish,video.list,user.info.profile&redirect_uri=${
        AppConfig.devMode
          ? `https://8f8f-2a0d-6fc0-704-9800-cdd0-a237-c6b6-a330.ngrok-free.app/app-integration/tiktok/auth`
          : AppConfig.serverUrl + "/app-integration/tiktok/auth"
      }&state=${websiteId}`;

      handleIntegrationUrl(url);
    } else {
      appIntegrationService
        .updateAppIntegrationByWebsiteId(tiktokIntegration.id, {
          isEnabled: value,
        })
        .then(() => {
          setAppIntegrations(
            appIntegrations.map((i) =>
              i.id === tiktokIntegration.id ? { ...i, isEnabled: value } : i
            )
          );
        });
    }
  };

  const instagramWithInstagramToggle = async (value: boolean) => {
    const instagramIntegration = appIntegrations.find(
      (i) =>
        i.appIntegrationApplicationType ===
        AppIntegrationApplicationType.INSTAGRAM
    );
    //
    if (!instagramIntegration || !instagramIntegration.isEnabled) {
      const url = `https://www.instagram.com/oauth/authorize?client_id=918811947006987&state=${websiteId}&redirect_uri=${
        AppConfig.devMode
          ? `https://cfd2-85-64-128-177.ngrok-free.app/app-integration/instagram/auth`
          : AppConfig.serverUrl + "/app-integration/instagram/auth"
      }&response_type=code&scope=instagram_business_basic%2Cinstagram_business_content_publish%2Cinstagram_business_manage_insights`;

      handleInstagramAfterLogin(url);
    } else {
      appIntegrationService
        .updateAppIntegrationByWebsiteId(instagramIntegration.id, {
          isEnabled: value,
        })
        .then(() => {
          setAppIntegrations(
            appIntegrations.map((i) =>
              i.id === instagramIntegration.id ? { ...i, isEnabled: value } : i
            )
          );
        });
    }
  };

  const getIntegrationToggle: Partial<
    Record<AppIntegrationApplicationType, (value: boolean) => {}>
  > = {
    [AppIntegrationApplicationType.FACEBOOK]: facebookToggle,
    [AppIntegrationApplicationType.LINKEDIN]: linkedinToggle,
    [AppIntegrationApplicationType.GOOGLEANALYTICS]: analyticsToggle,
    [AppIntegrationApplicationType.X]: xToggle,
    [AppIntegrationApplicationType.EMAIL]: onToggleAppIntegration,
    [AppIntegrationApplicationType.TIKTOK]: tiktokToggle,
    [AppIntegrationApplicationType.INSTAGRAM]: instagramWithToggleToggle,
    [AppIntegrationApplicationType.LINKEDIN_COMPANY]: linkedinCompanyToggle,
  };

  const handleIntegrationUrl = (
    url: string,
    type?: AppIntegrationApplicationType
  ) => {
    const newWindow = window.open(
      url,
      "_blank",
      "width=600,height=700,left=100,top=100,resizable=yes,scrollbars=yes"
    );
    if (newWindow) {
      const checkInterval = setInterval(() => {
        if (newWindow.closed) {
          console.log("here");
          clearInterval(checkInterval);
          appIntegrationService
            .getAppIntegrationsByWebsiteId(websiteId as string)
            .then((integrations) => {
              setAppIntegrations(integrations);

              if (type === AppIntegrationApplicationType.LINKEDIN_COMPANY) {
                const linkedCompanyIntegration = integrations.find(
                  (i) =>
                    i.appIntegrationApplicationType ===
                    AppIntegrationApplicationType.LINKEDIN_COMPANY
                );

                if (
                  linkedCompanyIntegration &&
                  linkedCompanyIntegration.externalId === ""
                ) {
                  setShowLinkedinCompanyModal(true);
                }
              }
            });
        }
      }, 500);
    }
  };

  const handleInstagramAfterLogin = (url: string) => {
    const newWindow = window.open(
      url,
      "_blank",
      "width=600,height=8000,left=100,top=100,resizable=yes,scrollbars=yes"
    );
    if (newWindow) {
      const checkInterval = setInterval(() => {
        if (newWindow.closed) {
          console.log("Window closed");
          clearInterval(checkInterval);

          // Start polling the backend for integration status
          const pollInterval = setInterval(() => {
            appIntegrationService
              .getAppIntegrationsByWebsiteId(websiteId as string)
              .then((integrations) => {
                setAppIntegrations(integrations);

                // Check if Instagram integration is completed
                const isIntegrated = integrations.find(
                  (i) =>
                    i.appIntegrationApplicationType ===
                    AppIntegrationApplicationType.INSTAGRAM
                );

                if (isIntegrated && isIntegrated.isEnabled) {
                  clearInterval(pollInterval); // Stop polling when integration is confirmed
                  console.log("Instagram integration completed!");
                }
              })
              .catch((error) => {
                console.error("Error checking integration status:", error);
              });
          }, 500); // Poll every 2 seconds
        }
      }, 100);
    }
  };

  return {
    getIntegrationToggle,
    showFacebookModal,
    facebookPages,
    showLinkedinCompanyModal,
    setShowLinkedinCompanyModal,
    setShowFacebookModal,
    instagramWithInstagramToggle,
  };
};

export default useIntegrations;
//
