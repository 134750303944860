// components/ProtectedButton.tsx
import React, { useState } from "react";
import { Features } from "neword-core";
import UpgradePackage from "../components/modals/UpgradePackage";
import { usePermissionCheck } from "../hooks/usePermission";

interface ProtectedButtonProps {
  featureKey: Features;
  onClick: () => void;
  children: (handleClick: () => void) => React.ReactElement;
}

export const ProtectedButton: React.FC<ProtectedButtonProps> = ({
  featureKey,
  onClick,
  children,
}) => {
  const { checkFeaturePermission } = usePermissionCheck();
  // const [modalOpen, setModalOpen] = useState(false);

  const handleClick = async () => {
    if (await checkFeaturePermission(featureKey)) {
      onClick();
    } else {
      // setModalOpen(true);
    }
  };

  return (
    <>
      {children(handleClick)}
      {/* {modalOpen && <UpgradePackage onHide={() => setModalOpen(false)} />} */}
    </>
  );
};
