import { atom } from "recoil";

const LOCAL_STORAGE_KEY = "NEWORD_SHRINK_STATE";

export const shrinkState = atom<boolean>({
  key: "shrinkState",
  default: false,
  effects_UNSTABLE: [
    ({ setSelf, onSet }) => {
      // Load from localStorage on initialization
      const savedValue = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (savedValue !== null) {
        setSelf(JSON.parse(savedValue));
      }

      // Save to localStorage whenever the state changes
      onSet((newValue) => {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newValue));
      });
    },
  ],
});
