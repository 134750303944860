import { Divider } from "primereact/divider";
import { InputTextarea } from "primereact/inputtextarea";
import {
  FieldErrors,
  FieldValues,
  Path,
  useFormContext,
  useWatch,
} from "react-hook-form";
import styled from "styled-components";
import IgPreview from "../postPreviews/IgPreview";
import { useRef, useEffect, useMemo, useState } from "react";
import CardTitle from "../../common/CardTitle";
import TextEditor from "../../common/publishPostModal/TextEditor";
import LocationDropdown from "../../common/publishPostModal/LocationDropdown";
import MultyAttachmentInput from "../../common/form/MultyAttachmentInput";
import Card from "../../common/Card";
import CircleBg from "../../../assets/images/bgcirclepurple.png";
import { Message } from "primereact/message";
import { coverPhotoValidation } from "./mediaValdiation/coverPhotoImageValidation";
import Button from "../../common/form/Button";
import VideoFrameImagePicker from "../videoFrameImagePicker";
import { AttachmentMedia } from "neword-core";
import { useTranslation } from "react-i18next";
import AttachmentInputFullMediaObject, {
  singleInputType,
} from "../../common/form/AttachmentsInputFullObject";
import TagInputsSection from "../TagInputsSection";

const LabelStyled = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: var(--text-color);
  padding-bottom: 10px;
`;

const ImageWrapper = styled.div`
  background-size: cover;
  position: relative;
  overflow: hidden;
  background-color: var(--light-bg);
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;

const CoverPhotoWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;

  .hov:hover {
    background: var(--bg-dark) !important;
  }
`;

function InstagramReelPostForm<FormDataType extends FieldValues>({
  name,
  index,
}: {
  name: Path<FormDataType>;
  index?: number;
}) {
  const {
    setValue,
    control,
    formState: { errors },
    getValues,
  } = useFormContext();
  const { t } = useTranslation();

  const { posts = [] } = useWatch({ control }); // Ensuring that posts has a default value

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [imageUrlError, setImageUrlError] = useState("");
  const [showFramePicker, setshowFramePicker] = useState<boolean>(false);

  const autoResizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const video = getValues(`${name}.imageUrls.0`);
  const coverPhotoUrl = useWatch({
    control,
    name: `${name}.coverPhotoUrl`,
  });

  useEffect(() => {
    autoResizeTextarea(); // Initial adjustment on mount or when post content changes
  }, [posts]);

  return (
    <div className="grid m-0 h-full">
      <div className="col-8 pl-4 pr-4 pt-4">
        <CardTitle title={t("instagram_reel_post_form.post_title")} />
        <Divider />

        <div className="post p-2 mb-4">
          <div className="cardoverflow">
            <LabelStyled>
              {t("instagram_reel_post_form.post_content_label")}
            </LabelStyled>
            <TextEditor haveTags name={name} />

            <Divider />
            <LabelStyled>
              {t("instagram_reel_post_form.add_media_label")}
            </LabelStyled>
            {/* <AttachmentInput<any> name={`${name}.imageUrl`} /> */}
            <MultyAttachmentInput
              name={`${name}.imageUrls`}
              videoOnly
              validate={(value) => {
                console.log("validate", value);

                const videos = value.filter(
                  (item) => item.metadata.type === "VIDEO"
                );
                const images = value.filter(
                  (item) => item.metadata.type === "IMAGE"
                );
                let errorText = "";

                if (videos.length !== 1) {
                  errorText = t(
                    "instagram_reel_post_form.error_message_video_required"
                  );
                }
                if (images.length > 0) {
                  errorText = t(
                    "instagram_reel_post_form.error_message_image_not_allowed"
                  );
                }

                setImageUrlError(errorText);
                if (errorText === "") {
                  return true;
                }

                return errorText;
              }}
            />
            <>
              {video && video.metadata.type === "VIDEO" && (
                <>
                  <LabelStyled>בחירת קאבר</LabelStyled>
                  <CoverPhotoWrapper>
                    <AttachmentInputFullMediaObject
                      name={`${name}.coverPhotoUrl`}
                      validate={(value) => {
                        const errorText = coverPhotoValidation(value);
                        setImageUrlError(errorText);
                        if (errorText === "") {
                          return true;
                        }
                        return errorText;
                      }}
                      type={singleInputType.onlyText}
                      includeVideos={false}
                    />
                    <Button
                      className="hov"
                      bgColor="bg"
                      onClick={() => {
                        setshowFramePicker(true);
                      }}
                    >
                      בחירת פריים מהסרטון
                    </Button>
                  </CoverPhotoWrapper>
                  {coverPhotoUrl ? (
                    <Message severity="success" text="נוסף תמונת קאבר" />
                  ) : (
                    <Message
                      className="mt-3"
                      severity="info"
                      text="אם לא נבחר קאבר, הקאבר יהיה הפריים הראשון"
                    />
                  )}
                </>
              )}
            </>

            {showFramePicker && video && video.metadata.type === "VIDEO" ? (
              <VideoFrameImagePicker
                url={video.url}
                onProcess={(attachment: AttachmentMedia) => {
                  setValue(name + ".coverPhotoUrl", attachment);
                }}
                imageSettings={{
                  imageCropAspectRatio: 9 / 16, // Maintain TikTok's vertical aspect ratio
                }}
                onClose={() => setshowFramePicker(false)}
              />
            ) : (
              <></>
            )}
          </div>

          <div className="media p-2 mt-0 ">
            {imageUrlError !== "" && (
              <Message severity="warn" text={imageUrlError} />
            )}
          </div>
          {/* <LabelStyled className="mt-2">הוסף מיקום</LabelStyled>
          <LocationDropdown name={`${name}.location`} /> */}
        </div>
      </div>
      <div className="col-4 p-0">
        <ImageWrapper className="">
          <IgPreview reel={true} name={name} />
        </ImageWrapper>
      </div>
    </div>
  );
}

export default InstagramReelPostForm;
