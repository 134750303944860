import { useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import AudienceSelect from "../../common/form/AudienceSelect";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import DocumentTone from "../components/DocumentTone";
import BlogPostOpinionDescription from "./formComponentes/BlogPostOpinionDescription";
import { AccordionProps } from "./types/AccordionProps";
import BlogPostSharedSettings from "./advencedSettings/BlogPostSharedSettings";
import BlogPostTipsDescription from "./formComponentes/BlogPostTipsDescription";
import BlogPostComparisonDescription from "./formComponentes/BlogPostComparisonDescription";
import {useTranslation} from "react-i18next";

export const useBlogPostComparisonAccordions = (): AccordionProps[] => {
  const { t } = useTranslation();
 return [
   {
     title: t("blog_post_case_study_accordions.content_description"),
     content: <BlogPostComparisonDescription />,
     fieldNames: ["inputParams.blogTopic", "inputParams.comparisonFocusPoints"],
   },

   {
     title: t("blog_post_case_study_accordions.target_audience"),
     content: (
       <AudienceSelect<
           DocumentEntity & { type: DocumentEntityType.BLOG_POST_COMPARISON }
         > fieldName="inputParams.audienceId" />
     ),
     fieldNames: ["inputParams.audienceId"],
   },
   {
     title: t("blog_post_case_study_accordions.writing_style"),
     content: (
       <>
         <DocumentTone<
             DocumentEntity & { type: DocumentEntityType.BLOG_POST_COMPARISON }
           >
           fieldName="inputParams.tone"
           threeColumn={true}
         />
       </>
     ),
     fieldNames: ["inputParams.tone"],
   },
 ];
}
