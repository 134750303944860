import { AttachmentMedia } from "neword-core";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18s";

export default (value: AttachmentMedia[]) => {
  const videos = value.filter((item) => item.metadata.type === "VIDEO");
  const images = value.filter((item) => item.metadata.type === "IMAGE");
  let errorText = "";

  // Basic validation for videos and images
  if (videos.length !== 1) {
    errorText = i18n.t("media_validation.video_limit_error");
  }
  if (images.length > 0) {
    errorText = i18n.t("media_validation.image_limit_error");
  }

  // Validation for Facebook Reels metadata

  return errorText;
};
