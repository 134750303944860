import { PostEntityType } from "neword-core";
import { Menu } from "primereact/menu";
import styled from "styled-components";
import { PostCardIcons } from "./postIconMappings";

import IconButton from "../common/IconButton";

import { ReactComponent as PlusIcon } from "../../assets/Icons/Plus.svg";
import { ReactComponent as RemoveIcon } from "../../assets/Icons/Remove.svg";

const SocialWrapper = styled.div`
  width: 80px;
`;

const IconWrapper = styled.div<{ isEditing: boolean }>`
  cursor: pointer;
`;

const PlatformsWrapper = styled.div`
  border-left: 1px solid var(--border-color);
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding-top: 20px;
`;

const RemoveSocial = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 15px;
  height: 15px;
  background: var(--title-color);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  opacity: 0;
  pointer-events: none;
`;

const PlatformIconWrapper = styled.div<{
  backgroundColor?: string;
  active?: boolean;
  dashed?: boolean;
  iconColor?: string;
}>`
  position: relative;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: ${(props) =>
    props.active ? props.backgroundColor : "var(--border-color)"};
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${(props) => (props.dashed ? "1px dashed lightgrey" : "none")};

  &:hover ${RemoveSocial} {
    opacity: 1;
    pointer-events: all;
  }

  &:hover {
    border-color: var(--primary-purple);
    svg path {
      fill: ${(props) =>
        props.iconColor
          ? props.iconColor
          : props.active
            ? "white !important"
            : "#9AA8B6 !important"};
    }
  }
  svg {
    width: 15px;
    height: 15px;
    fill: ${(props) =>
      props.iconColor
        ? props.iconColor
        : props.active
          ? "white !important"
          : "#9AA8B6 !imporatant"};
    path {
      fill: ${(props) =>
        props.iconColor
          ? props.iconColor
          : props.active
            ? "white !important"
            : "#9AA8B6 !important"};
    }
  }
`;
// Subcomponent for the social platforms icons (left sidebar)
interface SocialPlatformsProps {
  postsFields: any[];
  postIndex: number;
  getTypeIndex: (type: PostEntityType) => number;
  onClickIcon: (type: PostEntityType) => void;
  handleRemovePost: (type: PostEntityType) => void;
  addSocialMenuRef: React.RefObject<Menu>;
}
export const SocialPlatforms: React.FC<SocialPlatformsProps> = ({
  postsFields,
  postIndex,
  getTypeIndex,
  onClickIcon,
  handleRemovePost,
  addSocialMenuRef,
}) => {
  return (
    <SocialWrapper>
      <PlatformsWrapper>
        {Object.entries(PostCardIcons)
          .filter(
            ([key, value]) =>
              !!value.iconPlain && getTypeIndex(key as PostEntityType) > -1
          )
          .map(([key, value]) => {
            const isActive = getTypeIndex(key as PostEntityType);
            return (
              <IconWrapper
                key={key}
                onClick={() => onClickIcon(key as PostEntityType)}
                isEditing={isActive === postIndex}
              >
                <PlatformIconWrapper
                  active={isActive === postIndex}
                  backgroundColor={value.color}
                >
                  {isActive > -1 && (
                    <RemoveSocial
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemovePost(key as PostEntityType);
                      }}
                      className="remove-icon"
                      data-pr-tooltip="הסר פלטפורמה מפרסום"
                      data-pr-position="right"
                    >
                      <RemoveIcon />
                    </RemoveSocial>
                  )}
                  <IconButton icon={value.iconPlain && <value.iconPlain />} />
                </PlatformIconWrapper>
              </IconWrapper>
            );
          })}
        <PlatformIconWrapper
          backgroundColor="white"
          active
          dashed
          iconColor="lightgrey"
          onClick={(event) => addSocialMenuRef.current?.toggle(event)}
          aria-controls="socialmenu"
          aria-haspopup
        >
          <PlusIcon />
        </PlatformIconWrapper>
      </PlatformsWrapper>
    </SocialWrapper>
  );
};
