import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React from "react";
import styled from "styled-components";
import { FacebookPage } from "../../../core/entities/facebookPage";
import appIntegrationService from "../../../core/services/appIntegration.service";
import { useParams } from "react-router-dom";
import { AppIntegrationApplicationType } from "neword-core";
import { AppIntegrationType } from "../../../core/types/appIntegrationType";
import { useRecoilState, useRecoilValue } from "recoil";
import { appIntegrationState } from "../../../state/appIntegrationState";
import { LoginWith } from "../../../core/types/loginWith";
import { useTranslation } from "react-i18next";
import { linkedinCompanyState } from "../../../state/linkedinCompanyState";
import { LinkedInCompany } from "../../../core/entities/linkedinCompany";

interface ModalProps {
  onClose: () => void;
}

const Grid = styled.div`
  display: flex; /* Change to flex */
  flex-wrap: wrap; /* Allow wrapping of items */
  gap: 10px; /* Add gap between items */
  justify-content: center;
  flex-direction: column;
`;

const ProfileCard = styled.div`
  border: 1px solid var(--border-color);
  transition: border 0.3s;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;

  &:hover {
    border: 1px solid var(--title-color);
  }

  img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
`;

const Title = styled.h1`
  color: #0a2540;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 2.5rem */
  letter-spacing: -0.075rem;
  width: auto;
  text-align: center;
`;

const SubTitle = styled.h2`
  color: #425466;
  text-align: center;
  font-weight: 300;
`;

const WrapperX = styled.div`
  margin-top: 40px;
`;

const LinkedinCompanysModal: React.FC<ModalProps> = ({ onClose }) => {
  const { websiteId } = useParams();
  const { t } = useTranslation();
  const [appIntegrations, setAppIntegrations] = useRecoilState(
    appIntegrationState(websiteId as string)
  );

  const companys = useRecoilValue(linkedinCompanyState(websiteId as string));

  const onClick = async (page: LinkedInCompany) => {
    const integration = await appIntegrations.find(
      (a) =>
        a.appIntegrationApplicationType ===
        AppIntegrationApplicationType.LINKEDIN_COMPANY
    );

    if (!integration) {
      onClose();
      return;
    }

    const appIn = await appIntegrationService.updateAppIntegrationByWebsiteId(
      integration.id as string,
      {
        isEnabled: true,
        externalId: page.organizationURN,
        imageUrl: page.logo,
        name: page.name,
      }
    );

    setAppIntegrations([
      ...appIntegrations.filter((a) => a.id !== appIn.id),
      appIn,
    ]);

    onClose();
  };

  return (
    <Dialog
      header={
        <WrapperX>
          <Title>{t("form.linkedin_pages_modal.title")}</Title>
          <SubTitle>{t("form.linkedin_pages_modal.subtitle")}</SubTitle>
        </WrapperX>
      }
      visible
      onHide={onClose}
      modal
    >
      <br />
      {
        <Grid>
          {companys.map((page) => (
            <ProfileCard
              onClick={() => onClick(page)}
              key={page.organizationURN}
            >
              {page.logo && <img src={page.logo} alt="profilee" />}
              <div>{page.name}</div>
            </ProfileCard>
          ))}
        </Grid>
      }
    </Dialog>
  );
};

export default LinkedinCompanysModal;
