export enum AppIntegrationApplicationType {
  EMAIL = "EMAIL",
  GOOGLEANALYTICS = "GOOGLEANALYTICS",
  LINKEDIN = "LINKEDIN",
  LINKEDIN_COMPANY = "LINKEDIN_COMPANY",
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  WHATSAPP = "WHATSAPP",
  X = "X",
  TIKTOK = "TIKTOK",
}
