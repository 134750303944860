// BlogPostSharedSettings.tsx
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { Chips } from "primereact/chips";
import CustomChip from "../../components/CustomChip";
import GenericDescriptionInput from "../formComponentes/GenericDescriptionInput";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import {useTranslation} from "react-i18next";

function SmsSharedSettings() {
  const { t } = useTranslation();
  return (
    <div className="w-full step-2-wt">
      <GenericDescriptionInput<
        DocumentEntity & { type: DocumentEntityType.FLASH_SALE_SMS }
      >
        name="inputParams.legalInformation"
        label={t("sms_shared_settings.legal_information_label")}
        placeholder={t("sms_shared_settings.legal_information_placeholder")}
      />
      <GenericDescriptionInput<
        DocumentEntity & { type: DocumentEntityType.FLASH_SALE_SMS }
      >
        name="inputParams.additionalInformation"
        label={t("sms_shared_settings.additional_information_label")}
        placeholder={t("sms_shared_settings.additional_information_placeholder")}
      />
    </div>
  );
}

export default SmsSharedSettings;
