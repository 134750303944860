import { useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import AudienceSelect from "../../common/form/AudienceSelect";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import DocumentTone from "../components/DocumentTone";
import BlogPostOpinionDescription from "./formComponentes/BlogPostOpinionDescription";
import { AccordionProps } from "./types/AccordionProps";
import GenericDescriptionInput from "./formComponentes/GenericDescriptionInput";
import {useTranslation} from "react-i18next";

export const useInstagramSponsoredAdAccordions = (): AccordionProps[] => {
  const { t } = useTranslation();
  return [
    {
      title: t("instagram_sponsored_ad_accordions.content_title"),
      content: (
        <div className="w-full step-2-wt">
          <GenericDescriptionInput<
              DocumentEntity & { type: DocumentEntityType.INSTAGRAM_SPONSORED_AD }
            >
            name="inputParams.mainMessage"
            label={t("instagram_sponsored_ad_accordions.main_message_label")}
            placeholder={t("instagram_sponsored_ad_accordions.main_message_placeholder")}
            showAttchment
          />
          <GenericDescriptionInput<
              DocumentEntity & { type: DocumentEntityType.INSTAGRAM_SPONSORED_AD }
            >
            name="inputParams.adGoal"
            label={t("instagram_sponsored_ad_accordions.ad_goal_label")}
            placeholder={t("instagram_sponsored_ad_accordions.ad_goal_placeholder")}
            inputStyle={{ height: 60 }}
          />
        </div>
      ),
      fieldNames: [
        "inputParams.cartItems",
        "inputParams.supportContact",
        "inputParams.incentives",
      ],
    },

    {
      title: t("instagram_sponsored_ad_accordions.target_audience"),
      content: (
        <AudienceSelect<
            DocumentEntity & { type: DocumentEntityType.CART_ABANDONMENT_EMAIL }
          > fieldName="inputParams.audienceId" />
      ),
      fieldNames: ["inputParams.audienceId"],
    },
    {
      title: t("instagram_sponsored_ad_accordions.writing_style"),
      content: (
        <>
          <DocumentTone<
              DocumentEntity & { type: DocumentEntityType.CART_ABANDONMENT_EMAIL }
            >
            fieldName="inputParams.tone"
            threeColumn={true}
          />
        </>
      ),
      fieldNames: ["inputParams.tone"],
    },
  ];
};
