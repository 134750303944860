import { useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import AudienceSelect from "../../common/form/AudienceSelect";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import DocumentTone from "../components/DocumentTone";
import BlogPostOpinionDescription from "./formComponentes/BlogPostOpinionDescription";
import { AccordionProps } from "./types/AccordionProps";
import BlogPostSharedSettings from "./advencedSettings/BlogPostSharedSettings";
import BlogPostTipsDescription from "./formComponentes/BlogPostTipsDescription";
import BlogPostComparisonDescription from "./formComponentes/BlogPostComparisonDescription";
import BlogPostCaseStudyDescription from "./formComponentes/BlogPostCaseStudyDescription";
import CartAbandonmentMailDescription from "./formComponentes/CartAbandonmentMailDescription";
import GenericDescriptionInput from "./formComponentes/GenericDescriptionInput";
import DocChipInput from "./formComponentes/DocChipInput";
import {useTranslation} from "react-i18next";

export const usePostAccordions = (): AccordionProps[] => {
  const { t } = useTranslation();
  return [
    {
      title: t("post_accordions.general_description_title"),
      content: (
        <div className="w-full step-2-wt">
          <GenericDescriptionInput<
              DocumentEntity & { type: DocumentEntityType.INSTAGRAM_CAPTION }
            >
            name="inputParams.description"
            label={t("post_accordions.post_description_label")}
            placeholder={t("post_accordions.post_description_placeholder")}
            showQuickOptions
            showAttchment
          />
          <GenericDescriptionInput<
              DocumentEntity & { type: DocumentEntityType.INSTAGRAM_CAPTION }
            >
            name="inputParams.postGoal"
            label={t("post_accordions.post_goal_label")}
            placeholder={t("post_accordions.post_goal_placeholder")}
            hideRephrase
            inputStyle={{ height: 60 }}
          />
        </div>
      ),
      fieldNames: [
        "inputParams.cartItems",
        "inputParams.supportContact",
        "inputParams.incentives",
      ],
    },

    {
      title: t("post_accordions.target_audience"),
      content: (
        <AudienceSelect<
            DocumentEntity & { type: DocumentEntityType.CART_ABANDONMENT_EMAIL }
          > fieldName="inputParams.audienceId" />
      ),
      fieldNames: ["inputParams.audienceId"],
    },
    {
      title: t("post_accordions.writing_style"),
      content: (
        <>
          <DocumentTone<
              DocumentEntity & { type: DocumentEntityType.CART_ABANDONMENT_EMAIL }
            >
            fieldName="inputParams.tone"
            threeColumn={true}
          />
        </>
      ),
      fieldNames: ["inputParams.tone"],
    },

    // {
    //   title: "הגדרות מדיה",
    //   content: (
    //     <GenericDescriptionInput<
    //       DocumentEntity & { type: DocumentEntityType.INSTAGRAM_CAPTION }
    //     >
    //       name="inputParams.imageDescription"
    //       label="תיאור למחולל תמונה"
    //       placeholder={`כתבו תיאור מפורט על התמונה שתופיע בפוסט.`}
    //       showQuickOptions
    //       showAttchment
    //     />
    //   ),
    // },
  ];
};
