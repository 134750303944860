import { User } from "../entities/user";
import { ServiceBase } from "./service.base";
import {
  AppIntegrationApplicationType,
  PageMetricsInsights,
  PostEntity,
  PostEntityType,
  PostInsights,
  PostStatusType,
} from "neword-core";

class PostService extends ServiceBase {
  prefix = "/post";

  getPostById(postId: string) {
    return this.get<PostEntity>(`/${postId}`);
  }

  getPostsByWebsiteId(websiteId: string) {
    return this.get<PostEntity[]>(`/website/${websiteId}`);
  }

  getClientGantLink(websiteId: string) {
    return this.get<string>(`/gant/${websiteId}`);
  }

  sendClientGantLink(
    websiteId: string,
    guestContactString: string,
    notificationType: "email" | "whatsapp",
    updatedGuestUser?: User
  ) {
    return this.post<boolean>(`/sendGant/${websiteId}`, {
      guestContactString: guestContactString,
      updatedGuestUser: updatedGuestUser,
      notificationType: notificationType,
    });
  }

  schedulePost(posts: PostEntity[], websiteId: string, publishNow = false) {
    return this.post<PostEntity[]>(`website/${websiteId}/create`, {
      posts,
      publishNow,
    });
  }

  publishPost(postId: string) {
    return this.post<PostEntity>(`/${postId}/publish`);
  }

  updatePost(post: PostEntity) {
    return this.post<PostEntity>(`/update`, post);
  }

  updatePostStatus(postId: string, status: PostStatusType) {
    return this.patch<PostEntity>(`/${postId}/status/${status}`);
  }

  deletePost(postId: string) {
    return this.delete<PostEntity>(`/${postId}`);
  }

  addComment(postId: string, comment: string, websiteId: string) {
    return this.post(`/${postId}/comment`, { comment, websiteId });
  }

  getInsights(websiteId: string) {
    return this.get<PostInsights[]>(`website/${websiteId}/postInsights`);
  }

  getPageInsights(
    websiteId: string,
    platform: AppIntegrationApplicationType,
    startDate: string,
    endDate: string
  ) {
    return this.get<PageMetricsInsights>(
      `/website/${websiteId}/pageInsights?platform=${platform}&startDate=${startDate}&endDate=${endDate}`
    );
  }
}
const postService = new PostService();
export default postService;
