import React, { memo } from "react";
import styled from "styled-components";

const MediaItemWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
`;

const ImageBox = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
  border: 1px solid rgb(230, 230, 230);
  transition: border 0.2s;
  &:hover {
    border: 1px solid var(--primary-color);
  }
`;

const VideoBox = styled.video`
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
  border: 1px solid rgb(230, 230, 230);
  transition: border 0.2s;
  &:hover {
    border: 1px solid var(--primary-color);
  }
`;

const Checkbox = styled.input`
  position: absolute;
  top: 8px;
  left: 8px;
  width: 20px;
  height: 20px;
  z-index: 2;
`;

interface MediaItemProps {
  media: any;
  isSelected: boolean;
  multiSelectMode: boolean;
  toggleSelect: (url: string) => void;
  openPreview: (url: string) => void;
}

const MediaItem: React.FC<MediaItemProps> = ({
  media,
  isSelected,
  multiSelectMode,
  toggleSelect,
  openPreview,
}) => {
  const url = media.url;
  return (
    <MediaItemWrapper onClick={() => openPreview(url)}>
      {multiSelectMode && (
        <Checkbox
          type="checkbox"
          checked={isSelected}
          onChange={() => toggleSelect(url)}
          // Prevent click from triggering the preview
          onClick={(e) => e.stopPropagation()}
        />
      )}
      {media.metadata.type === "VIDEO" ? (
        <VideoBox src={url} controls preload="metadata" />
      ) : (
        <ImageBox src={url} alt="attachment" loading="lazy" />
      )}
    </MediaItemWrapper>
  );
};

export default memo(MediaItem);
