import { useRecoilValue } from "recoil";
import { allUsersState } from "../../state/userState";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import styled from "styled-components";
import Button from "../../components/common/form/Button";
import Link from "../../components/common/Link";
import AppConfig from "../../config/appConfig";
import { useCallback, useEffect, useState } from "react";
import userService from "../../core/services/user.service";
import InputStyle from "../../components/common/form/InputStyle";
import { InputText } from "primereact/inputtext";
import { User } from "../../core/entities/user";
import Card from "../../components/common/Card";
import { FormProvider, useForm } from "react-hook-form";
import Trend from "neword-core/dist/cjs/entities/trend/trendEntity";
import TextArea from "antd/es/input/TextArea";
import { toast } from "react-toastify";
import RadioButton from "../../components/common/form/RadioButton";
import RadioGroup from "../../components/common/form/RadioGroup";
import { PlatformType } from "neword-core/dist/cjs/types/platformType";
import { useTranslation } from "react-i18next";
import { Features, PackageType } from "neword-core";
import { UserRole } from "../../core/types/userRole";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { GridRenderEditCellParams } from "@mui/x-data-grid";

// Custom multi-select editor component for the features column.
function MultiSelectEditCell(props: GridRenderEditCellParams<string[]>) {
  const { id, field, value, api } = props;

  const handleChange = (event: any) => {
    const newValue = event.target.value as string[];
    // Update the cell value in the grid.
    api.setEditCellValue({ id, field, value: newValue }, event);
  };

  return (
    <Select
      multiple
      value={value || []}
      onChange={handleChange}
      renderValue={(selected) => (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
          {(selected as string[]).map((val) => (
            <Chip key={val} label={val} />
          ))}
        </Box>
      )}
      fullWidth
    >
      {Object.values(Features).map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
}

const DataGridStyled = styled(DataGrid)`
  background-color: white;
  border-radius: 13px;
  border: none !important;
`;

const Wrapper = styled.div`
  direction: ltr;
`;

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const SuperAdminAllUsersPage: React.FC = () => {
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState<User[]>([]);
  const { t } = useTranslation();

  const methods = useForm<Trend>();

  useEffect(() => {
    userService.getAllUsers(search).then((d) => setUsers(d));
  }, [search]);

  const generateSilentLoginLink = useCallback(async (id: string) => {
    const link = await userService.silentLoginLink(id);
    document.location.href = link;
  }, []);

  const onSubmitTrend = async () => {
    const values = methods.getValues();
    const trendToast = toast.loading("Creating Trend");
    try {
      await userService.createTrend(values);
      toast.success("Trend created");
    } catch (err) {
      toast.error("Error creating trend");
    } finally {
      toast.dismiss(trendToast);
    }
  };

  // Handler to capture cell edits and update the state
  const handleCellEditCommit = useCallback(async (params: any) => {
    console.log("Edited cell:", params);
    // Update the corresponding user with the new value

    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === params.id ? { ...user, [params.field]: params.value } : user
      )
    );
  }, []);

  const processRowUpdate: any = useCallback(
    async (newRow: User, oldRow: User) => {
      console.log("Row update:", newRow);

      await userService.updateUserAdmin(oldRow.id, newRow);
      setUsers((prevUsers) =>
        prevUsers.map((user) => (user.id === newRow.id ? newRow : user))
      );
      return newRow;
    },
    []
  );
  const columns: GridColDef[] = [
    {
      field: "email",
      headerName: t("super_admin_all_users_page.columns.email"),
      width: 200,
      editable: true, // allow editing
    },
    {
      field: "role",
      headerName: t("super_admin_all_users_page.columns.role"),
      width: 200,
      editable: true, // allow editing
      valueOptions: Object.values(UserRole),
      type: "singleSelect",
    },
    {
      field: "id",
      headerName: t("super_admin_all_users_page.columns.login_as"),
      renderCell: (params: any) => (
        <Link onClick={() => generateSilentLoginLink(params.id)}>
          {t("super_admin_all_users_page.columns.login")}
        </Link>
      ),
    },
    {
      field: "company.Subscription.type",
      headerName: t("super_admin_all_users_page.columns.type"),
      width: 200,
      valueOptions: Object.values(PackageType),
      type: "singleSelect",
      editable: true,
      valueGetter: (params) => params.row.company.Subscription.type,
      valueSetter: (params) => {
        const updatedRow = { ...params.row };
        updatedRow.company = {
          ...updatedRow.company,
          Subscription: {
            ...updatedRow.company.Subscription,
            type: params.value,
          },
        };
        return updatedRow;
      },
    },
    {
      editable: true,
      field: "company.Subscription.maxBusinesses",
      headerName: t("super_admin_all_users_page.columns.max_websites"),
      width: 200,
      type: "number",
      valueGetter: (params) => params.row.company.Subscription.maxBusinesses,
      valueSetter: (params) => {
        const updatedRow = { ...params.row };
        updatedRow.company = {
          ...updatedRow.company,
          Subscription: {
            ...updatedRow.company.Subscription,
            maxBusinesses: params.value,
          },
        };
        return updatedRow;
      },
    },
    {
      field: "company.Subscription.features",
      headerName: t("super_admin_all_users_page.columns.features"),
      width: 200,
      editable: true,
      renderEditCell: (params) => <MultiSelectEditCell {...params} />,
      // valueGetter returns the nested array of features directly.
      valueGetter: (params) => params.row.company.Subscription.features,
      // valueSetter updates the nested features array.
      valueSetter: (params) => {
        const updatedRow = { ...params.row };
        updatedRow.company = {
          ...updatedRow.company,
          Subscription: {
            ...updatedRow.company.Subscription,
            features: params.value, // params.value should be an array of strings
          },
        };
        return updatedRow;
      },
      // Render the cell as a comma-separated list when not editing.
      renderCell: (params) => (params.value ? params.value.join(", ") : ""),
    },
  ];

  return (
    <Wrapper>
      <Card>
        <h1>{t("super_admin_all_users_page.create_trend.title")}</h1>
        <FormProvider {...methods}>
          <FormStyled>
            <InputText
              {...methods.register("trendLink")}
              placeholder={t(
                "super_admin_all_users_page.create_trend.trend_link_placeholder"
              )}
            />

            <InputText
              {...methods.register("soundLink")}
              placeholder={t(
                "super_admin_all_users_page.create_trend.sound_link_placeholder"
              )}
            />

            <TextArea
              {...methods.register("description")}
              placeholder={t(
                "super_admin_all_users_page.create_trend.description_placeholder"
              )}
            />

            <RadioGroup<Trend, Trend["platform"]>
              options={[
                {
                  render: t("integrations.tiktok"),
                  value: PlatformType.TIKTOK,
                },
                {
                  render: t("integrations.instagram"),
                  value: PlatformType.INSTAGRAM,
                },
                {
                  render: t("integrations.twitter"),
                  value: PlatformType.TWITTER,
                },
                {
                  render: t("integrations.facebook"),
                  value: PlatformType.FACEBOOK,
                },
              ]}
              fieldName="platform"
            />

            <Button onClick={methods.handleSubmit(onSubmitTrend)}>
              {t("super_admin_all_users_page.create_trend.create_trend_button")}
            </Button>
          </FormStyled>
        </FormProvider>
      </Card>
      <br />
      <Card>
        <h1>{t("super_admin_all_users_page.search_accounts.title")}</h1>
        <InputText
          placeholder="kaki@gmail.com"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
        <DataGridStyled
          sortModel={[
            {
              field: "email",
              sort: "asc",
            },
          ]}
          columns={columns}
          rows={users || []}
          scrollbarSize={10}
          // onCellEditStop={handleCellEditCommit}
          processRowUpdate={processRowUpdate}
        />
      </Card>
    </Wrapper>
  );
};

export default SuperAdminAllUsersPage;
