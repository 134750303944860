import { PostEntity, PostStatusType } from "neword-core";
import React from "react";
import Button from "../../common/form/Button";
import { ReactComponent as ApproveIcon } from "../../../assets/Icons/Approved.svg";
import { ReactComponent as RejectIcon } from "../../../assets/Icons/Rejected.svg";
import { ReactComponent as ViewIcon } from "../../../assets/Icons/Views.svg";
import styled from "styled-components";

interface ContentProps {
  post: PostEntity;
  onApprove: () => void;
  onReject: () => void;
  openPreview: () => void;
}

const ButtonStyled = styled(Button)`
  padding: 0.5rem 1rem;
  font-weight: 500;
  font-size: 0.7rem;

  &:hover {
    opacity: 0.8;
  }
  svg {
    width: 0.7rem;
    height: 0.7rem;
    fill: var(--title-color);
  }

  .viewicon {
    fill: var(--title-color);
  }

  &.viewbutton {
    &:hover {
      background-color: #ceceff !important;
    }
  }
`;

const PostEventFooter: React.FC<ContentProps> = (props) => {
  // Wrap the onClick handlers to stop propagation
  const handleApproveClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    props.onApprove();
  };

  const handleRejectClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    props.onReject();
  };

  return (
    <>
      <div
        className="flex mt-3 w-full gap-10"
        style={{ justifyContent: "space-between" }}
      >
        <ButtonStyled
          disabled={props.post.status === PostStatusType.APPROVED}
          fullWidth
          onClick={handleApproveClick}
          primary
          textColor="title"
          bgColor="success"
          hoverColor="success"
          icon={<ApproveIcon />}
        >
          אישור
        </ButtonStyled>
        <ButtonStyled
          disabled={props.post.status === PostStatusType.REJECTED}
          fullWidth
          onClick={handleRejectClick}
          textColor="title"
          primary
          bgColor="danger"
          hoverColor="danger"
          icon={<RejectIcon />}
        >
          דחייה
        </ButtonStyled>
      </div>
      <div className="flex w-full justify-content-center mt-2 gap-10">
        {/* 
    <ButtonStyled onClick={props.openPreview} className="viewbutton" bgColor="bg" fullWidth textColor="title" icon={<ViewIcon className="viewicon" />}>
צפייה בפוסט   </ButtonStyled>
<ButtonStyled onClick={props.openPreview} className="viewbutton" bgColor="bg" fullWidth textColor="title" icon={<ViewIcon className="viewicon" />}>
הוסף הערה   </ButtonStyled> */}
      </div>
    </>
  );
};

export default PostEventFooter;
