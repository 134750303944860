import React from "react";
import { AppIntegrationApplicationType } from "neword-core";
import { ReactComponent as FacebookLogo } from "../assets/Icons/facebook/FacebookLogo.svg";
import { ReactComponent as LinkedInLogo } from "../assets/Icons/linkedin/LinkedInLogo.svg";
import { ReactComponent as XLogo } from "../assets/Icons/XLogo.svg";
import { ReactComponent as InstagramLogo } from "../assets/Icons/InstagramIcon.svg";
import { ReactComponent as GmailLogo } from "../assets/Icons/Gmail.svg";
import { ReactComponent as TiktokLogo } from "../assets/Icons/TiktokLogo.svg";

import { ReactComponent as GoogleAnaliticsLogo } from "../assets/Icons/ColoredGoogle.svg";
import AnalyticsLogo from "../../assets/images/integrations/googleAnalytics-logo.png";

interface IntegrationFactoryType {
  icon: any;
  label: string;
  text: string;
  scheduleAble: boolean;
  enableInsights?: boolean;
  showOnIntegrationPage?: boolean;
}

export const integrationFactory: Partial<
  Record<AppIntegrationApplicationType, IntegrationFactoryType>
> = {
  [AppIntegrationApplicationType.EMAIL]: {
    icon: GmailLogo,
    label: "integration_factory.email.label",
    text: "integration_factory.email.text",
    scheduleAble: false,

    showOnIntegrationPage: true,
  },
  [AppIntegrationApplicationType.FACEBOOK]: {
    icon: FacebookLogo,
    label: "פייסבוק",
    text: "חבר את חשבון הפייסבוק שלך כדי לאפשר פרסום פוסטים וניתוח נתונים ברשתות החברתיות.",
    scheduleAble: true,
    enableInsights: true,
  },
  [AppIntegrationApplicationType.LINKEDIN]: {
    icon: LinkedInLogo,
    label: "לינקדאין",
    text: "חבר את חשבון הלינקדאין שלך לצורך שיתוף תכנים ורישות מקצועי.",
    scheduleAble: true,
  },
  [AppIntegrationApplicationType.X]: {
    icon: XLogo,
    label: "טוויטר",
    text: "חבר את חשבון הטוויטר שלך כדי לתזמן ציוצים ולעקוב אחרי ביצועים.",
    scheduleAble: true,
  },
  [AppIntegrationApplicationType.INSTAGRAM]: {
    icon: InstagramLogo,
    label: "אינסטגרם",
    text: "חבר את חשבון האינסטגרם שלך לניהול פוסטים ותובנות בצורה יעילה.",
    scheduleAble: true,
    enableInsights: true,
  },
  [AppIntegrationApplicationType.TIKTOK]: {
    icon: TiktokLogo,
    label: "טיקטוק",
    text: "",
    scheduleAble: true,
  },
  [AppIntegrationApplicationType.GOOGLEANALYTICS]: {
    icon: GoogleAnaliticsLogo,
    label: "integration_factory.google_analytics.label",
    text: "integration_factory.google_analytics.text",
    scheduleAble: false,
    showOnIntegrationPage: true,
  },
};
