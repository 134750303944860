import { Dialog } from "primereact/dialog";
import Button from "../common/form/Button";
import FormStyle from "../common/form/FormStyle";
import styled from "styled-components";
import articleService from "../../core/services/article.service";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  ArticlesSummarySelector,
  articlesAtom,
} from "../../state/articlesState";
import { useNavigate, useParams } from "react-router";
import { websiteService } from "../../core/services/website.service";
import { websitesStateSelector } from "../../state/websitesState";
import {useTranslation} from "react-i18next";

interface Props {
  onHide: () => void;
}

const StyledButton = styled(Button)`
  width: fit-content;
`;

const DeleteWebsiteModal: React.FC<Props> = ({ onHide }) => {
  const { websiteId } = useParams();
  const { t } = useTranslation();
  const [websites, setWebsites] = useRecoilState(websitesStateSelector);
  const website = websites.find((w) => w.id === websiteId);
  const navigate = useNavigate();

  const onSubmitInternal = async () => {
    if (websiteId) {
      await websiteService.deleteWebsiteById(websiteId as string);
    }

    window.location.href = "/";
  };

  return (
    <Dialog
      modal
      visible
      header={t('delete_website_modal.header', { websiteName: website?.name })}
      onHide={onHide}
      footer={
        <StyledButton primary onClick={onSubmitInternal}>
          {t('delete_website_modal.delete_button')}
        </StyledButton>
      }
    >
      <p>{t('delete_website_modal.confirmation_message')}</p>
      <small>{t('delete_website_modal.info_message')}</small>
    </Dialog>
  );
};

export default DeleteWebsiteModal;
