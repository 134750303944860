import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import FormStyle from "../../../common/form/FormStyle";
import styled from "styled-components";
import { useFormContext } from "react-hook-form";
import { CreateWebsiteRequestData } from "../../../../core/services/requests/createWebsite/createWebsiteRequestData";
import RegexValidations from "../../../../core/validation/regexValidations";
import AttachmentInput from "../../../common/form/AttachmentInput";
import {useTranslation} from "react-i18next";

const InnerText = styled.div`
  position: absolute;
  top: 0%;
  padding-left: 20px;
  padding-right: 20px;
  color: rgb(10, 37, 64);
  font-size: 12px;
  font-weight: bold;
  height: 50px;
  display: flex;
  align-items: center;

  border-radius: 6px 0 0 6px;
  letter-spacing: -0.1px;
`;

const TextInsideInput = styled.div`
  margin-top: 10px;
  position: relative;
  width: 100%;
  /* width: calc(100% - 171px); */

  & input {
    margin-top: 0;
    padding-left: 65px;
  }
`;

const FormStyleStyled = styled(FormStyle)`
  max-width: 600px;
`;

interface BusinessType {
  label: string;
  value: string;
}

interface BusinessCategory {
  category: string;
  types: BusinessType[];
}

const BusinessDetails: React.FC = () => {
  const [filteredBusinessTypes, setFilteredBusinessTypes] = useState<
    BusinessCategory[]
  >([]);
  const { t } = useTranslation();

  const { register, formState } = useFormContext<CreateWebsiteRequestData>();

  const { errors, isLoading } = formState;

  return (
    <FormStyleStyled>
      <div className="grid">
        <div className="col-12 flex flex-column align-items-center">
          <label htmlFor="username" className="mb-3">
            {t("business_details.labels.business_logo")}
          </label>
          <AttachmentInput<CreateWebsiteRequestData>
            bigImage={true}
            name="website.logoSrc"
          ></AttachmentInput>
        </div>
        <div className="col-12">
          <label htmlFor="username">{t("business_details.labels.business_name")}</label>
          <InputText
            {...register("website.name", {
              required: {
                message: t("business_details.messages.business_name_required"),
                value: true,
              },
            })}
            placeholder={t("business_details.placeholders.business_name")}
          ></InputText>
        </div>
        <div className="col-12">
          <label htmlFor="username">{t("business_details.labels.business_website")}</label>
          <TextInsideInput
            dir="ltr"
            className={errors.website?.url ? "p-invalid" : ""}
          >
            <InnerText>https://</InnerText>
            <InputText
              disabled={isLoading}
              {...register("website.url", {
                setValueAs: (value: string) => {
                  const formatted = value
                    .replace("https://", "")
                    .replace("http://", "");
                  return `https://${formatted}`;
                },
                pattern: {
                  value: RegexValidations.url,
                  message: t("business_details.messages.invalid_website_url"),
                },
              })}
              placeholder="yourwebsite.com"
              className={
                "w-full unmask-clarity" +
                (errors.website?.url ? "p-invalid" : "")
              }
            />
          </TextInsideInput>
          <small className="mt-2">
            {t("business_details.messages.website_info")}
          </small>
        </div>
      </div>
    </FormStyleStyled>
  );
};

export default BusinessDetails;
