import { AttachmentMedia } from "neword-core";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18s";

export default (value: AttachmentMedia[]) => {
  const videos = value.filter((item) => item.metadata.type === "VIDEO");
  let errorText = "";

  // Validation for Facebook Reels metadata
  videos.map((videoItem) => {
    const video = videoItem.metadata;

    if (video.type === "VIDEO") {
      const [width, height] = video.aspectRatio.split(":").map(Number);
      const aspectRatio = width / height;
      if (aspectRatio < 0.5625 || aspectRatio > 1.91) {
        errorText = i18n.t("media_validation.aspect_ratio_error");
      }

      // Check resolution
      const [resWidth, resHeight] = video.resolution.split("x").map(Number);
      if (resWidth < 540 || resHeight < 960) {
        errorText = i18n.t("media_validation.resolution_error");
      }

      // Check duration
      if (video.duration < 3 || video.duration > 90) {
        errorText = i18n.t("media_validation.duration_error");
      }

      // Check frame rate
      if (video.frameRate < 24 || video.frameRate > 60) {
        errorText = i18n.t("media_validation.frame_rate_error");
      }
    }
  });

  return errorText;
};
