import React, { useEffect } from "react";
import SetupSelect from "../../../common/form/SetupSelect";
import styled from "styled-components";
import FormStyle from "../../../common/form/FormStyle";
import { CreateWebsiteRequestData } from "../../../../core/services/requests/createWebsite/createWebsiteRequestData";
import { t } from "i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { userState } from "../../../../state/userState";
import { useFormContext } from "react-hook-form";

const FormStyleStyled = styled(FormStyle)`
  margin-top: 60px;
  width: 600px;
`;

const ChooseSetup: React.FC = () => {
  const [user, setUser] = useRecoilState(userState);

  const { watch } = useFormContext<CreateWebsiteRequestData>();

  const companyType = watch("companyType");

  useEffect(() => {
    setUser({
      ...user,
      company: {
        ...user.company,
        type: companyType,
      },
    });
  }, [companyType]);

  return (
    <FormStyleStyled>
      <SetupSelect<CreateWebsiteRequestData> fieldName="companyType" />
    </FormStyleStyled>
  );
};

export default ChooseSetup;
