import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import styled from "styled-components";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { websiteState } from "../../../state/websitesState";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import { ReactComponent as Neword } from "../../../assets/Icons/BlueIcon.svg";
import { ReactComponent as WebsiteLogo } from "../../../assets/Icons/WebsiteLogo.svg";
import { BlockProps } from "./types/dictionaryProps";
import { RefProps } from "./types/refProps";
import EditableTextArea from "../components/EditableTextArea";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  padding: 10px;
  width: 100%;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AcountText = styled.div`
  font-weight: 700;
  font-size: 12.21px;
  display: flex;
  flex-direction: row-reverse;
`;

const LeftTopMenu = styled.div`
  display: flex;
  align-items: center;
`;
const SubjectWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const AnswerInputWrapper = styled.div`
  border-radius: 8px;
  background: var(--Card-Background, #f6f9fc);
  padding: 10px 20px;
  display: flex;
  align-items: center;
`;
const BoldText = styled.div`
  text-align: right;
  font-family: "Noto Sans Hebrew";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  white-space: nowrap;
  display: flex;
  min-width: 45px;
  margin: 20px 0px;
`;

const WbsiteUrl = styled.div`
  font-family: "Noto Sans Hebrew";
  font-size: 12.211px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 13.432px */
  letter-spacing: -0.366px;
`;
const WebsiteAboutBlock = forwardRef<RefProps, BlockProps>(
  ({ isEditing, blockIndex, handleBlur }, ref) => {
    const { websiteId } = useParams();
    const { t } = useTranslation();
    const website = useRecoilValue(websiteState(websiteId as string));

    const wrapperRef = useRef<HTMLDivElement>(null);

    const { getValues, control } = useFormContext<
      DocumentEntity & { type: DocumentEntityType.WEBSITE_ABOUT }
    >();

    useImperativeHandle(ref, () => ({
      onCopyCustom: () => {
        const value = getValues(`output.${blockIndex}`);
        return value.aboutContent;
      },
    }));

    return (
      <Wrapper ref={wrapperRef}>
        <TopSection>
          <WebsiteLogo />
          <LeftTopMenu>
            <AcountText className="ml-1">{website?.name}</AcountText>
            <WbsiteUrl>{website?.domain}</WbsiteUrl>{" "}
          </LeftTopMenu>
        </TopSection>

        <BoldText className="h-full ml-3"> {t("common.about_us")}</BoldText>

        <SubjectWrapper className="mt-3">
          <AnswerInputWrapper className="w-full">
            <EditableTextArea<DocumentEntityType.WEBSITE_ABOUT>
              fieldName={`output.${blockIndex}.aboutContent`}
              blockIndex={blockIndex}
              isEditing={isEditing}
              style={{ color: "black" }}
              handleBlur={handleBlur}
            />
          </AnswerInputWrapper>
        </SubjectWrapper>
      </Wrapper>
    );
  }
);

export default WebsiteAboutBlock;
