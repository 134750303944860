import { SubmitHandler } from "react-hook-form";
import ButtonWithDropdown from "../common/form/ButtonWithDropdown";
import styled from "styled-components";
import { ReactComponent as ScheduleIcon } from "../../assets/Icons/WebsiteIcons/Schedule.svg";
import { ReactComponent as PublishIcon } from "../../assets/Icons/Send.svg";
import PostDateInput from "./PostDateInput";
import { PostStatusType } from "neword-core";
const ButtonsWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: row;
  align-items: center;
`;
interface FooterButtonsProps {
  methods: any;
  isLoading: boolean;
  handleSubmit: SubmitHandler<any>;
  handlePublishSubmit: SubmitHandler<any>;
}
export const FooterButtons: React.FC<FooterButtonsProps> = ({
  methods,
  isLoading,
  handleSubmit,
  handlePublishSubmit,
}) => {
  return (
    <ButtonsWrapper className="">
      <ButtonWithDropdown
        options={[
          {
            label: "פרסם עכשיו",
            onClick: methods.handleSubmit(handlePublishSubmit),
            icon: <PublishIcon />,
          },
          {
            label: "הוסף כטיוטה",
            onClick: methods.handleSubmit((data: any) =>
              handleSubmit({ ...data, status: PostStatusType.DRAFT })
            ),
            icon: <PublishIcon />,
          },
        ]}
        loading={isLoading}
        primary
        onClick={methods.handleSubmit((data: any) =>
          handleSubmit({ ...data, status: PostStatusType.PENDING })
        )}
        icon={<ScheduleIcon />}
        bgColor="purple"
        textColor="white"
      >
        תזמון פוסט
      </ButtonWithDropdown>
      <div className="mr-3">
        <PostDateInput />
      </div>
    </ButtonsWrapper>
  );
};
