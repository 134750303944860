import { Task } from "neword-core";
import { ServiceBase } from "./service.base";

class TasksService extends ServiceBase {
  prefix = "/tasks";

  getAllTasks() {
    return this.get<Task[]>();
  }

  getTasksByAssignee(userId: string) {
    return this.get<Task[]>(`/assignee/${userId}`);
  }

  createTask(task: Task) {
    return this.post<Task>("", task);
  }

  updateTask(taskId: string, task: Partial<Task>) {
    return this.patch<Task>(`/${taskId}`, task);
  }

  deleteTask(taskId: string) {
    return this.delete<void>(`/${taskId}`);
  }
}

const tasksService = new TasksService();
export default tasksService;
