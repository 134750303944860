import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import ModalHeader from "./ModalHeader";
import { PostEntity, PostEntityType, PostStatusType } from "neword-core";
import postService from "../../core/services/post.service";
import { lastAddedPostsIds, postsSummariesState } from "../../state/postState";
import useNavigator from "../../hooks/useNavigator";
import NoteForm from "../post/forms/NoteForm";
import { createPostDeafultParams } from "../post/createPostDeafultParams";
import { Calendar } from "primereact/calendar";
import Button from "../common/form/Button";
import PostDateInput from "../post/PostDateInput";

const DialogStyled = styled(Dialog)`
  direction: rtl;
  .p-dialog-content {
    padding: 0;
    overflow: visible;
  }
  .p-dialog-header {
    padding: 0 !important;
  }
  .p-dialog-footer {
    padding: 10px !important;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const DatePickerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const DatePickerLabel = styled.label`
  font-size: 14px;
  color: var(--title-color);
`;

interface NoteModalProps {
  scheduleDate?: Date;
  onHide: () => void;
}

const NoteModal: React.FC<NoteModalProps> = ({ onHide, scheduleDate }) => {
  const { websiteId } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const setLatestPosts = useSetRecoilState(
    lastAddedPostsIds(websiteId as string)
  );
  const [postsSummaries, setPostsSummaries] = useRecoilState(
    postsSummariesState(websiteId as string)
  );
  const navigate = useNavigator();

  const methods = useForm<{ post: PostEntity; scheduleDate: Date }>({
    defaultValues: {
      post: {
        scheduleDate: scheduleDate || new Date(),
        type: PostEntityType.NOTE,
        data: createPostDeafultParams(PostEntityType.NOTE),
      },
      scheduleDate: scheduleDate || new Date(),
    },
  });

  const handleSubmit: SubmitHandler<{
    post: PostEntity;
    scheduleDate: Date;
  }> = async (values) => {
    try {
      setIsLoading(true);
      const postToSave = {
        ...values.post,
        scheduleDate: values.scheduleDate,
        status: PostStatusType.DRAFT,
      };

      const newPosts = await postService.schedulePost(
        [postToSave],
        websiteId as string
      );
      console.log(newPosts);
      setPostsSummaries([...postsSummaries, ...newPosts]);
      setLatestPosts(newPosts.map((p) => p.id));
      navigate(`/calendar`);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
      onHide();
    }
  };

  return (
    <FormProvider {...methods}>
      <form>
        <DialogStyled
          header={
            <ModalHeader
              downAbit
              OnClose={onHide}
            />
          }
          closable={false}
          visible
          position="center"
          style={{
            width: "60vw",
            margin: "0",
            boxShadow: "none",
            borderRadius: "8px",
            maxHeight: "90vh",
          }}
          onHide={onHide}
          draggable={false}
          resizable={false}
          footer={
            <FooterContainer>
              <DatePickerContainer>
                <DatePickerLabel>תאריך עבור הרעיון בגאנט:</DatePickerLabel>

                <PostDateInput />
              </DatePickerContainer>

              <ButtonContainer>
                <Button
                  onClick={methods.handleSubmit(handleSubmit)}
                  primary
                  type="submit"
                  loading={isLoading}
                >
                  שמור
                </Button>
              </ButtonContainer>
            </FooterContainer>
          }
        >
          <Wrapper>
            <NoteForm name="post.data" />
          </Wrapper>
        </DialogStyled>
      </form>
    </FormProvider>
  );
};

export default NoteModal;
