import styled from "styled-components";
import { FormProvider, useFormContext } from "react-hook-form";
import { useParams } from "react-router";
import { DocumentEntity, Features } from "neword-core";
import { SetStateAction, useEffect, useMemo, useState } from "react";
import Button from "../common/form/Button";
import { useRecoilState } from "recoil";
import {
  documentLoadingState,
  documentsSummariesState,
} from "../../state/documentState";
import documentService from "../../core/services/document.service";
import documentsDictionary from "./documentsDictionary";

import ParamAccordion from "./components/ParamAccordion";
import { ReactComponent as SparklesIcon } from "../../assets/Icons/Sparkles.svg";
import Accordion from "../common/form/Accordion";
import { useTranslation } from "react-i18next";
import { ProtectedButton } from "../../middleware/protectedButton";

const TextHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #000;
  font-family: "Assistant";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 20.128px */
  letter-spacing: -0.549px;
  margin-top: 20px;
  margin-bottom: 17px;
`;
const Wrapper = styled.div`
  height: 100%;
  border-left: 1px solid #e6e6e6;
  width: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Footer = styled.div`
  margin-bottom: -0.5rem;
  z-index: 100;
  border-top: 1px solid #e6e6e6;
  position: sticky;
  bottom: -0.5rem;
  width: 100%;
  backdrop-filter: blur(12px);
  background-color: rgba(255, 255, 255, 0.8);
  padding-inline: 30px;
  padding-block: 10px;
  display: flex;
  justify-content: center;
`;
const StyledButton = styled(Button)`
  background: var(--main-hebrew, #6300c1);
  width: 170px;
  justify-content: center;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  &.visible {
    opacity: 1;
  }
`;
const ContentWrapper = styled.div`
  padding: 10px;
`;

const NextButton = styled(Button)`
  margin-top: 20px;
`;

const Skip = styled(Button)`
  margin-top: 20px;
`;

const FormWrapper: React.FC<{ setDoc: SetStateAction<any> }> = ({ setDoc }) => {
  const { documentId, websiteId } = useParams<{
    documentId: string;
    websiteId: string;
  }>();

  const [documents, setDocuments] = useRecoilState(
    documentsSummariesState(websiteId as string)
  );
  const [isLoading, setIsLoaded] = useRecoilState(documentLoadingState);
  const methods = useFormContext<DocumentEntity>();

  const document = methods.getValues();

  const onGenerate = async () => {
    setIsLoaded(true);
    const params = methods.getValues().inputParams;

    try {
      const doc = await documentService.generateDocument(
        documentId as string,
        websiteId as string,
        params
      );
      methods.setValue("output", doc.output);
      methods.setValue("name", doc.name);

      if (!documents.find((d) => d.id === doc.id)) {
        setDocuments([doc, ...documents]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoaded(false);
    }
  };

  // Tracks which accordion is open
  const [activeIndex, setActiveIndex] = useState(0);
  const [transitioning, setTransitioning] = useState(false);
  // Tracks which accordions should show the "completed" icon
  const [iconsShown, setIconsShown] = useState<boolean[]>(
    new Array(3).fill(false) // assume 3 accordions (adjust if needed)
  );
  const { t } = useTranslation();

  const { form, AdvencedSettings } = documentsDictionary[document.type];
  const formData = form();

  /**
   * Toggles a single accordion by index.
   */
  const handleToggle = (index: number) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  /**
   * Moves to the next accordion by index, and marks the current one
   * as completed (showing an icon).
   */
  const handleNext = (currentIndex: number) => {
    setTransitioning(true);

    // Mark the current accordion as completed
    const updatedIcons = iconsShown.map((iconShown, i) =>
      i === currentIndex ? true : iconShown
    );
    setIconsShown(updatedIcons);

    // Move to next index
    const nextIndex = currentIndex + 1;
    // If we're at the last accordion, close everything
    if (nextIndex >= formData.length) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(nextIndex);
    }

    // Simple transition reset
    setTimeout(() => {
      setTransitioning(false);
    }, 300);
  };

  useEffect(() => {
    if (transitioning) {
      // Give time for the transition to play out
      const timer = setTimeout(() => {
        setTransitioning(false);
      }, 250);
      return () => clearTimeout(timer);
    }
  }, [transitioning]);

  return (
    <Wrapper className="col-4 scrolli">
      <ContentWrapper className="scrolli2">
        <FormProvider {...methods}>
          <div>
            {formData.map((accordion, index) => (
              <ParamAccordion
                key={index}
                title={accordion.title}
                isOpen={!transitioning && activeIndex === index}
                onToggle={() => handleToggle(index)}
                showIcon={iconsShown[index]}
              >
                {accordion.content}
                <NextButton
                  primary
                  bgColor="purple"
                  arrowPlacement="right"
                  onClick={() => handleNext(index)}
                >
                  {t("common.next")}
                </NextButton>
              </ParamAccordion>
            ))}
          </div>
          {/* 
          <Divider /> 
          If you have advanced settings, you can keep the Accordion below
          */}
          {/* {AdvencedSettings && (
            <Accordion
              title={t("common.advanced_settings")}
              icon={<SparklesIcon />}
            >
              <AdvencedSettings />
            </Accordion>
          )} */}
        </FormProvider>
      </ContentWrapper>
      <Footer>
        <ProtectedButton
          featureKey={Features.GENERATE_AI_CONTENT}
          onClick={methods.handleSubmit(onGenerate)}
        >
          {(handleClick) => (
            <StyledButton
              className={`step-6-wt ${activeIndex === -1 ? "visible" : ""}`}
              loading={isLoading}
              onClick={handleClick}
              primary
              icon={<SparklesIcon />}
            >
              {t("common.create_content")}
            </StyledButton>
          )}
        </ProtectedButton>
      </Footer>
    </Wrapper>
  );
};

export default FormWrapper;
