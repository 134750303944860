import React, { useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../../assets/Icons/InstagramIcon.svg";
import Card from "../../common/Card";
import { FieldValues, Path, useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { websiteState } from "../../../state/websitesState";
import InstagramPreview from "../../../assets/images/instagramPreview.png";
import { Galleria } from "primereact/galleria";
import { AttachmentMedia } from "neword-core";
import { ReactComponent as Edit } from "../../../assets/Icons/EditImage.svg";
import { ReactComponent as Remove } from "../../../assets/Icons/Times.svg";
import MediaEditor from "../MediaEditor";
import { Interface } from "readline";
import IphoneBG from "../../../assets/images/iphone_svg_bg.svg";

import { ReactComponent as Comment } from "../../../assets/Icons/InstagramLike.svg";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div<{ reel?: boolean }>`
  position: relative;
  padding: 0px;
  width: 100%;
  overflow: hidden;
  max-width: 350px;

  border-radius: 8px;

  border: none;
`;
const PhoneImage = styled.img`
  position: absolute;
  width: 103% !important;
  height: auto;
  left: -1.2%;
  display: block;
  z-index: 1;
  top: -1px;
  pointer-events: none;
  filter: drop-shadow(0px 0px 4px #4747471d);
`;

const IconsWrapper = styled.div`
  flex-direction: row-reverse;
  margin-top: 10px;
  opacity: 0.5;
  svg {
    width: 55px;
  }
`;

const Tools = styled.div`
  gap: 10px;
  display: flex;
  padding-bottom: 5px;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AcountText = styled.div`
  font-weight: 700;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  font-family: "Open Sans", "open sans Hebrew";
  letter-spacing: -0.2px;
`;

const ContentWrapper = styled.div`
  font-size: 12.21px;
  display: flex;
`;

const InsideContent = styled.div`
  max-height: 120px;
  overflow-y: auto;
`;

const InstagramPreviewWrapper = styled.img`
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover; /* Ensures the image fills the container */
`;

const InsideWrapper = styled.div`
  overflow: auto;
  border-radius: 60px 60px 0px 0px;
  padding-left: 0px; /* Add padding to move scrollbar inside */
  background-color: white;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a0;
  }

  &::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 4px;
  }
`;

const SyledGalleria = styled(Galleria)`
  position: relative;
  margin-bottom: 10px;

  .p-galleria-item {
    background-color: black;
    border-radius: 50px 50px 0px 0px;
    margin-top: 6px;
  }

  .p-galleria-item-next {
    right: auto !important;
    left: 0 !important;
    transform: rotate(180deg);
    z-index: 9999999;
  }

  .p-galleria-item-prev {
    left: auto !important;
    right: 0 !important;
    transform: rotate(180deg);
    z-index: 9999999;
  }

  .p-galleria-indicator button {
    width: 0.5rem !important;
    height: 0.5rem !important;
  }

  .p-galleria-indicators {
    padding: 0 !important;
  }

  .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: var(--primary-purple);
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    aspect-ratio: 4 / 5;
    border-radius: 50px 50px 0px 0px;
    margin-top: 6px;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 12px;
  bottom: 19px;
  z-index: 99;
  border-radius: 4px;
  margin-right: auto;
  background: #ffffffac;
  width: 25px;
  height: 15px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const PostImage = styled.img<{ reel?: boolean }>`
  aspect-ratio: ${({ reel }) => (reel ? "9/16" : "4/5")};
  border-radius: 50px 50px 0px 0px;
  width: 350px;
  object-fit: cover;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const ToolWrapper = styled.div`
  gap: 10px;
  cursor: pointer;
  span {
    font-size: 12px;
    color: white;
  }
  display: flex;
  height: 40px;
  width: 40px;

  justify-content: center;
  align-items: center;
  background-color: white;
  border: solid 1px var(--border-color);
  border-radius: 50%;
  transition-duration: 0.2s;

  position: relative;
  svg {
    fill: var(--text-color);
    width: 20px;
    height: 20px;
  }

  path {
    fill: var(--text-color);
  }

  &:hover {
    border-color: var(--title-color);
  }
  &::after {
    content: "עריכת מדיה";
    position: absolute;
    width: max-content;
    font-size: 12px;
    right: 120%;
    top: 49%;
    transform: translateY(-50%);
  }
`;

const WhiteWrapper = styled.div`
  background-color: white;
  border-radius: 50px 50px 0px 0px;
`;

function IgPreview<FormDataType extends FieldValues>({
  name,
  reel = false, // Default is false
}: {
  name: Path<FormDataType>;
  reel?: boolean;
}) {
  const methods = useFormContext();
  const { websiteId } = useParams();
  const { t } = useTranslation();
  const website = useRecoilValue(websiteState(websiteId as string));
  const values = useWatch({ control: methods.control, name: name });
  const [showMediaEditorModal, setMediaEditorModal] = useState(false);

  const imageUrl = useMemo(() => {
    return values?.imageUrls || InstagramPreview; // Default to empty string if no image is available
  }, [values]);

  const videoRef = useRef<HTMLVideoElement>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false);
  const [showCover, setShowCover] = useState(true);
  const [pauseTimer, setPauseTimer] = useState<NodeJS.Timeout | null>(null);
  const [whereAmI, SetwhereAmI] = useState("true");

  const clearPauseTimer = () => {
    if (pauseTimer) {
      clearTimeout(pauseTimer);
      setPauseTimer(null);
    }
  };

  const handlePause = () => {
    SetwhereAmI("handlePause");
    clearPauseTimer();
    const timer = setTimeout(() => {
      if (videoRef.current && !videoRef.current.seeking) {
        setShowCover(true);
        setCurrentTime(videoRef.current.currentTime);
      }
    }, 75);
    setPauseTimer(timer);
  };

  const handlePlay = () => {
    SetwhereAmI("handlePlay");
    clearPauseTimer();
    setShowCover(false);
  };

  const handleSeeking = () => {
    SetwhereAmI("handleSeeking");
    clearPauseTimer();
    setIsSeeking(true);
    setShowCover(false);
  };

  const handleSeeked = () => {
    SetwhereAmI("handleSeeked");
    setIsSeeking(false);
    const timer = setTimeout(() => {
      if (videoRef.current && videoRef.current.paused) {
        setShowCover(true);
        setCurrentTime(videoRef.current.currentTime);
      }
      return true;
    }, 75);
    setPauseTimer(timer);
  };

  const handleThumbnailClick = () => {
    if (videoRef.current && videoRef.current.paused) {
      videoRef.current.currentTime = currentTime;
      videoRef.current.play();
    }
  };

  return (
    <div className="h-full pt-2">
      <Tools>
        {/* <ToolWrapper
                    onClick={() =>
                      methods.setValue(
                        name + ".imageUrls",
                        values.imageUrls.filter(
                          (m: AttachmentMedia) => m !== item
                        )
                      )
                    }
                  >
                    <Remove />
                    <span>{t("facebook_caption_block.remove")}</span>
                  </ToolWrapper> */}
        <ToolWrapper onClick={() => setMediaEditorModal(true)}>
          <Edit />
          {/* <span>{t("facebook_caption_block.edit_media")}</span> */}
        </ToolWrapper>
      </Tools>

      <WhiteWrapper className="h-full">
        <Wrapper reel={reel} className="col-4">
          <PhoneImage src={IphoneBG} />
          <InsideWrapper>
            {/* <IconWrapper>
        <Logo />
      </IconWrapper> */}
            <ImageWrapper>
              <SyledGalleria
                value={
                  imageUrl.length > 0
                    ? imageUrl
                    : [{ url: InstagramPreview, metadata: { type: "IMAGE" } }]
                }
                showThumbnails={false}
                showIndicators={false}
                showItemNavigators
                item={(item: AttachmentMedia) => {
                  if (!item) return;

                  return (
                    <>
                      {item.metadata.type === "VIDEO" ? (
                        <div style={{ position: "relative" }}>
                          <video
                            ref={videoRef}
                            src={item.url}
                            controls
                            style={{
                              maxWidth: reel ? "350px;" : "auto",
                              aspectRatio: reel ? "9/16" : "4/5",
                            }}
                            onPlay={handlePlay}
                            onPause={handlePause}
                            onSeeking={handleSeeking}
                            onSeeked={handleSeeked}
                            onEnded={handlePause}
                          />
                          {showCover &&
                            !isSeeking &&
                            whereAmI !== "handleSeeked" &&
                            values.coverPhotoUrl && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  cursor: "pointer",
                                }}
                                onClick={handleThumbnailClick}
                              >
                                <img
                                  src={values.coverPhotoUrl.url}
                                  alt="Video thumbnail"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    borderRadius: "8px",
                                  }}
                                />
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    background: "rgba(0, 0, 0, 0.6)",
                                    borderRadius: "50%",
                                    width: "60px",
                                    height: "60px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: 0,
                                      height: 0,
                                      borderTop: "15px solid transparent",
                                      borderBottom: "15px solid transparent",
                                      borderLeft: "25px solid white",
                                      marginLeft: "5px",
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                        </div>
                      ) : (
                        <PostImage reel={reel} src={item.url} />
                      )}
                      {showMediaEditorModal && (
                        <MediaEditor
                          url={item.url}
                          onProcess={(attachment: AttachmentMedia) =>
                            methods.setValue(
                              name + ".imageUrls",
                              values.imageUrls.map((m: AttachmentMedia) =>
                                m === item ? attachment : m
                              )
                            )
                          }
                          onClose={() => setMediaEditorModal(false)}
                        />
                      )}
                    </>
                  );
                }}
              />
            </ImageWrapper>
            {!reel && (
              <div className="pr-4 pl-4">
                {!reel && <Comment />}
                <AcountText className="mt-1 flex row-revers">
                  {website?.name}
                </AcountText>
                <ContentWrapper className="mt-1 pb-0">
                  <InsideContent>{values && values.message}</InsideContent>
                </ContentWrapper>
              </div>
            )}
          </InsideWrapper>
        </Wrapper>
      </WhiteWrapper>
    </div>
  );
}

export default IgPreview;
