import React from "react";
import { ISRAELI_EVENTS } from "./events";
import { format } from "date-fns";
import styled from "styled-components";
import { TileWrapper } from "./PostCalendarEvent";
import { Theme } from "../../../core/theme/theme";

interface Props {
  day: Date;
}

const TileWrapperStyled = styled.div`
  user-select: none;
  background: var(--lightblue-opacity);
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  width: 100%; /* Allow the tile to take the full width */
  padding: 5px; /* Add some padding */
  box-sizing: border-box;
  position: relative;
  font-size: 12px;
`;

const CalendarPopularEvents: React.FC<Props> = (props) => {
  // Implement your component logic here
  const formattedDate = format(props.day, "dd/MM/yyyy");
  const events = ISRAELI_EVENTS[formattedDate];

  return (
    events && (
      <TileWrapperStyled>
        {events.map((event) => (
          <p>{event}</p>
        ))}
      </TileWrapperStyled>
    )
  );
};

export default CalendarPopularEvents;
