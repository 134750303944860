import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import { InputTextarea } from "primereact/inputtextarea";

import { Calendar } from "primereact/calendar";
import { Nullable } from "primereact/ts-helpers";
import { addLocale } from "primereact/api";
import {
  Controller,
  FormProvider,
  UseFieldArrayUpdate,
  useController,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import { DocumentEntity } from "neword-core";
import ModalHeader from "../../modals/ModalHeader";
import Button from "../../common/form/Button";
import documentService from "../../../core/services/document.service";
import { useParams } from "react-router";
import { useState } from "react";
import {useTranslation} from "react-i18next";

const InputTextareaStyled = styled(InputTextarea)`
  outline: none !important;
  height: fit-content;
  width: 100%;
  &::placeholder {
    color: var(--text-color);
    opacity: 0.5;
  }

  &:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
    border-color: none !important;
  }
`;

const LabelStyled = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: var(--text-color);
  padding-bottom: 10px;
`;

const IconsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const IconWrapper = styled.div<{ borderColor: string }>`
  position: relative;
  background: lightgrey;
  padding: 14px;
  border-radius: 8px;
  cursor: pointer;

  &.active {
    background: ${(props) => props.borderColor} !important;
  }

  .avatar:not(.opacity):before {
    content: "";
    width: 38px;
    height: 38px;
    border-radius: 50%;
    border: 2px solid ${(props) => props.borderColor}; /* Use borderColor prop */
    z-index: 2;
    position: absolute;
  }

  .avatar.opacity {
    opacity: 0.5;
  }

  .opacity {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
`;

const IconBg = styled.div<{ backgroundColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  svg {
    fill: white;
    width: 15px;
    height: 15px;

    path {
      fill: white !important;
    }
  }
`;

const DialogStyled = styled(Dialog)`
  .p-dialog-content {
    padding: 10px;
    overflow: visible;
  }

  .p-dialog-header {
    padding: 0px !important;
  }

  .p-dialog-footer {
    padding: 1.5em !important;
  }
`;

const StyledButton = styled(Button)`
  background: var(--main-hebrew, #6300c1);
  width: 170px;
  justify-content: center;
  color: white;

  &:hover {
    background: var(--title-color);
  }
  /* opacity: 0;
  transition: opacity 0.3s ease-in-out; */
`;

const ImageWrapper = styled.div`
  background: #fcf6f7;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }
`;

const CalendarStyled = styled(Calendar)`
  /* opacity: 0; */
  /* height: 0; */
  input {
    border: none;

    &:focus {
      outline: none;
      box-shadow: none !important;
    }
  }
`;
const SmallLabel = styled.label`
  font-family: "Assistant";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 13.2px */
  letter-spacing: -0.36px;
  cursor: pointer;
`;
const StyledInputText = styled.textarea`
  outline: none; /* This removes the default outline that appears when the input is focused */
  resize: auto; /* Disable manual resizing */
  font-family: inherit; /* Inherit font family */
  border-radius: 6px;
  border: 1px solid var(--Input-BorderColor, #e6e6e6);
  padding: 10px;
  margin-top: 10px;
  width: 100%;
`;

interface Props {
  index: number;
  onHide: () => void;
  update: UseFieldArrayUpdate<DocumentEntity>;
}

function RewriteModal({ onHide, index, update }: Props) {
  const { documentId } = useParams<{
    documentId: string;
  }>();

  const [change, setChange] = useState("");
  const [loading, setLoading] = useState(false);
  const { control, setValue } = useFormContext<DocumentEntity>();
  const { t } = useTranslation();

  const { field } = useController({
    control: control,
    name: `output.${index}`,
  });
  const submit = async () => {
    setLoading(true);
    try {
      const newBlock = await documentService.rewriteBlock(
        documentId as string,
        field.value,
        change
      );
      update(index, newBlock);
      onHide();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <DialogStyled
      header={<ModalHeader OnClose={onHide} right />}
      closable={false}
      visible
      position={"center"}
      style={{
        width: "30vw",
        margin: "0",
        boxShadow: "none",
        borderLeft: "solid 1px var(--border-color)",
        borderRadius: "0px",
        maxHeight: "100%",
        padding: "0",
      }}
      onHide={onHide}
      draggable={false}
      resizable={false}
    >
      {
        <div className="h-400">
          <div>
            <SmallLabel>{t("common.suggestions_for_improvement")}</SmallLabel>

            <StyledInputText
              value={change}
              onChange={(e) => setChange(e.target.value)}
            />
          </div>
          <StyledButton
            type={"button"}
            className="mt-2"
            onClick={submit}
            loading={loading}
          >
            {loading ? t("common.loading") : t("common.improve_content")}
          </StyledButton>
        </div>
      }
    </DialogStyled>
  );
}

export default RewriteModal;
