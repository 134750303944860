import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { audienceState } from "../../../state/audienceState";
import { FormProvider, useController, useForm } from "react-hook-form";
import { CreateWebsiteRequestData } from "../../../core/services/requests/createWebsite/createWebsiteRequestData";
import { InputText } from "primereact/inputtext";
import FormStyle from "./FormStyle";
import { ReactComponent as RefreshIcon } from "../../../assets/Icons/refresh.svg";
import RangeSlider from "./RangeSlider";
import Avatar, { genConfig } from "react-nice-avatar";
import { Dropdown } from "primereact/dropdown";
import { GenderType } from "../../../core/types/genderType";
import SelectKeywordsInput from "../../keywords/SelectKeywordsInput";
import Button from "./Button";
import writerService from "../../../core/services/writer.service";
import { WebsiteTone } from "../../../core/entities/tone";
import { InputTextarea } from "primereact/inputtextarea";
import { toneState } from "../../../state/toneState";
import {useTranslation} from "react-i18next";

const Title = styled.h2`
  color: var(--Main-TitleColor, #0a2540);
  font-size: 1.125rem; /* 18px */
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.03rem;
  margin-top: 0.5rem;
`;

const Subtitle = styled.h3`
  margin-top: 0.5rem;
  font-size: 1rem; /* 16px */
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.02625rem;
  color: #666;
`;

const DialogStyled = styled(Dialog)`
width: 550px;
  .p-dialog-header {
    background-color: #f7f9fc;
  }

  .p-dialog-content {
    padding: 1.5rem 1.5rem 0;
  }

  .p-dialog-footer {
    padding: 1rem 1.5rem;
  }

  @media screen and (max-width: 768px) {
    max-width: 90vw;
  }
`;

interface Props {
  onHide: () => void;
}

const CreateToneModal: React.FC<Props> = ({ onHide }) => {
  const { websiteId } = useParams();
  const { t } = useTranslation();
  const [tones, setTones] = useRecoilState(toneState(websiteId as string));
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm<
    Omit<WebsiteTone, "websiteId" | "id" | "companyId" | "summerizedToneText">
  >({
    defaultValues: {},
  });

  const onSubmit = async (
    data: Omit<
      WebsiteTone,
      "websiteId" | "id" | "companyId" | "summerizedToneText"
    >
  ) => {
    setIsLoading(true);
    const tone = await writerService.createTone(data, websiteId as string);
    setTones([...tones, tone]);
    onHide();
  };

  return (
    <DialogStyled
      header={
        <>
          <Title>{t("form.create_tone_modal.title")}</Title>
          <Subtitle>{t("form.create_tone_modal.subtitle")}</Subtitle>
        </>
      }
      visible
      onHide={onHide}
      modal
      footer={
        <>
          <Button
            data-pr-position="top"
            primary
            disabled={!methods.formState.isValid}
            onClick={methods.handleSubmit(onSubmit)}
            loading={isLoading}
          >
            {t("form.create_tone_modal.submit_button")}
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
      <FormStyle>
          <div className="grid" style={{ marginBottom: "1rem" }}>
            <div className="col-12">
              <label htmlFor="toneName" style={{ fontWeight: 500 }}>
                {t("form.create_tone_modal.tone_name")}
              </label>
              <InputText
                id="toneName"
                placeholder={t("form.create_tone_modal.tone_name_placeholder")}
                className="w-full mt-2"
                {...methods.register("name", { required: true })}
              />
            </div>
          </div>
          <div className="grid" style={{ marginBottom: "1rem" }}>
            <div className="col-12">
              <label htmlFor="toneText" style={{ fontWeight: 500 }}>
                {t("form.create_tone_modal.tone_text")}
              </label>
              <InputTextarea
                id="toneText"
                placeholder={t("form.create_tone_modal.tone_text_placeholder")}
                rows={5}
                className="w-full mt-2"
                autoResize
                {...methods.register("toneText", { required: true })}
              />
            </div>
          </div>
          {/* You could add an optional example snippet or additional fields if needed */}
        </FormStyle>
      </FormProvider>
    </DialogStyled>
  );
};

export default CreateToneModal;
