import { MenuItemProps } from "../../menu/types/MenuItemProps";
import { ReactComponent as DashboardIcon } from "../../../assets/Icons/WebsiteIcons/Dashboard.svg";
import { ReactComponent as ContentIcon } from "../../../assets/Icons/WebsiteIcons/Content.svg";
import { ReactComponent as TrendIcon } from "../../../assets/Icons//Trend.svg";
import { ReactComponent as ArticlesIcon } from "../../../assets/Icons/WebsiteIcons/Blog.svg";
import { ReactComponent as InsightsIcon } from "../../../assets/Icons/WebsiteIcons/Analytics.svg";
import { ReactComponent as KeywordsIcon } from "../../../assets/Icons/WebsiteIcons/Keyword.svg";
import { ReactComponent as WriterSettingsIcon } from "../../../assets/Icons/WebsiteIcons/Writer.svg";
import { ReactComponent as IntegrationIcon } from "../../../assets/Icons/WebsiteIcons/Integration.svg";
import { ReactComponent as ScheduleIcon } from "../../../assets/Icons/WebsiteIcons/Schedule.svg";
import { ReactComponent as TemplatesIcon } from "../../../assets/Icons/WebsiteIcons/Templates.svg";
import { ReactComponent as AutomaticIcon } from "../../../assets/Icons/WebsiteIcons/Automatic.svg";
import { ReactComponent as BusinessIcon } from "../../../assets/Icons/WebsiteIcons/BusinessInfo.svg";
import { ReactComponent as AudienceIcon } from "../../../assets/Icons/WebsiteIcons/Audience.svg";
import { ReactComponent as ToneIcon } from "../../../assets/Icons/WebsiteIcons/WritingStyle.svg";
import { ReactComponent as Leads } from "../../../assets/Icons/WebsiteIcons/Leads.svg";
import { ReactComponent as AddIcon } from "../../../assets/Icons/PlusBorder.svg";
import { ReactComponent as MediaIcon } from "../../../assets/Icons/Media.svg";
import ConstructionIcon from "@mui/icons-material/Construction";
import HistoryIcon from "@mui/icons-material/HistoryOutlined";
import { UserRole } from "../../../core/types/userRole";
import Templates from "../../../pages/websitePages/Templates";

const WebsiteMenuItems: MenuItemProps[] = [
  // {
  //   displayName: "דאשבורד",
  //   route: "/dashboard",
  //   icon: <DashboardIcon />,
  // },

  // {
  //   displayName: "tab_nav.tools",
  //   route: "/dashboard",
  //   isTitle: true,
  //   icon: <DashboardIcon />,
  // },

  {
    displayName: "tab_nav.content_templates",
    route: "",
    icon: <ContentIcon />,
  },

  {
    displayName: "tab_nav.content_organizer",
    route: "/calendar",
    icon: <ScheduleIcon />,
    className: "contentManagement-step",
  },

  // {
  //   displayName: "טרנדים",
  //   route: "",
  //   icon: <TrendIcon  />,
  //   pro: true,
  // },

  {
    isDivider: true,
    displayName: "tab_nav.content_creation",
    route: "",
  },

  {
    displayName: "tab_nav.my_documents",
    route: "/docs",
    icon: <ArticlesIcon />,
  },

  {
    displayName: "tab_nav.my_media",
    route: "/media",
    icon: <MediaIcon />,
  },

  {
    displayName: "tab_nav.data_analysis",
    route: "/analytics",
    icon: <InsightsIcon />,
  },

  {
    displayName: "tab_nav.general_settings",
    route: "",
    isTitle: true,
    isDivider: true,
    className: "generalSetings-step",
  },

  // {
  //   displayName: "לידים",
  //   route: "/leads",
  //   icon: <Leads />,
  // },

  {
    displayName: "tab_nav.business_info",
    route: "/business-info",
    icon: <BusinessIcon />,
  },

  {
    displayName: "tab_nav.audience_settings",
    route: "/audience-settings",
    icon: <AudienceIcon />,
  },
  {
    displayName: "tab_nav.writing_style",
    route: "/tone-settings",
    icon: <ToneIcon />,
  },

  // {
  //   displayName: "הגדרות בלוג",
  //   route: "/autowriter/writer-settings",
  //   icon: <AutomaticIcon />,
  //   parentRoute: "/autowriter",
  // },

  {
    displayName: "tab_nav.integrations",
    route: "/integrations",
    icon: <IntegrationIcon />,
  },

  {
    displayName: "tab_nav.super_admin",
    route: "/super-admin",
    icon: <AutomaticIcon />,
    role: UserRole.SUPER_ADMIN,
  },
];

export default WebsiteMenuItems;
