import { AttachmentMedia } from "neword-core";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18s";

export default (value: AttachmentMedia[]) => {
  let errorText = "";

  // Separate videos and images
  const videos = value.filter((item) => item.metadata.type === "VIDEO");
  const images = value.filter((item) => item.metadata.type === "IMAGE");

  // Validate Videos
  const videoErrors = videos
    .map((video) => {
      const { aspectRatio, resolution, frameRate, duration, size } =
        video.metadata as {
          type: "VIDEO";
          aspectRatio: string;
          resolution: string;
          frameRate: number;
          duration: number;
          size: number;
        };

      // Check aspect ratio
      const [width, height] = aspectRatio.split(":").map(Number);
      const ratio = width / height;
      if (ratio !== 9 / 16 && ratio !== 1 / 1 && ratio !== 16 / 9) {
        return i18n.t("media_validation.video_aspect_ratio_error_tiktok");
      }

      // Check resolution
      const [resWidth, resHeight] = resolution.split("x").map(Number);
      if (resWidth < 540 || resHeight < 960) {
        return i18n.t("media_validation.video_resolution_error_tiktok");
      }

      // Check duration
      if (duration < 3 || duration > 600) {
        return i18n.t("media_validation.video_duration_error_tiktok");
      }

      // Check frame rate
      if (frameRate < 24 || frameRate > 60) {
        return i18n.t("media_validation.video_frame_rate_error_tiktok");
      }

      // Check file size
      const maxSizeMB = 500;
      if (size > maxSizeMB * 1024 * 1024) {
        return i18n.t("media_validation.video_size_error_tiktok");
      }

      return null; // No error
    })
    .filter((error) => error !== null);

  // Validate Images
  const imageErrors = images
    .map((image) => {
      const { aspectRatio, resolution, size, format } = image.metadata as {
        type: "IMAGE";
        aspectRatio: string;
        resolution: string;
        size: number;
        format: string;
      };

      // Check aspect ratio
      const [width, height] = aspectRatio.split(":").map(Number);
      const ratio = width / height;
      if (ratio !== 9 / 16 && ratio !== 1 / 1 && ratio !== 16 / 9) {
        return i18n.t("media_validation.image_aspect_ratio_error_tiktok");
      }

      // Check resolution
      const [resWidth, resHeight] = resolution.split("x").map(Number);
      if (
        resWidth < 600 ||
        resHeight < 600 ||
        resWidth > 6000 ||
        resHeight > 6000
      ) {
        return i18n.t("media_validation.image_resolution_error_tiktok");
      }

      // Check file size
      const maxSizeMB = 5;
      if (size > maxSizeMB * 1024 * 1024) {
        return i18n.t("media_validation.image_size_error_tiktok");
      }

      // Check file format
      if (format !== "image/png" && format !== "image/jpeg") {
        return i18n.t("media_validation.image_format_error_tiktok");
      }

      return null; // No error
    })
    .filter((error) => error !== null);

  // Combine all errors
  const allErrors = [...videoErrors, ...imageErrors];
  if (allErrors.length > 0) {
    errorText = allErrors.join(". ");
  }

  return errorText;
};
