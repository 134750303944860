import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowDown } from "../../../assets/Icons/DownArrow.svg";
import Button from "./Button"; // No need to import Attributes separately
import { Theme } from "../../../core/theme/theme";

interface Option {
  label: string;
  icon?: JSX.Element;
  onClick?: () => void;
}

interface ButtonWithDropdownProps
  extends Omit<React.ComponentProps<typeof Button>, "onSelect"> {
  options: Option[];
}

const ButtonWithDropdownWrapper = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: stretch;
  position: relative;
  height: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: stretch;
  .border-r {
    border-radius: 0 8px 8px 0;
  }
`;

const DropdownButton = styled.div`
  display: flex;
  border-right: solid 1px white;
  justify-content: center;
  align-items: center;
  width: 35px;
  background: var(--primary-purple);
  border-radius: 8px 0 0 8px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background: var(--title-color);
  }

  & svg {
    width: 14px;
    height: 14px;
    transform: rotate(180deg);
    fill: white;
    path {
      fill: white;
    }
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  bottom: 130%;
  left: 0;
  background: white;
  border: solid 1px var(--border-color);
  border-radius: 8px;
  z-index: 100;
  min-width: 160px;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const DropdownItem = styled.div`
  padding: 10px 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  &:hover {
    background: var(--light-bg);
  }

  svg {
    fill: red;

    path {
      fill: var(--title-color);
    }
  }
`;

const ButtonWithDropdown: React.FC<ButtonWithDropdownProps> = ({
  options,
  ...buttonProps
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <ButtonWithDropdownWrapper ref={wrapperRef}>
      <Button className="border-r" {...buttonProps} />
      <DropdownButton onClick={toggleDropdown}>
        <ArrowDown />
      </DropdownButton>
      {isOpen && (
        <DropdownMenu>
          {options.map((option, index) => (
            <DropdownItem
              key={index}
              onClick={() => {
                option.onClick?.(); // Calls the custom onClick if it exists
                setIsOpen(false);
              }}
            >
              {option.icon && option.icon}
              {option.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </ButtonWithDropdownWrapper>
  );
};

export default ButtonWithDropdown;
