import {useTranslation} from "react-i18next";

const ArticleBuilderPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <p>{t("integrations.coming_soon")}</p>
    </>
  );
};

export default ArticleBuilderPage;
