import { PhonePerfixType } from "../types/phonePerfixType";
const PhonePerfix: PhonePerfixType[] = [
  {
    name: "Afghanistan",
    flag: "🇦🇫",
    code: "AF",
    dial_code: "+93",
    fullName: "+93 Afghanistan🇦🇫",
  },
  {
    name: "Åland Islands",
    flag: "🇦🇽",
    code: "AX",
    dial_code: "+358",
    fullName: "+358 Åland Islands🇦🇽",
  },
  {
    name: "Albania",
    flag: "🇦🇱",
    code: "AL",
    dial_code: "+355",
    fullName: "+355 Albania🇦🇱",
  },
  {
    name: "Algeria",
    flag: "🇩🇿",
    code: "DZ",
    dial_code: "+213",
    fullName: "+213 Algeria🇩🇿",
  },
  {
    name: "American Samoa",
    flag: "🇦🇸",
    code: "AS",
    dial_code: "+1684",
    fullName: "+1684 American Samoa🇦🇸",
  },
  {
    name: "Andorra",
    flag: "🇦🇩",
    code: "AD",
    dial_code: "+376",
    fullName: "+376 Andorra🇦🇩",
  },
  {
    name: "Angola",
    flag: "🇦🇴",
    code: "AO",
    dial_code: "+244",
    fullName: "+244 Angola🇦🇴",
  },
  {
    name: "Anguilla",
    flag: "🇦🇮",
    code: "AI",
    dial_code: "+1264",
    fullName: "+1264 Anguilla🇦🇮",
  },
  {
    name: "Antarctica",
    flag: "🇦🇶",
    code: "AQ",
    dial_code: "+672",
    fullName: "+672 Antarctica🇦🇶",
  },
  {
    name: "Antigua and Barbuda",
    flag: "🇦🇬",
    code: "AG",
    dial_code: "+1268",
    fullName: "+1268 Antigua and Barbuda🇦🇬",
  },
  {
    name: "Argentina",
    flag: "🇦🇷",
    code: "AR",
    dial_code: "+54",
    fullName: "+54 Argentina🇦🇷",
  },
  {
    name: "Armenia",
    flag: "🇦🇲",
    code: "AM",
    dial_code: "+374",
    fullName: "+374 Armenia🇦🇲",
  },
  {
    name: "Aruba",
    flag: "🇦🇼",
    code: "AW",
    dial_code: "+297",
    fullName: "+297 Aruba🇦🇼",
  },
  {
    name: "Australia",
    flag: "🇦🇺",
    code: "AU",
    dial_code: "+61",
    fullName: "+61 Australia🇦🇺",
  },
  {
    name: "Austria",
    flag: "🇦🇹",
    code: "AT",
    dial_code: "+43",
    fullName: "+43 Austria🇦🇹",
  },
  {
    name: "Azerbaijan",
    flag: "🇦🇿",
    code: "AZ",
    dial_code: "+994",
    fullName: "+994 Azerbaijan🇦🇿",
  },
  {
    name: "Bahamas",
    flag: "🇧🇸",
    code: "BS",
    dial_code: "+1242",
    fullName: "+1242 Bahamas🇧🇸",
  },
  {
    name: "Bahrain",
    flag: "🇧🇭",
    code: "BH",
    dial_code: "+973",
    fullName: "+973 Bahrain🇧🇭",
  },
  {
    name: "Bangladesh",
    flag: "🇧🇩",
    code: "BD",
    dial_code: "+880",
    fullName: "+880 Bangladesh🇧🇩",
  },
  {
    name: "Barbados",
    flag: "🇧🇧",
    code: "BB",
    dial_code: "+1246",
    fullName: "+1246 Barbados🇧🇧",
  },
  {
    name: "Belarus",
    flag: "🇧🇾",
    code: "BY",
    dial_code: "+375",
    fullName: "+375 Belarus🇧🇾",
  },
  {
    name: "Belgium",
    flag: "🇧🇪",
    code: "BE",
    dial_code: "+32",
    fullName: "+32 Belgium🇧🇪",
  },
  {
    name: "Belize",
    flag: "🇧🇿",
    code: "BZ",
    dial_code: "+501",
    fullName: "+501 Belize🇧🇿",
  },
  {
    name: "Benin",
    flag: "🇧🇯",
    code: "BJ",
    dial_code: "+229",
    fullName: "+229 Benin🇧🇯",
  },
  {
    name: "Bermuda",
    flag: "🇧🇲",
    code: "BM",
    dial_code: "+1441",
    fullName: "+1441 Bermuda🇧🇲",
  },
  {
    name: "Bhutan",
    flag: "🇧🇹",
    code: "BT",
    dial_code: "+975",
    fullName: "+975 Bhutan🇧🇹",
  },
  {
    name: "Bolivia, Plurinational State of bolivia",
    flag: "🇧🇴",
    code: "BO",
    dial_code: "+591",
    fullName: "+591 Bolivia, Plurinational State of bolivia🇧🇴",
  },
  {
    name: "Bosnia and Herzegovina",
    flag: "🇧🇦",
    code: "BA",
    dial_code: "+387",
    fullName: "+387 Bosnia and Herzegovina🇧🇦",
  },
  {
    name: "Botswana",
    flag: "🇧🇼",
    code: "BW",
    dial_code: "+267",
    fullName: "+267 Botswana🇧🇼",
  },
  {
    name: "Bouvet Island",
    flag: "🇧🇻",
    code: "BV",
    dial_code: "+47",
    fullName: "+47 Bouvet Island🇧🇻",
  },
  {
    name: "Brazil",
    flag: "🇧🇷",
    code: "BR",
    dial_code: "+55",
    fullName: "+55 Brazil🇧🇷",
  },
  {
    name: "British Indian Ocean Territory",
    flag: "🇮🇴",
    code: "IO",
    dial_code: "+246",
    fullName: "+246 British Indian Ocean Territory🇮🇴",
  },
  {
    name: "Brunei Darussalam",
    flag: "🇧🇳",
    code: "BN",
    dial_code: "+673",
    fullName: "+673 Brunei Darussalam🇧🇳",
  },
  {
    name: "Bulgaria",
    flag: "🇧🇬",
    code: "BG",
    dial_code: "+359",
    fullName: "+359 Bulgaria🇧🇬",
  },
  {
    name: "Burkina Faso",
    flag: "🇧🇫",
    code: "BF",
    dial_code: "+226",
    fullName: "+226 Burkina Faso🇧🇫",
  },
  {
    name: "Burundi",
    flag: "🇧🇮",
    code: "BI",
    dial_code: "+257",
    fullName: "+257 Burundi🇧🇮",
  },
  {
    name: "Cambodia",
    flag: "🇰🇭",
    code: "KH",
    dial_code: "+855",
    fullName: "+855 Cambodia🇰🇭",
  },
  {
    name: "Cameroon",
    flag: "🇨🇲",
    code: "CM",
    dial_code: "+237",
    fullName: "+237 Cameroon🇨🇲",
  },
  {
    name: "Canada",
    flag: "🇨🇦",
    code: "CA",
    dial_code: "+1",
    fullName: "+1 Canada🇨🇦",
  },
  {
    name: "Cape Verde",
    flag: "🇨🇻",
    code: "CV",
    dial_code: "+238",
    fullName: "+238 Cape Verde🇨🇻",
  },
  {
    name: "Cayman Islands",
    flag: "🇰🇾",
    code: "KY",
    dial_code: "+345",
    fullName: "+345 Cayman Islands🇰🇾",
  },
  {
    name: "Central African Republic",
    flag: "🇨🇫",
    code: "CF",
    dial_code: "+236",
    fullName: "+236 Central African Republic🇨🇫",
  },
  {
    name: "Chad",
    flag: "🇹🇩",
    code: "TD",
    dial_code: "+235",
    fullName: "+235 Chad🇹🇩",
  },
  {
    name: "Chile",
    flag: "🇨🇱",
    code: "CL",
    dial_code: "+56",
    fullName: "+56 Chile🇨🇱",
  },
  {
    name: "China",
    flag: "🇨🇳",
    code: "CN",
    dial_code: "+86",
    fullName: "+86 China🇨🇳",
  },
  {
    name: "Christmas Island",
    flag: "🇨🇽",
    code: "CX",
    dial_code: "+61",
    fullName: "+61 Christmas Island🇨🇽",
  },
  {
    name: "Cocos (Keeling) Islands",
    flag: "🇨🇨",
    code: "CC",
    dial_code: "+61",
    fullName: "+61 Cocos (Keeling) Islands🇨🇨",
  },
  {
    name: "Colombia",
    flag: "🇨🇴",
    code: "CO",
    dial_code: "+57",
    fullName: "+57 Colombia🇨🇴",
  },
  {
    name: "Comoros",
    flag: "🇰🇲",
    code: "KM",
    dial_code: "+269",
    fullName: "+269 Comoros🇰🇲",
  },
  {
    name: "Congo",
    flag: "🇨🇬",
    code: "CG",
    dial_code: "+242",
    fullName: "+242 Congo🇨🇬",
  },
  {
    name: "Congo, The Democratic Republic of the Congo",
    flag: "🇨🇩",
    code: "CD",
    dial_code: "+243",
    fullName: "+243 Congo, The Democratic Republic of the Congo🇨🇩",
  },
  {
    name: "Cook Islands",
    flag: "🇨🇰",
    code: "CK",
    dial_code: "+682",
    fullName: "+682 Cook Islands🇨🇰",
  },
  {
    name: "Costa Rica",
    flag: "🇨🇷",
    code: "CR",
    dial_code: "+506",
    fullName: "+506 Costa Rica🇨🇷",
  },
  {
    name: "Cote d'Ivoire",
    flag: "🇨🇮",
    code: "CI",
    dial_code: "+225",
    fullName: "+225 Cote d'Ivoire🇨🇮",
  },
  {
    name: "Croatia",
    flag: "🇭🇷",
    code: "HR",
    dial_code: "+385",
    fullName: "+385 Croatia🇭🇷",
  },
  {
    name: "Cuba",
    flag: "🇨🇺",
    code: "CU",
    dial_code: "+53",
    fullName: "+53 Cuba🇨🇺",
  },
  {
    name: "Cyprus",
    flag: "🇨🇾",
    code: "CY",
    dial_code: "+357",
    fullName: "+357 Cyprus🇨🇾",
  },
  {
    name: "Czech Republic",
    flag: "🇨🇿",
    code: "CZ",
    dial_code: "+420",
    fullName: "+420 Czech Republic🇨🇿",
  },
  {
    name: "Denmark",
    flag: "🇩🇰",
    code: "DK",
    dial_code: "+45",
    fullName: "+45 Denmark🇩🇰",
  },
  {
    name: "Djibouti",
    flag: "🇩🇯",
    code: "DJ",
    dial_code: "+253",
    fullName: "+253 Djibouti🇩🇯",
  },
  {
    name: "Dominica",
    flag: "🇩🇲",
    code: "DM",
    dial_code: "+1767",
    fullName: "+1767 Dominica🇩🇲",
  },
  {
    name: "Dominican Republic",
    flag: "🇩🇴",
    code: "DO",
    dial_code: "+1849",
    fullName: "+1849 Dominican Republic🇩🇴",
  },
  {
    name: "Ecuador",
    flag: "🇪🇨",
    code: "EC",
    dial_code: "+593",
    fullName: "+593 Ecuador🇪🇨",
  },
  {
    name: "Egypt",
    flag: "🇪🇬",
    code: "EG",
    dial_code: "+20",
    fullName: "+20 Egypt🇪🇬",
  },
  {
    name: "El Salvador",
    flag: "🇸🇻",
    code: "SV",
    dial_code: "+503",
    fullName: "+503 El Salvador🇸🇻",
  },
  {
    name: "Equatorial Guinea",
    flag: "🇬🇶",
    code: "GQ",
    dial_code: "+240",
    fullName: "+240 Equatorial Guinea🇬🇶",
  },
  {
    name: "Eritrea",
    flag: "🇪🇷",
    code: "ER",
    dial_code: "+291",
    fullName: "+291 Eritrea🇪🇷",
  },
  {
    name: "Estonia",
    flag: "🇪🇪",
    code: "EE",
    dial_code: "+372",
    fullName: "+372 Estonia🇪🇪",
  },
  {
    name: "Ethiopia",
    flag: "🇪🇹",
    code: "ET",
    dial_code: "+251",
    fullName: "+251 Ethiopia🇪🇹",
  },
  {
    name: "Falkland Islands (Malvinas)",
    flag: "🇫🇰",
    code: "FK",
    dial_code: "+500",
    fullName: "+500 Falkland Islands (Malvinas)🇫🇰",
  },
  {
    name: "Faroe Islands",
    flag: "🇫🇴",
    code: "FO",
    dial_code: "+298",
    fullName: "+298 Faroe Islands🇫🇴",
  },
  {
    name: "Fiji",
    flag: "🇫🇯",
    code: "FJ",
    dial_code: "+679",
    fullName: "+679 Fiji🇫🇯",
  },
  {
    name: "Finland",
    flag: "🇫🇮",
    code: "FI",
    dial_code: "+358",
    fullName: "+358 Finland🇫🇮",
  },
  {
    name: "France",
    flag: "🇫🇷",
    code: "FR",
    dial_code: "+33",
    fullName: "+33 France🇫🇷",
  },
  {
    name: "French Guiana",
    flag: "🇬🇫",
    code: "GF",
    dial_code: "+594",
    fullName: "+594 French Guiana🇬🇫",
  },
  {
    name: "French Polynesia",
    flag: "🇵🇫",
    code: "PF",
    dial_code: "+689",
    fullName: "+689 French Polynesia🇵🇫",
  },
  {
    name: "French Southern Territories",
    flag: "🇹🇫",
    code: "TF",
    dial_code: "+262",
    fullName: "+262 French Southern Territories🇹🇫",
  },
  {
    name: "Gabon",
    flag: "🇬🇦",
    code: "GA",
    dial_code: "+241",
    fullName: "+241 Gabon🇬🇦",
  },
  {
    name: "Gambia",
    flag: "🇬🇲",
    code: "GM",
    dial_code: "+220",
    fullName: "+220 Gambia🇬🇲",
  },
  {
    name: "Georgia",
    flag: "🇬🇪",
    code: "GE",
    dial_code: "+995",
    fullName: "+995 Georgia🇬🇪",
  },
  {
    name: "Germany",
    flag: "🇩🇪",
    code: "DE",
    dial_code: "+49",
    fullName: "+49 Germany🇩🇪",
  },
  {
    name: "Ghana",
    flag: "🇬🇭",
    code: "GH",
    dial_code: "+233",
    fullName: "+233 Ghana🇬🇭",
  },
  {
    name: "Gibraltar",
    flag: "🇬🇮",
    code: "GI",
    dial_code: "+350",
    fullName: "+350 Gibraltar🇬🇮",
  },
  {
    name: "Greece",
    flag: "🇬🇷",
    code: "GR",
    dial_code: "+30",
    fullName: "+30 Greece🇬🇷",
  },
  {
    name: "Greenland",
    flag: "🇬🇱",
    code: "GL",
    dial_code: "+299",
    fullName: "+299 Greenland🇬🇱",
  },
  {
    name: "Grenada",
    flag: "🇬🇩",
    code: "GD",
    dial_code: "+1473",
    fullName: "+1473 Grenada🇬🇩",
  },
  {
    name: "Guadeloupe",
    flag: "🇬🇵",
    code: "GP",
    dial_code: "+590",
    fullName: "+590 Guadeloupe🇬🇵",
  },
  {
    name: "Guam",
    flag: "🇬🇺",
    code: "GU",
    dial_code: "+1671",
    fullName: "+1671 Guam🇬🇺",
  },
  {
    name: "Guatemala",
    flag: "🇬🇹",
    code: "GT",
    dial_code: "+502",
    fullName: "+502 Guatemala🇬🇹",
  },
  {
    name: "Guernsey",
    flag: "🇬🇬",
    code: "GG",
    dial_code: "+44",
    fullName: "+44 Guernsey🇬🇬",
  },
  {
    name: "Guinea",
    flag: "🇬🇳",
    code: "GN",
    dial_code: "+224",
    fullName: "+224 Guinea🇬🇳",
  },
  {
    name: "Guinea-Bissau",
    flag: "🇬🇼",
    code: "GW",
    dial_code: "+245",
    fullName: "+245 Guinea-Bissau🇬🇼",
  },
  {
    name: "Guyana",
    flag: "🇬🇾",
    code: "GY",
    dial_code: "+592",
    fullName: "+592 Guyana🇬🇾",
  },
  {
    name: "Haiti",
    flag: "🇭🇹",
    code: "HT",
    dial_code: "+509",
    fullName: "+509 Haiti🇭🇹",
  },
  {
    name: "Heard Island and Mcdonald Islands",
    flag: "🇭🇲",
    code: "HM",
    dial_code: "+672",
    fullName: "+672 Heard Island and Mcdonald Islands🇭🇲",
  },
  {
    name: "Holy See (Vatican City State)",
    flag: "🇻🇦",
    code: "VA",
    dial_code: "+379",
    fullName: "+379 Holy See (Vatican City State)🇻🇦",
  },
  {
    name: "Honduras",
    flag: "🇭🇳",
    code: "HN",
    dial_code: "+504",
    fullName: "+504 Honduras🇭🇳",
  },
  {
    name: "Hong Kong",
    flag: "🇭🇰",
    code: "HK",
    dial_code: "+852",
    fullName: "+852 Hong Kong🇭🇰",
  },
  {
    name: "Hungary",
    flag: "🇭🇺",
    code: "HU",
    dial_code: "+36",
    fullName: "+36 Hungary🇭🇺",
  },
  {
    name: "Iceland",
    flag: "🇮🇸",
    code: "IS",
    dial_code: "+354",
    fullName: "+354 Iceland🇮🇸",
  },
  {
    name: "India",
    flag: "🇮🇳",
    code: "IN",
    dial_code: "+91",
    fullName: "+91 India🇮🇳",
  },
  {
    name: "Indonesia",
    flag: "🇮🇩",
    code: "ID",
    dial_code: "+62",
    fullName: "+62 Indonesia🇮🇩",
  },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    flag: "🇮🇷",
    code: "IR",
    dial_code: "+98",
    fullName: "+98 Iran, Islamic Republic of Persian Gulf🇮🇷",
  },
  {
    name: "Iraq",
    flag: "🇮🇶",
    code: "IQ",
    dial_code: "+964",
    fullName: "+964 Iraq🇮🇶",
  },
  {
    name: "Ireland",
    flag: "🇮🇪",
    code: "IE",
    dial_code: "+353",
    fullName: "+353 Ireland🇮🇪",
  },
  {
    name: "Isle of Man",
    flag: "🇮🇲",
    code: "IM",
    dial_code: "+44",
    fullName: "+44 Isle of Man🇮🇲",
  },
  {
    name: "Israel",
    flag: "🇮🇱",
    code: "IL",
    dial_code: "+972",
    fullName: "+972 Israel🇮🇱",
  },
  {
    name: "Italy",
    flag: "🇮🇹",
    code: "IT",
    dial_code: "+39",
    fullName: "+39 Italy🇮🇹",
  },
  {
    name: "Jamaica",
    flag: "🇯🇲",
    code: "JM",
    dial_code: "+1876",
    fullName: "+1876 Jamaica🇯🇲",
  },
  {
    name: "Japan",
    flag: "🇯🇵",
    code: "JP",
    dial_code: "+81",
    fullName: "+81 Japan🇯🇵",
  },
  {
    name: "Jersey",
    flag: "🇯🇪",
    code: "JE",
    dial_code: "+44",
    fullName: "+44 Jersey🇯🇪",
  },
  {
    name: "Jordan",
    flag: "🇯🇴",
    code: "JO",
    dial_code: "+962",
    fullName: "+962 Jordan🇯🇴",
  },
  {
    name: "Kazakhstan",
    flag: "🇰🇿",
    code: "KZ",
    dial_code: "+7",
    fullName: "+7 Kazakhstan🇰🇿",
  },
  {
    name: "Kenya",
    flag: "🇰🇪",
    code: "KE",
    dial_code: "+254",
    fullName: "+254 Kenya🇰🇪",
  },
  {
    name: "Kiribati",
    flag: "🇰🇮",
    code: "KI",
    dial_code: "+686",
    fullName: "+686 Kiribati🇰🇮",
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    flag: "🇰🇵",
    code: "KP",
    dial_code: "+850",
    fullName: "+850 Korea, Democratic People's Republic of Korea🇰🇵",
  },
  {
    name: "Korea, Republic of South Korea",
    flag: "🇰🇷",
    code: "KR",
    dial_code: "+82",
    fullName: "+82 Korea, Republic of South Korea🇰🇷",
  },
  {
    name: "Kosovo",
    flag: "🇽🇰",
    code: "XK",
    dial_code: "+383",
    fullName: "+383 Kosovo🇽🇰",
  },
  {
    name: "Kuwait",
    flag: "🇰🇼",
    code: "KW",
    dial_code: "+965",
    fullName: "+965 Kuwait🇰🇼",
  },
  {
    name: "Kyrgyzstan",
    flag: "🇰🇬",
    code: "KG",
    dial_code: "+996",
    fullName: "+996 Kyrgyzstan🇰🇬",
  },
  {
    name: "Laos",
    flag: "🇱🇦",
    code: "LA",
    dial_code: "+856",
    fullName: "+856 Laos🇱🇦",
  },
  {
    name: "Latvia",
    flag: "🇱🇻",
    code: "LV",
    dial_code: "+371",
    fullName: "+371 Latvia🇱🇻",
  },
  {
    name: "Lebanon",
    flag: "🇱🇧",
    code: "LB",
    dial_code: "+961",
    fullName: "+961 Lebanon🇱🇧",
  },
  {
    name: "Lesotho",
    flag: "🇱🇸",
    code: "LS",
    dial_code: "+266",
    fullName: "+266 Lesotho🇱🇸",
  },
  {
    name: "Liberia",
    flag: "🇱🇷",
    code: "LR",
    dial_code: "+231",
    fullName: "+231 Liberia🇱🇷",
  },
  {
    name: "Libyan Arab Jamahiriya",
    flag: "🇱🇾",
    code: "LY",
    dial_code: "+218",
    fullName: "+218 Libyan Arab Jamahiriya🇱🇾",
  },
  {
    name: "Liechtenstein",
    flag: "🇱🇮",
    code: "LI",
    dial_code: "+423",
    fullName: "+423 Liechtenstein🇱🇮",
  },
  {
    name: "Lithuania",
    flag: "🇱🇹",
    code: "LT",
    dial_code: "+370",
    fullName: "+370 Lithuania🇱🇹",
  },
  {
    name: "Luxembourg",
    flag: "🇱🇺",
    code: "LU",
    dial_code: "+352",
    fullName: "+352 Luxembourg🇱🇺",
  },
  {
    name: "Macao",
    flag: "🇲🇴",
    code: "MO",
    dial_code: "+853",
    fullName: "+853 Macao🇲🇴",
  },
  {
    name: "Macedonia",
    flag: "🇲🇰",
    code: "MK",
    dial_code: "+389",
    fullName: "+389 Macedonia🇲🇰",
  },
  {
    name: "Madagascar",
    flag: "🇲🇬",
    code: "MG",
    dial_code: "+261",
    fullName: "+261 Madagascar🇲🇬",
  },
  {
    name: "Malawi",
    flag: "🇲🇼",
    code: "MW",
    dial_code: "+265",
    fullName: "+265 Malawi🇲🇼",
  },
  {
    name: "Malaysia",
    flag: "🇲🇾",
    code: "MY",
    dial_code: "+60",
    fullName: "+60 Malaysia🇲🇾",
  },
  {
    name: "Maldives",
    flag: "🇲🇻",
    code: "MV",
    dial_code: "+960",
    fullName: "+960 Maldives🇲🇻",
  },
  {
    name: "Mali",
    flag: "🇲🇱",
    code: "ML",
    dial_code: "+223",
    fullName: "+223 Mali🇲🇱",
  },
  {
    name: "Malta",
    flag: "🇲🇹",
    code: "MT",
    dial_code: "+356",
    fullName: "+356 Malta🇲🇹",
  },
  {
    name: "Marshall Islands",
    flag: "🇲🇭",
    code: "MH",
    dial_code: "+692",
    fullName: "+692 Marshall Islands🇲🇭",
  },
  {
    name: "Martinique",
    flag: "🇲🇶",
    code: "MQ",
    dial_code: "+596",
    fullName: "+596 Martinique🇲🇶",
  },
  {
    name: "Mauritania",
    flag: "🇲🇷",
    code: "MR",
    dial_code: "+222",
    fullName: "+222 Mauritania🇲🇷",
  },
  {
    name: "Mauritius",
    flag: "🇲🇺",
    code: "MU",
    dial_code: "+230",
    fullName: "+230 Mauritius🇲🇺",
  },
  {
    name: "Mayotte",
    flag: "🇾🇹",
    code: "YT",
    dial_code: "+262",
    fullName: "+262 Mayotte🇾🇹",
  },
  {
    name: "Mexico",
    flag: "🇲🇽",
    code: "MX",
    dial_code: "+52",
    fullName: "+52 Mexico🇲🇽",
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    flag: "🇫🇲",
    code: "FM",
    dial_code: "+691",
    fullName: "+691 Micronesia, Federated States of Micronesia🇫🇲",
  },
  {
    name: "Moldova",
    flag: "🇲🇩",
    code: "MD",
    dial_code: "+373",
    fullName: "+373 Moldova🇲🇩",
  },
  {
    name: "Monaco",
    flag: "🇲🇨",
    code: "MC",
    dial_code: "+377",
    fullName: "+377 Monaco🇲🇨",
  },
  {
    name: "Mongolia",
    flag: "🇲🇳",
    code: "MN",
    dial_code: "+976",
    fullName: "+976 Mongolia🇲🇳",
  },
  {
    name: "Montenegro",
    flag: "🇲🇪",
    code: "ME",
    dial_code: "+382",
    fullName: "+382 Montenegro🇲🇪",
  },
  {
    name: "Montserrat",
    flag: "🇲🇸",
    code: "MS",
    dial_code: "+1664",
    fullName: "+1664 Montserrat🇲🇸",
  },
  {
    name: "Morocco",
    flag: "🇲🇦",
    code: "MA",
    dial_code: "+212",
    fullName: "+212 Morocco🇲🇦",
  },
  {
    name: "Mozambique",
    flag: "🇲🇿",
    code: "MZ",
    dial_code: "+258",
    fullName: "+258 Mozambique🇲🇿",
  },
  {
    name: "Myanmar",
    flag: "🇲🇲",
    code: "MM",
    dial_code: "+95",
    fullName: "+95 Myanmar🇲🇲",
  },
  {
    name: "Namibia",
    flag: "🇳🇦",
    code: "NA",
    dial_code: "+264",
    fullName: "+264 Namibia🇳🇦",
  },
  {
    name: "Nauru",
    flag: "🇳🇷",
    code: "NR",
    dial_code: "+674",
    fullName: "+674 Nauru🇳🇷",
  },
  {
    name: "Nepal",
    flag: "🇳🇵",
    code: "NP",
    dial_code: "+977",
    fullName: "+977 Nepal🇳🇵",
  },
  {
    name: "Netherlands",
    flag: "🇳🇱",
    code: "NL",
    dial_code: "+31",
    fullName: "+31 Netherlands🇳🇱",
  },
  {
    name: "Netherlands Antilles",
    flag: "",
    code: "AN",
    dial_code: "+599",
    fullName: "+599 Netherlands Antilles",
  },
  {
    name: "New Caledonia",
    flag: "🇳🇨",
    code: "NC",
    dial_code: "+687",
    fullName: "+687 New Caledonia🇳🇨",
  },
  {
    name: "New Zealand",
    flag: "🇳🇿",
    code: "NZ",
    dial_code: "+64",
    fullName: "+64 New Zealand🇳🇿",
  },
  {
    name: "Nicaragua",
    flag: "🇳🇮",
    code: "NI",
    dial_code: "+505",
    fullName: "+505 Nicaragua🇳🇮",
  },
  {
    name: "Niger",
    flag: "🇳🇪",
    code: "NE",
    dial_code: "+227",
    fullName: "+227 Niger🇳🇪",
  },
  {
    name: "Nigeria",
    flag: "🇳🇬",
    code: "NG",
    dial_code: "+234",
    fullName: "+234 Nigeria🇳🇬",
  },
  {
    name: "Niue",
    flag: "🇳🇺",
    code: "NU",
    dial_code: "+683",
    fullName: "+683 Niue🇳🇺",
  },
  {
    name: "Norfolk Island",
    flag: "🇳🇫",
    code: "NF",
    dial_code: "+672",
    fullName: "+672 Norfolk Island🇳🇫",
  },
  {
    name: "Northern Mariana Islands",
    flag: "🇲🇵",
    code: "MP",
    dial_code: "+1670",
    fullName: "+1670 Northern Mariana Islands🇲🇵",
  },
  {
    name: "Norway",
    flag: "🇳🇴",
    code: "NO",
    dial_code: "+47",
    fullName: "+47 Norway🇳🇴",
  },
  {
    name: "Oman",
    flag: "🇴🇲",
    code: "OM",
    dial_code: "+968",
    fullName: "+968 Oman🇴🇲",
  },
  {
    name: "Pakistan",
    flag: "🇵🇰",
    code: "PK",
    dial_code: "+92",
    fullName: "+92 Pakistan🇵🇰",
  },
  {
    name: "Palau",
    flag: "🇵🇼",
    code: "PW",
    dial_code: "+680",
    fullName: "+680 Palau🇵🇼",
  },
  {
    name: "Panama",
    flag: "🇵🇦",
    code: "PA",
    dial_code: "+507",
    fullName: "+507 Panama🇵🇦",
  },
  {
    name: "Papua New Guinea",
    flag: "🇵🇬",
    code: "PG",
    dial_code: "+675",
    fullName: "+675 Papua New Guinea🇵🇬",
  },
  {
    name: "Paraguay",
    flag: "🇵🇾",
    code: "PY",
    dial_code: "+595",
    fullName: "+595 Paraguay🇵🇾",
  },
  {
    name: "Peru",
    flag: "🇵🇪",
    code: "PE",
    dial_code: "+51",
    fullName: "+51 Peru🇵🇪",
  },
  {
    name: "Philippines",
    flag: "🇵🇭",
    code: "PH",
    dial_code: "+63",
    fullName: "+63 Philippines🇵🇭",
  },
  {
    name: "Pitcairn",
    flag: "🇵🇳",
    code: "PN",
    dial_code: "+64",
    fullName: "+64 Pitcairn🇵🇳",
  },
  {
    name: "Poland",
    flag: "🇵🇱",
    code: "PL",
    dial_code: "+48",
    fullName: "+48 Poland🇵🇱",
  },
  {
    name: "Portugal",
    flag: "🇵🇹",
    code: "PT",
    dial_code: "+351",
    fullName: "+351 Portugal🇵🇹",
  },
  {
    name: "Puerto Rico",
    flag: "🇵🇷",
    code: "PR",
    dial_code: "+1939",
    fullName: "+1939 Puerto Rico🇵🇷",
  },
  {
    name: "Qatar",
    flag: "🇶🇦",
    code: "QA",
    dial_code: "+974",
    fullName: "+974 Qatar🇶🇦",
  },
  {
    name: "Romania",
    flag: "🇷🇴",
    code: "RO",
    dial_code: "+40",
    fullName: "+40 Romania🇷🇴",
  },
  {
    name: "Russia",
    flag: "🇷🇺",
    code: "RU",
    dial_code: "+7",
    fullName: "+7 Russia🇷🇺",
  },
  {
    name: "Rwanda",
    flag: "🇷🇼",
    code: "RW",
    dial_code: "+250",
    fullName: "+250 Rwanda🇷🇼",
  },
  {
    name: "Reunion",
    flag: "🇷🇪",
    code: "RE",
    dial_code: "+262",
    fullName: "+262 Reunion🇷🇪",
  },
  {
    name: "Saint Barthelemy",
    flag: "🇧🇱",
    code: "BL",
    dial_code: "+590",
    fullName: "+590 Saint Barthelemy🇧🇱",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    flag: "🇸🇭",
    code: "SH",
    dial_code: "+290",
    fullName: "+290 Saint Helena, Ascension and Tristan Da Cunha🇸🇭",
  },
  {
    name: "Saint Kitts and Nevis",
    flag: "🇰🇳",
    code: "KN",
    dial_code: "+1869",
    fullName: "+1869 Saint Kitts and Nevis🇰🇳",
  },
  {
    name: "Saint Lucia",
    flag: "🇱🇨",
    code: "LC",
    dial_code: "+1758",
    fullName: "+1758 Saint Lucia🇱🇨",
  },
  {
    name: "Saint Martin",
    flag: "🇲🇫",
    code: "MF",
    dial_code: "+590",
    fullName: "+590 Saint Martin🇲🇫",
  },
  {
    name: "Saint Pierre and Miquelon",
    flag: "🇵🇲",
    code: "PM",
    dial_code: "+508",
    fullName: "+508 Saint Pierre and Miquelon🇵🇲",
  },
  {
    name: "Saint Vincent and the Grenadines",
    flag: "🇻🇨",
    code: "VC",
    dial_code: "+1784",
    fullName: "+1784 Saint Vincent and the Grenadines🇻🇨",
  },
  {
    name: "Samoa",
    flag: "🇼🇸",
    code: "WS",
    dial_code: "+685",
    fullName: "+685 Samoa🇼🇸",
  },
  {
    name: "San Marino",
    flag: "🇸🇲",
    code: "SM",
    dial_code: "+378",
    fullName: "+378 San Marino🇸🇲",
  },
  {
    name: "Sao Tome and Principe",
    flag: "🇸🇹",
    code: "ST",
    dial_code: "+239",
    fullName: "+239 Sao Tome and Principe🇸🇹",
  },
  {
    name: "Saudi Arabia",
    flag: "🇸🇦",
    code: "SA",
    dial_code: "+966",
    fullName: "+966 Saudi Arabia🇸🇦",
  },
  {
    name: "Senegal",
    flag: "🇸🇳",
    code: "SN",
    dial_code: "+221",
    fullName: "+221 Senegal🇸🇳",
  },
  {
    name: "Serbia",
    flag: "🇷🇸",
    code: "RS",
    dial_code: "+381",
    fullName: "+381 Serbia🇷🇸",
  },
  {
    name: "Seychelles",
    flag: "🇸🇨",
    code: "SC",
    dial_code: "+248",
    fullName: "+248 Seychelles🇸🇨",
  },
  {
    name: "Sierra Leone",
    flag: "🇸🇱",
    code: "SL",
    dial_code: "+232",
    fullName: "+232 Sierra Leone🇸🇱",
  },
  {
    name: "Singapore",
    flag: "🇸🇬",
    code: "SG",
    dial_code: "+65",
    fullName: "+65 Singapore🇸🇬",
  },
  {
    name: "Slovakia",
    flag: "🇸🇰",
    code: "SK",
    dial_code: "+421",
    fullName: "+421 Slovakia🇸🇰",
  },
  {
    name: "Slovenia",
    flag: "🇸🇮",
    code: "SI",
    dial_code: "+386",
    fullName: "+386 Slovenia🇸🇮",
  },
  {
    name: "Solomon Islands",
    flag: "🇸🇧",
    code: "SB",
    dial_code: "+677",
    fullName: "+677 Solomon Islands🇸🇧",
  },
  {
    name: "Somalia",
    flag: "🇸🇴",
    code: "SO",
    dial_code: "+252",
    fullName: "+252 Somalia🇸🇴",
  },
  {
    name: "South Africa",
    flag: "🇿🇦",
    code: "ZA",
    dial_code: "+27",
    fullName: "+27 South Africa🇿🇦",
  },
  {
    name: "South Sudan",
    flag: "🇸🇸",
    code: "SS",
    dial_code: "+211",
    fullName: "+211 South Sudan🇸🇸",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    flag: "🇬🇸",
    code: "GS",
    dial_code: "+500",
    fullName: "+500 South Georgia and the South Sandwich Islands🇬🇸",
  },
  {
    name: "Spain",
    flag: "🇪🇸",
    code: "ES",
    dial_code: "+34",
    fullName: "+34 Spain🇪🇸",
  },
  {
    name: "Sri Lanka",
    flag: "🇱🇰",
    code: "LK",
    dial_code: "+94",
    fullName: "+94 Sri Lanka🇱🇰",
  },
  {
    name: "Sudan",
    flag: "🇸🇩",
    code: "SD",
    dial_code: "+249",
    fullName: "+249 Sudan🇸🇩",
  },
  {
    name: "Suriname",
    flag: "🇸🇷",
    code: "SR",
    dial_code: "+597",
    fullName: "+597 Suriname🇸🇷",
  },
  {
    name: "Svalbard and Jan Mayen",
    flag: "🇸🇯",
    code: "SJ",
    dial_code: "+47",
    fullName: "+47 Svalbard and Jan Mayen🇸🇯",
  },
  {
    name: "Swaziland",
    flag: "🇸🇿",
    code: "SZ",
    dial_code: "+268",
    fullName: "+268 Swaziland🇸🇿",
  },
  {
    name: "Sweden",
    flag: "🇸🇪",
    code: "SE",
    dial_code: "+46",
    fullName: "+46 Sweden🇸🇪",
  },
  {
    name: "Switzerland",
    flag: "🇨🇭",
    code: "CH",
    dial_code: "+41",
    fullName: "+41 Switzerland🇨🇭",
  },
  {
    name: "Syrian Arab Republic",
    flag: "🇸🇾",
    code: "SY",
    dial_code: "+963",
    fullName: "+963 Syrian Arab Republic🇸🇾",
  },
  {
    name: "Taiwan",
    flag: "🇹🇼",
    code: "TW",
    dial_code: "+886",
    fullName: "+886 Taiwan🇹🇼",
  },
  {
    name: "Tajikistan",
    flag: "🇹🇯",
    code: "TJ",
    dial_code: "+992",
    fullName: "+992 Tajikistan🇹🇯",
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    flag: "🇹🇿",
    code: "TZ",
    dial_code: "+255",
    fullName: "+255 Tanzania, United Republic of Tanzania🇹🇿",
  },
  {
    name: "Thailand",
    flag: "🇹🇭",
    code: "TH",
    dial_code: "+66",
    fullName: "+66 Thailand🇹🇭",
  },
  {
    name: "Timor-Leste",
    flag: "🇹🇱",
    code: "TL",
    dial_code: "+670",
    fullName: "+670 Timor-Leste🇹🇱",
  },
  {
    name: "Togo",
    flag: "🇹🇬",
    code: "TG",
    dial_code: "+228",
    fullName: "+228 Togo🇹🇬",
  },
  {
    name: "Tokelau",
    flag: "🇹🇰",
    code: "TK",
    dial_code: "+690",
    fullName: "+690 Tokelau🇹🇰",
  },
  {
    name: "Tonga",
    flag: "🇹🇴",
    code: "TO",
    dial_code: "+676",
    fullName: "+676 Tonga🇹🇴",
  },
  {
    name: "Trinidad and Tobago",
    flag: "🇹🇹",
    code: "TT",
    dial_code: "+1868",
    fullName: "+1868 Trinidad and Tobago🇹🇹",
  },
  {
    name: "Tunisia",
    flag: "🇹🇳",
    code: "TN",
    dial_code: "+216",
    fullName: "+216 Tunisia🇹🇳",
  },
  {
    name: "Turkey",
    flag: "🇹🇷",
    code: "TR",
    dial_code: "+90",
    fullName: "+90 Turkey🇹🇷",
  },
  {
    name: "Turkmenistan",
    flag: "🇹🇲",
    code: "TM",
    dial_code: "+993",
    fullName: "+993 Turkmenistan🇹🇲",
  },
  {
    name: "Turks and Caicos Islands",
    flag: "🇹🇨",
    code: "TC",
    dial_code: "+1649",
    fullName: "+1649 Turks and Caicos Islands🇹🇨",
  },
  {
    name: "Tuvalu",
    flag: "🇹🇻",
    code: "TV",
    dial_code: "+688",
    fullName: "+688 Tuvalu🇹🇻",
  },
  {
    name: "Uganda",
    flag: "🇺🇬",
    code: "UG",
    dial_code: "+256",
    fullName: "+256 Uganda🇺🇬",
  },
  {
    name: "Ukraine",
    flag: "🇺🇦",
    code: "UA",
    dial_code: "+380",
    fullName: "+380 Ukraine🇺🇦",
  },
  {
    name: "United Arab Emirates",
    flag: "🇦🇪",
    code: "AE",
    dial_code: "+971",
    fullName: "+971 United Arab Emirates🇦🇪",
  },
  {
    name: "United Kingdom",
    flag: "🇬🇧",
    code: "GB",
    dial_code: "+44",
    fullName: "+44 United Kingdom🇬🇧",
  },
  {
    name: "United States",
    flag: "🇺🇸",
    code: "US",
    dial_code: "+1",
    fullName: "+1 United States🇺🇸",
  },
  {
    name: "Uruguay",
    flag: "🇺🇾",
    code: "UY",
    dial_code: "+598",
    fullName: "+598 Uruguay🇺🇾",
  },
  {
    name: "Uzbekistan",
    flag: "🇺🇿",
    code: "UZ",
    dial_code: "+998",
    fullName: "+998 Uzbekistan🇺🇿",
  },
  {
    name: "Vanuatu",
    flag: "🇻🇺",
    code: "VU",
    dial_code: "+678",
    fullName: "+678 Vanuatu🇻🇺",
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    flag: "🇻🇪",
    code: "VE",
    dial_code: "+58",
    fullName: "+58 Venezuela, Bolivarian Republic of Venezuela🇻🇪",
  },
  {
    name: "Vietnam",
    flag: "🇻🇳",
    code: "VN",
    dial_code: "+84",
    fullName: "+84 Vietnam🇻🇳",
  },
  {
    name: "Virgin Islands, British",
    flag: "🇻🇬",
    code: "VG",
    dial_code: "+1284",
    fullName: "+1284 Virgin Islands, British🇻🇬",
  },
  {
    name: "Virgin Islands, U.S.",
    flag: "🇻🇮",
    code: "VI",
    dial_code: "+1340",
    fullName: "+1340 Virgin Islands, U.S.🇻🇮",
  },
  {
    name: "Wallis and Futuna",
    flag: "🇼🇫",
    code: "WF",
    dial_code: "+681",
    fullName: "+681 Wallis and Futuna🇼🇫",
  },
  {
    name: "Yemen",
    flag: "🇾🇪",
    code: "YE",
    dial_code: "+967",
    fullName: "+967 Yemen🇾🇪",
  },
  {
    name: "Zambia",
    flag: "🇿🇲",
    code: "ZM",
    dial_code: "+260",
    fullName: "+260 Zambia🇿🇲",
  },
  {
    name: "Zimbabwe",
    flag: "🇿🇼",
    code: "ZW",
    dial_code: "+263",
    fullName: "+263 Zimbabwe🇿🇼",
  },
];

export default PhonePerfix;
