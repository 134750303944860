import { useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import AudienceSelect from "../../common/form/AudienceSelect";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import DocumentTone from "../components/DocumentTone";
import GenericDescriptionInput from "./formComponentes/GenericDescriptionInput";
import {AccordionProps} from "./types/AccordionProps";
import {useTranslation} from "react-i18next";

const SmallLabel = styled.label`
  font-family: "Assistant";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 13.2px */
  letter-spacing: -0.36px;
`;

export const useDefaultAccordions = (): AccordionProps[] => {
  const { t } = useTranslation();
  return [
    {
      title: t("default_accordions.content_description"),
      content: (
        <GenericDescriptionInput<DocumentEntity & { type: DocumentEntityType }>
          name="inputParams.description"
          label={t("default_accordions.content_description_label")}
          placeholder={t("default_accordions.content_description_placeholder")}
        />
      ),
    },
    {
      title: t("default_accordions.target_audience"),
      content: (
        <AudienceSelect<
            DocumentEntity & { type: DocumentEntityType.BLOG_POST_TIPS }
          > fieldName="inputParams.audienceId" />
      ),
    },
    {
      title: t("default_accordions.writing_style"),
      content: (
        <>
          <DocumentTone<
              DocumentEntity & { type: DocumentEntityType.BLOG_POST_TIPS }
            >
            fieldName="inputParams.tone"
            threeColumn={true}
          />
        </>
      ),
    },
  ];
};
