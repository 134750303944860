import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import styled from "styled-components";
import ModalHeader from "../ModalHeader";
import { Message } from "primereact/message";
import Button from "../../common/form/Button";
import { Skeleton } from "primereact/skeleton";
import { Badge } from "primereact/badge";
import { Button as PrimeButton } from "primereact/button";
import {
  FieldValues,
  Path,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import SelectKeywordsInput from "../../keywords/SelectKeywordsInput";

interface Props {
  onHide: () => void;
  name: Path<FieldValues>;
}

const Wrapper = styled.div`
  background: var(--light-bg);
  padding: 20px;
  position: relative;
  border-radius: 8px;

  .header-row {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 15px;
  }

  .p-inputgroup {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 6px;
    overflow: hidden;
    transition: border 0.2s ease;

    &:hover {
      border-color: #999;
    }
  }

  .p-inputtext {
    flex-grow: 1;
    border: none;
    padding: 10px;
    outline: none;
    font-size: 1rem;
  }

  .p-inputgroup > .p-button {
    border: none;
    background: var(--primary-purple);
    color: #fff;
    padding: 10px 14px;
    transition: background 0.2s ease;
  }

  .p-inputgroup > .p-button:hover {
    background: var(--primary-purple);
  }

  .dropdown {
    position: absolute;
    top: 80px;
    left: 0;
    width: calc(100% - 40px);
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    z-index: 999;
    margin: 0 20px;
  }

  .dropdown-item {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 20px;
    transition: background 0.2s ease;

    &:hover {
      background-color: #f7f7f7;
    }

    img,
    .skeleton {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      margin-right: 12px;
    }
  }

  .tags-container {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .tag-badge {
    display: flex;
    align-items: center;
    gap: 6px;
    background-color: black;
    color: white;
    border-radius: 6px;
    padding: 6px 12px;
    font-size: 0.9rem;
  }

  .remove-icon {
    font-size: 0.8rem;
    cursor: pointer;
    color: #757575;
    transition: color 0.2s ease;
  }

  .remove-icon:hover {
    color: #e53935;
  }
`;

const TagPeopleModal: React.FC<Props> = (props) => {
  const { setValue } = useFormContext();
  return (
    <Dialog
      style={{ width: "500px" }}
      closable={false}
      modal
      visible
      header={
        <ModalHeader
          OnClose={() => {
            setValue(`${props.name}.tags`, []);
            props.onHide();
          }}
        />
      }
      onHide={props.onHide}
    >
      <Wrapper>
        <SelectKeywordsInput<any>
          fieldName={`${props.name}.tags`}
          placeholder="הזן את המשתמש ולחץ Enter"
        />
      </Wrapper>

      <Message
        className="mt-5 mb-5"
        severity="info"
        text="על מנת לתייג אנשים יש לרשום את שם המשתמש המלא שלהם"
      />
      <Button onClick={props.onHide} primary bgColor="purple">
        שמור
      </Button>
    </Dialog>
  );
};

export default TagPeopleModal;
