import React, { useState } from "react";
import styled from "styled-components";
import Button from "../../common/form/Button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { TaskPriority } from "neword-core";

const HeaderContainer = styled.header`
  padding: 1rem;
  background-color: white;
  display: flex;
  border-radius: 12px;
  border: solid 1px var(--border-color);
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const FilterLabel = styled.label`
  font-size: 14px;
  color: var(--text-color);
  margin-right: 5px;
`;

const HeaderButton = styled(Button)`
  padding: 10px 15px;
`;

const ToggleContainer = styled.div`
  display: flex;
  background-color: var(--light-bg);
  border-radius: 8px;
  padding: 5px;
  gap: 5px;
`;

const ToggleButton = styled(Button)<{ active: boolean }>`
  background-color: ${(props) =>
    props.active ? "var(--purple-opacity) !important" : "transparent"};
  color: ${(props) => (props.active ? "var(--primary-purple)" : "inherit")};

  &:hover {
    background-color: var(--bg-dark);
  }
  height: max-content;
  padding: 8px 14px;
`;

interface HeaderProps {
  onCreateContent?: () => void;
  view: "kanban" | "list";
  setView: (view: "kanban" | "list") => void;
  onFilterChange?: (filter: string) => void;
  onPriorityFilterChange?: (priority: TaskPriority | "") => void;
  onSortChange?: (sortBy: string) => void;
  sortBy?: string;
}

const Header: React.FC<HeaderProps> = ({
  onCreateContent,
  view,
  setView,
  onFilterChange,
  onPriorityFilterChange,
  onSortChange,
  sortBy = "date",
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [priority, setPriority] = useState<TaskPriority | "">("");
  const [sort, setSort] = useState(sortBy);

  // Handle search filter change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (onFilterChange) {
      onFilterChange(value);
    }
  };

  // Handle priority filter change (PrimeReact Dropdown)
  const handlePriorityChange = (e: { value: TaskPriority | "" }) => {
    const value = e.value;
    setPriority(value);
    if (onPriorityFilterChange) {
      onPriorityFilterChange(value);
    }
  };

  // Handle sort change (PrimeReact Dropdown)
  const handleSortChange = (e: { value: string }) => {
    const value = e.value;
    setSort(value);
    if (onSortChange) {
      onSortChange(value);
    }
  };

  // Options for the dropdowns
  const priorityOptions = [
    { label: "כל העדיפויות", value: "" },
    { label: "גבוהה", value: "HIGH" },
    { label: "בינונית", value: "MEDIUM" },
    { label: "נמוכה", value: "LOW" },
  ];

  const sortOptions = [
    { label: "תאריך (מהחדש לישן)", value: "date" },
    { label: "עדיפות", value: "priority" },
    { label: "כותרת", value: "title" },
  ];

  return (
    <HeaderContainer>
      <HeaderButton primary onClick={onCreateContent}>
        הוספת משימה
      </HeaderButton>
      <FiltersContainer>
        <div>
          <FilterLabel>חיפוש משימה:</FilterLabel>
          <InputText
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="חיפוש..."
            style={{ width: "200px" }}
          />
        </div>

        <div>
          <FilterLabel>סינון לפי עדיפות:</FilterLabel>
          <Dropdown
            value={priority}
            options={priorityOptions}
            onChange={handlePriorityChange}
            placeholder="בחר עדיפות"
          />
        </div>

        <div>
          <FilterLabel>מיון לפי:</FilterLabel>
          <Dropdown
            value={sort}
            options={sortOptions}
            onChange={handleSortChange}
            placeholder="בחר מיון"
          />
        </div>
      </FiltersContainer>
      <ButtonGroup>
        <ToggleContainer>
          <ToggleButton
            active={view === "kanban"}
            onClick={() => setView("kanban")}
          >
            תצוגת בלוקים
          </ToggleButton>
          <ToggleButton
            active={view === "list"}
            onClick={() => setView("list")}
          >
            תצוגת רשימה
          </ToggleButton>
        </ToggleContainer>
      </ButtonGroup>
    </HeaderContainer>
  );
};

export default Header;
