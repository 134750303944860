import { PostEntityType } from "neword-core";
import { PostData } from "neword-core/dist/cjs/entities/post/postData";

export const createPostDeafultParams = (postType: PostEntityType): PostData => {
  switch (postType) {
    case PostEntityType.FACEBOOK_POST:
      return {
        type: postType,
        message: "",
        published: false,
        imageUrls: [],
      };

    case PostEntityType.INSTAGRAM_POST:
      return {
        type: postType,
        media_type: "IMAGE",
        media_ids: [],
        message: "",
        imageUrls: [],
      };

    case PostEntityType.INSTAGRAM_STORY:
      return {
        type: postType,
        media_ids: [],
        message: "",
        imageUrls: [],
      };

    case PostEntityType.LINKEDIN_POST:
      return {
        type: postType,
        message: "",
        imageUrls: [],
      };

    case PostEntityType.LINKEDIN_COMPANY_POST:
      return {
        type: postType,
        message: "",
        imageUrls: [],
      };
    case PostEntityType.TIKTOK_POST:
      return {
        type: postType,
        message: "",
        imageUrls: [],
      };

    case PostEntityType.INSTAGRAM_REEL:
      return {
        type: postType,
        message: "",
        imageUrls: [],
      };

    case PostEntityType.FACEBOOK_REEL:
      return {
        type: postType,
        message: "",
        imageUrls: [],
      };

    case PostEntityType.EMAIL:
      return {
        type: postType,
        subject: "",
        body: "",
        recepientsGroupId: "",
        from: "",
        message: "",
        imageUrls: [],
      };

    case PostEntityType.NOTE:
      return {
        type: postType,
        message: "",
        imageUrls: [],
      };

    default:
      return {
        type: PostEntityType.FACEBOOK_POST,
        message: "",
        published: false,
        imageUrls: [],
      };
  }
};
