const devMode = process.env.NODE_ENV === "development";

const apiUrl: string = (window as any).__ENV__.REACT_APP_API_URL;
console.log(apiUrl);
const AppConfig = {
  devMode,
  serverUrl: devMode ? "http://localhost:3001" : apiUrl,
  appUrl: devMode ? "http://localhost:3000" : "https://app.neword.ai",
};
console.log(AppConfig.serverUrl);
export default AppConfig;
