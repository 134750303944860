import { ReactComponent as WandIcon } from "../../assets/Icons/Wand.svg";
import { ReactComponent as AddBorderIcon } from "../../assets/Icons/PlusBorder.svg";
import { ReactComponent as AddArticle } from "../../assets/Icons/AddArticle.svg";
import { ReactComponent as NoteIcon } from "../../assets/Icons/Idea.svg";

import { useState } from "react";
import { CreateDocument } from "./calendarStyled";
import CreateDocModal from "../common/form/CreateDocModal";
import AddManualContent from "../modals/AddManualContent";
import NoteModal from "../modals/NoteModal";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const EmptyTileWrapper = styled.div`
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  :hover {
    opacity: 1;
  }
`;

function EmptyTile({ date, clientView }: { date: Date; clientView?: boolean }) {
  const { t } = useTranslation();
  const [showPublishPostModal, setShowPublishPostModal] = useState(false);
  const [showCreateDocModal, setShowCreateDocModal] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);

  return (
    <>
      {!clientView && (
        <EmptyTileWrapper className="flex gap-10 flex-column">
          <CreateDocument onClick={() => setShowCreateDocModal(true)}>
            <WandIcon />
            <span>{t("calendar.title.create_with_ai")}</span>
          </CreateDocument>
          <CreateDocument onClick={() => setShowPublishPostModal(true)}>
            <AddArticle />
            <span>{t("calendar.title.create_manual_content")}</span>
          </CreateDocument>
          <CreateDocument onClick={() => setShowNoteModal(true)}>
            <NoteIcon />
            <span>{t("calendar.title.create_note")}</span>
          </CreateDocument>
          {showPublishPostModal && (
            <AddManualContent
              date={date}
              onHide={() => setShowPublishPostModal(false)}
            />
          )}

          {showCreateDocModal && (
            <CreateDocModal
              dateParam={date}
              onHide={() => setShowCreateDocModal(false)}
            />
          )}

          {showNoteModal && (
            <NoteModal
              scheduleDate={date}
              onHide={() => setShowNoteModal(false)}
            />
          )}
        </EmptyTileWrapper>
      )}
    </>
  );
}
export default EmptyTile;
