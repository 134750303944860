import { Dialog } from "primereact/dialog";
import Button from "../common/form/Button";
import FormStyle from "../common/form/FormStyle";
import styled from "styled-components";
import articleService from "../../core/services/article.service";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  ArticlesSummarySelector,
  articlesAtom,
} from "../../state/articlesState";
import { useParams } from "react-router";
import {useTranslation} from "react-i18next";

interface Props {
  onHide: () => void;
  onSubmit: () => void;
  articleId: string;
}

const StyledButton = styled(Button)`
  width: fit-content;
`;

const DeleteArticleModal: React.FC<Props> = ({
  onHide,
  articleId,
  onSubmit,
}) => {
  const { websiteId } = useParams();
  const { t } = useTranslation();
  const [articles, setArticles] = useRecoilState(
    articlesAtom(websiteId as string)
  );
  const onSubmitInternal = async () => {
    await articleService.deleteArticle(articleId);
    setArticles(articles?.filter((a) => a.id !== articleId));
    onSubmit();
  };

  return (
    <Dialog
      modal
      visible
      header={t('delete_article_modal.header')}
      onHide={onHide}
      footer={
        <StyledButton primary onClick={onSubmitInternal}>
          {t('delete_article_modal.delete_button')}
        </StyledButton>
      }
    >
      <p>{t('delete_article_modal.confirmation_message')}</p>
      <small>{t('delete_article_modal.info_message')}</small>
    </Dialog>
  );
};

export default DeleteArticleModal;
