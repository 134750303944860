import { TourNames } from "../../core/types/tourNames";
import { Tour } from "../../core/types/tourType";
import {useTranslation} from "react-i18next";
import { StepCard } from "./StepCard";

export const useWelcomeTour = (): Tour => {
  const { t } = useTranslation();

  return {
    name: TourNames.WelcomeTour,
    isRunning: false,
    steps: [
      {
        target: ".welcome-step",
        content: (
          <StepCard
          title="ברוכים הבאים!"

            description={t("welcome_tour.welcome")}
            icon={<span>👋</span>}
          />
        ),
        disableBeacon: true,
      },
      {
        target: ".recomendedContainer-step",
        content: (
          <StepCard
            title="קופי מדויק עם AI מותאם אישית"
            description={t("welcome_tour.templates_for_social_media")}
            icon={<span>🤖</span>}
          />
        ),
        disableBeacon: true,
      },
      {
        target: ".contentManagement-step",
        content: (
          <StepCard
            title="תכנון ופרסום חכם של תוכן"
            description={t("welcome_tour.content_management")}
            icon={<span>📅</span>}
          />
        ),
        disableBeacon: true,
      },
      {
        target: ".changeBisness-step",
        content: (
          <StepCard
            title="מעבר מהיר בין מותגים"
            description={t("welcome_tour.switch_business")}
            icon={<span>🔁</span>}
          />
        ),
        disableBeacon: true,
      },
      {
        target: ".generalSetings-step",
        content: (
          <StepCard
            title="הגדרות מותג והתאמה אישית"
            description={t("welcome_tour.general_settings")}
            icon={<span>⚙️</span>}
          />
        ),
        disableBeacon: true,
      },
    ],
  };
};


export const DOCUMENT_TOUR: Tour = {
  name: TourNames.DocumentTour,
  isRunning: false,
  steps: [
    {
      target: ".test2",
      content: (
        <div>
          <p>אפשר לרנדר פה הכל</p>
          <i className="pi pi-star" style={{ fontSize: "1.5rem" }} />
        </div>
      ),
    },
    {
      target: ".test3",
      content: (
        <div>
          <p>הנה תמונה לדוגמה</p>
          <img
            src="https://fastly.picsum.photos/id/1051/150/150.jpg?hmac=_j4pME5rAp5wxYcxe-c2RKpLiJ8nfNzjaHbXSn9zNpg"
            alt="Example"
            style={{ width: "150px", height: "150px" }}
          />
        </div>
      ),
      disableBeacon: true,
    },
  ],
};
