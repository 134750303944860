import {
  Controller,
  Path,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";

import { DocumentEntityType, DocumentEntity } from "neword-core";

import GenericDescriptionInput from "./GenericDescriptionInput";
import {useTranslation} from "react-i18next";

function CartAbandonmentMailDescription() {
  const { t } = useTranslation();
  return (
    <div className="w-full step-2-wt">
      <GenericDescriptionInput<
        DocumentEntity & { type: DocumentEntityType.CART_ABANDONMENT_EMAIL }
      >
        name="inputParams.incentives"
        label={t("cart_abandonment_mail.incentives_label")}
        placeholder={t("cart_abandonment_mail.incentives_placeholder")}
      />

      <GenericDescriptionInput<
        DocumentEntity & { type: DocumentEntityType.CART_ABANDONMENT_EMAIL }
      >
        name="inputParams.cartItems"
        label={t("cart_abandonment_mail.cart_items_label")}
        placeholder={t("cart_abandonment_mail.cart_items_placeholder")}
        hideRephrase
      />
      <GenericDescriptionInput<
        DocumentEntity & { type: DocumentEntityType.CART_ABANDONMENT_EMAIL }
      >
        name="inputParams.customerSupport"
        label={t("cart_abandonment_mail.customer_support_label")}
        placeholder={t("cart_abandonment_mail.customer_support_placeholder")}
        hideRephrase
      />
    </div>
  );
}

export default CartAbandonmentMailDescription;
