import { useRecoilSnapshot, useRecoilValue } from "recoil";
import { CreateWebsiteRequestData } from "../../../core/services/requests/createWebsite/createWebsiteRequestData";
import { userState } from "../../../state/userState";
import FormStepType from "../../common/form/types/formStepType";
import BusinessDetails from "./steps/BusinessDetails";
import BusinessExtraDetails from "./steps/BusinessExtraDetails";
import ChooseSetup from "./steps/ChooseSetup";
import CreateAudience from "./steps/CreateAudience";
import Integrations from "./steps/Integrations";
import { useTranslation } from "react-i18next";
import { websitesStateSelector } from "../../../state/websitesState";

export const useCreateWebsiteSteps = (isBrand: boolean = false) => {
  const { t } = useTranslation();
  const snapshot = useRecoilSnapshot();
  const websites = snapshot.getLoadable(websitesStateSelector).getValue();

  const stepsKey = isBrand
    ? "create_website_steps_brand"
    : "create_website_steps";

  const createWebsiteSteps: FormStepType<CreateWebsiteRequestData>[] = [
    {
      component: <BusinessDetails />,
      fields: ["website.name", "website.url"],
      title: t(`${stepsKey}.business_details.title`),
      subtitle: t(`${stepsKey}.business_details.subtitle`),
      nextButtonText: t(`${stepsKey}.business_details.next_button_text`),
    },
    {
      component: <BusinessExtraDetails />,
      fields: ["website.description", "website.category"],
      title: t(`${stepsKey}.business_extra_details.title`),
      subtitle: t(`${stepsKey}.business_extra_details.subtitle`),
      nextButtonText: t(`${stepsKey}.business_extra_details.next_button_text`),
    },
    {
      title: t(`${stepsKey}.audience.title`),
      subtitle: t(`${stepsKey}.audience.subtitle`),
      component: <CreateAudience />,
      fields: ["audience"],
      nextButtonText: t(`${stepsKey}.audience.next_button_text`),
    },
    {
      component: <Integrations />,
      fields: ["website.description"],
      title: t(`${stepsKey}.integrations.title`),
      subtitle: t(`${stepsKey}.integrations.subtitle`),
      nextButtonText: t(`${stepsKey}.integrations.next_button_text`),
    },
  ];

  if (!websites.length) {
    createWebsiteSteps.unshift({
      component: <ChooseSetup />,
      fields: ["companyType"],
      nextButtonText: t(`${stepsKey}.choose_setup.next_button_text`),
    });
  }

  return createWebsiteSteps;
};
