import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../../assets/Icons/linkedin/LinkedInLogo.svg";
import Card from "../../common/Card";
import { FieldValues, Path, useFormContext, useWatch } from "react-hook-form";
import { AttachmentMedia, PostEntity, PostEntityType } from "neword-core";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { websiteState } from "../../../state/websitesState";
import { Galleria } from "primereact/galleria";
import LinkedinPreviewImage from "../../../assets/images/LinkedInPreview.png"; // Add Facebook preview image
import MediaEditor from "../MediaEditor";
import { ReactComponent as Edit } from "../../../assets/Icons/EditImage.svg";
import { ReactComponent as Remove } from "../../../assets/Icons/Times.svg";
import { useTranslation } from "react-i18next";
import IphoneBG from "../../../assets/images/iphone_svg_bg.svg";

const Wrapper = styled(Card)<{ reel?: boolean }>`
  position: relative;
  padding: 0px;
  width: 100%;
  overflow: hidden;
  max-width: 350px;
  border-radius: 8px;
  border: none;
  border-radius: 50px 50px 0px 0px;
`;
const PhoneImage = styled.img`
  position: absolute;
  width: 103% !important;
  height: auto;
  left: -1.2%;
  display: block;
  z-index: 1;
  top: -1px;
  pointer-events: none;
  filter: drop-shadow(0px 0px 4px #4747471d);
`;
const TopSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const AccountName = styled.div`
  font-weight: 600;
  font-size: 14px;
`;

const PostTime = styled.div`
  font-size: 12px;
  color: #00000099;
  margin-inline: 10px;
`;

const ContentWrapper = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 50px;
  padding: 0 20px;
  color: #00000099;
`;

const ImageWrapper = styled.div`
  width: 100%;
  padding-top: 52.5%; /* LinkedIn post image aspect ratio */
  position: relative;
  margin-bottom: 10px;
`;

const SyledGalleria = styled(Galleria)`
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  min-height: 230px;
  .p-galleria-item {
    background-color: black;
    border-radius: 8px;
  }

  .p-galleria-item-next {
    right: auto !important;
    left: 0 !important;
    transform: rotate(180deg);
    z-index: 9999999;
  }

  .p-galleria-item-prev {
    left: auto !important;
    right: 0 !important;
    transform: rotate(180deg);
    z-index: 9999999;
  }

  .p-galleria-indicator button {
    width: 0.5rem !important;
    height: 0.5rem !important;
  }

  .p-galleria-indicators {
    padding: 0 !important;
  }

  .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: var(--primary-purple);
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    aspect-ratio: 4 / 5;
    border-radius: 8px;
  }
`;

const ToolWrapper = styled.div`
  gap: 10px;
  cursor: pointer;
  span {
    font-size: 12px;
    color: white;
  }
  display: flex;
  height: 40px;
  width: 40px;

  justify-content: center;
  align-items: center;
  background-color: white;
  border: solid 1px var(--border-color);
  border-radius: 50%;
  transition-duration: 0.2s;

  position: relative;
  svg {
    fill: var(--text-color);
    width: 20px;
    height: 20px;
  }

  path {
    fill: var(--text-color);
  }

  &:hover {
    border-color: var(--title-color);
  }
  &::after {
    content: "עריכת מדיה";
    position: absolute;
    width: max-content;
    font-size: 12px;
    right: 120%;
    top: 49%;
    transform: translateY(-50%);
  }
`;

const Tools = styled.div`
  gap: 10px;
  display: flex;
  padding-bottom: 5px;
`;

const PostImage = styled.img`
  aspect-ratio: 4 / 5;
  border-radius: 8px;
  width: 100%;
  object-fit: cover;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 15px;
  bottom: 15px;
  z-index: 99;
`;

function LinkedInPreview<FormDataType extends FieldValues>({
  name,
}: {
  name: Path<FormDataType>;
}) {
  const methods = useFormContext();
  const { websiteId } = useParams();
  const { t } = useTranslation();
  const website = useRecoilValue(websiteState(websiteId as string));
  const values = useWatch({ control: methods.control, name: name }); // Ensure posts has a default value

  const [showMediaEditorModal, setMediaEditorModal] = useState(false);
  const imageUrls: string[] = useMemo(() => {
    return values?.imageUrls && values.imageUrls.length > 0
      ? values.imageUrls
      : [{ url: LinkedinPreviewImage, metadata: { type: "IMAGE" } }]; // Default to empty string if no image is available
  }, [values]);
  const text = methods.getValues(`${name}.message`);
  return (
    <div className="h-full pt-2">
      <Tools>
        {/* <ToolWrapper
                  onClick={() =>
                    methods.setValue(
                      name + ".imageUrls",
                      values.imageUrls.filter(
                        (m: AttachmentMedia) => m !== item
                      )
                    )
                  }
                >
                  <Remove />
                  <span>{t("facebook_caption_block.remove")}</span>
                </ToolWrapper> */}
        <ToolWrapper onClick={() => setMediaEditorModal(true)}>
          <Edit />
          {/* <span>{t("facebook_caption_block.edit_media")}</span> */}
        </ToolWrapper>
      </Tools>
      <Wrapper>
        <PhoneImage src={IphoneBG} />
        {/* <TopSection>
        <AccountName>{website?.name}</AccountName>
        <PostTime>{t("facebook_caption_block.post_time")}</PostTime>
      </TopSection> */}
        <ContentWrapper>{text}</ContentWrapper>
        <SyledGalleria
          value={imageUrls}
          // style={{ maxWidth: "640px" }}
          // changeItemOnIndicatorHover
          showThumbnails={false}
          showIndicators={false}
          showItemNavigators
          item={(item: AttachmentMedia) => {
            if (!item) return;

            return (
              <>
                {item.metadata.type === "VIDEO" ? (
                  <video src={item.url} controls />
                ) : (
                  <PostImage src={item.url} />
                )}
                {showMediaEditorModal && (
                  <MediaEditor
                    url={item.url}
                    onProcess={(attachment: AttachmentMedia) =>
                      methods.setValue(
                        name + ".imageUrls",
                        values.imageUrls.map((m: AttachmentMedia) =>
                          m === item ? attachment : m
                        )
                      )
                    }
                    onClose={() => setMediaEditorModal(false)}
                  />
                )}
              </>
            );
          }}
        />

      </Wrapper>
    </div>
  );
}

export default LinkedInPreview;
