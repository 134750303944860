import { Dialog } from "primereact/dialog";
import React from "react";
import styled from "styled-components";
import Button from "../common/form/Button";
import ModalHeader from "./ModalHeader";
import {useTranslation} from "react-i18next";

interface Props {
  onHide: () => void;
  onSubmit: () => void;
}

const DialogTitle = styled.h1`
  color: var(--main-title-color, #0a2540);
  font-size: 4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 4rem */
  letter-spacing: -0.18rem;
  text-align: center;
`;

const SubTitle = styled.h2`
  margin-top: 20px;
  color: #425466;
  text-align: right;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 1.25rem */
  letter-spacing: -0.0375rem;
  text-align: center;
`;

const Title = styled.h1`
  color: #0a2540;
  font-size: 2.5rem;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 2.5rem */
  letter-spacing: -0.075rem;
`;

const DialogStyled = styled(Dialog)`
  width: 60%;
`;

const Row = styled.div`
  display: flex;
`;

const IntegrationWarningModal: React.FC<Props> = ({ onHide, onSubmit }) => {
  const { t } = useTranslation();
  return (
    <DialogStyled
      onHide={onHide}
      visible
      header={<ModalHeader OnClose={onHide} right />}
      draggable={false}
      closable={false}
      footer={
        <Row>
          <Button primary onClick={onHide}>
            {t("integration_warning_modal.buttons.connect_social")}
          </Button>
          <Button onClick={() => onSubmit()}>{t("integration_warning_modal.buttons.continue_without")}</Button>
        </Row>
      }
    >
      <Title>
        {t("integration_warning_modal.title")}
      </Title>

      <SubTitle>
        <div dangerouslySetInnerHTML={{__html: t("integration_warning_modal.subtitle")}}/>
      </SubTitle>
    </DialogStyled>
  );
};

export default IntegrationWarningModal;
