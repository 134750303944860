import {
  Controller,
  Path,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";

import { DocumentEntityType, DocumentEntity } from "neword-core";

import GenericDescriptionInput from "./GenericDescriptionInput";
import {useTranslation} from "react-i18next";

function BlogPostOpinionDescription() {
  const { t} = useTranslation();
  return (
    <div className="w-full step-2-wt">
      <GenericDescriptionInput<
        DocumentEntity & { type: DocumentEntityType.BLOG_POST_OPINION }
      >
        name="inputParams.blogTopic"
        label={t("blog_post_opinion.blog_topic_label")}
        placeholder={t("blog_post_opinion.blog_topic_placeholder")}
        inputStyle={{ height: 45 }}
      />
      <GenericDescriptionInput<
        DocumentEntity & { type: DocumentEntityType.BLOG_POST_OPINION }
      >
        name="inputParams.blogOpinion"
        label={t("blog_post_opinion.blog_opinion_label")}
        placeholder={t("blog_post_opinion.blog_opinion_placeholder")}
      />
      <GenericDescriptionInput<
        DocumentEntity & { type: DocumentEntityType.BLOG_POST_OPINION }
      >
        name="inputParams.description"
        label={t("blog_post_opinion.description_label")}
        placeholder={t("blog_post_opinion.description_placeholder")}
      />
    </div>
  );
}

export default BlogPostOpinionDescription;
