import { InputText } from "primereact/inputtext";
import {
  Controller,
  FieldValues,
  Path,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import styled from "styled-components";
import { ReactComponent as SpeaklesIcon } from "../../assets/Icons/Sparkles.svg";
import { ReactComponent as CloseIcon } from "../../assets/Icons/Close.svg";
import React, { useState } from "react";
import Link from "../common/Link";
import CardSubtitle from "../common/CardSubtitle";
import SuggestionsKeywords from "./KeywordsSuggestions";
import Badge from "../common/Badge";
import { useTranslation } from "react-i18next";

const KeywordsContainer = styled.div`
  margin-top: 0px;
`;

const KeywordsWrapper = styled.div`
  margin-top: 0px;
  display: flex;
  gap: 0.625rem;
  flex-wrap: wrap;

  & .kw-bg {
    /* border: solid 1px var(--border-color); */
    color: var(--primary-purple);
    background: var(--light-bg);
  }
`;

const KeywordBadge = styled(Link)<{ whiteText?: boolean }>`
  cursor: pointer !important;
  &:hover .kw-overly {
    opacity: 0.85;
  }

  &:hover .kw-overly svg path {
    opacity: 1;
  }
  & .close path {
    fill: ${({ whiteText }) => (whiteText ? "white" : "var(--primary-purple)")};
  }
  ${({ whiteText }) =>
    whiteText &&
    `
    color: white;
  `}
`;

const KeywordOverlay = styled(Link)`
  width: 100%;
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-purple);
  border-radius: 600px;
  opacity: 0;
  transition-duration: 0.2s;

  & svg path {
    fill: white;
    opacity: 0;
    transition-duration: 0.2s;
  }
`;

const SuggestedKeywordWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 30px;
`;

interface Props<T> {
  fieldName: Path<T>;
  middleText?: string;
  keywordsHeader?: string;
  displaySuggestions?: boolean;
  placeholder?: string;
  // Optionally, you can add a prop to conditionally show the add button
  showAddButton?: boolean;
  blackBadge?: boolean;
}

const Wrapper = styled.div`
  margin-top: 20px;
`;

// Container to wrap input and add button
const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

// Style the input field as before
const InputTextWrapper = styled(InputText)`
  width: 500px;
`;

// Styled add button with a plus sign
const AddButton = styled.button`
  margin-left: 8px;
  background-color: var(--primary-purple);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

function SelectKeywordsInput<FormDataType extends FieldValues>({
  fieldName,
  middleText,
  displaySuggestions,
  placeholder,
  blackBadge = false,
  showAddButton = false, // default to showing the add button
}: Props<FormDataType>) {
  const { control } = useFormContext<FormDataType>();
  const keywords = (useWatch({ control, name: fieldName }) ?? []) as string[];
  const { t } = useTranslation();

  const { append, remove } = useFieldArray({
    name: fieldName as any,
    control,
  });

  const [keyword, setKeyword] = useState("");

  const onEnter = async (keyword: string) => {
    if (keyword && !keywords.includes(keyword)) {
      setKeyword("");
      append(keyword as any);
    }
  };

  return (
    <div className="w-full">
      <Controller
        name={fieldName}
        control={control}
        render={({ fieldState }) => (
          <>
            <InputContainer>
              <InputTextWrapper
                value={keyword}
                placeholder={
                  placeholder ?? t("select_keywords_input.placeholder")
                }
                onChange={(e) => setKeyword(e.target.value)}
                onKeyDown={(e) => e.keyCode === 13 && onEnter(keyword)}
                className={"w-full  " + (fieldState.error ? "p-invalid" : "")}
              />
              {showAddButton && (
                <AddButton
                  type="button"
                  onClick={() => onEnter(keyword)}
                  disabled={!keyword.trim()}
                  title={t("select_keywords_input.add_keyword")}
                >
                  +
                </AddButton>
              )}
            </InputContainer>
            <small className="mb-4 block">{middleText}</small>

            {displaySuggestions && (
              <Wrapper>
                <label>
                  {t("select_keywords_input.suggested_keywords_label")}
                </label>
                <SuggestedKeywordWrapper>
                  <React.Suspense
                    fallback={
                      <small>
                        {t("select_keywords_input.loading_suggestions")}
                      </small>
                    }
                  >
                    <SuggestionsKeywords onClickKeyword={onEnter} />
                  </React.Suspense>
                </SuggestedKeywordWrapper>
              </Wrapper>
            )}

            <KeywordsContainer>
              {/* <label>{keywordsHeader}</label> */}
              <KeywordsWrapper>
                {keywords.map((keyword, index) => (
                  <KeywordBadge
                    key={keyword + index}
                    className="kw-bg"
                    whiteText={blackBadge}
                  >
                    <Badge
                      icon={<CloseIcon className="close" />}
                      hover
                      bgColor={blackBadge ? "title" : "bg"}
                      clickable
                      textColor={blackBadge ? "white" : "purple"}
                      large
                      onClick={() => {
                        remove(index);
                      }}
                    >
                      {keyword}
                    </Badge>
                  </KeywordBadge>
                ))}
              </KeywordsWrapper>
            </KeywordsContainer>
          </>
        )}
      />
    </div>
  );
}

export default SelectKeywordsInput;
