import TopMenu from "./topMenu";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import SideBar from "../menu/SideBar";
import { useRecoilState, useRecoilValue } from "recoil";
import { userState } from "../../state/userState";
import Preloader from "../common/Preloader";
import Protected from "../../middleware/protected";
import { UserRole } from "../../core/types/userRole";
import Intercom from "@intercom/messenger-js-sdk";
import ClientPage from "../../pages/websitePages/ClientPage";
import { shrinkState } from "../../state/shrinkState";
import IsMobile from "./IsMobile";

interface Props {
  children: React.ReactElement;
  subMenu?: React.ReactElement;
  hideSideBar?: boolean;
}

const ContentWrapper = styled.div<{
  hideSideBar?: boolean;
  isExpanded?: boolean;
}>`
  transition-duration: 0.1s;
  width: ${({ hideSideBar, isExpanded }) =>
    hideSideBar
      ? "100%"
      : isExpanded
        ? "calc(100% - 12rem)"
        : "calc(100% - 80px)"};
`;

const PageWrapperOverflow = styled.div`
  /* padding-right: 30px;
  padding-left: 30px;
  padding-top: 30px; */
  height: calc(100vh - 55px);
  overflow-y: auto;
  background: var(--light-bg);
`;

const SidebarWrapper = styled.div<{ isExpanded: boolean }>`
  width: ${(props) => (props.isExpanded ? "12rem" : "80px")};
  height: 100vh;
  transition-duration: 0.1s;
`;

const InsideLayout = styled.div<{ paddingRight?: Boolean }>`
  width: 100%;
  padding: 10px;
`;

const SubMenuWrapper = styled.div`
  width: 10rem;
  height: 100%;
  border: solid 1px var(--border-color);
  background: white;
  padding: 20px 20px 40px 20px ;
  border-radius:8px;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  gap:20px;
`;

const Content = styled.div`
  width: calc(100% - 12rem);
  height: calc(100vh - 115px);
  overflow: auto;
`;

const PrivateLayout: React.FC<Props> = ({ children, subMenu, hideSideBar }) => {
  const [showPackagesModal, setShowPackagesModal] = useState(false);
  const [isShrinked, setisShrinked] = useRecoilState(shrinkState);
  const user = useRecoilValue(userState);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // You can adjust the breakpoint as needed (e.g., 768px)
      setIsMobile(window.innerWidth < 768);
    };

    // Initial check
    handleResize();

    // Listen for resize events
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  Intercom({
    app_id: "c0efdcqp",
    user_id: user.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name: user.firstName, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: user.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
  });

  useEffect(() => {
    if (
      user?.company &&
      (!user.company.Subscription || !user.company.Subscription.isActive)
    ) {
      setShowPackagesModal(true);
    }
  }, [user?.company, user?.company?.Subscription?.isActive]);

  return (
    <React.Suspense fallback={<Preloader />}>
      {isMobile && user.role !== UserRole.CLIENT ? (
        <IsMobile />
      ) :  (<div className="flex">
        <Protected neededRole={UserRole.EDITOR}>
          {!hideSideBar && (
            <SidebarWrapper isExpanded={!isShrinked}>
              <SideBar />
            </SidebarWrapper>
          )}
        </Protected>
        <ContentWrapper
          isExpanded={!isShrinked}
          hideSideBar={hideSideBar || user.role === UserRole.CLIENT}
        >
          <TopMenu />

          <PageWrapperOverflow>
            <InsideLayout>
              <Protected neededRole={UserRole.EDITOR} fallback={<ClientPage />}>
                {/* {isMobile && <IsMobile />} */}
                {subMenu ? (
                  <Wrapper>
                    <SubMenuWrapper>{subMenu}</SubMenuWrapper>
                    <Content>{children}</Content>
                  </Wrapper>
                ) : (
                  children
                )}
              </Protected>
            </InsideLayout>
          </PageWrapperOverflow>
        </ContentWrapper>
      </div>)}
    </React.Suspense>
  );
};

export default PrivateLayout;
