// FilterPanel.tsx
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Schedule } from "../../../assets/Icons/Schedule.svg";
import { ReactComponent as ExportIcon } from "../../../assets/Icons/Share.svg";
import { ReactComponent as CheckIcon } from "../../../assets/Icons/Approved.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/Icons/Calendar.svg";
import { ReactComponent as GridIcon } from "../../../assets/Icons/Grid.svg";
import { ReactComponent as FeedIcon } from "../../../assets/Icons/Feed.svg";
import { ReactComponent as NoteIcon } from "../../../assets/Icons/Idea.svg";

import { PostEntityType } from "neword-core";
import Button from "../../common/form/Button";
import { PostCardIcons } from "../../post/postIconMappings";
import DropDownMenu from "../../common/form/DropDownMenu";
import ViewToggle from "./ViewToggle";
import NoteModal from "../../modals/NoteModal";
import { useRecoilValue } from "recoil";
import { userState } from "../../../state/userState";
import { UserRole } from "../../../core/types/userRole";
import ShareGantLinkModal from "../../modals/shareGantLinkModal";
import useIsMobile from "./useIsMobile";

interface DropDownOption {
  label: string;
  icon?: React.ReactNode | JSX.Element;
  customTemplate?: React.ReactNode;
  link?: string;
  corners?: boolean;
}

interface FilterPanelProps {
  toggleModal: () => void;
  hideToggle?: boolean;
  filterPostType: PostEntityType[];
  setFilterPostType: React.Dispatch<React.SetStateAction<PostEntityType[]>>;
  currentView: "month" | "week" | "day";
  setCurrentView: React.Dispatch<
    React.SetStateAction<"month" | "week" | "day">
  >;
  datePicker?: JSX.Element;
}

const PanelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftSection = styled.div`
  display: flex;
  gap: 10px;
  width: 40%;
`;

const CenterSection = styled.div`
  display: flex;
  justify-content: center;
  width: 30%;
  position: relative;

  .clear {
    font-size: 12px;
    position: absolute;
    left: -40px;
    top: 2px;
    color: var(--title-color);
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
  }
  .choose {
    opacity: 0;
    pointer-events: none;
    font-size: 12px;
    position: absolute;
    left: -40px;
    bottom: 2px;
    color: var(--title-color);
    cursor: pointer;
  }
`;

const RightSection = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 40%;
  justify-content: flex-end;
`;

const ButtonStyled = styled(Button)`
  height: 100%;
  padding: 10px 15px;

  &.share {
    &:hover {
      background-color: var(--bg-dark);
    }
    padding: 13px 15px;
    svg {
      margin-left: 0;
    }
  }

  &.note {
    &:hover {
      background-color: hsl(
        44.516129032258064,
        79.48717948717952%,
        60.70588235294117%
      );
    }
    padding: 13px 15px;
    svg {
      margin-left: 0;
      fill: var(--title-color);
    }
  }
`;

const TopMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
const CheckWrapper = styled.div<{ active?: boolean }>`
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.active ? "var(--text-color)" : "transparent"};
  right: -5px;
  top: -5px;
  transition-duration: 0.2s;
  svg {
    fill: white;
    width: 6px !important;
    height: 6px !important;
  }
`;
const IconCheckBoxWrapper = styled.div<{ active?: boolean }>`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(props) =>
    props.active
      ? "1px solid var(--text-color)"
      : "1px solid var(--border-color)"};
  cursor: pointer;
  &:hover {
    border-color: var(--text-color);
    ${CheckWrapper} {
      background-color: var(--border-color);
    }
  }

  svg {
    filter: ${(props) => (props.active ? "grayscale(0);" : "grayscale(1);")};
    opacity: ${(props) => (props.active ? "1" : "0.2")};

    width: 18px;
    height: 18px;
  }
`;

const FilterPanel: React.FC<FilterPanelProps> = ({
  toggleModal,
  hideToggle,
  filterPostType,
  setFilterPostType,
  currentView,
  setCurrentView,
  datePicker,
}) => {
  const SocialIcons = PostCardIcons;
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [showGantModal, setShowGantModal] = useState(false);
  const isMobile = useIsMobile(); // 👈 updated here

  // Dropdown options for additional views (Calendar/Feed/Grid)
  const [selectedOption, setSelectedOption] = useState<DropDownOption | null>(
    null
  );

  const isClient = useRecoilValue(userState).role === UserRole.CLIENT;

  // Options to pass into DropDownMenu
  const options: DropDownOption[] = [
    {
      label: "תצוגת לוח שנה",
      icon: <CalendarIcon />,
      link: "/calendar",
    },
    // {
    //   label: "תצוגת פיד",
    //   icon: <FeedIcon />,
    //   link: "/feed",
    // },
    {
      label: "תצוגת גריד",
      icon: <GridIcon />,
      link: "/grid",
    },
  ];

  useEffect(() => {
    const currentPath = window.location.pathname.split("/").pop();
    const matchedOption = options.find((option) => {
      const path = option.link?.split("/").pop();
      return currentPath === path;
    });
    if (matchedOption) {
      setSelectedOption(matchedOption);
    }
  }, []);

  const handleSelect = (option: DropDownOption) => {
    setSelectedOption(option);
  };

  // Build platform icons from PostCardIcons
  const groupedIcons = Object.entries(SocialIcons).reduce<
    Record<string, { icons: { icon: React.ReactNode; type: string }[] }>
  >((acc, [key, val]) => {
    if (val.scheduleAble) {
      if (!acc[val.platform]) {
        acc[val.platform] = { icons: [] };
      }
      acc[val.platform].icons.push({ icon: val.icon, type: key });
    }
    return acc;
  }, {});

  return (
    <>
      <PanelWrapper>
        <LeftSection>
          {!isClient && (
            <>
              <ButtonStyled
                icon={<Schedule />}
                primary
                bgColor="purple"
                onClick={toggleModal}
              >
                תזמון פוסט
              </ButtonStyled>
              <ButtonStyled
                icon={<NoteIcon />}
                primary
                className="note"
                bgColor="yellowOpacity"
                textColor="title"
                onClick={() => setShowNoteModal(true)}
              ></ButtonStyled>
              <ButtonStyled
                className="share"
                onClick={() => {
                  setShowGantModal(true);
                }}
                icon={<ExportIcon />}
                bgColor="bg"
              ></ButtonStyled>
              {!isMobile && datePicker}
            </>
          )}
        </LeftSection>

        <CenterSection>
          <TopMenu>
            {Object.entries(groupedIcons).map(([platform, data]) => {
              const firstIcon = data.icons[0];
              const isActive = filterPostType.includes(
                firstIcon.type as PostEntityType
              );
              return (
                <IconCheckBoxWrapper
                  key={platform}
                  active={isActive}
                  onClick={() => {
                    if (isActive) {
                      setFilterPostType((prev) =>
                        prev.filter(
                          (p) => !data.icons.some((icon) => icon.type === p)
                        )
                      );
                    } else {
                      setFilterPostType((prev) => [
                        ...prev,
                        ...data.icons.map(
                          (icon) => icon.type as PostEntityType
                        ),
                      ]);
                    }
                  }}
                >
                  <CheckWrapper active={isActive}>
                    <CheckIcon />
                  </CheckWrapper>
                  {firstIcon.icon}
                </IconCheckBoxWrapper>
              );
            })}
          </TopMenu>
        </CenterSection>

        <RightSection>
          {!isClient && (
          <DropDownMenu
            options={options}
            onSelect={handleSelect}
            selectedValue={selectedOption}
          />
        )}
          <ViewToggle
            currentView={currentView}
            setCurrentView={setCurrentView}
          />
        </RightSection>
        {showNoteModal && <NoteModal onHide={() => setShowNoteModal(false)} />}
        {showGantModal && (
          <ShareGantLinkModal onHide={() => setShowGantModal(false)} />
        )}
      </PanelWrapper>
      {isMobile && <div>{datePicker}</div>}
    </>
  );
};

export default FilterPanel;
