import React, { useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { TooltipClasses } from "@mui/material/Tooltip";
import "primereact/resources/themes/lara-light-indigo/theme.css"; // prime theme
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "./App.css";
import "./prime.override.css";
import "react-toastify/dist/ReactToastify.css";

import { Route, BrowserRouter, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import GlobalModalContainer from "./components/modals/globalModal/globalModalContainer";
import AppWrapper from "./wrappers/AppWrapper";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TourProvider } from "./components/appGuide/TourProvider";
import { MountPoint } from "./components/core/MountPoint";
import i18 from "./i18s"; // Translation
import Preloader from "./components/common/Preloader";
import routes from "./routes";
import PageWrapper from "./components/core/PageWrapper";
import MountUpgradeModal from "./components/core/MountUpgradeModal";

// Create a styled wrapper
const Wrapper = styled.div<{ dir: "rtl" | "ltr" }>`
  padding: 0em;
`;

// Define your MUI theme with Tooltip style overrides
const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          // Tooltip bubble itself
          backgroundColor: "white",
          color: "black",
          fontSize: "0.9rem",
          borderRadius: "4px",
          border: "1px solid #333",
          // etc...
        },
        arrow: {
          // The arrow color (needs the arrow prop in Tooltip)
          color: "#333",
        },
      },
    },
  },
});

function App() {
  useEffect(() => {
    const html = document.documentElement;
    html.className = i18.dir();
  }, [i18.language]);

  return (
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <Wrapper dir={i18.dir()}>
          <TourProvider>
            <AppWrapper>
              <React.Suspense fallback={<Preloader />}>
                <MountPoint />
              </React.Suspense>
              <DndProvider backend={HTML5Backend}>
                <BrowserRouter>
                  <Routes>
                    {routes.map((route) => {
                      return (
                        <Route
                          element={<PageWrapper Route={route}></PageWrapper>}
                          key={route.path}
                          path={route.path}
                        />
                      );
                    })}
                  </Routes>
                </BrowserRouter>
                <GlobalModalContainer />
                <ToastContainer rtl={true} />
              </DndProvider>
            </AppWrapper>
          </TourProvider>
        </Wrapper>
      </RecoilRoot>
    </ThemeProvider>
  );
}

export default App;
