export const ISRAELI_EVENTS: { [date: string]: string[] } = {
  // ינואר
  "04/01/2025": ["📝 יום הברייל הבינלאומי"],
  "21/01/2025": ["🤗 יום החיבוקים הבינלאומי"],
  "27/01/2025": ["🕯️ יום הזיכרון הבינלאומי לשואה"],
  // פברואר
  "09/02/2025": ["🍕 יום הפיצה הבינלאומי"],
  "14/02/2025": ["❤️ יום האהבה הבינלאומי"],
  "20/02/2025": ["👨‍👩‍👧‍👦 יום המשפחה"],
  "21/02/2025": ["😄 יום השפה האם הבינלאומי"],
  // מרץ
  "08/03/2025": ["👩 יום האישה הבינלאומי"],
  "14/03/2025": ["🥧 יום הפאי הבינלאומי"],
  "20/03/2025": ["😃 יום האושר הבינלאומי"],
  "21/03/2025": ["🧦 יום תסמונת דאון הבינלאומי"],
  "22/03/2025": ["💧 יום המים הבינלאומי"],
  "23/03/2025": ["🐶 יום הגורים הבינלאומי"],
  "25/03/2025": ["🧇 יום הוופל הבינלאומי"],
  // אפריל
  "01/04/2025": ["🤡 אחד באפריל"],
  "02/04/2025": ["🔵 יום המודעות לאוטיזם"],
  "07/04/2025": ["🌍 יום הבריאות העולמי"],
  "12/04/2025": ["🍞 ערב פסח"],
  "13/04/2025": ["🍷 פסח"],
  "19/04/2025": ["🌊 שביעי של פסח"],
  "22/04/2025": ["🌎 יום כדור הארץ"],
  "23/04/2025": ["🕯️ יום הזיכרון לשואה ולגבורה"],
  "29/04/2025": ["🕯️ יום הזיכרון לחללי מערכות ישראל"],
  "30/04/2025": ["🇮🇱 יום העצמאות"],
  // מאי
  "01/05/2025": ["👷 יום הפועלים הבינלאומי"],
  "04/05/2025": ["🦸 יום מלחמת הכוכבים"],
  "08/05/2025": ["🥤 יום הקולה"],
  "15/05/2025": ['🔥 ל"ג בעומר'],
  "25/05/2025": ["🏙️ יום ירושלים"],
  // יוני
  "01/06/2025": ["👶 יום הילד הבינלאומי"],
  "05/06/2025": ["🌳 יום איכות הסביבה"],
  "12/06/2025": ["📜 שבועות"],
  "14/06/2025": ["🍟 יום הצ'יפס הבינלאומי"],
  "20/06/2025": ["👨 יום האב הבינלאומי"],
  "21/06/2025": ["🎶 יום המוזיקה הבינלאומי"],
  // יולי
  "07/07/2025": ["🍫 יום השוקולד העולמי"],
  "13/07/2025": ["🕯️ צום שבעה עשר בתמוז"],
  "20/07/2025": ["🌍 יום הידידות הבינלאומי"],
  // אוגוסט
  "02/08/2025": ["🕯️ ערב תשעה באב"],
  "03/08/2025": ["🕯️ תשעה באב"],
  "08/08/2025": ["🐱 יום החתול הבינלאומי"],
  "12/08/2025": ["👦 יום הנוער הבינלאומי"],
  "13/08/2025": ["✋ יום השמאליים הבינלאומי"],
  "19/08/2025": ["📷 יום הצילום העולמי"],
  // ספטמבר
  "08/09/2025": ["📚 יום האוריינות הבינלאומי"],
  "21/09/2025": ["🕊️ יום השלום הבינלאומי"],
  "22/09/2025": ["🍎 ערב ראש השנה"],
  "23/09/2025": ["🍯 ראש השנה"],
  "24/09/2025": ["🍯 ראש השנה"],
  // אוקטובר
  "01/10/2025": ["☕ יום הקפה הבינלאומי"],
  "02/10/2025": ["🕯️ יום כיפור"],
  "04/10/2025": ["🐶 יום החיות העולמי"],
  "06/10/2025": ["🌿 ערב סוכות"],
  "07/10/2025": ["🏕️ סוכות"],
  "10/10/2025": ["🧠 יום בריאות הנפש העולמי"],
  "13/10/2025": ["💧 הושענא רבה"],
  "14/10/2025": ["🎉 שמיני עצרת", "🎊 שמחת תורה"],
  "31/10/2025": ["🎃 ליל כל הקדושים"],
  // נובמבר
  "01/11/2025": ["🧛 יום המתים"],
  "13/11/2025": ["🙂 יום הטוב לב הבינלאומי"],
  "20/11/2025": ["👶 יום הילד הבינלאומי"],
  "25/11/2025": ["🚫 יום המאבק באלימות נגד נשים"],
  // דצמבר
  "01/12/2025": ["❤️ יום האיידס העולמי"],
  "03/12/2025": ["♿ יום הנכים הבינלאומי"],
  "10/12/2025": ["🗽 יום זכויות האדם"],
  "14/12/2025": ["🕎 חנוכה"],
  "15/12/2025": ["🕎 חנוכה"],
  "16/12/2025": ["🕎 חנוכה"],
  "17/12/2025": ["🕎 חנוכה"],
  "18/12/2025": ["🕎 חנוכה"],
  "19/12/2025": ["🕎 חנוכה"],
  "20/12/2025": ["🕎 חנוכה"],
};
