import React from "react";
import styled from "styled-components";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import MultyAttachmentInput from "../../common/form/MultyAttachmentInput";
import { InputTextarea } from "primereact/inputtextarea";
import Card from "../../common/Card";
import { Divider } from "primereact/divider";
import CardTitle from "../../common/CardTitle";
import {ReactComponent as IdeaIcon} from "../../../assets/Icons/Idea.svg";

const PostContainer = styled(Card)`
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom:20px;
`;

const InputTextareaStyled = styled(InputTextarea)`
  outline: none !important;
  min-height: fit-content;
  height: auto;
  width: 100%;
  border: none;
  resize: none; /* Optional: prevent manual resizing */
  &::placeholder {
    color: var(--text-color);
    opacity: 0.5;
  }

  &:hover {
    background: var(--light-bg);
  }
  &:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
    background: var(--light-bg);
    border-color: none !important;
  }
`;

const SectionTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: var(--title-color);
  margin-bottom: 10px;
`;

const TitleWrapper = styled.div`
display: flex;
gap:20px;
svg{
  width: 20px;
  height: 20px;
}
`;

interface NoteFormProps {
  name: string;
  index?: number;
}

const NoteForm: React.FC<NoteFormProps> = ({ name }) => {
  const { register, watch } = useFormContext();
  const { t } = useTranslation();

  return (
    <div className="p-3">
    <TitleWrapper>
    <IdeaIcon/>
    <CardTitle className="mb-0" title="הוספת רעיון חדש לגאנט" />
            
    </TitleWrapper>
            <Divider />
    <PostContainer>
      <div>
        <SectionTitle>טקסט</SectionTitle>
        <InputTextareaStyled
          {...register(`${name}.message`)}
          placeholder={"כיתבו כאן מה שזורם..."}
        />
      </div>

      <div>
        <SectionTitle>מדיה</SectionTitle>
        <MultyAttachmentInput name={`${name}.imageUrls`} />
      </div>
    </PostContainer>
    </div>
  );
};

export default NoteForm;
