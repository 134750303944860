import { PinturaEditor } from "@pqina/react-pintura";
import {
  createDefaultImageWriter,
  createDefaultMediaWriter,
  getEditorDefaults,
  setPlugins,
  imageStateToCanvas,
  PinturaEditorDefaultOptions,
  PinturaDefaultImageWriterOptions,
} from "@pqina/pintura";
import {
  plugin_trim_locale_en_gb,
  plugin_trim,
  createDefaultVideoWriter,
  createMediaStreamEncoder,
  PinturaMediaStreamEncoderOptions,
} from "@pqina/pintura-video";

import "@pqina/pintura-video/pinturavideo.css";
import "@pqina/pintura/pintura.css";
import { Dialog } from "primereact/dialog";
import { useRef, useState } from "react";
import { round } from "lodash";
import { useParams } from "react-router";
import { websiteService } from "../../core/services/website.service";
import { AttachmentMedia } from "neword-core";

setPlugins(plugin_trim);

function VideoFramePicker({
  url,
  onClose,
  onProcess,
  videoSettings,
  imageSettings,
  imageWriterSettings,
  hideCloseButton,
}: {
  url: string;
  onProcess: (coverPhotoMS: number, attachment: AttachmentMedia) => void;
  onClose: () => void;
  videoSettings?: Partial<PinturaMediaStreamEncoderOptions>;
  imageSettings?: PinturaEditorDefaultOptions;
  imageWriterSettings?: PinturaDefaultImageWriterOptions;
  hideCloseButton?: boolean;
}) {
  const [disabled, setDisabled] = useState(false);
  const [selectedTime, setSelectedTime] = useState<number>(0);
  const { websiteId } = useParams();

  const editorRef = useRef<any>(null);
  const processingRef = useRef(false);

  const handleDone = async () => {
    if (processingRef.current) return;
    processingRef.current = true;

    try {
      const result = await editorRef.current.processImage({
        imageWriter: createDefaultImageWriter({
          mimeType: "image/jpeg",
          quality: 0.95,
        }),
      });

      if (result && result.dest) {
        const blob = result.dest;

        const timestamp = new Date().getTime();
        const filename = `frame_${timestamp}.jpg`;

        const file = new File([blob], filename, {
          type: "image/jpeg",
        });

        const reader = new FileReader();
        reader.onload = async () => {
          const base64String = reader.result?.toString().split(",")[1];
          if (base64String) {
            // Ensure the upload filename has .jpg extension
            url.split(".");
            const uploadFilename = `frameFrom_${url.split(".")[0]}.jpg`;
            const attachmentUrl = await websiteService.uploadAttachment(
              websiteId as string,
              uploadFilename,
              base64String
            );
            onClose();
            onProcess(selectedTime, attachmentUrl);
          }
        };
        reader.onerror = (error) => {
          console.error("Error converting file to Base64:", error);
        };

        reader.readAsDataURL(file);
      }
    } catch (err) {
      console.error("Export error:", err);
    } finally {
      processingRef.current = false;
    }
  };
  return (
    <Dialog
      style={{
        width: "60vw",
        margin: "0",
        boxShadow: "none",
        height: "94vh",
        borderLeft: "solid 1px var(--border-color)",
        borderRadius: "0px",
        maxHeight: "100%",
        padding: "0",
      }}
      closable={!hideCloseButton}
      visible
      onHide={onClose}
      modal
    >
      <div>
        לבחירת תמונת פתיחה יש לבחור את הזמן בו מופיעה התמונה המתאימה וללחוץ על
        done
      </div>
      <PinturaEditor
        onInit={(editor) => {
          editorRef.current = editor;
        }}
        src={url}
        onProcess={handleDone}
        onUpdate={(state) => {
          if (state?.currentTime != null) {
            const newTime = round(state.currentTime * 1000);
            setSelectedTime(newTime);
          }
        }}
        // Add the video plugin
        {...{
          ...getEditorDefaults({
            ...imageSettings,
            utils: ["trim"],
            locale: {
              // Add the Trim plugin locale
              ...plugin_trim_locale_en_gb,
            },
            imageWriter: createDefaultMediaWriter(
              // Generic Media Writer options, passed to image and video writer
              { ...imageWriterSettings },
              [
                createDefaultImageWriter({
                  quality: 0.9,
                  mimeType: "image/jpeg",
                }),

                // For handling videos
                createDefaultVideoWriter({
                  // Video writer instructions here
                  // ...

                  // Encoder to use
                  encoder: createMediaStreamEncoder({
                    imageStateToCanvas,
                    framesPerSecond: 35, // Ensure a stable framerate
                    videoBitrate: 2_500_000, // Adjust to balance quality vs. performance

                    ...videoSettings,
                  }),
                }),
              ]
            ),
          }),
        }}
      />
    </Dialog>
  );
}

export default VideoFramePicker;
