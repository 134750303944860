import { Dialog } from "primereact/dialog";
import Button from "../common/form/Button";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { subscriptionService } from "../../core/services/subscription.service";
import { useState } from "react";
import { userState } from "../../state/userState";
import { toast } from "react-toastify";
import {useTranslation} from "react-i18next";

interface Props {
  onHide: () => void;
  onSubmit: () => void;
}

const StyledButton = styled(Button)`
  width: fit-content;
`;

const TerminateSubscriptionModal: React.FC<Props> = ({ onHide, onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUserState] = useRecoilState(userState);
  const { t } = useTranslation();
  const onSubmitInternal = async () => {
    setIsLoading(true);
    try {
      await subscriptionService.cancelSubscription();

      setUserState({
        ...user!,
        company: {
          ...user.company,
          Subscription: {
            ...user.company.Subscription,
            isActive: false,
          },
        },
      });

      toast("terminate_subscription_modal.Subscription was terminated successfully", {
        type: "success",
      });

      onSubmit();
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      modal
      visible
      header={t("terminate_subscription_modal.header")}
      onHide={onHide}
      footer={
        <StyledButton loading={isLoading} primary onClick={onSubmitInternal}>
          {t("terminate_subscription_modal.footer_button")}
        </StyledButton>
      }
    >
      <p>{t("terminate_subscription_modal.body_text")}</p>
      <small>
        {t("terminate_subscription_modal.small_text")}{" "}
        <a target="_blank" href="mailto:admin@ghostwrites.ai" rel="noreferrer">
          admin@ghostwrites.ai
        </a>
      </small>
    </Dialog>
  );
};

export default TerminateSubscriptionModal;
