import { InputText } from "primereact/inputtext";
import { ReactComponent as GoogleIcon } from "../assets/Icons/ColoredGoogle.svg";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { userState } from "../state/userState";
import styled from "styled-components";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import RegexValidations from "../core/validation/regexValidations";
import FormStyle from "../components/common/form/FormStyle";
import Button from "../components/common/form/Button";
import { ReactComponent as NewordTextLogo } from "../assets/Icons/NewordTextLogo.svg";
import { SignupRequestData } from "../core/services/requests/signup/signupRequestData";
import companyService from "../core/services/company.service";
import Link from "../components/common/Link";
import { Checkbox } from "primereact/checkbox";
import AppConfig from "../config/appConfig";
import ErrorMessage from "../components/common/ErrorMessage";
import chillGuy from "../assets/images/chillguy.png";
import bgImagenoise from "../assets/images/bg-purple-noise.jpg";
import Testimonials from "../assets/images/testimonials.png";
import { useTranslation } from "react-i18next";
import bgImage from "../assets/images/loginimage.png";


const LoginPageWrapper = styled.div`
  margin: auto;
  width: 60%;
  background: white;
  height: 98.2vh;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  border-radius: 12px;


  .logo{
    width: 100px;
    margin-left:auto;
    height: auto;

    @media only screen and (max-width: 1000px) {

      margin: auto;
    }
  }
  @media only screen and (max-width: 1000px) {
width: 80%;
height: auto;
padding-top:30px;

    
    .form-mobile {
      width: 100%;
    }

    label {
      font-size: 16px;
    }

    input {
      font-size: 22px;
      height: 75px;
    }

    .google-button-mobile {
      height: 75px;
      font-size: 18px;
      margin-bottom:1px0;
    }

    .or-text-mobile {
      font-size: 20px;
      margin: 20px 0;

      &:before {
    content: "";
    position: absolute;
    width: 20%;
    height: 1px;
    background: var(--main-text-color, #425466);
    transform: translate(-50%, -50%);
    left: 120%;
    top: 50%;
    z-index: 0;
  }

  &:after {
    content: "";
    position: absolute;
    width: 20%;
    height: 1px;
    background: var(--main-text-color, #425466);
    transform: translate(-50%, -50%);
    left: -20%;
    top: 50%;
    z-index: 0;
  }
    }

    #gw-button {
      text-align: center;
      justify-content: center;
      height: 75px;
      font-size: 18px;
      width: 100%;
      svg {
        margin-left: 20px;
      }
    }
    .small-text-mobile {
      margin-top: 30px;
      font-size: 18px;
      a {
        font-size: 18px;
      }
    }

    .mobile-header {
      position: relative;

      svg {
        width: 100px;
        height: 40px;
      }
      h1 {
        width: 100%;
        font-size: 2rem;
      }
    }
  }
`;

const CenteredForm = styled(FormStyle)`
 
  h1 {
    font-weight: 600;
    font-family: "Noto Sans Hebrew", sans-serif;
    font-size: 4rem;
    letter-spacing: -0.6px;
    color: var(--title-color);
    text-align: right;
    width: 100%;
  }
  h3 {
    font-weight: 500;
    color: var(--title-color);
    text-align: right;
    width: 100%;
    margin-bottom:1.5rem;
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 40px !important;
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: 1000px) {
  h1 {
    font-weight: 600;
    font-family: "Noto Sans Hebrew", sans-serif;
    font-size: 2.5rem;
margin:10px 0 ;
    letter-spacing: -0.6px;
    color: var(--title-color);
    text-align: center;
    width: 100%;
  }
  h3 {
    font-weight: 500;
    color: var(--title-color);
    text-align: center;
    width: 100%;
  }
 }
`;

const GoogleButton = styled(Link)`
  border-radius: 6px;
  border: 1px solid var(--dark-green);
  display: flex;
  height: 3.125rem;
  width: 100%;
  padding: 1.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  margin-bottom: 30px;
  color: var(--dark-green);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 0.75rem */
  letter-spacing: -0.0225rem;
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    border-color: var(--title-color);
  }
  & svg {
    width: 16px;
  }
`;

const StyledCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
`;

const OrText = styled.div`
  color: var(--main-text-color, #425466);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 0.75rem */
  letter-spacing: -0.0225rem;
  text-transform: capitalize;
  margin: 20px 0;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 50%;
    height: 1px;
    background: var(--main-text-color, #425466);
    transform: translate(-50%, -50%);
    left: 140%;
    top: 50%;
    z-index: 0;
  }

  &:after {
    content: "";
    position: absolute;
    width: 50%;
    height: 1px;
    background: var(--main-text-color, #425466);
    transform: translate(-50%, -50%);
    left: -40%;
    top: 50%;
    z-index: 0;
  }
`;

const Bg = styled.div`
  
  background-image: url(${bgImagenoise});
  border:solid 15px white;
  border-radius: 34px;
  background-size: cover;
  height: 100%;
  padding: 40px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  gap: 40px;
/* 
  .purple {
    color: #ffcb57;
  } */

  span {
    color: var(--title-color);;
    font-size: 3.8rem;
    font-family: "Noto Sans Hebrew", sans-serif;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 1.5rem */
    letter-spacing: -0.15rem;
    text-transform: capitalize;
  }
`;

const ImgWrapper = styled.div`
  margin-top: 100px;
  margin-bottom: -300px;
`;

const BackgroundWrapper = styled.div`
  /* background: var(--primary-purple); */
`;


const ButtonLogin = styled(Button)`
@media only screen and (max-width: 1000px) {
  display: none;
}
position: absolute;
left: 40px;
top: 40px;
border:solid 1px var(--dark-green);
border-radius: 12px;

&:hover{
  background-color: var(--dark-green);
  color: white;
}
`;

const Signup: React.FC = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    control,
  } = useForm<SignupRequestData>();
  const setUserState = useSetRecoilState(userState);
  const [isLoading, setIsLoading] = useState(false);
  const [queryParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<SignupRequestData> = async (data) => {
    try {
      setIsLoading(true);
      // const captcha = (document as any).grecaptcha as any;
      // const token = await captcha.enterprise.execute(
      //   "6Lf6qDUpAAAAAOqUoyZcgH4wkZJHtZqSlfhBpEsM",
      //   { action: "LOGIN" }
      // );

      const res = await companyService.signup({
        ...data,
      });

      console.log(res);
      setUserState(res);
      const redirectPath = queryParams.get("redirect") || "/";
      navigate(redirectPath);
    } catch (err) {
      console.log(err);
      setError("root", {});
    } finally {
      setIsLoading(false);
    }
  };

  const onClickGoogleButton = () => {
    const redirectPath = queryParams.get("redirect") || "/";
    document.location.href = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${
      AppConfig.serverUrl
    }/auth%2Fgoogle%2Fcallback&client_id=89013921262-762mm9l2lfq3dfv4rf185srjgq8ulihg.apps.googleusercontent.com&access_type=offline&response_type=code&prompt=select_account&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&state=${encodeURIComponent(
      redirectPath
    )}`;
  };

  return (
    <>

      <BackgroundWrapper className="flex grid m-0">
        
        <div className="lg:col-6 sm:col-12">
          <LoginPageWrapper>
            <div className="mobile-header">
            <Link path="/login">
          <ButtonLogin className="hide-mobile">כניסה למערכת</ButtonLogin>
        </Link>
            </div>
            <NewordTextLogo className="logo" />
            <CenteredForm className="form-mobile" autoComplete="off">
              <h1>{t("signup.title")}</h1>
              <h3 className="">{t("signup.subtitle")}</h3>
              <GoogleButton
                onClick={onClickGoogleButton}
                className="google-button-mobile"
              >
                <GoogleIcon />
                {t("signup.google_button")}
              </GoogleButton>
              <OrText className="or-text-mobile">{t("signup.or_text")}</OrText>
              <div className="w-full mb-5">
                <div className="flex">
                  <div className="mb-5 ml-5 w-full">
                    <label>{t("signup.first_name_label")}</label>

                    <InputText
                      {...register("firstName", {
                        required: true,
                        maxLength: 20,
                      })}
                      type="text"
                      placeholder={t("signup.first_name_placeholder")}
                      className={errors.firstName ? "p-invalid" : ""}
                    />
                  </div>
                  <div className="mb-5 w-full">
                    <label>{t("signup.last_name_label")}</label>

                    <InputText
                      {...register("lastName", {
                        required: true,
                        maxLength: 20,
                      })}
                      placeholder={t("signup.last_name_placeholder")}
                      className={errors.lastName ? "p-invalid" : ""}
                    />
                  </div>
                </div>

                <label>{t("signup.email_label")}</label>
                <InputText
                  autoComplete="off"
                  {...register("email", {
                    required: true,
                    maxLength: 40,
                    pattern: {
                      value: RegexValidations.email,
                      message: t("signup.email_error"),
                    },
                  })}
                  type="text"
                  placeholder={t("signup.email_placeholder")}
                  className={errors.email ? "p-invalid" : ""}
                />
                <ErrorMessage>{errors.email?.message}</ErrorMessage>
              </div>
              <div className="w-full mb-5">
                <label>{t("signup.password_label")}</label>

                <InputText
                  autoComplete="off"
                  {...register("password", {
                    required: true,
                    maxLength: 20,
                    pattern: {
                      value: RegexValidations.password,
                      message: t("signup.password_error"),
                    },
                  })}
                  type="password"
                  placeholder="J12345678w!"
                  className={errors.password ? "p-invalid" : ""}
                />
                <ErrorMessage>{errors.password?.message}</ErrorMessage>
              </div>
              <StyledCheckboxWrapper>
                <Controller
                  control={control}
                  name="signedForNewsletter"
                  render={({ field }) => (
                    <Checkbox checked={field.value} onChange={field.onChange} />
                  )}
                />
                <label>{t("signup.newsletter_label")}</label>
              </StyledCheckboxWrapper>

              <Button
                onClick={handleSubmit(onSubmit)}
                loading={isLoading}
                primary
                arrowPlacement="right"
              >
                {t("signup.submit_button")}
              </Button>
              <small className="mt-3">{t("signup.terms_text")}</small>
              <div className="flex gap-10 mt-4">
                <a
                  href="https://he.neword.ai/privacy-policy-2/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("signup.privacy_policy")}
                </a>
                <a
                  href="https://he.neword.ai/terms-of-use-agreement/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("signup.terms_of_use")}
                </a>
              </div>

              {/* <RegularText className="mb-4 mt-4 small-text-mobile">
                כבר יש לך חשבון? <ColoredLink path="/login">התחבר</ColoredLink>
              </RegularText> */}
              {/* <RegularText className="mb-4 text-center small-text-mobile">
                על ידי לחיצה על "צור חשבון" או "המשך עם גוגל", אתה מסכים{" "}
                <ColoredLink
                  path="https://ghostwrites.ai/legals/terms-of-use-agreement/"
                  differentTab
                >
                  לתנאי שימוש{" "}
                </ColoredLink>
                . של Neword{" "}
                <ColoredLink
                  differentTab
                  path="https://ghostwrites.ai/legals/privacy-policy/"
                >
                  {" "}
                  קרא את מדיניות הפרטיות שלנו{" "}
                </ColoredLink>
                .
              </RegularText> */}
            </CenteredForm>
          </LoginPageWrapper>
        </div>
        <div className="lg:col-6 sm:col-12 p-0">
                  <Bg>
                  <div className="senja-embed" data-id="63b28178-24c1-42a1-ad1e-1c7e060310d4" data-mode="shadow" data-lazyload="false" style={{ display: "block", width: "100%" , direction:"ltr" }}></div>

                   
                  </Bg>
                </div>
      </BackgroundWrapper>
    </>
  );
};

export default Signup;
