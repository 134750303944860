import React from "react";
import styled from "styled-components";
import { ReactComponent as CheckIcon } from "../../assets/Icons/Approved.svg";
import { ReactComponent as FacebookLogo } from "../../assets/Icons/facebook/FacebookLogo.svg";
import { ReactComponent as LinkedInLogo } from "../../assets/Icons/linkedin/LinkedInLogo.svg";
import { ReactComponent as XLogo } from "../../assets/Icons/XLogo.svg";
import { ReactComponent as InstagramLogo } from "../../assets/Icons/InstagramIcon.svg";
import { ReactComponent as TiktokLogo } from "../../assets/Icons/TiktokLogo.svg";

import { AppIntegrationApplicationType } from "neword-core";
import { integrationFactory } from "../../integrations/integrationFactory";
import { useRecoilValue } from "recoil";
import { appIntegrationState } from "../../state/appIntegrationState";
import { useParams } from "react-router";

const TopMenu = styled.div`
  z-index: 9999999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

interface IconCheckBoxWrapperProps {
  active?: boolean;
  disabled?: boolean;
}

const IconCheckBoxWrapper = styled.div<IconCheckBoxWrapperProps>`
  position: relative;
  width: 45px;
  height: 45px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-color);
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  &:hover {
    border-color: var(--primary-purple);
  }
`;

const CheckWrapper = styled.div<{ active?: boolean }>`
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.active ? "var(--primary-purple)" : "transparent"};
  right: -5px;
  top: -5px;
  svg {
    fill: white;
    width: 6px;
    height: 6px;
  }
`;

interface SinglePlatformSelectorProps {
  filterPostType: AppIntegrationApplicationType | null;
  setFilterPostType: React.Dispatch<
    React.SetStateAction<AppIntegrationApplicationType | null>
  >;
}

export const SinglePlatformSelector: React.FC<SinglePlatformSelectorProps> = ({
  filterPostType,
  setFilterPostType,
}) => {
  const { websiteId } = useParams();
  const integrations = useRecoilValue(appIntegrationState(websiteId as string));

  return (
    <div className="col-6 m-auto">
      <TopMenu>
        {Object.entries(integrationFactory).map(([key, value]) => {
          if (!value.scheduleAble) return null;

          const Icon = value.icon;
          const integrationType = key as AppIntegrationApplicationType;
          // Check if the integration is enabled in state.
          const isEnabled = integrations.some(
            (integration) =>
              integration.appIntegrationApplicationType === integrationType &&
              integration.isEnabled
          );
          // Mark as disabled if the factory marks it as not scheduleAble or it isn't enabled.
          const disabled = !value.enableInsights || !isEnabled;
          const isActive = filterPostType === integrationType;
          return (
            <IconCheckBoxWrapper
              key={key}
              active={isActive}
              disabled={disabled}
              onClick={() => {
                if (!disabled) {
                  setFilterPostType(isActive ? null : integrationType);
                }
              }}
            >
              <CheckWrapper active={isActive}>
                <CheckIcon />
              </CheckWrapper>
              <Icon />
            </IconCheckBoxWrapper>
          );
        })}
      </TopMenu>
    </div>
  );
};
