import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { websiteService } from "../core/services/website.service";
import { Website } from "../core/entities/website";
import {
  CreateWbsiteAudience,
  ScanWebsiteResponse,
} from "../core/services/requests/createWebsite/scanWebsiteResponse";
import generatorService from "../core/services/generator.service";
import crollerService from "../core/services/croller.service";
import { Language } from "../core/types/language";

const websiteSelector = selector<Website[]>({
  key: "websiteSelector",
  get: () => websiteService.listWebsites(),
});

export const websitesStateSelector = atom<Website[]>({
  key: "websitesStateSelector",
  default: websiteSelector,
});

export const websiteState = atomFamily<Website | undefined, string | null>({
  key: "websiteState",
  default: selectorFamily({
    key: "websiteStateSelector",
    get:
      (websiteId: string | null) =>
      async ({ get }) => {
        if (websiteId) {
          const websites = get(websitesStateSelector);
          return (
            (websites.find((website) => website.id === websiteId) as Website) ||
            websiteService.getWebsiteById(websiteId)
          );
        }
      },
  }),
});

export const scanWebsiteState = atomFamily<
  ScanWebsiteResponse | undefined,
  string
>({
  key: "scanWebsiteState",
  default: selectorFamily({
    key: "scanWebsiteStateSelector",
    get: (websiteUrl: string) => async () => {
      if (websiteUrl === "https://") return;
      try {
        return await crollerService.scanWebsite(websiteUrl);
      } catch (err) {
        return {
          name: "",
          description: "",
          language: Language.HEBREW,
          audience: [],
        };
      }
    },
  }),
});

export const scanWebsiteAudienceState = atomFamily<
  CreateWbsiteAudience[],
  string
>({
  key: "scanWebsiteAudienceState",
  default: selectorFamily({
    key: "scanWebsiteAudienceStateSelector",
    get: (description: string) => async () => {
      try {
        return await generatorService.generateAudienceFromDescription(
          description
        );
      } catch (err) {
        return [];
      }
    },
  }),
});
