import { Divider } from "primereact/divider";
import { AttachmentMedia, PostEntity, PostEntityType } from "neword-core";
import { InputTextarea } from "primereact/inputtextarea";
import {
  FieldErrors,
  FieldValues,
  Path,
  useFormContext,
  useWatch,
} from "react-hook-form";
import styled from "styled-components";

import { useMemo, useEffect, useRef, useState } from "react";
import FbPreview from "../postPreviews/fbPreview";
import CardTitle from "../../common/CardTitle";
import MultyAttachmentInput from "../../common/form/MultyAttachmentInput";
import facebookReelMediaValidation from "./mediaValdiation/facebookReelMediaValidation";
import MediaEditor from "../MediaEditor";
import Card from "../../common/Card";
import CircleBg from "../../../assets/images/bgcirclepurple.png";
import { Message } from "primereact/message";
import FbReelPreview from "../postPreviews/fbReelPreview";
import facebookReelMediaWarning from "./mediaValdiation/facebookReelMediaWarning";
import { coverPhotoValidation } from "./mediaValdiation/coverPhotoImageValidation";
import Button from "../../common/form/Button";
import VideoFrameImagePicker from "../videoFrameImagePicker";
import { useTranslation } from "react-i18next";
import AttachmentInputFullMediaObject, {
  singleInputType,
} from "../../common/form/AttachmentsInputFullObject";

const InputTextareaStyled = styled(InputTextarea)`
  outline: none !important;
  min-height: fit-content;
  height: auto;
  width: 100%;
  border: none;
  resize: none; /* Optional: prevent manual resizing */
  &::placeholder {
    color: var(--text-color);
    opacity: 0.5;
  }

  &:hover {
    background: var(--light-bg);
  }
  &:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
    background: var(--light-bg);
    border-color: none !important;
  }
`;

const LabelStyled = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: var(--text-color);
  padding-bottom: 10px;
`;

const ImageWrapper = styled.div`
  background-size: cover;
  position: relative;
  overflow: hidden;
  background-color: var(--light-bg);
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;
const CoverPhotoWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;

  .hov:hover {
    background: var(--bg-dark) !important;
  }
`;

function FacebookReelForm<FormDataType extends FieldValues>({
  name,
  index,
}: {
  name: Path<FormDataType>;
  index?: number;
}) {
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  const values = useWatch({ control, name: name }); // Ensure posts has a default value of an empty array
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [imageUrlError, setImageUrlError] = useState("");
  const [mediaToEdit, setMediaToEdit] = useState<AttachmentMedia | null>(null);
  const [showFramePicker, setshowFramePicker] = useState<boolean>(false);

  const coverPhotoUrl = useWatch({
    control,
    name: `${name}.coverPhotoUrl`,
  });

  const warningMediaValidation = facebookReelMediaWarning(values.imageUrls);

  // Function to automatically adjust textarea height
  const autoResizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  // Run auto-resize on mount and when posts change
  useEffect(() => {
    autoResizeTextarea();
  }, [values]);

  return (
    <div className="grid h-full m-0">
      <div className="col-8 p-0 pl-4 pr-4 pt-4">
        <CardTitle title={t("common.reels_post")} />
        <Divider />
        <div>
          <LabelStyled>{t("common.post_content")}</LabelStyled>
          <InputTextareaStyled
            {...register(`${name}.message`)}
            className="w-90 pl-3"
            placeholder={t("common.reels_post_placeholder")}
            onInput={autoResizeTextarea}
            ref={(e) => {
              register(`${name}.message`).ref(e);
              textareaRef.current = e;
            }}
          />
          <Divider />
          <LabelStyled>{t("common.add_media")}</LabelStyled>
          <MultyAttachmentInput
            name={`${name}.imageUrls`}
            validate={(value) => {
              const errorText = facebookReelMediaValidation(value);
              setImageUrlError(errorText);
              if (errorText === "") {
                return true;
              }

              return errorText;
            }}
            onAddMedia={(media) => {
              setMediaToEdit(media);
            }}
          />

          {values.imageUrls.length === 1 &&
          values.imageUrls[0].metadata.type === "VIDEO" ? (
            <>
              <LabelStyled>בחירת קאבר</LabelStyled>
              <CoverPhotoWrapper>
                <AttachmentInputFullMediaObject
                  name={`${name}.coverPhotoUrl`}
                  validate={(value) => {
                    const errorText = coverPhotoValidation(value);
                    setImageUrlError(errorText);
                    if (errorText === "") {
                      return true;
                    }
                    return errorText;
                  }}
                  includeVideos={false}
                  type={singleInputType.onlyText}
                />
                <Button
                  className="hov"
                  bgColor="bg"
                  onClick={() => {
                    setshowFramePicker(true);
                  }}
                >
                  בחירת פריים מהסרטון
                </Button>
              </CoverPhotoWrapper>

              {coverPhotoUrl ? (
                <Message severity="success" text="נוסף תמונת קאבר" />
              ) : (
                <Message
                  className="mt-3"
                  severity="info"
                  text="אם לא נבחר קאבר, הקאבר יהיה הפריים הראשון"
                />
              )}
            </>
          ) : (
            <></>
          )}

          {values.imageUrls.length === 1 &&
          values.imageUrls[0].metadata.type === "VIDEO" &&
          showFramePicker ? (
            <VideoFrameImagePicker
              url={values.imageUrls[0].url}
              onProcess={(attachment: AttachmentMedia) => {
                setValue(name + ".coverPhotoUrl", attachment);
              }}
              imageSettings={{
                imageCropAspectRatio: 9 / 16, // Maintain TikTok's vertical aspect ratio
              }}
              onClose={() => setshowFramePicker(false)}
            />
          ) : (
            <></>
          )}
          {mediaToEdit ? (
            <MediaEditor
              url={mediaToEdit.url}
              onProcess={(attachment: AttachmentMedia) =>
                setValue(
                  name + ".imageUrls",
                  values.imageUrls.map((m: AttachmentMedia) =>
                    m.url === mediaToEdit.url ? attachment : m
                  )
                )
              }
              onClose={() => setMediaToEdit(null)}
              imageSettings={{
                imageCropAspectRatio: 9 / 16,
                imageCropMinSize: { height: 960, width: 540 },
              }}
              videoSettings={{ framesPerSecond: 28, videoBitrate: 5000000 }}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="media p-2 mt-4">
          {imageUrlError != "" && (
            <Message severity="error" text={imageUrlError} />
          )}
        </div>
        <div className="media p-2">
          {warningMediaValidation != "" && (
            <Message severity="warn" text={warningMediaValidation} />
          )}
        </div>
      </div>
      <div className="col-4 p-0">
        <ImageWrapper className="">
          <FbReelPreview reel={true} name={name} />
        </ImageWrapper>
      </div>
    </div>
  );
}

export default FacebookReelForm;
