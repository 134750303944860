import React from "react";
import { usePermissionCheck } from "../../hooks/usePermission";
import Preloader from "../common/Preloader";
import UpgradePackage from "../modals/UpgradePackage";
import { use } from "i18next";
import { globalUpgradeModalState } from "../../state/globalUpgradeModalState";
import { useRecoilValue } from "recoil";

function MountUpgradeModal() {
  const { closeUpgradeModal, checkBusinessPermission } = usePermissionCheck();
  const upgradeModal = useRecoilValue(globalUpgradeModalState);
  return (
    <React.Suspense fallback={<Preloader />}>
      {upgradeModal && <UpgradePackage onHide={closeUpgradeModal} />}
    </React.Suspense>
  );
}

export default MountUpgradeModal;
