import {useTranslation} from "react-i18next";

const ClosedPage = () => {
  const { t } = useTranslation();
  window.close();
  return (
    <div>
      <h1>{t("sorry_this_page_is_closed")}</h1>
    </div>
  );
};

export default ClosedPage;
