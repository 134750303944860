import React from "react";
import { PostEntity, PostEntityType } from "neword-core";
import { DraggablePost } from "./draggablePost";
import EmptyTile from "./emptyTile";

interface CalendarTileContentProps {
  date: Date;
  view: string;
  posts: PostEntity[];
  filterPostType: PostEntityType[];
  clientView?: boolean;
}

const CalendarTileContent: React.FC<CalendarTileContentProps> = ({
  date,
  view,
  posts,
  filterPostType,
  clientView,
}) => {
  const today = new Date();
  const isPast = date < today;
  // Get events for the given date (filtering by post type)
  const getEventsForDate = (date: Date) => {
    return posts.filter(
      (post) =>
        new Date(post.scheduleDate).toDateString() === date.toDateString() &&
        (filterPostType.length === 0 || filterPostType.includes(post.type))
    );
  };

  const events = getEventsForDate(date);

  if (events.length > 0) {
    return (
      <>
        {events.map((post) => (
          <DraggablePost
            key={post.id}
            clientView={clientView}
            post={{ ...post, scheduleDate: new Date(post.scheduleDate) }}
            isPast={isPast}
          />
        ))}
      </>
    );
  }

  // For dates in the future or today, show an empty tile
  if (date >= today || date.toDateString() === today.toDateString()) {
    return <EmptyTile clientView={clientView} date={date} />;
  }

  return null;
};

export default CalendarTileContent;
