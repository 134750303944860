import { atomFamily } from "recoil";
import {
  AppIntegrationApplicationType,
  DocumentEntity,
  PageMetricsInsights,
  PostEntity,
  PostEntityType,
  PostInsights,
} from "neword-core";

import postService from "../core/services/post.service";

export const postInsightsState = atomFamily<PostInsights[], string>({
  key: `postInsightsState`,
  default: (websiteId: string) => postService.getInsights(websiteId),
});

export const pageInsightsState = atomFamily<
  PageMetricsInsights,
  {
    websiteId: string;
    platform: AppIntegrationApplicationType;
    startDate: string;
    endDate: string;
  }
>({
  key: `pageInsightsState`,
  default: ({ websiteId, platform, startDate, endDate }) =>
    postService.getPageInsights(websiteId, platform, startDate, endDate),
});
