import { InputText } from "primereact/inputtext";
import React, { useEffect, useMemo, useState } from "react";
import FormStyle from "../../../common/form/FormStyle";
import { InputTextarea } from "primereact/inputtextarea";
import styled from "styled-components";
import { ReactComponent as EditIcon } from "../../../../assets/Icons/Edit.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/Icons/PlusBorder.svg";
import Avatar, { genConfig } from "react-nice-avatar";
import { useController, useFieldArray, useFormContext } from "react-hook-form";
import { CreateWebsiteRequestData } from "../../../../core/services/requests/createWebsite/createWebsiteRequestData";
import { Dropdown } from "primereact/dropdown";
import { GenderType } from "../../../../core/types/genderType";
import { Divider } from "primereact/divider";
import { useTranslation } from "react-i18next";
import Badge from "../../../common/Badge";
import SelectKeywordsInput from "../../../keywords/SelectKeywordsInput";
import { fi } from "date-fns/locale";
import UpdateAudienceModal from "../../../common/form/UpdateAudienceModal";
import CreateAudienceModal from "../../../common/form/createAudienceModal";
import { Audience } from "../../../../core/entities/audience";
import { useRecoilValue } from "recoil";
import { scanWebsiteAudienceState } from "../../../../state/websitesState";
const AudienceContainer = styled.div``;

const IconWrapper = styled.div`
  position: absolute;
  bottom: 12px;
  left: 17px;
  opacity: 0;
  transition-duration: 0.1s;
`;

const AudienceCard = styled.div`
  background-color: #fff;
  display: flex;
  gap: 20px;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  position: relative;
  transition-duration: 0.1s;
  cursor: pointer;

  &.add-audience {
    border: 1px dashed #ddd;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      width: 20px;
      height: 20px;
    }
  }

  &:hover {
    border-color: var(--title-color);

    ${IconWrapper} {
      opacity: 1;
    }
  }
`;

const AudienceName = styled.span`
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-top: 10px;
  font-weight: bold;
  span {
    font-weight: 500;
  }
`;

const AvatarWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
`;

const FormStyleStyled = styled(FormStyle)`
  margin-top: 40px;
  width: 100%;
`;

const CreateAudience: React.FC = () => {
  const { register, setValue, watch, control, getValues } =
    useFormContext<CreateWebsiteRequestData>();
  const { t } = useTranslation();

  const generatedAudience = useRecoilValue(
    scanWebsiteAudienceState(getValues("website.description"))
  );

  const [updateAudience, setUpdateAudience] = useState<Omit<
    Audience,
    "websiteId" | "id" | "companyId"
  > | null>(null);

  const [createAudienceModal, showCreateAudienceModal] = useState(false);
  const [updateAudienceModal, showUpdateAudienceModal] = useState(false);

  const fields = useFieldArray({ control, name: "audience" });
  console.log(fields.fields);

  useEffect(() => {
    setValue(
      "audience",
      generatedAudience.map((a) => ({ ...a, imageProps: genConfig() })) ?? []
    );
  }, [generatedAudience, generatedAudience]);

  const getGenderText = (gender: GenderType): string => {
    switch (gender) {
      case GenderType.MALE:
        return t("form.audience.male");
      case GenderType.FEMALE:
        return t("form.audience.female");
      case GenderType.BOTH:
        return t("form.audience.both");
      default:
        return t("form.audience.unknown");
    }
  };

  const handleAudienceClick = (
    audiene: Omit<Audience, "websiteId" | "companyId">
  ) => {
    setUpdateAudience(audiene); // Set the audience ID in the state
    showUpdateAudienceModal(true); // Show the update modal
  };

  return (
    <>
      <FormStyleStyled>
        <div className="grid">
          {fields.fields.length > 0 ? (
            fields.fields.map((audience, i) => (
              <div className="col-4" key={audience.id}>
                <AudienceCard
                  onClick={() =>
                    handleAudienceClick({ ...audience, id: i.toString() })
                  }
                >
                  <AvatarWrapper>
                    <Avatar
                      style={{ width: "2.5rem", height: "2.5rem" }}
                      {...audience.imageProps} // Display audience avatar
                    />
                  </AvatarWrapper>

                  <AudienceName>
                    <div className="flex">
                      <div>
                        {t("form.audience.audience_name")}{" "}
                        <span>{audience.name}</span>
                      </div>
                      <div className="mr-2 ml-2 ">|</div>
                      <div>
                        {t("form.audience.gender")}:{" "}
                        <span>{getGenderText(audience.gender)}</span>
                      </div>
                    </div>

                    <div className="flex align-items-center mt-3">
                      {t("form.audience.keywords")}
                      <div className="flex">
                        {audience.painPoints.map((painPoint) => (
                          <div className="mr-2" key={painPoint}>
                            <Badge bgColor="bg" textColor="purple">
                              {painPoint}
                            </Badge>
                          </div>
                        ))}
                      </div>
                    </div>
                  </AudienceName>
                  <IconWrapper>
                    <EditIcon />
                  </IconWrapper>
                </AudienceCard>
              </div>
            ))
          ) : (
            <></>
          )}

          <div className="col-4">
            <AudienceCard
              className="add-audience"
              onClick={() => showCreateAudienceModal(true)}
            >
              <PlusIcon className="icon" />
              {t("form.audience.add_target_audience")}
            </AudienceCard>
          </div>
        </div>
        {createAudienceModal && (
          <CreateAudienceModal
            onSubmit={(data) => {
              fields.append(data);
            }}
            onHide={() => showCreateAudienceModal(false)}
          />
        )}

        {updateAudienceModal && (
          <UpdateAudienceModal
            currentAudience={
              updateAudience as Omit<Audience, "websiteId" | "companyId">
            }
            onSubmit={(data) => {
              fields.update(parseInt(data.id), data);
            }}
            onDelete={(id) => {
              fields.remove(parseInt(id));
            }}
            onHide={() => showUpdateAudienceModal(false)} // Correct this onHide function
          />
        )}
      </FormStyleStyled>
    </>
  );
};

export default CreateAudience;
