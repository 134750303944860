import { AttachmentMedia } from "neword-core";

export const coverPhotoValidation = (value: AttachmentMedia | null) => {
  if (!value) {
    return "";
  }

  const { metadata, url } = value;

  // Ensure it's not a video
  if (metadata.type === "VIDEO") {
    return "לא ניתן להעלות סרטונים";
  }

  // Ensure it's an image
  if (metadata.type !== "IMAGE") {
    return "הקובץ חייב להיות תמונה";
  }

  // Validate the image format
  const imageType = url.split(".").pop()?.toLowerCase();
  if (!["png", "jpg", "jpeg"].includes(imageType || "")) {
    return "התמונה חייבת להיות בפורמט PNG, JPG או JPEG";
  }

  // Validate image properties
  const { aspectRatio, resolution, size } = metadata;

  // Aspect Ratio: 1:2.4 to 2.4:1
  const [widthRatio, heightRatio] = aspectRatio.split(":").map(Number);
  const ratio = widthRatio / heightRatio;
  if (ratio < 1 / 2.4 || ratio > 2.4) {
    return "יחס רוחב-גובה של התמונה חייב להיות בין 1:2.4 ל-2.4:1";
  }

  // Resolution: Minimum 256 pixels on the shorter side
  const [width, height] = resolution
    .split("x")
    .map((dim) => parseInt(dim.trim(), 10));
  if (isNaN(width) || isNaN(height) || Math.min(width, height) < 256) {
    return "הרזולוציה של התמונה חייבת להיות לפחות 256 פיקסלים בצד הקצר";
  }

  // File Size: Maximum 5MB
  if (size > 5 * 1024 * 1024) {
    return "גודל הקובץ של התמונה לא יכול לעלות על 5MB";
  }

  return ""; // No errors
};
