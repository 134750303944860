import styled from "styled-components";
import Button from "../common/form/Button";
import PackageIncludes from "./PackageIncludes";
import { useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { Color } from "../../core/theme/types/color";
import { subscriptionService } from "../../core/services/subscription.service";
import { toast } from "react-toastify";
import { userState } from "../../state/userState";
import Badge from "../common/Badge";
import { useTranslation } from "react-i18next";
import { Package, PackageType } from "neword-core";
import { Tooltip } from "@mui/material";

// Extend your Props interface with an optional counter flag
interface Props {
  children?: JSX.Element | JSX.Element[];
  color: Color;
  packageInfo: Package;
  companyId: string;
  showChecks?: boolean;
  isSelected?: boolean;
  isFreeTrial?: boolean;
  recommended?: boolean; // Add the recommended prop
  yearly?: boolean;
  counter?: boolean; // if true, show the counter UI for client management
}

// Styled component for the card wrapper
const CardWrapper = styled.div<{ recommended?: boolean }>`
  /* box-shadow: -10px 10px 20px -5px rgba(0, 0, 0, 0.1); */

  width: 30%;
  border-radius: 24px;
  position: relative;
  border: 1px solid
    ${(props) =>
      props.recommended
        ? "var(--primary-purple)"
        : "var(--dark-green)"};
  background: #fff;
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex-shrink: 0;
`;

// Other styled components…
const CardTitle = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--title-color);
  font-size: 18px;
  font-weight: 600;
  line-height: 20.042px;
  letter-spacing: 0.735px;
  text-transform: uppercase;
  margin-bottom: 12px;
  font-family: "Space Grotesk", Sans-serif;
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;

const PriceTextWrapper = styled.div<{ isCustom: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: end;
  margin: ${(props) => (props.isCustom ? "1px 0" : "0")};
`;

const PriceDesc = styled.div`
  color: var(--title-color);
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: -0.4px;
`;

const Price = styled.div<{ color: Color; isCustom: boolean }>`
  font-family: "Space Grotesk", Sans-serif;
    font-weight: 600;
    font-size: ${(props) => (props.isCustom ? "4rem" : "94px")};
    text-transform: uppercase;
    line-height: 75px;
    color: var(--dark-green);
  & span {
    color: var(--title-color);
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
    margin-right: 5px;
  }
`;

const FreeTrialText = styled.div`
  color: #000;
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.01875rem;
`;

const ButtonStyling = styled(Button)<{ color: Color; recommended?: boolean }>`
  padding: 16px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid
    ${(props) => (props.recommended ? "white" : "var(--primary-purple)")};
  color: ${(props) => (props.recommended ? "white" : "var(--primary-purple)")};
  background: ${(props) =>
    props.recommended ? "var(--primary-purple)" : "white"};

  &:hover {
    background: ${(props) =>
      props.recommended ? "var(--title-color)" : "var(--purple-opacity)"};
    color: ${(props) =>
      props.recommended ? "white" : "var(--primary-purple)"};
  }
`;

const SmallStyled = styled.small`
  font-family: "Assistant", Sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #7A7A7A;
`;

const BadgeStyled = styled(Badge)`
  position: absolute;
  top: 10px;
  left: 10px;
`;

// New styled components for the counter
const CounterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
  width: 100%;
  border: solid 1px var(--border-color);
  padding: 10px;
  border-radius: 8px;
`;

const CounterButton = styled.button`
  width: 30px;
  height: 30px;
  border: 1px solid var(--primary-purple);
  border-radius: 5px;
  background: white;
  color: var(--primary-purple);
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  transition-duration: 0.1s;

  &:hover {
    background: var(--primary-purple);
    color: white;
  }
`;

const CounterDisplay = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  width: 40px;
  text-align: center;
`;

const PackageCard: React.FC<Props> = ({
  color,
  packageInfo,
  companyId,
  showChecks,
  isFreeTrial,
  isSelected,
  yearly,
  recommended,
  counter, // flag to show the counter UI
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useRecoilState(userState);
  const { t } = useTranslation();
  const isCustom = useMemo(
    () => packageInfo.type === PackageType.CUSTOM,
    [packageInfo.type]
  );

  const isFree = useMemo(
    () => packageInfo.type === PackageType.STARTER_PACK,
    [packageInfo.type]
  );

  // State for the counter – defaults to 5
  const [counterValue, setCounterValue] = useState<number>(5);

  // Handlers to increase or decrease the counter value
  const handleIncrease = () => {
    if (counterValue < 20) setCounterValue(counterValue + 5);
  };

  const handleDecrease = () => {
    if (counterValue > 5) setCounterValue(counterValue - 5);
  };

  // Update pricing based on the counter. Base pricing covers 5 clients.
  const baseMonthlyCost = packageInfo.monthlyCost;
  const additionalCost = ((counterValue - 5) / 5) * 55;
  const newMonthlyCost = baseMonthlyCost + additionalCost;
  const calculatedPrice = yearly
    ? (newMonthlyCost * 0.8).toLocaleString(undefined, {
        maximumFractionDigits: 0,
      })
    : newMonthlyCost.toLocaleString(undefined, {
        maximumFractionDigits: 0,
      });
  const yearlyprice = yearly ? newMonthlyCost * 12 * 0.8 : newMonthlyCost * 12;
  const formattedYearlyPrice = yearlyprice.toLocaleString(undefined, {
    maximumFractionDigits: 0,
  });

  const onPackageClick = async () => {
    setIsLoading(true);
    try {
      const res = await subscriptionService.buySubscription(
        packageInfo.type,
        counterValue,
        !!yearly
      );
      if (res.isDone && res.subscription) {
        toast(t("package_card.toast_success_message"), {
          type: "success",
        });
        setUser({
          ...user,
          company: {
            ...user.company,
            Subscription: res.subscription,
          },
        });
      } else if (res.redirectUrl) {
        window.location.href = res.redirectUrl;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const isUpgradeButtonDisabled = useMemo(
    () =>
      isSelected && counterValue === user.company.Subscription.maxBusinesses,
    [counterValue, isSelected, user.company.Subscription.maxBusinesses]
  );

  return (
    <CardWrapper recommended={recommended}>
      <div className="flex flex-column w-full">
        <CardTitle color={color}>
          {packageInfo.name}
          {recommended && (
            <BadgeStyled bgColor="purple" textColor="white" large>
              {t("package_card.recommended_badge")}
            </BadgeStyled>
          )}
        </CardTitle>
        {/* <div>{packageInfo.description}</div> */}
      </div>

      <div className="flex w-full align-items-center">
        <PriceTextWrapper isCustom={isCustom}>
          <Price color={color} isCustom={isCustom}>
            {isCustom ? t("package_card.custom_package") : calculatedPrice}
          </Price>
          <PriceDesc>{t("package_card.monthly_price_text")}</PriceDesc>
        </PriceTextWrapper>
      </div>

      {isCustom ? (
  <SmallStyled>מחיר מותאם אישית</SmallStyled>
) : isFree ? (
  <SmallStyled>חינם לגמרי, לתמיד
</SmallStyled>
) : (
  <SmallStyled>
    {!yearly
      ? `${calculatedPrice}${t("package_card.yearly_price_text")}`
      : `${t("package_card.one_time_payment_text")} ${formattedYearlyPrice} ${t(
          "package_card.after_one_time_payment_text"
        )} ${t("package_card.before_tax_text")}`}
  </SmallStyled>
)}

      {isUpgradeButtonDisabled ? (
        <ButtonStyling
          color={color}
          onClick={onPackageClick}
          arrowPlacement="right"
          primary
          disabled
        >
          {t("package_card.selected_button")}
        </ButtonStyling>
      ) : isCustom ? (
        <ButtonStyling
          fullWidth
          color={color}
          onClick={() =>
            (document.location.href = "https://ghostwrites.ai/how-it-works/")
          }
          arrowPlacement="right"
          primary
        >
          {t("package_card.contact_button")}
        </ButtonStyling>
      ) : (
        <ButtonStyling
          recommended={recommended}
          fullWidth
          color={color}
          onClick={onPackageClick}
          arrowPlacement="right"
          primary
          loading={isLoading}
        >
          {t("package_card.upgrade_button")}
        </ButtonStyling>
      )}
      {counter && (
        <CounterWrapper>
          <div className="flex flex-column">
            <strong>כמות מותגים:</strong>
            <small>כמה מותגים תנהלו במערכת</small>
          </div>
          <div className="flex">
            <CounterButton
              onClick={handleDecrease}
              disabled={counterValue <= 5}
            >
              -
            </CounterButton>
            <CounterDisplay>{counterValue}</CounterDisplay>
            <Tooltip
              data-pr-showondisabled
              title="מעל 20 מותגים, צרו איתנו קשר"
            >
              <CounterButton
                onClick={handleIncrease}
                disabled={counterValue >= 20}
              >
                +
              </CounterButton>
            </Tooltip>
          </div>
        </CounterWrapper>
      )}
      {showChecks === false && (
        <PackageIncludes isCustom={isCustom} isFree={isFree} bullets={packageInfo.bullets} />
      )}
    </CardWrapper>
  );
};

export default PackageCard;
