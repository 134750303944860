import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { useMemo, useRef } from "react";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import {
  websiteState,
  websitesStateSelector,
} from "../../../../state/websitesState";
import WebsiteDropdownItem from "./WebsiteDropdownItem";
import { ReactComponent as DownButton } from "../../../../assets/Icons/DownArrow.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/Icons/PlusBorder.svg";
import Link from "../../Link";
import GWBwutton from "../../form/Button";
import e from "express";
import Protected from "../../../../middleware/protected";
import { UserRole } from "../../../../core/types/userRole";
import { useTranslation } from "react-i18next";

const IconStyle = styled.div`
  transition-duration: 0.2s;
  position: relative;
  top: 1px;
  margin-right: 7px;
  svg {
    transform: rotate(180deg);
    width: 10px;
    width: 10px;
  }
  svg path {
    fill: black;
  }
`;

const IconWrapper = styled.div`
  width: 14px;
  height: 14px;
  svg {
    width: 14px;
    height: 14px;
  }
`;
const ButtonStyled = styled(Button)`
  border-radius: none !important;
  font-size: 14px;
  letter-spacing: -0.0225rem;
  font-weight: 500 !important;
  padding: 0;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: black !important;

  &:hover {
    ${IconStyle} {
      top: 3px;
    }
  }
`;

const AddProject = styled(Link)`
  padding: 10px 1rem;
  display: flex;
  color: var(--primary-purple);
  align-items: center;
  gap: 10px;
  min-width: 300px;
  svg {
    fill: var(--primary-purple);
  }
  span {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.4px;
  }
`;

const MenuStyled = styled(Menu)`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & li:nth-child(4n-3) .square-colored {
    background: #a960ee;
  }
  & li:nth-child(4n-2) .square-colored {
    background: #f92b75;
  }
  & li:nth-child(4n-1) .square-colored {
    background: #ffcb57;
  }
  & li:nth-child(4n) .square-colored {
    background: #90e0ff;
  }
`;

const Wrapper = styled.div``;

interface WebsiteDropdownMenuProps {
  className?: string;
}

const WebsiteDropdownMenu: React.FC<WebsiteDropdownMenuProps> = ({
  className,
}) => {
  const websitesSelector = useRecoilValue(websitesStateSelector);
  const { websiteId } = useParams();
  const { t } = useTranslation();

  const selectedWebsite = useRecoilValue(websiteState(websiteId as string));

  const menuWebsites = useRef<Menu>(null);

  const websites = useMemo(() => {
    const websitesTemplates = websitesSelector.map((website) => ({
      template: () => {
        return (
          <WebsiteDropdownItem
            isSelected={websiteId === website.id}
            websiteId={website.id}
            websiteLogo={website.logoSrc}
          >
            {website.name || website.domain}
          </WebsiteDropdownItem>
        );
      },
    }));

    websitesTemplates.push({
      template: () => {
        return (
          <AddProject path="/websites/new" global>
            <GWBwutton
              icon={<PlusIcon />}
              textColor="purple"
              className="pl-0"
              color=""
              arrowPlacement="right"
            >
              {t("form.website_dropdown.add_new_business")}{" "}
            </GWBwutton>
          </AddProject>
        );
      },
    });

    return websitesTemplates;
  }, [websiteId, websitesSelector]);

  return (
    <Wrapper className={`flex align-items-center ${className}`}>
      <Protected
        fallback={
          <p>{websitesSelector[0].name || websitesSelector[0].domain}</p>
        }
        neededRole={UserRole.EDITOR}
      >
        <MenuStyled
          model={websites}
          popup
          ref={menuWebsites}
          id="popup_websites"
        />

        <ButtonStyled
          iconPos="right"
          onClick={(event) => menuWebsites.current?.toggle(event)}
          aria-controls="popup_websites"
          aria-haspopup
        >
          {selectedWebsite
            ? selectedWebsite.name || selectedWebsite.domain
            : t("form.website_dropdown.choose_business")}
          <IconStyle>
            <DownButton />
          </IconStyle>
        </ButtonStyled>
      </Protected>
    </Wrapper>
  );
};

export default WebsiteDropdownMenu;
