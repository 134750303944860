import React, { useState } from "react";
import styled from "styled-components";
import { FieldValues, Path, useFormContext, useWatch } from "react-hook-form";
import { ReactComponent as TagIcon } from "../../assets/Icons/instagram/Tag.svg";
import { ReactComponent as CollabIcon } from "../../assets/Icons/instagram/Collab.svg";
import TagPeopleModal from "../modals/postPublish/TagPeopleModal";
import CollabPeopleModal from "../modals/postPublish/CollabPeopleModal";
import Badge from "../common/Badge";

const TagInput = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 12px;
  background-color: var(--light-bg);
  border-radius: 8px;
  gap: 10px;
  cursor: pointer;
  transition-duration: 0.2s;
  font-size: 12px;

  .badge {
    font-size: 12px;
    padding: 0 8px;
  }
  svg {
    width: 12px;
    height: 12px;
  }
  &:hover {
    background-color: var(--bg-dark);
  }
`;

interface TagInputsSectionProps<FormDataType extends FieldValues> {
  name: Path<FormDataType>;
}

function TagInputsSection<FormDataType extends FieldValues>({
  name,
}: TagInputsSectionProps<FormDataType>) {
  const { getValues } = useFormContext();
  const [showModalTag, setShowModalTag] = useState(false);
  const [showModalCollab, setShowModalCollab] = useState(false);

  const numberOfTags = getValues(`${name}.tags`)?.length ?? 0;
  const numberOfCollab = getValues(`${name}.collaborators`)?.length ?? 0;

  return (
    <>
      <div className="flex gap-10">
        <TagInput onClick={() => setShowModalTag(true)}>
          <TagIcon />
          <span>הוספת תיוגים</span>
          <Badge className="badge" bgColor="lightBlue" textColor="title">
            {numberOfTags}
          </Badge>
        </TagInput>
        <TagInput onClick={() => setShowModalCollab(true)}>
          <CollabIcon />
          <span> הוספת Collaboration</span>
          <Badge className="badge" bgColor="lightBlue" textColor="title">
            {numberOfCollab}
          </Badge>
        </TagInput>
      </div>

      {showModalTag && (
        <TagPeopleModal name={name} onHide={() => setShowModalTag(false)} />
      )}
      {showModalCollab && (
        <CollabPeopleModal
          name={name}
          onHide={() => setShowModalCollab(false)}
        />
      )}
    </>
  );
}

export default TagInputsSection;
