import { Dialog } from "primereact/dialog";

import styled from "styled-components";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { useParams } from "react-router";
import Button from "../../common/form/Button";
import {
  documentState,
  documentsSummariesState,
} from "../../../state/documentState";
import documentService from "../../../core/services/document.service";
import {useTranslation} from "react-i18next";

interface Props {
  onHide: () => void;
  onSubmit: () => void;
  documentId: string;
}

const StyledButton = styled(Button)`
  width: fit-content;
`;

const DeleteDocumentModal: React.FC<Props> = ({
  onHide,
  documentId,
  onSubmit,
}) => {
  const { websiteId } = useParams();
  const { t } = useTranslation();

  const [documents, setDocuments] = useRecoilState(
    documentsSummariesState(websiteId as string)
  );

  const onSubmitInternal = async () => {
    setDocuments(documents?.filter((a) => a.id !== documentId));
    documentService.deleteDocument(documentId);
    onSubmit();
  };

  return (
    <Dialog
      modal
      visible
      header={t("delete_document_modal.header")}
      onHide={onHide}
      footer={
        <StyledButton primary onClick={onSubmitInternal}>
          {t("delete_document_modal.delete_button")}
        </StyledButton>
      }
    >
      <p>{t("delete_document_modal.confirmation_message")}</p>
      <small>{t("delete_document_modal.note")}</small>
    </Dialog>
  );
};

export default DeleteDocumentModal;
