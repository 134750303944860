import React, { useCallback, useMemo, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Menu } from "primereact/menu";
import { Tooltip } from "primereact/tooltip";
import styled from "styled-components";
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import ModalHeader from "../modals/ModalHeader";

import IntegrationsModal from "../modals/IntegrationsModal";

import { PostEntity, PostEntityType, PostStatusType } from "neword-core";
import { postTypeToForm } from "./forms/postTypeToForm";
import postService from "../../core/services/post.service";
import { lastAddedPostsIds, postsSummariesState } from "../../state/postState";
import { appIntegrationState } from "../../state/appIntegrationState";
import { postTypeToappIntegrationApplicationType } from "./postTypeToappIntegrationApplicationType";
import { createPostDeafultParams } from "./createPostDeafultParams";
import useNavigator from "../../hooks/useNavigator";
import ButtonWithDropdown from "../common/form/ButtonWithDropdown";
import { useTranslation } from "react-i18next";
import { PostCardIcons } from "./postIconMappings";
import { FooterButtons } from "./FooterButtons";
import { SocialPlatforms } from "./SocialPlatformSelector";

const DialogStyled = styled(Dialog)`
  direction: rtl;
  .p-dialog-content {
    padding: 0;
    overflow: visible;
  }
  .p-dialog-header {
    padding: 0 !important;
  }
  .p-dialog-footer {
    position: absolute;
    right: 90px;
    /* border: solid 1px var(--title-color); */
    padding: 10px !important;
    border: solid 1px var(--border-color);
    bottom: 10px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const RemoveSocial = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 15px;
  height: 15px;
  background: var(--title-color);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  opacity: 0;
  pointer-events: none;
`;

const AddSocialButton = styled.div`
  cursor: pointer;
  padding: 8px;
  &:hover {
    background: var(--light-bg);
  }
`;

const PlatformIconWrapper = styled.div<{
  backgroundColor?: string;
  active?: boolean;
  dashed?: boolean;
  iconColor?: string;
}>`
  position: relative;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: ${(props) =>
    props.active ? props.backgroundColor : "var(--border-color)"};
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${(props) => (props.dashed ? "1px dashed lightgrey" : "none")};

  &:hover ${RemoveSocial} {
    opacity: 1;
    pointer-events: all;
  }

  &:hover {
    border-color: var(--primary-purple);
    svg path {
      fill: ${(props) =>
        props.iconColor
          ? props.iconColor
          : props.active
            ? "white !important"
            : "#9AA8B6 !important"};
    }
  }
  svg {
    width: 15px;
    height: 15px;
    fill: ${(props) =>
      props.iconColor
        ? props.iconColor
        : props.active
          ? "white !important"
          : "#9AA8B6 !important"};
    path {
      fill: ${(props) =>
        props.iconColor
          ? props.iconColor
          : props.active
            ? "white !important"
            : "#9AA8B6 !important"};
    }
  }
`;

// Subcomponent for the modal footer buttons

interface PublishPostModalProps {
  postDefaults?: PostEntity["data"];
  postEntityType: PostEntityType;
  scheduleDate?: Date;
  onHide: () => void;
}

const PublishPostModal: React.FC<PublishPostModalProps> = ({
  onHide,
  postDefaults,
  scheduleDate,
  postEntityType,
}) => {
  const addSocialMenuRef = useRef<Menu>(null);
  const { websiteId } = useParams();
  const appIntegrations = useRecoilValue(
    appIntegrationState(websiteId as string)
  );
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [showIntegrationModal, setShowIntegrationModal] =
    useState<boolean>(false);
  const [postsSummaries, setPostsSummaries] = useRecoilState(
    postsSummariesState(websiteId as string)
  );
  const setLatestPosts = useSetRecoilState(
    lastAddedPostsIds(websiteId as string)
  );
  const navigate = useNavigator();

  const methods = useForm<{ posts: PostEntity[]; scheduleDate: Date }>({
    defaultValues: {
      posts: [
        {
          scheduleDate: scheduleDate,
          type: postEntityType,
          appIntegrationApplicationType:
            postTypeToappIntegrationApplicationType(postEntityType),
          data: postDefaults ?? createPostDeafultParams(postEntityType),
        },
      ],
      scheduleDate: scheduleDate,
    },
  });

  const posts = useFieldArray({ control: methods.control, name: "posts" });
  const [postType, setPostType] = useState(postEntityType);

  const getTypeIndex = useCallback(
    (type: PostEntityType) =>
      posts.fields.findIndex((post) => post.type === type),
    [posts.fields]
  );

  const postIndex = useMemo(
    () => getTypeIndex(postType),
    [posts.fields, postType]
  );

  const FormComponent =
    postTypeToForm[methods.getValues(`posts.${postIndex}.data.type`)];

  const isMissingIntegration = useMemo(
    () =>
      posts.fields
        .map((p) =>
          appIntegrations.find(
            (integration) =>
              integration.appIntegrationApplicationType ===
                postTypeToappIntegrationApplicationType(
                  p.type as PostEntityType
                ) && integration.isEnabled
          )
        )
        .includes(undefined),
    [appIntegrations, posts.fields]
  );

  const menuitems = useMemo(() => {
    return Object.entries(PostCardIcons)
      .filter(
        ([key, value]) =>
          !!value.iconPlain && getTypeIndex(key as PostEntityType) < 0
      )
      .map(([key, value]) => ({
        template: () => (
          <AddSocialButton
            onClick={() => onClickIcon(key as PostEntityType)}
            className="p-3 flex gap-10 align-items-center"
          >
            <PlatformIconWrapper backgroundColor={value.color} active>
              {value.iconPlain && <value.iconPlain />}
            </PlatformIconWrapper>
            {value.socialPostingText && value.socialPostingText}
          </AddSocialButton>
        ),
      }));
  }, [getTypeIndex /* eslint-disable-line react-hooks/exhaustive-deps */]);

  const handleSubmitForm: SubmitHandler<{
    posts: PostEntity[];
    status: PostStatusType;
  }> = async (values) => {
    if (isMissingIntegration) {
      setShowIntegrationModal(true);
    } else {
      const allScheduleDate = methods.getValues("scheduleDate") || new Date();
      try {
        setIsLoading(true);
        const newPosts = await postService.schedulePost(
          values.posts.map((p) => ({
            ...p,
            scheduleDate: allScheduleDate,
            status: values.status,
          })),
          websiteId as string
        );
        toast(t("publish_post_modal.toast.post_created_success"), {
          type: "success",
        });
        setPostsSummaries([...postsSummaries, ...newPosts]);
        setLatestPosts(newPosts.map((p) => p.id));
        navigate(`/calendar`);
      } catch (err) {
        // handle error if needed
      } finally {
        setIsLoading(false);
      }
      onHide();
    }
  };

  const handlePublishSubmit: SubmitHandler<{ posts: PostEntity[] }> =
    useCallback(
      async (values) => {
        if (isMissingIntegration) {
          setShowIntegrationModal(true);
        } else {
          try {
            setIsLoading(true);
            const newPosts = await postService.schedulePost(
              values.posts,
              websiteId as string,
              true
            );
            toast(t("publish_post_modal.toast.post_published_success"), {
              type: "success",
            });
            setPostsSummaries([...postsSummaries, ...newPosts]);
            setLatestPosts(newPosts.map((p) => p.id));
            navigate(`/calendar`);
          } catch (err) {
            // handle error if needed
          } finally {
            setIsLoading(false);
          }
          onHide();
        }
      },
      [isMissingIntegration, websiteId, navigate]
    );

  const onClickIcon = (type: PostEntityType) => {
    if (getTypeIndex(type) < 0) {
      const postData = createPostDeafultParams(type);
      const currentPost = methods.getValues(`posts.${postIndex}`) as PostEntity;

      const newPost: PostEntity = {
        ...currentPost,
        data: {
          ...postData,
          message: currentPost.data.message,
          imageUrls: currentPost.data.imageUrls,
        },
        type,
        appIntegrationApplicationType:
          postTypeToappIntegrationApplicationType(type),
      };
      posts.append(newPost);
    }
    setPostType(type);
    methods.trigger("posts");
  };

  const handleRemovePost = useCallback(
    (typeToRemove: PostEntityType) => {
      const indexToRemove = getTypeIndex(typeToRemove);
      if (posts.fields.length === 1) {
        onHide();
        return;
      }
      if (typeToRemove === postType) {
        const nextPost = posts.fields.find(
          (field) => field.type !== typeToRemove
        );
        if (nextPost) {
          setPostType(nextPost.type as PostEntityType);
        }
      }
      posts.remove(indexToRemove);
    },
    [posts, postType, getTypeIndex, onHide]
  );

  return (
    <FormProvider {...methods}>
      <form>
        <DialogStyled
          header={<ModalHeader OnClose={onHide} downAbit />}
          closable={false}
          visible
          position="center"
          style={{
            width: "80vw",
            margin: "0",
            boxShadow: "none",
            borderLeft: "solid 1px var(--border-color)",
            borderRadius: "0",
            maxHeight: "100%",
            padding: "0",
          }}
          onHide={onHide}
          draggable={false}
          resizable={false}
          footer={
            <div className=" m-0">
              <FooterButtons
                methods={methods}
                isLoading={isLoading}
                handleSubmit={handleSubmitForm}
                handlePublishSubmit={handlePublishSubmit}
              />
            </div>
          }
        >
          <div className="flex m-0 h-full">
            <Tooltip target=".remove-icon" />
            <Menu
              model={menuitems}
              popup
              ref={addSocialMenuRef}
              id="socialmenu"
            />
            <SocialPlatforms
              postsFields={posts.fields}
              postIndex={postIndex}
              getTypeIndex={getTypeIndex}
              onClickIcon={onClickIcon}
              handleRemovePost={handleRemovePost}
              addSocialMenuRef={addSocialMenuRef}
            />
            <Wrapper>
              {posts.fields.map((field, index) => {
                const Component =
                  postTypeToForm[methods.getValues(`posts.${index}.data.type`)];
                return Component ? (
                  <div
                    key={field.id}
                    style={{ display: index === postIndex ? "block" : "none" }}
                  >
                    <Component name={`posts.${index}.data`} index={index} />
                  </div>
                ) : (
                  <div key={field.id}>{t("publish_post_modal.no_form")}</div>
                );
              })}
            </Wrapper>
          </div>
        </DialogStyled>
      </form>
      {showIntegrationModal && (
        <IntegrationsModal
          onSuccess={() => {}}
          onHide={() => setShowIntegrationModal(false)}
        />
      )}
    </FormProvider>
  );
};

export default PublishPostModal;
