import {
  Controller,
  Path,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";

import { DocumentEntityType, DocumentEntity } from "neword-core";

import GenericDescriptionInput from "./GenericDescriptionInput";
import {useTranslation} from "react-i18next";

function BlogPostCaseStudyDescription() {
  const { t } = useTranslation();
  return (
    <div className="w-full step-2-wt">
      <GenericDescriptionInput<
        DocumentEntity & { type: DocumentEntityType.BLOG_POST_CASE_STUDY }
      >
        name="inputParams.description"
        label={t("blog_post_case_study.description_label")}
        placeholder={t("blog_post_case_study.description_placeholder")}
        generateText={t("blog_post_case_study.description_generate_text")}
        reGenerateText={t("blog_post_case_study.description_re_generate_text")}
      />
      <GenericDescriptionInput<
        DocumentEntity & { type: DocumentEntityType.BLOG_POST_CASE_STUDY }
      >
        name="inputParams.solutionDescription"
        label={t("blog_post_case_study.solution_description_label")}
        placeholder={t("blog_post_case_study.solution_description_placeholder")}
        generateText={t("blog_post_case_study.solution_generate_text")}
        reGenerateText={t("blog_post_case_study.solution_re_generate_text")}
      />

      <GenericDescriptionInput<
        DocumentEntity & { type: DocumentEntityType.BLOG_POST_CASE_STUDY }
      >
        name="inputParams.results"
        label={t("blog_post_case_study.results_label")}
        placeholder={t("blog_post_case_study.results_placeholder")}
        generateText={t("blog_post_case_study.results_generate_text")}
        reGenerateText={t("blog_post_case_study.results_re_generate_text")}
      />
    </div>
  );
}

export default BlogPostCaseStudyDescription;
