import {
  DocumentCategory,
  DocumentEntityType,
  DocumentTypeToCategory,
} from "neword-core";
import { Dialog } from "primereact/dialog";
import React, { useState, useCallback, useMemo } from "react";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import documentService from "../../../core/services/document.service";
import useNavigator from "../../../hooks/useNavigator";
import { websiteState } from "../../../state/websitesState";
import {useDocBoxes} from "../../document/components/DocBoxes";
import documentsDictionary from "../../document/documentsDictionary";
import CardFilterItem from "../../website/overview/cardsFilter/CardFilterItem";
import ContentCard from "../../website/overview/contentCards/ContentCard";
import { ReactComponent as PopularIcon } from "../../../assets/Icons/overviewFilterIcons/popular_icon.svg";
import { ReactComponent as BlogIcon } from "../../../assets/Icons/overviewFilterIcons/blog_icon.svg";
import { ReactComponent as AdsIcon } from "../../../assets/Icons/overviewFilterIcons/ads_icon.svg";
import { ReactComponent as WebsiteIcon } from "../../../assets/Icons/overviewFilterIcons/website_icon.svg";
import { ReactComponent as MarketingIcon } from "../../../assets/Icons/overviewFilterIcons/marketing_icon.svg";
import { ReactComponent as SocialIcon } from "../../../assets/Icons/overviewFilterIcons/social_icon.svg";
import { ReactComponent as CampaignIcon } from "../../../assets/Icons/overviewFilterIcons/Campaign.svg";
import { InputText } from "primereact/inputtext";
import ModalHeader from "../../modals/ModalHeader";
import {useTranslation} from "react-i18next";

const DialogStyled = styled(Dialog)`
  padding: 0;
  .p-dialog-header {
    padding: 0;
  }

  .p-dialog-content {
    padding: 0;
  }
`;

const HeaderWrapper = styled.div``;

const SearchInputStyled = styled(InputText)`
  width: 100%;
  &,
  &:hover {
    border: solid 1px var(--text-color) !important;
  }
`;

const MenuWrapper = styled.div`
  padding: 10px;
  background: var(--light-bg);
  height: 100%;
`;

const ScrollSection = styled.div`
  overflow-y: auto;
`;

const CreateDocModal: React.FC<{ onHide: () => void; dateParam?: Date }> = ({
  onHide,
  dateParam,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { websiteId } = useParams();
  const DocBoxes = useDocBoxes();
  const navigate = useNavigator();
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState<
    DocumentCategory | "all"
  >("all");
  const website = useRecoilValue(websiteState(websiteId as string));
  const createDoc = useCallback(
    async (type: DocumentEntityType) => {
      if (!documentsDictionary[type]) return;
      const docId = await documentService.createDocument(
        type,
        websiteId as string,
        dateParam
      );
      navigate(`/document/${docId}`);
      onHide();
    },
    [websiteId]
  );

  const docBoxesFiltered = useMemo(() => {
    // 2) If you have a custom array of doc types you want to show, define it here:
    const myCustomDocTypes: DocumentEntityType[] = [
      DocumentEntityType.GENERIC_CAMPAIGN,
      DocumentEntityType.INSTAGRAM_CAPTION,
      DocumentEntityType.INSTAGRAM_SPONSORED_AD,
      DocumentEntityType.FACEBOOK_POST_CAPTION,
      DocumentEntityType.FACEBOOK_SPONSORED_AD,
      DocumentEntityType.LINKEDIN_POST_CAPTION,
      DocumentEntityType.LINKEDIN_SPONSORED_AD,
      DocumentEntityType.TWITTER_TWIT,
      DocumentEntityType.SMS_CONTENT,
      // etc...
    ];

    return DocBoxes.filter((doc) => {
      // If there's a search term, we always filter by title match first:
      if (searchTerm) {
        // Simple "includes" check. Feel free to refine as needed:
        if (!doc.title.toLowerCase().includes(searchTerm.toLowerCase())) {
          return false;
        }
      }

      // Then handle the selectedCategory
      switch (selectedCategory) {
        case "all":
          // 3) Show only docs whose type is in `myCustomDocTypes`
          return myCustomDocTypes.includes(doc.type);
        default:
          // For normal categories (DocumentCategory.*):
          return DocumentTypeToCategory[doc.type] === selectedCategory;
      }
    }).sort();
  }, [searchTerm, selectedCategory]);

  return (
    <DialogStyled
      visible
      position={"right"}
      style={{
        height: "100vh",
        margin: "0",
        width: "45vw",
        boxShadow: "none",

        borderLeft: "solid 1px var(--border-color)",
        borderRadius: "0px",
        maxHeight: "100%",
        padding: "0",
      }}
      onHide={onHide}
      header={<ModalHeader OnClose={onHide} right downAbit />}
      modal={true}
      draggable={false}
      closable={false}
    >
      <div className="grid h-full m-0">
        <div className="col-3 h-full p-0">
          <MenuWrapper>
            <div className="flex flex-column gap-10 mt-2">
              <CardFilterItem
                vertical
                onClick={() => setSelectedCategory("all")}
                title={t("form.create_doc_modal.recommended")}
                icon={<PopularIcon />}
                selected={selectedCategory === "all"}
              />
              <CardFilterItem
                vertical
                onClick={() => setSelectedCategory(DocumentCategory.CAMPAIGN)}
                title={t("form.create_doc_modal.campaigns")}
                icon={<CampaignIcon />}
                selected={selectedCategory === DocumentCategory.CAMPAIGN}
              />
              <CardFilterItem
                vertical
                onClick={() =>
                  setSelectedCategory(DocumentCategory.SOCIAL_MEDIA)
                }
                title={t("form.create_doc_modal.social")}
                icon={<SocialIcon />}
                selected={selectedCategory === DocumentCategory.SOCIAL_MEDIA}
              />
              <CardFilterItem
                vertical
                onClick={() => setSelectedCategory(DocumentCategory.PAID)}
                title={t("form.create_doc_modal.paid_ads")}
                icon={<AdsIcon />}
                selected={selectedCategory === DocumentCategory.PAID}
              />
              <CardFilterItem
                vertical
                onClick={() => setSelectedCategory(DocumentCategory.MAILING)}
                title={t("form.create_doc_modal.mailing")}
                icon={<MarketingIcon />}
                selected={selectedCategory === DocumentCategory.MAILING}
              />
              <CardFilterItem
                vertical
                onClick={() => setSelectedCategory(DocumentCategory.BLOG)}
                title={t("form.create_doc_modal.blog")}
                icon={<BlogIcon />}
                selected={selectedCategory === DocumentCategory.BLOG}
              />

              <CardFilterItem
                vertical
                onClick={() => setSelectedCategory(DocumentCategory.WEB)}
                title={t("form.create_doc_modal.websites")}
                icon={<WebsiteIcon />}
                selected={selectedCategory === DocumentCategory.WEB}
              />
            </div>
          </MenuWrapper>
        </div>
        <div className="col-9 h-full">
          <ScrollSection>
            <HeaderWrapper>
              <SearchInputStyled
                placeholder={t("form.create_doc_modal.search_placeholder")}
                onChange={(e: any) => {
                  setSearchTerm(e.target.value);
                  if (selectedCategory) {
                    setSelectedCategory("all");
                  }
                }}
                value={searchTerm}
              />
            </HeaderWrapper>
            <div className="grid w-full m-0">
              {docBoxesFiltered.map((doc, index) => (
                <>
                  <div
                    onClick={() => createDoc(doc.type)}
                    key={index}
                    className="col-6"
                  >
                    <ContentCard
                      horizontal
                      title={doc.title}
                      description={doc.description}
                      image={doc.image}
                      type={doc.platformType}
                      highlightTitleTerm={searchTerm}
                    />
                  </div>
                </>
              ))}
            </div>
          </ScrollSection>
        </div>
      </div>
      <div className="grid"></div>
    </DialogStyled>
  );
};

export default CreateDocModal;
