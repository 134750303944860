import React, { useMemo } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { PostEntity, PostEntityType } from "neword-core";
import SliderTile from "./calendarEventTile/clientView/SliderTile";

import "swiper/css";
import CustomPagination from "./calendarEventTile/clientView/CustomPagination";
// Remove or omit "swiper/css/pagination" if you're not using the built-in pagination

interface SliderViewProps {
  posts: PostEntity[];
  filterPostType: PostEntityType[];
  clientView?: boolean;
}

const Header = styled.div`
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
text-align: center;
font-weight: bold;
width: 100%;
  font-size:40px;
  letter-spacing: -0.2px;
  span{
    font-size:22px;
    font-weight: 400;
  }
`;

const SliderContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  height: 92vh; /* Full viewport height */
`;

const MobileSliderView: React.FC<SliderViewProps> = ({
  posts,
  filterPostType,
  clientView = false,
}) => {
  // Filter posts only by type (no date filtering)
  const filteredPosts = useMemo(() => {
    return posts.filter(
      (post) =>
        filterPostType.length === 0 || filterPostType.includes(post.type)
    );
  }, [posts, filterPostType]);

  return (
    <SliderContainer>
      <Swiper
        direction="vertical"
        // Remove Swiper's built-in pagination
        // modules={[]}  // don't import Pagination from 'swiper'
        spaceBetween={50}
        slidesPerView={1}
        style={{ height: "100%" }}
      >
        {filteredPosts.map((post) => (
          <SwiperSlide
            key={post.id}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <SliderTile post={post} />
          </SwiperSlide>
        ))}

        {/* 1) Insert your custom pagination component */}
        <CustomPagination slidesCount={filteredPosts.length} />

      </Swiper>
    </SliderContainer>
  );
};

export default MobileSliderView;
