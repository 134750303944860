import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useFormContext, Controller } from "react-hook-form";
import styled from "styled-components";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { websiteState } from "../../../state/websitesState";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import { ReactComponent as Neword } from "../../../assets/Icons/BlueIcon.svg";
import { ReactComponent as FacebookLogo } from "../../../assets/Icons/facebook/FacebookLogo.svg"; // Add Facebook logo
import FacebookPreview from "../../../assets/images/facebookPreview.png"; // Add Facebook preview image
import { ReactComponent as FacebookLike } from "../../../assets/Icons/facebook/FacebookLike.svg"; // Add Facebook like icon
import { ReactComponent as FacebookComment } from "../../../assets/Icons/facebook/FacebookComment.svg"; // Add Facebook like icon
import { ReactComponent as FacebookShare } from "../../../assets/Icons/facebook/FacebookShare.svg"; // Add Facebook like icon
import { ReactComponent as FacebookLikeCount } from "../../../assets/Icons/facebook/FacebookLikeCount.svg"; // Add Facebook like icon

import { BlockProps } from "./types/dictionaryProps";
import { RefProps } from "./types/refProps";
import EditableTextArea from "../components/EditableTextArea";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  padding: 10px;
  width: 100%;
`;

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px;
  justify-content: space-between;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AccountText = styled.div`
  font-weight: 700;
  font-size: 12.21px;
  display: flex;
  flex-direction: row-reverse;
`;

const ContentWrapper = styled.div`
  font-size: 12.21px;
  display: flex;
`;

const LeftTopMenu = styled.div`
  display: flex;
  align-items: center;
`;

const FacebookPreviewWrapper = styled.div`
  border-radius: 19.576px;
  margin-top: 10px;
  width: 100%;
  background-color: #f6f9fc;
  height: 216px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const PurpelText = styled.div`
  color: var(--main-hebrew, #6300c1);
  text-align: center;
  font-family: "Noto Sans Hebrew";
  font-size: 16px;
  font-weight: 700;
`;
const ImageText = styled.div`
  color: "";
  text-align: center;
  font-family: "Noto Sans Hebrew";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 13.432px */
  letter-spacing: -0.366px;
`;

const FacebookPostIdeaBlock = forwardRef<RefProps, BlockProps>(
  ({ isEditing, blockIndex, handleBlur }, ref) => {
    const { websiteId } = useParams();
    const { t } = useTranslation();
    const website = useRecoilValue(websiteState(websiteId as string));

    const { getValues } = useFormContext<
      DocumentEntity & { type: DocumentEntityType.FACEBOOK_POST_IDEA }
    >();
    const imageText = getValues(`output.${blockIndex}.imageIdea`);

    useImperativeHandle(ref, () => ({
      onCopyCustom: () => {
        const output = getValues(`output.${blockIndex}`);
        return output.postIdea;
      },
    }));

    return (
      <Wrapper className="col-4 mb-2">
        <TopSection>
          <FacebookLogo />
          <LeftTopMenu>
            <AccountText className="ml-1">{website?.name}</AccountText>
            <Neword />
          </LeftTopMenu>
        </TopSection>

        <ContentWrapper className="mt-1">
          <EditableTextArea<DocumentEntityType.FACEBOOK_POST_IDEA>
            fieldName={`output.${blockIndex}.postIdea`}
            handleBlur={handleBlur}
            blockIndex={blockIndex}
            isEditing={isEditing}
          />
        </ContentWrapper>
        <FacebookPreviewWrapper>
          <PurpelText>תמונה לדוגמה:</PurpelText>
          {/* <ImageText>{imageText}</ImageText> */}
        </FacebookPreviewWrapper>
        <div className="flex mt-1">
          <FacebookLikeCount />
          869
        </div>
        <IconsWrapper>
          <div className="flex">
            <FacebookShare className="ml-2" />
            {t("facebook_caption_block.share")}
          </div>
          <div className="flex">
            <FacebookComment className="ml-2" />
            {t("facebook_caption_block.comment")}
          </div>

          <div className="flex">
            <FacebookLike className="ml-2" />
            {t("facebook_caption_block.like")}
          </div>
        </IconsWrapper>
      </Wrapper>
    );
  }
);

export default FacebookPostIdeaBlock;
