import React, { useState, useEffect } from 'react';
import CalendarViewClient from '../../components/clientView/CalendarViewClient';
import DayView from '../../components/calendar/DayView';
import MobileViewClient from '../../components/clientView/MobileViewClient';

const ClientPage: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // You can adjust the breakpoint as needed (e.g., 768px)
      setIsMobile(window.innerWidth < 768);
    };

    // Initial check
    handleResize();
    
    // Listen for resize events
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      {isMobile ? <MobileViewClient /> : <CalendarViewClient />}
    </div>
  );
};

export default ClientPage;
