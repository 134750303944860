// CalendarContainer.tsx
import React, { useState } from "react";
import { useParams } from "react-router";
import { Value } from "react-calendar/dist/cjs/shared/types";
import { useRecoilState } from "recoil";

import { PostEntity, PostEntityType } from "neword-core";

import CalendarControls from "./CalendarControls";
import CalendarView from "./MonthView";
import { postsSummariesState } from "../../state/postState";
import postService from "../../core/services/post.service";
import DayView from "./DayView";
import WeekView from "./WeekView";
import MonthView from "./MonthView";

const CalendarContainer: React.FC = () => {
  const { websiteId } = useParams<{ websiteId: string }>();

  // Modal state
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal((prev) => !prev);

  // Posts state from Recoil
  const [posts, setPosts] = useRecoilState(
    postsSummariesState(websiteId as string)
  );

  // Post type filter state
  const [filterPostType, setFilterPostType] = useState<PostEntityType[]>(
    Object.values(PostEntityType) as PostEntityType[]
  );

  // Calendar selected date
  const [value, setValue] = useState<Date>(new Date());

  // **NEW VIEW STATE:** "month" | "week" | "day"
  const [currentView, setCurrentView] = useState<"month" | "week" | "day">(
    "month"
  );

  // Helper: combine a date and a time into one Date object
  const combineDateAndTime = (dateObj: Date, timeObj: Date) => {
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth();
    const day = dateObj.getDate();
    const test = new Date(timeObj);
    const hours = test.getHours();
    const minutes = test.getMinutes();
    const seconds = test.getSeconds();
    return new Date(year, month, day, hours, minutes, seconds);
  };

  // Called when an event (post) is dropped to a new date/time
  const handleEventDrop = (post: PostEntity, newDate: Date) => {
    postService.updatePost({ ...post, scheduleDate: newDate }).then(() => {
      setPosts((oldPosts) =>
        oldPosts.map((p) =>
          p.id === post.id
            ? {
                ...p,
                scheduleDate: combineDateAndTime(newDate, p.scheduleDate),
              }
            : p
        )
      );
    });
  };

  return (
    <>
      <CalendarControls
        filterPostType={filterPostType}
        setFilterPostType={setFilterPostType}
        toggleModal={toggleModal}
        showModal={showModal}
        currentView={currentView}
        setCurrentView={setCurrentView}
        value={value} // Pass your date state
        setValue={setValue} // Pass your state setter
      />

      {currentView === "month" && (
        <MonthView
          date={new Date(value as Date)}
          posts={posts}
          filterPostType={filterPostType}
          handleEventDrop={handleEventDrop}
        />
      )}
      {/* {currentView === "day" && (
        <DayView
          date={new Date(value as Date)}
          posts={posts}
          filterPostType={filterPostType}
          handleEventDrop={handleEventDrop}
        />
      )} */}
      {currentView === "week" && (
        <WeekView
          date={new Date(value as Date)}
          posts={posts}
          filterPostType={filterPostType}
          handleEventDrop={handleEventDrop}
        />
      )}
    </>
  );
};

export default CalendarContainer;
