import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { userState } from "../state/userState";
import { websitesStateSelector } from "../state/websitesState";
import { Features, PackageType } from "neword-core";
import { canCreateBusiness, hasFeature } from "../common/utils/permissionUtils";
import { globalUpgradeModalState } from "../state/globalUpgradeModalState";

export const usePermissionCheck = () => {
  const user = useRecoilValue(userState);
  const websites = useRecoilValue(websitesStateSelector);
  const [upgradeModal, setUpgradeModal] = useRecoilState(
    globalUpgradeModalState
  );

  const openUpgradeModal = (): Promise<string> => {
    return new Promise((resolve) => {
      setUpgradeModal(true);
    });
  };

  const closeUpgradeModal = () => {
    setUpgradeModal(false);
  };

  const hasFeaturePermission = (featureKey: Features): boolean => {
    if (!user.company.Subscription) return false;
    return hasFeature(user.company.Subscription, featureKey);
  };

  const checkFeaturePermission = async (
    featureKey: Features
  ): Promise<boolean> => {
    if (hasFeaturePermission(featureKey)) return true;

    setUpgradeModal(true);
    return false;
    // const selectedPackage = await openUpgradeModal();
    // if (selectedPackage !== "cancel") {
    //   return hasFeaturePermission(featureKey);
    // }
    // return false;
  };

  const checkBusinessPermission = async (): Promise<boolean> => {
    const isPermitted = (): boolean => {
      if (!user.company.Subscription) return false;
      return canCreateBusiness(user.company.Subscription, websites.length);
    };

    if (isPermitted()) return true;

    const selectedPackage = await openUpgradeModal();
    if (selectedPackage !== "cancel") {
      return isPermitted();
    }
    return false;
  };

  const isPro = () => {
    return (
      user.company.Subscription.isActive &&
      user.company.Subscription.type !== PackageType.STARTER_PACK
    );
  };

  return {
    hasFeaturePermission,
    checkFeaturePermission,
    checkBusinessPermission,
    isPro: isPro(),
    upgradeModal,
    closeUpgradeModal,
  };
};
