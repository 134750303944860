import { atomFamily } from "recoil";
import { PostEntity } from "neword-core";
import postService from "../core/services/post.service";

export const postState = atomFamily<PostEntity, string>({
  key: `postState`,
  default: (documentId: string) => postService.getPostById(documentId),
});

export const postsSummariesState = atomFamily<PostEntity[], string>({
  key: `postSummariesState`,
  default: (websiteId: string) => postService.getPostsByWebsiteId(websiteId),
});

export const lastAddedPostsIds = atomFamily<string[], string>({
  key: `lastAddedPosts`,
  default: [],
});
