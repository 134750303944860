import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { postsSummariesState } from "../state/postState";
import { useParams } from "react-router";
import styled from "styled-components";
import { ContentCardIcons } from "../components/website/overview/contentCards/iconMappings";
import { convertPostToEvent } from "../components/post/convertPostToEvent";
import Card from "../components/common/Card";
import { InputTextarea } from "primereact/inputtextarea";
import { Avatar } from "primereact/avatar";
import { userState } from "../state/userState";
import { InputText } from "primereact/inputtext";
import Button from "../components/common/form/Button";
import { Galleria } from "primereact/galleria";
import CalendarMenu from "../components/calendar/CalendarMenu";
import {
  AppIntegrationApplicationType,
  AttachmentMedia,
  PostEntityType,
} from "neword-core";
import postService from "../core/services/post.service";

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 30%;
  max-width: 1000px;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;
`;

const PostCard = styled.div`
  display: flex;
  flex-direction: column;

  gap: 20px;
`;

const PostContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 00;
  }
  &:hover::after {
    content: "";

    opacity: 0.5;
  }
`;

const PostImage = styled.img`
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
`;

const GalleriaStyled = styled(Galleria)`
  ul {
    line-height: 0;
  }
  .p-galleria-indicator button {
    width: 0.5rem !important;
    height: 0.1rem !important;
  }
  .p-galleria-indicators {
    margin-top: -15px;
    padding-bottom: 10px !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    z-index: 9999;
  }

  .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: white;
  }
`;

const PostText = styled.div``;

const GridView: React.FC = () => {
  const { websiteId } = useParams();
  const [posts, setPosts] = useRecoilState(
    postsSummariesState(websiteId as string)
  );
  const [showModal, setShowModal] = useState(false);
  const [comment, setComment] = useState("");
  const [filterPostType, setFilterPostType] = useState<
    (AppIntegrationApplicationType | null)[]
  >([AppIntegrationApplicationType.INSTAGRAM]);
  const [expandedCommentIndex, setExpandedCommentIndex] = useState<
    number | null
  >(null);

  const user = useRecoilValue(userState);

  const toggleModal = () => setShowModal((prev) => !prev);

  const events = posts
    .filter(
      (post) =>
        filterPostType.length === 0 ||
        filterPostType.includes(post.appIntegrationApplicationType || null)
    )
    .map((post) => convertPostToEvent(post))
    .reverse();

  const handleBlur = (index: number) => {
    if (expandedCommentIndex === index) {
      setExpandedCommentIndex(null);
    }
  };

  const addComment = async (content: string, postId: string) => {
    if (content.trim() === "") {
      return;
    }
    const post = posts.find((post) => post.id === postId);
    setPosts(
      posts.map((p) => {
        if (p.id === postId) {
          return {
            ...p,
            comments: [
              {
                content,
                user: user,
                createdAt: new Date(),
                updatedAt: new Date(),
                id: Math.random().toString(),
              },
              ...(p.comments ?? []),
            ],
          };
        }
        return p;
      })
    );
    await postService.addComment(postId, content, websiteId as string);
    setComment("");
  };

  return (
    <>
      <CalendarMenu
        setFilterPostType={setFilterPostType}
        filterPostType={filterPostType}
        toggleModal={toggleModal}
        singleFilterSelect
      />
      <PageWrapper>
        <ContentWrapper>
          {/**
           * Our new Grid-based layout wrapper
           */}
          <GridWrapper>
            {events.map((event, index) => (
              <div key={event.id}>
                {/* Each grid cell can contain both the post card and its comment section */}
                <PostCard>
                  <PostContentWrapper>
                    {/* <TopWrapper>
                        <TimeWrapper>
                            {event.date.toLocaleDateString()}{" "}
                            {event.date.toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                            })}
                        </TimeWrapper>
                      <PlatformsWrapper>
                        {event.platforms.map((p) => p)}
                      </PlatformsWrapper>
                    </TopWrapper> */}

                    {event.imageUrls && event.imageUrls.length > 0 ? (
                      <GalleriaStyled
                        value={event.imageUrls}
                        numVisible={1}
                        showThumbnails={false}
                        showIndicators
                        item={(item: AttachmentMedia) => {
                          // 1) Log out the entire metadata to the console
                          console.log("Item metadata:", item.metadata);
                          console.log("Item metadata:", item.url);

                          // 2) Check for video or image
                          if (item.metadata.type === "VIDEO") {
                            return (
                              <video
                                src={item.url}
                                autoPlay
                                loop
                                muted
                                style={{
                                  width: "100%",
                                  height: "auto",
                                  aspectRatio: "1 / 1",
                                  objectFit: "cover",
                                  // your styles
                                }}
                              />
                            );
                          } else {
                            return (
                              <>
                                <PostImage className="fuk" src={item.url} />
                                {/* <small>{item.url}</small> */}
                              </>
                            );
                          }
                        }}
                      />
                    ) : null}

                    {/* <PostText>{event.event}</PostText> */}
                  </PostContentWrapper>
                </PostCard>
              </div>
            ))}
          </GridWrapper>
        </ContentWrapper>
      </PageWrapper>
    </>
  );
};

export default GridView;
