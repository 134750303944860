import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import ModalHeader from "../modals/ModalHeader";
import { InputTextarea } from "primereact/inputtextarea";
import { ContentCardIcons } from "../website/overview/contentCards/iconMappings";
import { Divider } from "primereact/divider";
import { useCallback, useMemo, useRef, useState } from "react";
import Button from "../common/form/Button";
import AddPlatformDropDown from "../AddPlatformDropDown";
import IgPreview from "./postPreviews/IgPreview";
import { Calendar } from "primereact/calendar";
import { Nullable } from "primereact/ts-helpers";
import ClockSVG from "../common/ClockSVG";
import { addLocale } from "primereact/api";
import { ReactComponent as CancelIcon } from "../../assets/Icons/Times.svg";
import { ReactComponent as ErrorIcon } from "../../assets/Icons/Error.svg";
import { ReactComponent as ApprovedIcon } from "../../assets/Icons/Approved.svg";
import { ReactComponent as RejectedIcon } from "../../assets/Icons/Rejected.svg";
import { PostEntity, PostEntityType, PostStatusType } from "neword-core";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useController,
  useFieldArray,
  useForm,
} from "react-hook-form";
import AttachmentInput from "../common/form/AttachmentInput";
import FbPreview from "./postPreviews/fbPreview";
import { postTypeToForm } from "./forms/postTypeToForm";
import postService from "../../core/services/post.service";
import { useParams } from "react-router";
import { Menu } from "primereact/menu";
import { Tooltip } from "primereact/tooltip";
import { ReactComponent as TrashIcon } from "../../assets/Icons/Trash.svg";
import { useRecoilState, useRecoilValue } from "recoil";
import { postState, postsSummariesState } from "../../state/postState";
import DateInput from "./PostDateInput";
import PostDateInput from "./PostDateInput";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import { fill } from "lodash";
import Badge from "../common/Badge";
import { Color } from "../../core/theme/types/color";
import { Theme } from "../../core/theme/theme";
import BadgeDropDown from "../common/form/BadgeDropDown";
import { appIntegrationState } from "../../state/appIntegrationState";
import { postTypeToappIntegrationApplicationType } from "./postTypeToappIntegrationApplicationType";
import Card from "../common/Card";
import { InputText } from "primereact/inputtext";
import { Avatar } from "primereact/avatar";
import { useTranslation } from "react-i18next";

const DialogStyled = styled(Dialog)`
  .p-dialog-content {
    padding: 0px;
    overflow: visible;
  }

  .p-dialog-header {
    padding: 0px !important;
  }

  .p-dialog-footer {
    position: absolute;
    padding: 10px !important;
    border: solid 1px var(--border-color);
    right: 10px;
    /* border: solid 1px var(--title-color); */
    /* width: 100%; */
    bottom: 10px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
`;

interface Props {
  post: PostEntity;
  onHide: () => void;
}

const PlatformsWrapper = styled.div`
  border-right: 1px solid var(--border-color);
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding-top: 20px;
  height: 100%;
`;

const RemoveSocial = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 15px;
  height: 15px;
  background: var(--title-color);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  opacity: 0;
  pointer-events: none;
`;

const PlatformIconWrapper = styled.div<{
  backgroundColor?: string;
  active?: boolean;
  dashed?: boolean;
  iconColor?: string;
}>`
  &:hover ${RemoveSocial} {
    opacity: 1;
    pointer-events: all;
  }
  position: relative;
  cursor: pointer;
  width: 40px;
  height: 40px;

  border-radius: 6px;
  background: ${(props) =>
    props.active ? props.backgroundColor : "var(--border-color)"};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  border: ${(props) => (props.dashed ? "1px dashed lightgrey" : "none")};
  svg {
    width: 20px;
    height: 20px;
    fill: ${(props) =>
      props.iconColor ? props.iconColor : "white !important"};

    path {
      fill: ${(props) =>
        props.iconColor ? props.iconColor : "white !important"};
    }
  }
`;

const BadgeStyled = styled(Badge)`
  svg {
    width: 8px !important;
    height: 8px !important;
  }
`;

const AddSocialButton = styled.div`
  cursor: pointer;

  &:hover {
    background: var(--light-bg);
  }
`;

const PreviewPostModal: React.FC<Props> = ({ onHide, post }) => {
  const addSocialMenu = useRef<Menu>(null);
  const { websiteId } = useParams();
  const { t } = useTranslation();

  const [_, setPosts] = useRecoilState(
    postsSummariesState(websiteId as string)
  );

  const appIntegrations = useRecoilValue(
    appIntegrationState(websiteId as string)
  );
  const methods = useForm<PostEntity>({
    defaultValues: { ...post, scheduleDate: new Date(post.scheduleDate) },
  });

  const FormComponent = postTypeToForm[methods.getValues("type")];

  const handleSubmit: SubmitHandler<PostEntity> = async (values) => {
    postService
      .updatePost(values)
      .then(() =>
        setPosts((currentPosts) =>
          currentPosts.map((p) => (p.id === post.id ? { ...p, ...values } : p))
        )
      );

    onHide();
  };

  const getBadgeColor = useCallback(
    (status: PostStatusType): { color: Color; text: string } => {
      switch (status) {
        case PostStatusType.PUBLISHED:
          return { color: "purpleOpacity", text: "פורסם" }; // Make sure the return value matches keyof ThemeType["colors"]
        case PostStatusType.PENDING:
          return { color: "yellow", text: "ממתין לאישור" }; // Change to a valid key like "yellow"
        case PostStatusType.APPROVED:
          return { color: "success", text: "אושר" }; // Update this as per the keys available
        case PostStatusType.REJECTED:
          return { color: "danger", text: "נדחה" }; // This should match exactly one of the keys in colors
        default:
          return { color: "title", text: "פורסם" }; // This should be one of the valid keys as well
      }
    },
    []
  );

  const onDelet = async () => {
    await postService.deletePost(post.id);
    toast(t("preview_post_modal.toast.post_deleted"));
    setPosts((prev) => prev.filter((p) => p.id != post.id));
    onHide();
  };

  let menuitems = Object.entries(ContentCardIcons)
    .filter(([key, value]) => !!value.iconPlain) // Filter out entries without iconPlain
    .map(([key, value]) => ({
      template: () => (
        <AddSocialButton className="p-3 flex gap-10  align-items-center">
          <PlatformIconWrapper backgroundColor={value.color} active>
            {value.iconPlain && <value.iconPlain />}
          </PlatformIconWrapper>
          {value.socialPostingText && value.socialPostingText}
        </AddSocialButton>
      ),
    }));

  const isMissingIntegration = useMemo(
    () =>
      appIntegrations.find(
        (integration) =>
          integration.appIntegrationApplicationType ===
            postTypeToappIntegrationApplicationType(
              post.type as PostEntityType
            ) && integration.isEnabled
      ),
    [appIntegrations]
  );

  return (
    <FormProvider {...methods}>
      <form>
        <DialogStyled
          header={<ModalHeader OnClose={onHide} downAbit />}
          closable={false}
          visible
          position={"center"}
          style={{
            width: "80vw",
            margin: "0",
            boxShadow: "none",
            borderLeft: "solid 1px var(--border-color)",
            borderRadius: "0px",
            maxHeight: "100%",
            padding: "0",
          }}
          onHide={onHide}
          draggable={false}
          resizable={false}
          footer={
            <div className="flex gap-10">
              <div className="flex align-items-center gap-10">
                <Button
                  onClick={methods.handleSubmit(handleSubmit)}
                  bgColor="purple"
                  primary
                  className="mb-0"
                >
                  {t("preview_post_modal.buttons.save_changes")}
                </Button>
                <div className=" gap-10 flex">
                  <PostDateInput />
                  <div className="flex gap-10 align-items-center">
                    <strong>{t("preview_post_modal.status_label")}</strong>
                    <Tooltip target=".fail-icon" />
                    {post.status === PostStatusType.PUBLISH_FAILED && (
                      <BadgeStyled
                        bgColor="title"
                        textColor="white"
                        className="fail-icon"
                        data-pr-tooltip={t(
                          "preview_post_modal.tooltips.publish_failed"
                        )}
                        data-pr-position="top"
                        icon={<ErrorIcon />}
                      >
                        {t("preview_post_modal.status.publish_failed")}
                      </BadgeStyled>
                    )}
                    <Tooltip target=".publish-icon" />
                    {post.status === PostStatusType.PUBLISHED && (
                      <BadgeStyled
                        bgColor="purple"
                        textColor="white"
                        className="publish-icon"
                        data-pr-tooltip={t(
                          "preview_post_modal.tooltips.published"
                        )}
                        data-pr-position="top"
                        icon={<ApprovedIcon />}
                      >
                        {t("preview_post_modal.status.published")}
                      </BadgeStyled>
                    )}
                    {post.status === PostStatusType.APPROVED && (
                      <BadgeStyled
                        bgColor="success"
                        textColor="white"
                        className="approved-icon"
                        data-pr-tooltip={t(
                          "preview_post_modal.tooltips.approved"
                        )}
                        data-pr-position="top"
                        icon={<ApprovedIcon />}
                      >
                        {t("preview_post_modal.status.approved")}
                      </BadgeStyled>
                    )}

                    <Tooltip target=".error-icon" />
                    {post.status === PostStatusType.PENDING && (
                      <BadgeStyled
                        bgColor="yellow"
                        textColor="title"
                        className="error-icon"
                        data-pr-tooltip={t(
                          "preview_post_modal.tooltips.pending"
                        )}
                        data-pr-position="top"
                        icon={<ErrorIcon />}
                      >
                        {t("preview_post_modal.status.pending")}
                      </BadgeStyled>
                    )}
                    <Tooltip target=".cancel-icon" />
                    {post.status === PostStatusType.REJECTED && (
                      <BadgeStyled
                        bgColor="danger"
                        textColor="white"
                        className="cancel-icon"
                        data-pr-tooltip={t(
                          "preview_post_modal.tooltips.rejected"
                        )}
                        data-pr-position="top"
                        icon={<RejectedIcon />}
                      >
                        {t("common.cancelled")}
                      </BadgeStyled>
                    )}
                  </div>
                </div>
                <Button
                  icon={<TrashIcon style={{ fill: "red" }} />}
                  onClick={onDelet}
                  bgColor="transparent"
                  textColor="danger"
                  className="mb-0 mr-2"
                >
                  {/* {t("preview_post_modal.buttons.delete_post")} */}
                </Button>

                {/* {!isMissingIntegration && <span>שים לב אין אינטגרציה </span>} */}
                <div>|</div>
                <Button
                  icon={
                    post.status === PostStatusType.APPROVED ? (
                      <CancelIcon style={{ fill: "red" }} />
                    ) : (
                      <ApprovedIcon style={{ fill: "green" }} />
                    )
                  }
                  onClick={() => {
                    const newStatus =
                      post.status === PostStatusType.APPROVED
                        ? PostStatusType.REJECTED // Assuming "CANCELLED" is a valid status.
                        : PostStatusType.APPROVED;

                    postService
                      .updatePostStatus(post.id, newStatus)
                      .then((newPost) => {
                        setPosts((oldPosts) =>
                          oldPosts.map((p) => (p.id === post.id ? newPost : p))
                        );
                        toast.success(
                          newStatus === PostStatusType.APPROVED
                            ? t(
                                "preview_post_modal.toast.toast_post_approved_success"
                              )
                            : t(
                                "preview_post_modal.toast.toast_post_rejected_success"
                              )
                        );
                      })
                      .catch((error) => {
                        toast.error(
                          t("preview_post_modal.toast.post_update_error")
                        );
                        console.error(error);
                      });
                  }}
                  bgColor="transparent"
                  textColor={
                    post.status === PostStatusType.APPROVED
                      ? "danger"
                      : "success"
                  }
                  className="mb-0 mr-2"
                >
                  {/* {post.status === PostStatusType.APPROVED
                    ? t("preview_post_modal.buttons.reject_post")
                    : t("preview_post_modal.buttons.approve_post")} */}
                </Button>

                <div className="flex gap-10 align-items-center"></div>
              </div>
            </div>
          }
        >
          <div className="flex m-0 h-full">
            <Tooltip target=".remove-icon" />
            <Menu model={menuitems} popup ref={addSocialMenu} id="socialmenu" />
            <Wrapper>
              {FormComponent ? (
                <>
                  <FormComponent name="data" />
                </>
              ) : (
                <div>{t("preview_post_modal.form_component.no_form")}</div>
              )}
            </Wrapper>
          </div>
        </DialogStyled>
      </form>
    </FormProvider>
  );
};

export default PreviewPostModal;
