// ListView.tsx
import React, { useState } from "react";
import styled from "styled-components";
import TaskCard from "./TaskCard";
import { useDrop } from "react-dnd";
import { Task } from "neword-core";

const StatusCircle = styled.div<{ status: string }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${({ status }) => {
    switch (status) {
      case "backlog":
        return "#6C757D"; // Gray for draft
      case "inprogress":
        return "#FFC107"; // Yellow for pending
      case "review":
        return "#007BFF"; // Blue for waiting for approval
      case "done":
        return "#28A745"; // Green for completed
      default:
        return "#6C757D";
    }
  }};
`;

const AccordionContainer = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: #f4f5f7;
  overflow-y: auto;
`;

const AccordionSection = styled.div`
  margin-bottom: 1rem;
  border: 1px solid #dfe1e6;
  border-radius: 4px;
`;

const AccordionHeader = styled.div`
  background-color: #ebecf0;
  padding: 0.75rem;
  font-size: 1.25rem;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
`;

const AccordionContent = styled.div`
  padding: 0.5rem;
`;

interface ListAccordionProps {
  columnId: string;
  title: string;
  tasks: Task[];
  moveTask: (
    taskId: string,
    fromColumnId: string,
    toColumnId: string,
    toIndex: number
  ) => Promise<void>;
  onTaskClick?: (task: Task) => void;
}

const ListAccordion: React.FC<ListAccordionProps> = ({
  columnId,
  title,
  tasks,
  moveTask,
  onTaskClick,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen((prev) => !prev);
  };

  // Create a drop area for tasks.
  const [{ isOver }, dropRef] = useDrop<
    { taskId: string; fromColumnId: string },
    void,
    { isOver: boolean }
  >({
    accept: "TASK",
    drop: (item, monitor) => {
      if (!monitor.didDrop()) {
        // Drop at the end of the list.
        moveTask(item.taskId, item.fromColumnId, columnId, tasks.length);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <AccordionSection>
      <AccordionHeader onClick={toggleAccordion}>
        <StatusCircle status={columnId} />
        {title}
      </AccordionHeader>
      {isOpen && (
        <AccordionContent
          ref={dropRef}
          style={{
            backgroundColor: isOver ? "#f0f5ff" : "inherit",
            transition: "background-color 0.2s ease",
            padding: isOver ? "8px" : "0.5rem",
            border: isOver ? "1px dashed #3880ff" : "none",
            borderRadius: "4px",
          }}
        >
          {tasks.map((task, index) => (
            <TaskCard
              key={task.id}
              task={task}
              onClick={onTaskClick}
              columnId={columnId}
              index={index}
              moveTask={moveTask}
            />
          ))}
          {isOver && tasks.length === 0 && (
            <div
              style={{
                border: "2px dashed #3880ff",
                borderRadius: "4px",
                padding: "1rem",
                color: "#3880ff",
                textAlign: "center",
                fontWeight: 500,
                backgroundColor: "rgba(56, 128, 255, 0.05)",
              }}
            >
              תניח כאן
            </div>
          )}
        </AccordionContent>
      )}
    </AccordionSection>
  );
};

interface ListViewProps {
  columns: { id: string; title: string; tasks: Task[] }[];
  moveTask: any;
  onTaskClick?: (task: Task) => void;
}

const ListView: React.FC<ListViewProps> = ({
  columns,
  moveTask,
  onTaskClick,
}) => {
  return (
    <AccordionContainer>
      {columns.map((column) => (
        <ListAccordion
          key={column.id}
          columnId={column.id}
          title={column.title}
          tasks={column.tasks}
          moveTask={moveTask}
          onTaskClick={onTaskClick}
        />
      ))}
    </AccordionContainer>
  );
};

export default ListView;
