import { Divider } from "primereact/divider";
import { PostEntity, PostEntityType } from "neword-core";
import { InputTextarea } from "primereact/inputtextarea";
import {
  FieldErrors,
  FieldValues,
  Path,
  useFormContext,
  useWatch,
} from "react-hook-form";
import styled from "styled-components";
// Add Facebook preview image
import { useEffect, useRef, useState } from "react";
import FbPreview from "../postPreviews/fbPreview";
import CardTitle from "../../common/CardTitle";
import MultyAttachmentInput from "../../common/form/MultyAttachmentInput";
import LinkedInPreview from "../postPreviews/LinkedInPreview";
import linkedInPostMediaValidation from "./mediaValdiation/linkedInPostMediaValidation";
import CircleBg from "../../../assets/images/bgcirclepurple.png";
import Card from "../../common/Card";
import { Message } from "primereact/message";
import { useTranslation } from "react-i18next";

const InputTextareaStyled = styled(InputTextarea)`
  outline: none !important;
  min-height: fit-content;
  height: auto;
  width: 100%;
  border: none;
  resize: none; /* Optional: prevent manual resizing */
  &::placeholder {
    color: var(--text-color);
    opacity: 0.5;
  }

  &:hover {
    background: var(--light-bg);
  }
  &:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
    background: var(--light-bg);
    border-color: none !important;
  }
`;

const LabelStyled = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: var(--text-color);
  padding-bottom: 10px;
`;

const ImageWrapper = styled.div`
  background-size: cover;
  position: relative;
  overflow: hidden;
  background-color: var(--light-bg);
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;

function LinkedinPostForm<FormDataType extends FieldValues>({
  name,
  index,
}: {
  name: Path<FormDataType>;
  index?: number;
}) {
  const {
    control,
    getValues,
    register,
    formState: { errors },
  } = useFormContext();

  const values = useWatch({ control, name: name }); // Ensure posts has a default value of an empty array
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [imageUrlError, setImageUrlError] = useState("");
  const { t } = useTranslation();

  // Function to automatically adjust textarea height
  const autoResizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  // Run auto-resize on mount and when posts change
  useEffect(() => {
    autoResizeTextarea();
  }, [values]);

  return (
    <div className="grid h-full m-0">
      <div className="col-8 p-0 pl-4 pr-4 pt-4 pb-5">
        <CardTitle title={t("linkedin_post_form.post_title")} />
        <Divider />
        <Card>
          <LabelStyled>
            {t("linkedin_post_form.post_content_label")}
          </LabelStyled>
          <InputTextareaStyled
            {...register(`${name}.message`)}
            className="w-90 pl-3"
            placeholder={t("linkedin_post_form.post_content_placeholder")}
            onInput={autoResizeTextarea}
            ref={(e) => {
              register(`${name}.message`).ref(e);
              textareaRef.current = e;
            }}
          />
          <Divider />
          <LabelStyled>{t("linkedin_post_form.add_media_label")}</LabelStyled>
          <MultyAttachmentInput
            name={`${name}.imageUrls`}
            // validate={(value) => {
            //   const errorText = linkedInPostMediaValidation(value);
            //   setImageUrlError(errorText);
            //   if (errorText === "") {
            //     return true;
            //   }
            //   return errorText;
            // }}
          />
        </Card>
        <div className="media p-2 mt-4">
          {imageUrlError != "" && (
            <Message severity="warn" text={imageUrlError} />
          )}
        </div>
      </div>
      <div className="col-4 p-0">
        <ImageWrapper className="p-4">
          <LinkedInPreview name={name} />
        </ImageWrapper>
      </div>
    </div>
  );
}

export default LinkedinPostForm;
