import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { websitesStateSelector } from "../state/websitesState";
import { useNavigate } from "react-router";
import Preloader from "../components/common/Preloader";
import { userState } from "../state/userState";
import { UserRole } from "../core/types/userRole";

const AfterLoginPage: React.FC = () => {
  const websites = useRecoilValue(websitesStateSelector);
  const user = useRecoilValue(userState);

  const navigate = useNavigate();
  useEffect(() => {
    const websiteId = localStorage.getItem("websiteId");

    const savedWebsite =
      websites.find((w) => w.id === websiteId) || websites[0];

    if (user.role === UserRole.CLIENT) {
      return navigate(`/websites/${savedWebsite.id}/calendar`);
    }

    if (!websites.length) {
      return navigate(`/websites/new?hideExit=true`);
    }

    return navigate(`/websites/${savedWebsite.id}/`);
  }, [navigate, websites]);

  return <Preloader />;
};

export default AfterLoginPage;
