// PostBlock.tsx
import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { toast } from "react-toastify";
import { PostEntity, PostStatusType } from "neword-core";
import { postsSummariesState } from "../../../../state/postState";
import postService from "../../../../core/services/post.service";
import SliderTileContent from "./SliderTileContent";
import Preloader from "../../../common/Preloader";
import PreviewPostModal from "../../../post/PreviewPostModal";
import SliderTileFooter from "./SliderTileFooter";
import SliderTileHeader from "./SliderTileHeader";

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  position: relative;

  &:hover {
    border-color: var(--primary-purple);

    .menu-container {
      pointer-events: all;
      opacity: 1;
    }
  }

  .menu-container {
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.1s;
    z-index: 9;
  }
`;

interface PostBlockProps {
  post: PostEntity;
  clientView?: boolean;
}

const SliderTile: React.FC<PostBlockProps> = ({ post, clientView = false }) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const { websiteId } = useParams();
  const navigate = useNavigate();

  const [posts, setPosts] = useRecoilState(
    postsSummariesState(websiteId as string)
  );
  const [showPreview, setShowPreview] = useState(false);
  const [isRightAligned, setIsRightAligned] = useState(false);

  useEffect(() => {
    if (cardRef.current) {
      const rect = cardRef.current.getBoundingClientRect();
      setIsRightAligned(window.innerWidth - rect.right < 400);
    }
  }, []);

  // Handler to update post status
  const updatePostStatus = (newStatus: PostStatusType) => {
    postService.updatePostStatus(post.id, newStatus).then((newPost) => {
      setPosts((oldPosts) =>
        oldPosts.map((p) => (p.id === post.id ? newPost : p))
      );
      const message =
        newStatus === PostStatusType.APPROVED
          ? "הפוסט אושר בהצלחה"
          : "הפוסט בוטל בהצלחה";
      toast.success(message);
    });
  };

  // Handler to publish post
  const publishPost = () => {
    const loadingToastId = toast.loading("Publishing post...");
    postService
      .publishPost(post.id)
      .then((newPost) => {
        setPosts((oldPosts) =>
          oldPosts.map((p) => (p.id === post.id ? newPost : p))
        );
        toast.dismiss(loadingToastId);
        toast.success("Post published successfully");
      })
      .catch(() => {
        toast.dismiss(loadingToastId);
        toast.error("Error publishing post");
      });
  };

  return (
    <CardWrapper ref={cardRef} >
      <SliderTileHeader post={post} />
      <SliderTileContent post={post} />
      <SliderTileFooter
        onApprove={() => updatePostStatus(PostStatusType.APPROVED)}
        onReject={() => updatePostStatus(PostStatusType.REJECTED)}
        post={post}
      />
      {/* {post.status !== PostStatusType.PUBLISHED && (
        <PostEventFooter
          post={post}
          onApprove={() => updateStatus(PostStatusType.APPROVED)}
          onReject={() => updateStatus(PostStatusType.REJECTED)}
          openPreview={() => setShowPreview(true)}
        />
      )} */}

      <div
        className="menu-container"
        style={{
          left: isRightAligned ? "auto" : undefined,
          right: isRightAligned ? undefined : "10px",
        }}
      ></div>

      {showPreview && (
        <React.Suspense fallback={<Preloader />}>
          <PreviewPostModal post={post} onHide={() => setShowPreview(false)} />
        </React.Suspense>
      )}
    </CardWrapper>
  );
};

export default SliderTile;
