import React, { useMemo } from "react";
import styled from "styled-components";
import { addDays, startOfWeek } from "date-fns";
import { PostEntity, PostEntityType } from "neword-core";
import DroppableTile from "./droppableTile";
import { DraggablePost } from "./draggablePost";
import CalendarTileContent from "./CalendarTileContent";
import CalendarPopularEvents from "./calendarEventTile/CalendarPopularEvents";

interface WeekViewProps {
  date: Date;
  posts: PostEntity[];
  filterPostType: PostEntityType[];
  handleEventDrop: (post: PostEntity, newDate: Date) => void;
  clientView?: boolean;
}

const WeekContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  height: calc(100vh - 150px);
  background: white;
  border-radius: 0 0 12px 12px;
  border-right: solid 1px var(--border-color);
  border-bottom: solid 1px var(--border-color);
`;

const DaysHeader = styled.div`
  display: flex;
`;

const DayHeader = styled.div`
  flex: 1;
  text-align: center;
  padding: 10px;
  background: white;
  border-bottom: 1px solid var(--border-color);
  text-transform: uppercase;
  font-family: "Open Sans", "open sans Hebrew";
  font-size: 12px;
  color: var(--text-color);
`;

// Define DayColumn with a prop to set background for past days.
const DayColumn = styled.div<{ isPast: boolean }>`
  flex: 1;
  border-left: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  padding: 5px;
  background-color: ${(props) =>
    props.isPast ? "var(--light-bg)" : "transparent"};
`;

const WeekGrid = styled.div`
  display: flex;
  flex: 1;
`;

const WeekView: React.FC<WeekViewProps> = ({
  date,
  posts,
  filterPostType,
  handleEventDrop,
  clientView,
}) => {
  // Calculate the start of the week.
  const weekStart = startOfWeek(date, { weekStartsOn: 0 });
  const weekDays = Array.from({ length: 7 }, (_, i) => addDays(weekStart, i));

  // Compute the start of today.
  const now = new Date();
  const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  // Create a mapping: day string -> sorted events for that day.
  const eventsByDay = useMemo(() => {
    const map: Record<string, PostEntity[]> = {};
    weekDays.forEach((day) => {
      const dayString = day.toDateString();
      map[dayString] = posts.filter((post) => {
        const postDate = new Date(post.scheduleDate);
        return (
          postDate.toDateString() === dayString &&
          (filterPostType.length === 0 || filterPostType.includes(post.type))
        );
      });
      // Sort events by time (ascending)
      map[dayString].sort(
        (a, b) =>
          new Date(a.scheduleDate).getTime() -
          new Date(b.scheduleDate).getTime()
      );
    });
    return map;
  }, [weekDays, posts, filterPostType]);

  return (
    <WeekContainer>
      <DaysHeader>
        {weekDays.map((day) => (
          <DayHeader key={day.toDateString()}>
            {day.toLocaleDateString("he-IL", {
              weekday: "short",
              day: "numeric",
              month: "short",
            })}
          </DayHeader>
        ))}
      </DaysHeader>
      <WeekGrid>
        {weekDays.map((day) => {
          const dayString = day.toDateString();
          // Determine if the day is in the past.
          const isPast = day < todayStart;
          return (
            <DayColumn key={dayString} isPast={isPast}>
              <CalendarPopularEvents day={day} />
              <DroppableTile date={day} onDropEvent={handleEventDrop}>
                <CalendarTileContent
                  date={day}
                  view="month"
                  posts={posts}
                  filterPostType={filterPostType}
                  clientView={clientView}
                />
              </DroppableTile>
            </DayColumn>
          );
        })}
      </WeekGrid>
    </WeekContainer>
  );
};

export default WeekView;
