import styled from "styled-components";
import { ReactComponent as CheckIcon } from "../../assets/Icons/Check.svg";
import Button from "../common/form/Button";
import StyledSwitch from "../common/form/StyledSwitch";

interface Props {
  children?: JSX.Element | JSX.Element[];
  bullets: string[];
  isCustom?: boolean;
  isFree?: boolean;
}

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const ListItem = styled.div`
  display: flex;
  gap: 10px;

  font-family: "Assistant", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.4px;

  & svg {
    fill: #0a2540;
    width: 0.81363rem;
    height: 0.81363rem;
  }
`;

const EverythingIncluded = styled.strong`
    font-family: "Assistant", Sans-serif;
    font-size: 16px;
    letter-spacing: -0.4px;
    text-decoration: underline;
`;

const TextDivider = styled.span`
display: flex;
align-items: center;
&::before, &::after {
  border-block-end: 0;
    border-block-start: 1px solid var(--dark-green);
    content: "";
    display: block;
    flex-grow: 1;
}

.itmem{
  padding:0 10px;
}
`;

const PackageIncludes: React.FC<Props> = ({ bullets, isFree, isCustom }) => {
  return (
    <ListWrapper>
      <TextDivider><span className="itmem">מה כלול בחבילה</span></TextDivider>
{!isFree && !isCustom ? (
  <EverythingIncluded>כל מה שבחינם ועוד:</EverythingIncluded>
) : isCustom ? (
  <EverythingIncluded>כל מה שבחבילת סושיאל ועוד:</EverythingIncluded>
) : null}

      {bullets.map((item, index) => (
        <ListItem key={index}>
          <div>
            <CheckIcon />
          </div>
          <div>{item}</div>
          
        </ListItem>
      ))}
    </ListWrapper>
  );
};

export default PackageIncludes;
