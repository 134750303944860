import { DocumentEntityType } from "neword-core";
import InstagramCaptionImg from "../../../assets/images/contentCardsImages/instagram_caption_card_img.png";
import InstagramBio from "../../../assets/images/contentCardsImages/Frame 209-1.png";
import StoryIdea from "../../../assets/images/contentCardsImages/Frame 209-2.png";
import ReelIdea from "../../../assets/images/contentCardsImages/Frame 209-3.png";
import FacebookCaption from "../../../assets/images/contentCardsImages/Frame 209-4.png";
import FacebookPosts from "../../../assets/images/contentCardsImages/Frame 209-5.png";
import FacebookBio from "../../../assets/images/contentCardsImages/Frame 209-6.png";
import LinkedinPost from "../../../assets/images/contentCardsImages/Frame 209-7.png";
import LinkedinPostIdeas from "../../../assets/images/contentCardsImages/Frame 209-8.png";
import LinkedinBio from "../../../assets/images/contentCardsImages/Frame 209-10.png";
import Email from "../../../assets/images/contentCardsImages/Frame 209-11.png";
import Threads from "../../../assets/images/contentCardsImages/Frame 209-20.png";
import SMS from "../../../assets/images/contentCardsImages/Frame 209-30.png";
import Twitter from "../../../assets/images/contentCardsImages/Frame 209-40.png";
import MultiInsta from "../../../assets/images/contentCardsImages/multiinsta.png";
import Keywords from "../../../assets/images/contentCardsImages/Frame 209 (13).png";
import WebAbout from "../../../assets/images/contentCardsImages/Frame11 209-1.png";
import WebRec from "../../../assets/images/contentCardsImages/Frame11 209-2.png";
import ProdAbout from "../../../assets/images/contentCardsImages/Frame11 209-3.png";
import Faq from "../../../assets/images/contentCardsImages/Frame11 209.png";
import Blogpost_opinion from "../../../assets/images/contentCardsImages/blogpost_opinion.png";
import Blogpost_tips from "../../../assets/images/contentCardsImages/blogpost_tips.png";
import Blogpost_case from "../../../assets/images/contentCardsImages/blogpost_case.png";
import Blogpost_vs from "../../../assets/images/contentCardsImages/blogpost_vs.png";
import CampaignBG from "../../../assets/images/contentCardsImages/campaignBG.png";
import Blogpost_intro from "../../../assets/images/contentCardsImages/blogpost_intro.png";
import Blogpost_post from "../../../assets/images/contentCardsImages/blogpost_post.png";
import Serachengine from "../../../assets/images/contentCardsImages/searchengine.png";
import LinkedinAd from "../../../assets/images/contentCardsImages/LinkedinAd.png";
import InstaAd from "../../../assets/images/contentCardsImages/insta_ad.png";
import { ContentCardType } from "../../website/overview/contentCards/cardTypes";
import { useTranslation } from "react-i18next";

export const useDocBoxes = (): {
  type: DocumentEntityType;
  title: string;
  image: string;
  description: string;
  platformType: ContentCardType;
}[] => {
  const { t } = useTranslation();
  return [
    {
      type: DocumentEntityType.GENERIC_CAMPAIGN,
      title: t("document_types.generic_campaign.title"),
      image: CampaignBG,
      description: t("document_types.generic_campaign.description"),
      platformType: ContentCardType.CAMPAIGN,
    },
    {
      type: DocumentEntityType.INSTAGRAM_BIO_DESCRIPTION,
      title: t("document_types.instagram_bio.title"),
      image: InstagramBio,
      description: t("document_types.instagram_bio.description"),
      platformType: ContentCardType.INSTAGRAM,
    },
    {
      type: DocumentEntityType.INSTAGRAM_CAPTION,
      title: t("document_types.instagram_caption.title"),
      image: InstagramCaptionImg,
      description: t("document_types.instagram_caption.description"),
      platformType: ContentCardType.INSTAGRAM,
    },
    // {
    //   type: DocumentEntityType.INSTAGRAM_POST_IDEA,
    //   title: t("document_types.instagram_post_idea.title"),
    //   image: MultiInsta,
    //   description: t("document_types.instagram_post_idea.description"),
    //   platformType: ContentCardType.INSTAGRAM,
    // },
    // {
    //   type: DocumentEntityType.INSTAGRAM_REEL_IDEA,
    //   title: t("document_types.instagram_reel_idea.title"),
    //   image: ReelIdea,
    //   description: t("document_types.instagram_reel_idea.description"),
    //   platformType: ContentCardType.INSTAGRAM,
    // },
    // {
    //   type: DocumentEntityType.INSTAGRAM_STORY_IDEA,
    //   title: t("document_types.instagram_story_idea.title"),
    //   image: StoryIdea,
    //   description: t("document_types.instagram_story_idea.description"),
    //   platformType: ContentCardType.INSTAGRAM,
    // },
    {
      type: DocumentEntityType.FACEBOOK_PAGE_ABOUT,
      title: t("document_types.facebook_page_about.title"),
      image: FacebookBio,
      description: t("document_types.facebook_page_about.description"),
      platformType: ContentCardType.FACEBOOK,
    },
    {
      type: DocumentEntityType.FACEBOOK_POST_CAPTION,
      title: t("document_types.facebook_post_caption.title"),
      image: FacebookCaption,
      description: t("document_types.facebook_post_caption.description"),
      platformType: ContentCardType.FACEBOOK,
    },
    // {
    //   type: DocumentEntityType.FACEBOOK_POST_IDEA,
    //   title: t("document_types.facebook_post_idea.title"),
    //   image: FacebookPosts,
    //   description: t("document_types.facebook_post_idea.description"),
    //   platformType: ContentCardType.FACEBOOK,
    // },
    {
      type: DocumentEntityType.GOOGLE_ADS_KEYWORDS,
      title: t("document_types.google_ads_keywords.title"),
      image: Keywords,
      description: t("document_types.google_ads_keywords.description"),
      platformType: ContentCardType.GOOGLE,
    },
    {
      type: DocumentEntityType.LINKEDIN_PAGE_ABOUT,
      title: t("document_types.linkedin_page_about.title"),
      image: LinkedinBio,
      description: t("document_types.linkedin_page_about.description"),
      platformType: ContentCardType.LINKEDIN,
    },
    {
      type: DocumentEntityType.LINKEDIN_POST_CAPTION,
      title: t("document_types.linkedin_post_caption.title"),
      image: LinkedinPost,
      description: t("document_types.linkedin_post_caption.description"),
      platformType: ContentCardType.LINKEDIN,
    },
    // {
    //   type: DocumentEntityType.LINKEDIN_POST_IDEA,
    //   title: t("document_types.linkedin_post_idea.title"),
    //   image: LinkedinPostIdeas,
    //   description: t("document_types.linkedin_post_idea.description"),
    //   platformType: ContentCardType.LINKEDIN,
    // },
    {
      type: DocumentEntityType.SMS_CONTENT,
      title: t("document_types.sms_content.title"),
      image: SMS,
      description: t("document_types.sms_content.description"),
      platformType: ContentCardType.SMS,
    },
    {
      type: DocumentEntityType.FLASH_SALE_SMS,
      title: t("document_types.flash_sale_sms.title"),
      image: SMS,
      description: t("document_types.flash_sale_sms.description"),
      platformType: ContentCardType.SMS,
    },
    {
      type: DocumentEntityType.NOTIFICATION_SMS,
      title: t("document_types.notification_sms.title"),
      image: SMS,
      description: t("document_types.notification_sms.description"),
      platformType: ContentCardType.SMS,
    },
    {
      type: DocumentEntityType.PUSH_NOTIFICATION,
      title: t("document_types.push_notification.title"),
      image: SMS,
      description: t("document_types.push_notification.description"),
      platformType: ContentCardType.SMS,
    },
    {
      type: DocumentEntityType.THREADS_TWIT,
      title: t("document_types.threads_twit.title"),
      image: Threads,
      description: t("document_types.threads_twit.description"),
      platformType: ContentCardType.TWITTER,
    },
    {
      type: DocumentEntityType.TWITTER_TWIT,
      title: t("document_types.twitter_twit.title"),
      image: Twitter,
      description: t("document_types.twitter_twit.description"),
      platformType: ContentCardType.THREADS,
    },
    {
      type: DocumentEntityType.WEBSITE_ABOUT,
      title: t("document_types.website_about.title"),
      image: WebAbout,
      description: t("document_types.website_about.description"),
      platformType: ContentCardType.WEB,
    },
    {
      type: DocumentEntityType.WEBSITE_FAQ,
      title: t("document_types.website_faq.title"),
      image: Faq,
      description: t("document_types.website_faq.description"),
      platformType: ContentCardType.WEB,
    },
    {
      type: DocumentEntityType.WEBSITE_PRODUCT_DESCRIPTION,
      title: t("document_types.website_product_description.title"),
      image: ProdAbout,
      description: t("document_types.website_product_description.description"),
      platformType: ContentCardType.WEB,
    },
    {
      type: DocumentEntityType.WEBSITE_TESTEMONIAL,
      title: t("document_types.website_testimonial.title"),
      image: WebRec,
      description: t("document_types.website_testimonial.description"),
      platformType: ContentCardType.WEB,
    },
    {
      type: DocumentEntityType.ARTICLE,
      title: t("document_types.article.title"),
      image: Blogpost_post,
      description: t("document_types.article.description"),
      platformType: ContentCardType.WEB,
    },
    {
      type: DocumentEntityType.BLOG_POST_OPINION,
      title: t("document_types.blog_post_opinion.title"),
      image: Blogpost_opinion,
      description: t("document_types.blog_post_opinion.description"),
      platformType: ContentCardType.WEB,
    },
    {
      type: DocumentEntityType.BLOG_POST_TIPS,
      title: t("document_types.blog_post_tips.title"),
      image: Blogpost_tips,
      description: t("document_types.blog_post_tips.description"),
      platformType: ContentCardType.WEB,
    },
    {
      type: DocumentEntityType.BLOG_POST_GUIDE,
      title: t("document_types.blog_post_guide.title"),
      image: Blogpost_intro,
      description: t("document_types.blog_post_guide.description"),
      platformType: ContentCardType.WEB,
    },
    {
      type: DocumentEntityType.BLOG_POST_COMPARISON,
      title: t("document_types.blog_post_comparison.title"),
      image: Blogpost_vs,
      description: t("document_types.blog_post_comparison.description"),
      platformType: ContentCardType.WEB,
    },
    {
      type: DocumentEntityType.BLOG_POST_CASE_STUDY,
      title: t("document_types.blog_post_case_study.title"),
      image: Blogpost_case,
      description: t("document_types.blog_post_case_study.description"),
      platformType: ContentCardType.WEB,
    },
    {
      type: DocumentEntityType.EMAIL_CONTENT,
      title: t("document_types.email_content.title"),
      image: Email,
      description: t("document_types.email_content.description"),
      platformType: ContentCardType.EMAIL,
    },
    {
      type: DocumentEntityType.CART_ABANDONMENT_EMAIL,
      title: t("document_types.cart_abandonment_email.title"),
      image: Email,
      description: t("document_types.cart_abandonment_email.description"),
      platformType: ContentCardType.EMAIL,
    },
    {
      type: DocumentEntityType.TRAFFIC_EMAIL,
      title: t("document_types.traffic_email.title"),
      image: Email,
      description: t("document_types.traffic_email.description"),
      platformType: ContentCardType.EMAIL,
    },
    {
      type: DocumentEntityType.NEWSLETTER_EMAIL,
      title: t("document_types.newsletter_email.title"),
      image: Email,
      description: t("document_types.newsletter_email.description"),
      platformType: ContentCardType.EMAIL,
    },
    {
      type: DocumentEntityType.PRODUCT_UPDATE_EMAIL,
      title: t("document_types.product_update_email.title"),
      image: Email,
      description: t("document_types.product_update_email.description"),
      platformType: ContentCardType.EMAIL,
    },
    {
      type: DocumentEntityType.WELCOME_EMAIL,
      title: t("document_types.welcome_email.title"),
      image: Email,
      description: t("document_types.welcome_email.description"),
      platformType: ContentCardType.EMAIL,
    },
    {
      type: DocumentEntityType.INSTAGRAM_SPONSORED_AD,
      title: t("document_types.instagram_sponsored_ad.title"),
      image: InstaAd,
      description: t("document_types.instagram_sponsored_ad.description"),
      platformType: ContentCardType.INSTAGRAM,
    },
    {
      type: DocumentEntityType.LINKEDIN_SPONSORED_AD,
      title: t("document_types.linkedin_sponsored_ad.title"),
      image: LinkedinAd,
      description: t("document_types.linkedin_sponsored_ad.description"),
      platformType: ContentCardType.LINKEDIN,
    },
    {
      type: DocumentEntityType.FACEBOOK_SPONSORED_AD,
      title: t("document_types.facebook_sponsored_ad.title"),
      image: FacebookCaption,
      description: t("document_types.facebook_sponsored_ad.description"),
      platformType: ContentCardType.FACEBOOK,
    },
    {
      type: DocumentEntityType.SEARCH_AD,
      title: t("document_types.search_ad.title"),
      image: Serachengine,
      description: t("document_types.search_ad.description"),
      platformType: ContentCardType.GOOGLE,
    },
  ];
};
