import React, { useState } from "react";
import { Features } from "neword-core";
import { usePermissionCheck } from "../hooks/usePermission";

interface FeatureProtectedPageProps {
  featureKey: Features;
  // Content to display as a preview if the user lacks the feature.
  previewComponent?: React.ReactNode;
  // The protected content to render if the user has permission.
  children: React.ReactNode;
}

const FeatureProtectedPage: React.FC<FeatureProtectedPageProps> = ({
  featureKey,
  previewComponent,
  children,
}) => {
  const { hasFeaturePermission, checkFeaturePermission } = usePermissionCheck();

  // Initialize permission using the synchronous function.
  const [hasPermission, setHasPermission] = useState<boolean>(
    hasFeaturePermission(featureKey)
  );

  const handleUpgradeClick = async () => {
    const permitted = await checkFeaturePermission(featureKey);
    setHasPermission(permitted);
  };

  if (hasPermission) {
    return <>{children}</>;
  }

  return (
    <>
      {previewComponent || (
        <div>
          <p>This feature is not available with your current package.</p>
          <button onClick={handleUpgradeClick}>Upgrade to unlock</button>
        </div>
      )}
    </>
  );
};

export default FeatureProtectedPage;
