import React, { useRef, useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { Menu, MenuItem, IconButton } from "@mui/material";
import TodoIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import PersonIcon from "@mui/icons-material/Person";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TagIcon from "@mui/icons-material/LocalOffer";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "../common/form/Button";
import FormStyle from "../common/form/FormStyle";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import {
  useForm,
  Controller,
  useFieldArray,
  useController,
} from "react-hook-form";
import { Task } from "neword-core";
import tasksService from "../../core/services/tasks.service";
import { useRecoilState, useRecoilValue } from "recoil";
import { usersState } from "../../state/userState";
import { User } from "../../core/entities/user";
import { taskState } from "../../state/taskState";

// ========== STYLED COMPONENTS ==========

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Dialog = styled.div`
  background: #fff;
  border-radius: 8px;
  width: 700px;
  max-width: 95%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
`;

const DialogHeader = styled.div`
  padding: 0 1rem 0 0;
  border-bottom: 1px solid #e1e4e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderTitle = styled.h2`
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
`;

const CloseButton = styled(IconButton)`
  && {
    color: #586069;
  }
`;

const DialogBody = styled.div`
  padding: 1rem;
  flex: 1;
  overflow-y: auto;
`;

const FormContainer = styled(FormStyle)`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: 600;
  margin-bottom: 0.3rem;
  font-size: 0.85rem;
  color: #333;
`;

const Input = styled(InputText)`
  padding: 0.55rem 0.75rem;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
`;

const Textarea = styled(InputTextarea)`
  padding: 0.6rem;
  border: 1px solid #dcdcdc;
  resize: vertical;
`;

const SubtaskItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.4rem;
`;

const SubtaskInput = styled.input`
  flex: 1;
  padding: 0.4rem;
  font-size: 0.9rem;
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  border-radius: 0px !important;
  margin-top: 0 !important;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
`;

const ButtonStyled = styled(Button)<{ primary?: boolean }>`
  padding: 0.6rem 1.4rem;
  font-size: 12px;
  background: var(--light-bg);
  svg {
    margin-left: 5px;
    width: 14px;
    height: 14px;
  }
  &:hover {
    background: var(--bg-dark);
  }
`;

const ToolbarRow = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  align-items: center;
`;

// ========== MAIN COMPONENT ==========

interface CreateTaskDialogProps {
  onClose: () => void;
}

const CreateTaskDialog: React.FC<CreateTaskDialogProps> = ({ onClose }) => {
  const users = useRecoilValue(usersState);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [activeMenu, setActiveMenu] = useState<
    "todo" | "priority" | "assignee" | "due" | "tags" | null
  >(null);
  const [tags, setTags] = useState<string[]>([]);
  const [todo, setTodo] = useState("");
  const [priority, setPriority] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tasks, setTasks] = useRecoilState(taskState);

  const { control, register, handleSubmit, reset } = useForm<Task>({
    defaultValues: {
      title: "",
      description: "",
      checklist: [{ text: "", completed: false }],
      status: "PENDING",
      deadline: new Date(),
      priority: "MEDIUM",
    },
  });

  const { field } = useController({
    control,
    name: "assigneeUserId",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "checklist",
  });

  const onSubmit = async (data: Task) => {
    setIsLoading(true);

    try {
      const task = await tasksService.createTask({
        ...data,
        status: "PENDING",
      });

      setTasks((prev) => [...prev, task]);
      onClose();
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const openMenu = (
    menuKey: typeof activeMenu,
    event: React.MouseEvent<HTMLElement>
  ) => {
    setMenuAnchorEl(event.currentTarget);
    setActiveMenu(menuKey);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
    setActiveMenu(null);
  };

  const selectOption = (
    field: "tags" | "todo" | "priority" | "assignee" | "dueDate",
    value: string
  ) => {
    if (field === "tags") {
      setTags((prev) => (prev.includes(value) ? prev : [...prev, value]));
    } else {
      field === "todo" && setTodo(value);
      field === "priority" && setPriority(value);
      field === "dueDate" && setDueDate(value);
    }
    closeMenu();
  };

  const assignee = useMemo(() => {
    return users.find((user) => user.id === field.value);
  }, [field.value, users]);

  return (
    <Overlay>
      <Dialog>
        <DialogHeader>
          <HeaderTitle>יצירת משימה חדשה</HeaderTitle>
          <CloseButton onClick={onClose} size="large">
            <CloseIcon />
          </CloseButton>
        </DialogHeader>

        <DialogBody>
          <ToolbarRow>
            <ButtonStyled onClick={(e) => openMenu("todo", e)}>
              <TodoIcon />
              <span>סטטוס {todo && `(${todo})`}</span>
            </ButtonStyled>

            <ButtonStyled onClick={(e) => openMenu("priority", e)}>
              <PriorityHighIcon />
              <span>עדיפות {priority && `(${priority})`}</span>
            </ButtonStyled>

            <ButtonStyled onClick={(e) => openMenu("assignee", e)}>
              <PersonIcon />
              <span>
                שיוך לחבר צוות{" "}
                {field.value &&
                  assignee &&
                  `(${assignee.firstName} ${assignee.lastName})`}
              </span>
            </ButtonStyled>

            <ButtonStyled onClick={(e) => openMenu("due", e)}>
              <AccessTimeIcon />
              <span>דד-ליין {dueDate && `(${dueDate})`}</span>
            </ButtonStyled>

            <ButtonStyled onClick={(e) => openMenu("tags", e)}>
              <TagIcon />
              <span>תגיות {tags.length > 0 && `(${tags.join(", ")})`}</span>
            </ButtonStyled>
          </ToolbarRow>

          <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl) && activeMenu !== null}
            onClose={closeMenu}
          >
            {activeMenu === "todo" &&
              ["Task", "Bug"].map((option) => (
                <MenuItem
                  key={option}
                  onClick={() => selectOption("todo", option)}
                >
                  {option}
                </MenuItem>
              ))}
            {activeMenu === "priority" &&
              ["Urgent", "High", "Medium", "Low"].map((option) => (
                <MenuItem
                  key={option}
                  onClick={() => selectOption("priority", option)}
                >
                  {option}
                </MenuItem>
              ))}
            {activeMenu === "assignee" &&
              users.map((option) => (
                <MenuItem
                  key={option.id}
                  onClick={() => field.onChange(option.id)}
                >
                  {option.firstName} {option.lastName}
                </MenuItem>
              ))}
            {activeMenu === "due" &&
              ["Today", "Tomorrow", "Next Week", "No date"].map((option) => (
                <MenuItem
                  key={option}
                  onClick={() => selectOption("dueDate", option)}
                >
                  {option}
                </MenuItem>
              ))}
            {activeMenu === "tags" &&
              ["Bug", "Feature", "Enhancement", "Urgent"].map((option) => (
                <MenuItem
                  key={option}
                  onClick={() => selectOption("tags", option)}
                >
                  {option}
                </MenuItem>
              ))}
          </Menu>

          <FormContainer onSubmit={handleSubmit(onSubmit)}>
            <FieldGroup>
              <Label>כותרת</Label>
              <Input
                {...register("title", { required: true })}
                placeholder="כותרת המשימה"
              />
            </FieldGroup>

            <FieldGroup>
              <Label>תיאור המשימה</Label>
              <Textarea
                {...register("description")}
                rows={3}
                placeholder="תתארו במילים את המשימה"
              />
            </FieldGroup>

            <FieldGroup>
              <Label>תת משימות</Label>
              {fields.map((field, index) => (
                <SubtaskItem key={field.id}>
                  <Controller
                    control={control}
                    name={`checklist.${index}.completed`}
                    render={({ field }) => (
                      <Checkbox
                        inputId={`subtask-${index}`}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.checked)}
                      />
                    )}
                  />
                  <SubtaskInput
                    {...register(`checklist.${index}.text`)}
                    placeholder={`תת משימה לדוגמה ${index + 1}`}
                  />
                  <IconButton onClick={() => remove(index)}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </SubtaskItem>
              ))}
              <Button
                type="button"
                onClick={() => append({ text: "", completed: false })}
              >
                + הוספת תת משימה
              </Button>
            </FieldGroup>

            <ButtonRow>
              <Button type="button" onClick={onClose}>
                ביטול
              </Button>
              <Button
                loading={isLoading}
                onClick={handleSubmit(onSubmit)}
                type="submit"
                primary
              >
                הוסף משימה
              </Button>
            </ButtonRow>
          </FormContainer>
        </DialogBody>
      </Dialog>
    </Overlay>
  );
};

export default CreateTaskDialog;
