import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { ReactComponent as DownArrow } from "../../../assets/Icons/DownArrow.svg";
import Link from "../Link";
import { useTranslation } from "react-i18next";

interface DropDownOption {
  label: string;
  icon?: React.ReactNode | JSX.Element;
  customTemplate?: React.ReactNode;
  link?: string;
}

interface DropDownMenuProps {
  options: DropDownOption[];
  onSelect: (selectedOption: DropDownOption) => void;
  selectedValue?: DropDownOption | null;
}

// Keyframes for Dropdown Animation
const fadeInSlideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled Components
const DropDownContainer = styled.div`
  z-index: 9999;
  position: relative;
  display: inline-block;
  width: 100%;
`;

const DropDownButton = styled.div<{ isOpen: boolean }>`
  margin-right: auto;
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  width: 60px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  background-color: var(--light-bg);

  &:hover {
    background-color: var(--light-bg);
  }

  svg {
    transition-duration: 0.2s;
    width: 12px;
    height: 12px;

    &.arrowsvg {
      transform: ${({ isOpen }) =>
        isOpen ? "rotate(0deg)" : "rotate(180deg)"};
    }
  }
`;

const DropDownListContainer = styled.div`
  position: absolute;
  top: 120%;
  left: 0;
  width: 30%;
  z-index: 20;
  border: 1px solid #ccc;
  border-radius: 8px;
  animation: ${fadeInSlideDown} 0.3s ease-out;
`;

const DropDownListItem = styled(Link)<{ isSelected: boolean }>`
  padding: 10px;
  cursor: pointer;
  background-color: ${({ isSelected }) => (isSelected ? "#f0f0f0" : "#fff")};
  display: flex;
  align-items: center;
  gap: 10px;

  .label {
    font-size: 14px !important;
    color: var(--title-color);
  }

  svg {
    width: 15px;
    height: 15px;
  }

  &:hover {
    background-color: #f9f9f9;
  }

  border-bottom: 1px solid #e0e0e0;

  &:last-child {
    border-bottom: none;
    border-radius: 0px 0px 8px 8px;
  }

  &:first-child {
    border-radius: 8px 8px 0px 0px;
  }
`;

// Main Component
const DropDownMenu: React.FC<DropDownMenuProps> = ({
  options,
  onSelect,
  selectedValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option: DropDownOption) => {
    if (!option.link) {
      onSelect(option);
      setIsOpen(false);
    }
  };

  return (
    <DropDownContainer>
      <DropDownButton onClick={toggleDropdown} isOpen={isOpen}>
        {selectedValue ? (
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {selectedValue.icon && <>{selectedValue.icon}</>}{" "}
            {/* Render icon */}
            {/* <span>{selectedValue.label}</span> */}
          </div>
        ) : (
          t("form.dropdown.select_view")
        )}
        <DownArrow className="arrowsvg" />
      </DropDownButton>

      {isOpen && (
        <DropDownListContainer>
          {options.map((option, index) => {
            return (
              <DropDownListItem
                isSelected={selectedValue?.label === option.label}
                className="w-full"
                path={option.link ? option.link : "#"} // Use "#" if link is not provided
                key={index}
                onClick={() => handleSelect(option)}
              >
                <>
                  {option.customTemplate ? (
                    option.customTemplate
                  ) : (
                    <>
                      <div className="flex gap-10 align-items-center">
                        {option.icon && option.icon}
                        <div className="label">{option.label}</div>
                      </div>
                    </>
                  )}
                </>
              </DropDownListItem>
            );
          })}
        </DropDownListContainer>
      )}
    </DropDownContainer>
  );
};

export default DropDownMenu;
