import React, { useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../../assets/Icons/facebook/FacebookLogo.svg";
import { ReactComponent as Edit } from "../../../assets/Icons/EditImage.svg";
import { ReactComponent as Remove } from "../../../assets/Icons/Times.svg";
import Card from "../../common/Card";
import {
  FieldValues,
  Path,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { AttachmentMedia } from "neword-core";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { websiteState } from "../../../state/websitesState";
import FacebookPreview from "../../../assets/images/facebookPreview.png"; // Add Facebook preview image
import { Galleria } from "primereact/galleria";

import MediaEditor from "../MediaEditor";
import { useTranslation } from "react-i18next";
import IphoneBG from "../../../assets/images/iphone_svg_bg.svg";

const Wrapper = styled.div<{ reel?: boolean }>`
  position: relative;
  padding: 0px;
  width: 100%;
  overflow: hidden;
  max-width: 350px;

  border: none;
  
`;

const PhoneImage = styled.img`
position: absolute;
width: 103% !important;
  height: auto;
  left: -1.2%;
  display: block;
  z-index: 1;
  top: -1px;
  pointer-events: none;
  filter: drop-shadow(0px 0px 4px #4747471d);

`;

const TopSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const AccountName = styled.div`
  font-weight: 700;
  font-size: 14px;
  font-family: "Helvetica", "Arial", sans-serif;
`;

const PostTime = styled.div`
  font-size: 12px;
  color: #65676b;
  margin-inline: 10px;
`;

const ContentWrapper = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`;

const SyledGalleria = styled(Galleria)`
  width: 100%;
  position: relative;
  
  min-height: 230px;
  .p-galleria-item {
    background-color: black;
    border-radius: 8px;
    border-radius: 50px 50px 0px 0px;
  }

  .p-galleria-item-next {
    right: auto !important;
    left: 0 !important;
    transform: rotate(180deg);
    z-index: 9999999;
  }

  .p-galleria-item-prev {
    left: auto !important;
    right: 0 !important;
    transform: rotate(180deg);
    z-index: 9999999;
  }

  .p-galleria-indicator button {
    width: 0.5rem !important;
    height: 0.5rem !important;
  }

  .p-galleria-indicators {
    padding: 0 !important;
  }

  .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: var(--primary-purple);
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    aspect-ratio: 1 / 1;
    border-radius: 8px;
    border-radius: 50px 50px 0px 0px;
  }
`;

const PostImage = styled.img`
  aspect-ratio: 9 / 16;
  border-radius: 8px;
  border-radius: 50px 50px 0px 0px;
  max-width: 100%;
  object-fit: cover;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 15px;
  bottom: 15px;
  z-index: 99;
`;

const ToolWrapper = styled.div`
  gap: 10px;
  cursor: pointer;
  span {
    font-size: 12px;
    color: white;
  }
  display: flex;
  height: 40px;
  width: 40px;

  justify-content: center;
  align-items: center;
  background-color: white;
  border:solid 1px var(--border-color);
  border-radius: 50%;
  transition-duration: 0.2s;

  position: relative;
  svg {
    fill: var(--text-color);
    width: 20px;
    height: 20px;
  }

  path {
    fill: var(--text-color);
  }

  &:hover {
    border-color:var(--title-color);

  }
  &::after{
    content:"עריכת מדיה";
    position: absolute;
    width: max-content;
    font-size: 12px;
    right: 120%;
    top: 49%;
    transform: translateY(-50%);
  }
`;

const Tools = styled.div`
  gap: 10px;
  display: flex;
padding-bottom:5px;
`;
function FbReelPreview<FormDataType extends FieldValues>({
  name,
  reel = false, // Default is false
}: {
  name: Path<FormDataType>;
  reel?: boolean;
}) {
  const methods = useFormContext();
  const { websiteId } = useParams();
  const { t } = useTranslation();
  const website = useRecoilValue(websiteState(websiteId as string));
  const values = useWatch({
    control: methods.control,
    name: name,
  }); // Ensure posts has a default value
  const [showMediaEditorModal, setMediaEditorModal] = useState(false);

  // UseMemo to calculate imageUrl based on the current posts array and postIndex
  const imageUrl = useMemo<AttachmentMedia[]>(() => {
    return values?.imageUrls || FacebookPreview;
  }, [values]);

  const videoRef = useRef<HTMLVideoElement>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false);
  const [showCover, setShowCover] = useState(true);
  const [pauseTimer, setPauseTimer] = useState<NodeJS.Timeout | null>(null);
  const [whereAmI, SetwhereAmI] = useState("true");

  const clearPauseTimer = () => {
    if (pauseTimer) {
      clearTimeout(pauseTimer);
      setPauseTimer(null);
    }
  };

  const handlePause = () => {
    SetwhereAmI("handlePause");
    clearPauseTimer();
    const timer = setTimeout(() => {
      if (videoRef.current && !videoRef.current.seeking) {
        setShowCover(true);
        setCurrentTime(videoRef.current.currentTime);
      }
    }, 75);
    setPauseTimer(timer);
  };

  const handlePlay = () => {
    SetwhereAmI("handlePlay");
    clearPauseTimer();
    setShowCover(false);
  };

  const handleSeeking = () => {
    SetwhereAmI("handleSeeking");
    clearPauseTimer();
    setIsSeeking(true);
    setShowCover(false);
  };

  const handleSeeked = () => {
    SetwhereAmI("handleSeeked");
    setIsSeeking(false);
    const timer = setTimeout(() => {
      if (videoRef.current && videoRef.current.paused) {
        setShowCover(true);
        setCurrentTime(videoRef.current.currentTime);
      }
      return true;
    }, 75);
    setPauseTimer(timer);
  };

  const handleThumbnailClick = () => {
    if (videoRef.current && videoRef.current.paused) {
      videoRef.current.currentTime = currentTime;
      videoRef.current.play();
    }
  };

  return (
    <div className="h-full pt-2">
      {imageUrl.length > 0 && (
                <Tools>
                  {/* <ToolWrapper
                    onClick={() =>
                      methods.setValue(
                        name + ".imageUrls",
                        values.imageUrls.filter(
                          (m: AttachmentMedia) => m !== item
                        )
                      )
                    }
                  >
                    <Remove />
                    <span>{t("facebook_caption_block.remove")}</span>
                  </ToolWrapper> */}

                  <ToolWrapper onClick={() => setMediaEditorModal(true)}>
                    <Edit />
                    {/* <span>{t("facebook_caption_block.edit_media")}</span> */}
                  </ToolWrapper>
                </Tools>
              )}
    <Wrapper reel={reel}>
      <PhoneImage src={IphoneBG} />
      {/* <TopSection>
        <AccountName>{website?.name}</AccountName>
        <PostTime>{t("facebook_caption_block.post_time")}</PostTime>
      </TopSection> */}
      {/* <ContentWrapper>{values && values.message}</ContentWrapper> */}

      <div className="pos-rel"></div>
      <SyledGalleria
        value={
          imageUrl.length > 0
            ? imageUrl
            : [{ url: FacebookPreview, metadata: { type: "IMAGE" } }]
        }
        showThumbnails={false}
        showIndicators={false}
        showItemNavigators
        item={(item: AttachmentMedia) => {
          if (!item) return;

          return (
            <>
              

              {item.metadata.type === "VIDEO" ? (
                <div style={{ position: "relative" }}>
                  <video
                    ref={videoRef}
                    src={item.url}
                    controls
                    style={{
                      maxWidth: reel ? "350px" : "auto",
                      aspectRatio: reel ? "9/16" : "1/1",
                    }}
                    onPlay={handlePlay}
                    onPause={handlePause}
                    onSeeking={handleSeeking}
                    onSeeked={handleSeeked}
                    onEnded={handlePause}
                  />

                  {showCover &&
                    !isSeeking &&
                    whereAmI !== "handleSeeked" &&
                    values.coverPhotoUrl && (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                        }}
                        onClick={handleThumbnailClick}
                      >
                        <img
                          src={values.coverPhotoUrl.url}
                          alt="Video thumbnail"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "8px",
                          }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            background: "rgba(0, 0, 0, 0.6)",
                            borderRadius: "50%",
                            width: "60px",
                            height: "60px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: 0,
                              height: 0,
                              borderTop: "15px solid transparent",
                              borderBottom: "15px solid transparent",
                              borderLeft: "25px solid white",
                              marginLeft: "5px",
                            }}
                          />
                        </div>
                      </div>
                    )}
                </div>
              ) : (
                <PostImage src={item.url} />
              )}
              {showMediaEditorModal && (
                <MediaEditor
                  url={item.url}
                  onProcess={(attachment: AttachmentMedia) =>
                    methods.setValue(
                      name + ".imageUrls",
                      values.imageUrls.map((m: AttachmentMedia) =>
                        m === item ? attachment : m
                      )
                    )
                  }
                  onClose={() => setMediaEditorModal(false)}
                  imageSettings={{
                    imageCropAspectRatio: 9 / 16,
                    // imageCropMinSize: { height: 960, width: 540 },
                  }}
                  videoSettings={{ framesPerSecond: 28, videoBitrate: 5000000 }}
                />
              )}
            </>
          );
        }}
      />
      {/* <IconWrapper>
        <Logo width="20" height="20" />
      </IconWrapper> */}
    </Wrapper>
    </div>
  );
}

export default FbReelPreview;
