import Route from "./core/types/route";
import Homepage from "./pages/Homepage";
import LoginPage from "./pages/LoginPage1";
import Dashboard from "./pages/websitePages/Dashboard";
import Keywords from "./pages/websitePages/KeywordsPage";
import DocsPage from "./pages/websitePages/Docs";
import HistoryPage from "./pages/websitePages/HistoryPage";
import { UserRole } from "./core/types/userRole";
import WriterSettingsPage from "./pages/websitePages/WriterSettingsPage";
import IntegrationPage from "./pages/websitePages/IntegrationsPage";
import CreateWebsitePage from "./pages/CreateWebsitePage";
import ArticleBuilderPage from "./pages/websitePages/ArticleBuilderPage";
import Signup from "./pages/Signup";
import ResetPassword from "./pages/ResetPassword";
import UpdatePassword from "./pages/UpdatePassword";
import PluginDownloads from "./pages/PluginDownloads";
import UserPreferences from "./pages/companyPages/UserPreferences";
import SubscriptionManage from "./pages/companyPages/SubscriptionManage";
import Scheduler from "./pages/websitePages/Scheduler";
import WordpressPage from "./pages/pluginPages/WordpressPage";
import ArticleEditorPage from "./pages/articlePages/ArticleEditorPage";
import ArticleViewerPage from "./pages/articlePages/ArticleViewerPage";
import TeamPage from "./pages/companyPages/TeamPage";
import CustomPluginPage from "./pages/pluginPages/CustomPluginPage";
import Templates from "./pages/websitePages/Templates";
import SuperAdminAllUsersPage from "./pages/adminPages/SuperAdminPage";
import PerferenceTabsNav from "./components/website/PerferenceTabsNav";
import AutomaticWriterTabsNav from "./components/website/AutomaticWriterTabsNav";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import AfterLoginPage from "./pages/AfterLoginPage";
import PublishSettingsPage from "./pages/websitePages/PublishSettingsPage";
import BusinessInfo from "./pages/websitePages/BusinessInfo";
import ErrorPage from "./pages/ErrorPage";
import AfterPaymentPage from "./pages/AfterPaymentPage";
import TopicGeneratorPage from "./pages/freeTools/TopicGeneratorPage";
import DocumentPage from "./pages/documentPage";
import CalendarPage from "./pages/CalendarPage";
import FeedPage from "./pages/FeedPage";
import GridView from "./pages/GridView";
import Audience from "./pages/websitePages/Audience";
import UploadFilesPage from "./pages/mobile/UploadFilesPage";
import ChooseBusinessPage from "./pages/mobile/ChooseBusinessPage";
import Overview from "./pages/websitePages/Overview";
import ClosedPage from "./pages/websitePages/ClosePage";
import Leads from "./pages/websitePages/Leads";
import Media from "./pages/websitePages/Media";
import TonePage from "./pages/websitePages/TonePage";
import InsightsPage from "./pages/websitePages/InsightsPage";
import ClientPage from "./pages/websitePages/ClientPage";
import FeatureProtectedPage from "./middleware/featureProtectedPage";
import { Features } from "neword-core";
import InghitsPreview from "./components/insghits/inghitsPreview";
import { TaskManager } from "./pages/websitePages/TaskManager";

const routes: Route[] = [
  {
    path: "/login",
    Page: <LoginPage />,
    displayName: "routes.login",
  },
  {
    path: "/signup",
    Page: <Signup />,
    displayName: "routes.signup",
  },
  {
    path: "/complete-signup",
    Page: <VerifyEmailPage />,
    displayName: "routes.complete_signup",
    hideGlobalLayout: true,
  },
  {
    path: "/reset-password",
    Page: <ResetPassword />,
    displayName: "routes.reset_password",
  },
  {
    path: "/update-password",
    Page: <UpdatePassword />,
    isProtected: true,
    hideGlobalLayout: true,
    displayName: "routes.update_password",
  },
  {
    path: "/company/new",
    Page: <LoginPage />,
  },
  {
    path: "/",
    Page: <AfterLoginPage />,
    hideGlobalLayout: true,
    isProtected: true,
  },
  {
    path: "/websites",
    Page: <Homepage />,
    isProtected: true,
    hideGlobalLayout: true,
    displayName: "routes.websites",
  },
  {
    path: "/support",
    Page: <p>עמוד תמיכה</p>,
    isProtected: true,
    displayName: "routes.support",
  },
  {
    path: "/websites/:websiteId/user-preferences/team",
    Page: <TeamPage />,
    isProtected: true,
    displayName: "routes.team",
    subMenu: <PerferenceTabsNav />,
  },
  {
    path: "/websites/:websiteId/user-preferences/account-settings",
    Page: <UserPreferences />,
    isProtected: true,
    displayName: "routes.account_settings",
    subMenu: <PerferenceTabsNav />,
  },
  {
    path: "/websites/:websiteId/user-preferences/subscription-manage",
    Page: <SubscriptionManage />,
    isProtected: true,
    displayName: "routes.subscription_manage",
    subMenu: <PerferenceTabsNav />,
  },
  {
    path: "/websites/:websiteId/user-preferences/billing-and-payment",
    Page: <SubscriptionManage />,
    isProtected: true,
    displayName: "routes.billing_and_payment",
    subMenu: <PerferenceTabsNav />,
  },
  {
    path: "/websites/:websiteId/",
    Page: <Overview />,
    isProtected: true,
    displayName: "routes.overview",
  },
  {
    path: "/websites/:websiteId/dashboard",
    Page: <Dashboard />,
    isProtected: true,
    displayName: "routes.dashboard",
  },
  {
    path: "/websites/:websiteId/tasks",
    Page: <TaskManager />,
    isProtected: true,
    displayName: "routes.tasks",
  },
  {
    path: "/websites/:websiteId/calendar",
    Page: <CalendarPage />,
    isProtected: true,
    displayName: "routes.calendar",
  },
  {
    path: "/websites/:websiteId/client-view",
    Page: <ClientPage />,
    isProtected: true,
    displayName: "יומן",
    role: UserRole.CLIENT,
  },
  {
    path: "/websites/:websiteId/feed",
    Page: <FeedPage />,
    isProtected: true,
    displayName: "routes.feed",
  },
  {
    path: "/websites/:websiteId/grid",
    Page: <GridView />,
    isProtected: true,
    displayName: "routes.grid",
  },
  {
    path: "/websites/:websiteId/autowriter/keywords",
    Page: <Keywords />,
    isProtected: true,
    displayName: "routes.autowriter_keywords",
    subMenu: <AutomaticWriterTabsNav />,
  },
  {
    path: "/websites/:websiteId/autowriter/writer-settings",
    Page: <WriterSettingsPage />,
    isProtected: true,
    displayName: "routes.writer_settings",
    subMenu: <AutomaticWriterTabsNav />,
  },
  {
    path: "/websites/:websiteId/autowriter/publish-settings",
    Page: <PublishSettingsPage />,
    isProtected: true,
    displayName: "routes.publish_settings",
    subMenu: <AutomaticWriterTabsNav />,
  },
  {
    path: "/websites/:websiteId/autowriter/templates",
    Page: <Templates />,
    isProtected: true,
    displayName: "routes.templates",
    subMenu: <AutomaticWriterTabsNav />,
  },
  {
    path: "/websites/:websiteId/integrations",
    Page: <IntegrationPage />,
    isProtected: true,
    displayName: "routes.integrations",
  },
  {
    path: "/websites/:websiteId/leads",
    Page: <Leads />,
    isProtected: true,
    displayName: "routes.leads",
  },
  {
    path: "/websites/:websiteId/business-info",
    Page: <BusinessInfo />,
    isProtected: true,
    displayName: "routes.business_info",
  },
  {
    path: "/websites/:websiteId/audience-settings",
    Page: <Audience />,
    isProtected: true,
    displayName: "routes.audience_settings",
  },
  {
    path: "/websites/:websiteId/tone-settings",
    Page: <TonePage />,
    isProtected: true,
    displayName: "routes.tone_settings",
  },
  {
    path: "/websites/:websiteId/autowriter/scheduler",
    Page: <Scheduler />,
    isProtected: true,
    displayName: "routes.autowriter_scheduler",
    subMenu: <AutomaticWriterTabsNav />,
  },
  {
    path: "/websites/:websiteId/docs",
    Page: <DocsPage />,
    isProtected: true,
    displayName: "routes.docs",
  },
  {
    path: "/websites/:websiteId/analytics",
    Page: (
      <FeatureProtectedPage
        featureKey={Features.INSIGHTS_PAGE}
        previewComponent={<InghitsPreview />}
      >
        <InsightsPage />{" "}
      </FeatureProtectedPage>
    ),
    isProtected: true,
    displayName: "routes.analytics",
  },
  {
    path: "/websites/:websiteId/media",
    Page: <Media />,
    isProtected: true,
    displayName: "routes.media",
  },
  {
    path: "/websites/builder",
    Page: <ArticleBuilderPage />,
    isProtected: true,
  },
  {
    path: "/websites/:websiteId/history",
    Page: <HistoryPage />,
    isProtected: true,
    role: UserRole.SUPER_ADMIN,
    displayName: "routes.history",
  },
  {
    path: "/websites/:websiteId/articles/:articleId",
    Page: <ArticleViewerPage />,
    isProtected: false,
    hideGlobalLayout: true,
    displayName: "routes.article_viewer",
  },
  {
    path: "/websites/:websiteId/articles/:articleId/edit",
    Page: <ArticleEditorPage />,
    isProtected: true,
    hideGlobalLayout: true,
    displayName: "routes.article_editor",
  },
  {
    path: "/websites/:websiteId/document/:documentId",
    Page: <DocumentPage />,
    isProtected: true,
    hideGlobalLayout: true,
    displayName: "routes.document",
  },
  {
    path: "/websites/new",
    Page: <CreateWebsitePage />,
    isProtected: true,
    hideGlobalLayout: true,
    displayName: "routes.new_website",
  },
  {
    path: "/websites/:websiteId/plugins",
    Page: <PluginDownloads />,
    isProtected: true,
    hideGlobalLayout: false,
  },
  {
    path: "/websites/:websiteId/plugins/wordpress",
    Page: <WordpressPage />,
    isProtected: true,
    hideGlobalLayout: false,
  },
  {
    path: "/websites/:websiteId/cms/custom",
    Page: <CustomPluginPage />,
    isProtected: true,
  },
  {
    path: "/websites/:websiteId/super-admin",
    Page: <SuperAdminAllUsersPage />,
    isProtected: true,
    role: UserRole.SUPER_ADMIN,
  },
  {
    path: "/payment/:status",
    Page: <AfterPaymentPage />,
    displayName: "routes.payment",
    isProtected: true,
    hideGlobalLayout: true,
  },
  {
    path: "/tools/topics",
    Page: <TopicGeneratorPage />,
    displayName: "routes.tools_topics",
    isProtected: false,
    hideGlobalLayout: true,
  },
  {
    path: "/mobile",
    Page: <ChooseBusinessPage />,
    displayName: "routes.mobile",
    isProtected: true,
    hideGlobalLayout: true,
  },
  {
    path: "/websites/:websiteId/mobile/attachments",
    Page: <UploadFilesPage />,
    displayName: "routes.mobile_attachments",
    isProtected: true,
    hideGlobalLayout: true,
  },
  {
    path: "/close",
    Page: <ClosedPage />,
    displayName: "routes.close",
  },
  {
    path: "/error",
    Page: <ErrorPage />,
    displayName: "routes.error",
  },
  {
    path: "/*",
    Page: <h1>אופס... העמוד לא קיים :(</h1>,
    displayName: "routes.404",
  },
];

export default routes;
