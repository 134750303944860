import React, { useState, useEffect } from "react";
import { useSwiper } from "swiper/react";
import styled from "styled-components";
import { ReactComponent as DownArrow } from "../../../../assets/Icons/DownArrow.svg";

// CustomPagination.tsx

const PaginationWrapper = styled.div`
  position: absolute;
  z-index: 9999; /* Make sure it’s above other elements */
  top: 50%; /* Adjust as needed */
  transform: translateY(-50%);
  right: -25px; /* Adjust as needed */
  display: flex;
  flex-direction: column;
  gap: 8px;

  button {
    border: none;
    padding: 6px 12px;
    cursor: pointer;
    background: #ccc;
    border-radius: 4px;

    &.active {
      background: var(--primary-purple);
      color: #fff;
    }
  }
`;

const SlideDown = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  svg {
    width: 15px;
    height: 15px;
    transform: rotate(180deg);
  }
`;

interface CustomPaginationProps {
  slidesCount: number;
}

const CustomPagination: React.FC<CustomPaginationProps> = ({ slidesCount }) => {
  const swiper = useSwiper(); // 1) Get swiper instance
  const [activeIndex, setActiveIndex] = useState(0);

  // 2) Listen for slide changes
  useEffect(() => {
    const handleSlideChange = () => {
      setActiveIndex(swiper.activeIndex);
    };
    swiper.on("slideChange", handleSlideChange);

    // Cleanup when unmounted
    return () => {
      swiper.off("slideChange", handleSlideChange);
    };
  }, [swiper]);

  // 3) Render buttons for each slide
  return (
    <>
      <PaginationWrapper className="custom-pagination">
        {Array.from({ length: slidesCount }).map((_, index) => (
          <button
            key={index}
            onClick={() => swiper.slideTo(index)}
            className={activeIndex === index ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}
      </PaginationWrapper>
      <SlideDown>
        <span> החליקו למטה לראות את שאר הפוסטים והרעיונות</span>
        <DownArrow />
      </SlideDown>
    </>
  );
};

export default CustomPagination;
