import React, { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import {
  startOfMonth,
  endOfMonth,
  startOfWeek,
  addDays,
  isSameMonth,
} from "date-fns";
import { PostEntity, PostEntityType } from "neword-core";
import DroppableTile from "./droppableTile";
import { DraggablePost } from "./draggablePost";
import EmptyTile from "./emptyTile";
import CalendarTileContent from "./CalendarTileContent";
import CalendarPopularEvents from "./calendarEventTile/CalendarPopularEvents";

interface MonthViewProps {
  date: Date;
  posts: PostEntity[];
  filterPostType: PostEntityType[];
  handleEventDrop: (post: PostEntity, newDate: Date) => void;
  clientView?: boolean;
}

const MonthContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  height: calc(100vh - 150px);
  background-color: white;
  border: solid 1px var(--border-color);
  border-top: none;
  border-radius: 0 0 12px 12px;
`;

const DaysHeader = styled.div`
  display: flex;
  background: white;
`;

const DayHeader = styled.div`
  flex: 1;
  text-align: center;
  padding: 10px;
  font-size: 12px;
  text-transform: uppercase;
  font-family: "Open Sans", "open sans Hebrew";
  color: var(--text-color);
`;

const MonthGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  flex-grow: 1;
`;

const DayCell = styled.div<{ isOtherMonth: boolean }>`
  min-height: 100px;
  padding: 5px;
  border-right: 1px solid var(--border-color);
  border-top: 1px solid var(--border-color);
  background-color: ${(props) =>
    props.isOtherMonth ? "var(--light-bg)" : "transparent"};
  position: relative;
`;

const DateLabel = styled.div<{ isToday: boolean }>`
  font-size: 12px;
  background-color: ${(props) =>
    props.isToday ? "var(--purple-opacity) !important" : "transparent"};
  color: ${(props) => (props.isToday ? "var(--primary-purple)" : "inherit")};
  width: fit-content;
  padding: 5px 5px;
  border-radius: 8px;
  font-weight: ${(props) => (props.isToday ? "600" : "400")};
`;

const MonthView: React.FC<MonthViewProps> = ({
  date,
  posts,
  filterPostType,
  handleEventDrop,
  clientView,
}) => {
  const now = new Date();
  const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const monthStart = startOfMonth(date);
  const monthEnd = endOfMonth(date);
  const firstDay = startOfWeek(monthStart, { weekStartsOn: 0 });
  const totalDays = 42; // 6 rows × 7 days to cover all cases
  const monthContainerRef = useRef<HTMLDivElement>(null);

  const monthDays = Array.from({ length: totalDays }, (_, i) =>
    addDays(firstDay, i)
  );

    // // Scroll if today is greater than 15
    // useEffect(() => {
    //   if (todayStart.getDate() > 15 && monthContainerRef.current) {
    //     monthContainerRef.current.scrollTo({
    //       top: monthContainerRef.current.scrollHeight - 850,
    //       behavior: "smooth",
    //     });
    //   }
    // }, [todayStart]);

  const eventsByDay = useMemo(() => {
    const map: Record<string, PostEntity[]> = {};
    monthDays.forEach((day) => {
      const dayString = day.toDateString();
      map[dayString] = posts.filter((post) => {
        const postDate = new Date(post.scheduleDate);
        return (
          postDate.toDateString() === dayString &&
          (filterPostType.length === 0 || filterPostType.includes(post.type))
        );
      });
      map[dayString].sort(
        (a, b) =>
          new Date(a.scheduleDate).getTime() -
          new Date(b.scheduleDate).getTime()
      );
    });
    return map;
  }, [monthDays, posts, filterPostType]);

  return (
    <MonthContainer ref={monthContainerRef}>
      <DaysHeader>
        {Array.from({ length: 7 }, (_, i) => (
          <DayHeader key={i}>
            {addDays(
              startOfWeek(new Date(), { weekStartsOn: 0 }),
              i
            ).toLocaleDateString("he-IL", {
              weekday: "short",
            })}
          </DayHeader>
        ))}
      </DaysHeader>
      <MonthGrid>
        {monthDays.map((day) => {
          const dayString = day.toDateString();
          const isPast = day < todayStart;
          const isToday = day.toDateString() === todayStart.toDateString();
          return (
            <DayCell key={dayString} isOtherMonth={isPast}>
              <DateLabel isToday={isToday}>{day.getDate()}</DateLabel>
              {!clientView && <CalendarPopularEvents day={day} />}
              <DroppableTile date={day} onDropEvent={handleEventDrop}>
                <CalendarTileContent
                  date={day}
                  view="month"
                  posts={posts}
                  filterPostType={filterPostType}
                  clientView={clientView}
                />
              </DroppableTile>
            </DayCell>
          );
        })}
      </MonthGrid>
    </MonthContainer>
  );
};

export default MonthView;
