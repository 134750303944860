import React, { useMemo, useState } from "react";
import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import PackageCard from "../packages/PackageCard";
import { packagesState } from "../../state/packagesState";
import { useRecoilValue } from "recoil";
import { userState } from "../../state/userState";
import { useTranslation } from "react-i18next";
import { PackageType } from "neword-core";

const DialogTitle = styled.h1`
  color: var(--main-title-color, #0a2540);
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 4rem */
  letter-spacing: -0.1rem;
  font-family: "Noto Sans Hebrew", sans-serif;  
`;

const DialogSubtitle = styled.h2`
  color: var(--main-text-color, #425466);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 1.125rem */
  letter-spacing: -0.03375rem;
  margin-top: 15px;
`;

const CancelAnyTime = styled.h2`
  font-weight: 600;
  color: var(--main-text-color, #425466);
  font-size: 1rem;
  font-style: normal;
  margin: 20px 0;
`;

const PeriodSwitcherWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 40px;
`;
const SwitcherText = styled.div`
  color: var(--main-text-color, #425466);
  font-size: 0.96713rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.019rem;
  text-transform: capitalize;

  & span {
    color: var(--primary-purple);
    font-weight: 700;
  }
`;

const CardsWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: center;
  flex-wrap: wrap;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  max-width: 80vw;
  margin: auto;
  background: "#FFFFFA";

`;

const CostSwitchWrapper = styled.div`
  display: flex;
  gap: 10px;
  font-size: 12px;
  align-items: center;
`;

const ToggleWrapper = styled.div`
  display: flex;
  direction: ltr;
  position: relative;
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 25px;
  background-color: #f8f9fa;
  width: 250px;
  height: 50px;
  padding: 5px;
`;

const ToggleBackground = styled.div<{ position: "left" | "right" }>`
  position: absolute;
  top: 5px;
  bottom: 5px;
  width: 47%;
  background-color: var(--primary-purple);
  border-radius: 20px;
  transition: transform 0.3s ease-in-out;
  transform: ${(props) =>
    props.position === "left" ? "translateX(2%)" : "translateX(100%)"};
`;

const ToggleOption = styled.div<{ isSelected: boolean }>`
  flex: 1;
  text-align: center;
  z-index: 1; /* Ensure text is above the background */
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => (props.isSelected ? "#fff" : "#003e47")};
  opacity: ${(props) => (props.isSelected ? 1 : 0.6)};
  cursor: pointer;
  user-select: none;
`;

interface Props {
  onHide: () => void;
  isOops?: boolean;
}

const UpgradePackage: React.FC<Props> = ({ onHide, isOops }) => {
  const packages = useRecoilValue(packagesState);
  const user = useRecoilValue(userState);
  const { t } = useTranslation();
  const [yearly, setYearly] = useState(true);
  const packagesFiltered = useMemo(
    () => packages.filter((p) => !p.hideOnUi),
    [packages]
  );

  return (
    <Dialog
      header={
        <HeaderWrapper>
          {isOops ? (
            <div className="flex justify-content-between align-items-center w-full pr-5 pl-5">
             <div className="pr-5">
              <DialogTitle>
                {t("upgrade_package_modal.header_title")}
              </DialogTitle>
              <DialogSubtitle>
                {t("upgrade_package_modal.header_subtitle")}
              </DialogSubtitle>
              </div>

              <PeriodSwitcherWrapper>
          <ToggleWrapper>
            {/* Background that slides */}
            <ToggleBackground position={!yearly ? "left" : "right"} />

            {/* Toggle Options */}
            <ToggleOption isSelected={!yearly} onClick={() => setYearly(false)}>
              {t("upgrade_package_modal.monthly_payment")}
            </ToggleOption>
            <ToggleOption isSelected={yearly} onClick={() => setYearly(true)}>
              {t("upgrade_package_modal.yearly_payment")}
            </ToggleOption>
          </ToggleWrapper>
          <SwitcherText>
            {t("upgrade_package_modal.save_20_percent")}
          </SwitcherText>
        </PeriodSwitcherWrapper>
        </div>
          ) : (
            <div className="flex justify-content-between align-items-center w-full pr-5 pl-5">
             <div className="pr-5">
             <DialogTitle>
                {t("upgrade_package_modal.header_title_2")}
              </DialogTitle>
              <DialogSubtitle>
                {t("upgrade_package_modal.header_subtitle")}
              </DialogSubtitle>
             </div>

              <PeriodSwitcherWrapper className="pl-5">
          <ToggleWrapper>
            {/* Background that slides */}
            <ToggleBackground position={!yearly ? "left" : "right"} />

            {/* Toggle Options */}
            <ToggleOption isSelected={!yearly} onClick={() => setYearly(false)}>
              {t("upgrade_package_modal.monthly_payment")}
            </ToggleOption>
            <ToggleOption isSelected={yearly} onClick={() => setYearly(true)}>
              {t("upgrade_package_modal.yearly_payment")}
            </ToggleOption>
          </ToggleWrapper>
          <SwitcherText>
            {t("upgrade_package_modal.save_20_percent")}
          </SwitcherText>
        </PeriodSwitcherWrapper>
            </div>
          )}
        </HeaderWrapper>
      }
      style={{
        width: "90vw",
        height: "120vh",
      }}
      visible
      onHide={() => onHide()}
      draggable={false}
      modal={true}
      resizable={false}
    >
      <Wrapper>
        
        <CardsWrapper className="mt-3">
          {packagesFiltered.map((p, i) => (
            <PackageCard
              yearly={yearly}
              isSelected={
                user.company.Subscription.isActive &&
                user.company.Subscription.type === p.type
              }
              isFreeTrial={
                user.company.Subscription.type === PackageType.STARTER_PACK
              }
              packageInfo={p}
              companyId={user.companyId}
              showChecks={false}
              counter={i === 1}
              color={
                i === 0
                  ? "purple"
                  : i === 1
                    ? "pink"
                    : i === 2
                      ? "yellow"
                      : "purple"
              }
            />
          ))}
        </CardsWrapper>
      </Wrapper>
    </Dialog>
  );
};

export default UpgradePackage;
