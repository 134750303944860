import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Card from "../common/Card";
import { ReactComponent as LogoIcon } from "../../assets/Logo/ColoredLogo.svg";
import { useTranslation } from "react-i18next";
import Img from "../../assets/images/waiting_iphone.png";

interface Props {
  children?: JSX.Element | JSX.Element[] | string;
}



const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
align-items: center;
justify-content: center;
padding: 70px;

  svg{
    width: 120px;
    height: 80px;
  }
  .texts{
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
    text-align: center;
  }

  background: white;
  @media only screen and (min-device-width: 600px) {
    .asd {
      display: none;
    }
  }
`;

const IsMobile: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <>
      <Wrapper>
            <LogoIcon/>
            <img src={Img} alt="" />
            <h3>הנייד שלך אחלה, באמת.</h3>
            <div className="texts">{t("is_mobile.mobile_not_supported")}</div>
            <div className="texts">{t("is_mobile.mobile_not_supported2")}</div>
      </Wrapper>
      {children}
    </>
  );
};

export default IsMobile;
