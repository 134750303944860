import React from "react";
import styled from "styled-components";
import { ReactComponent as CheckIcon } from "../../assets/Icons/Approved.svg";

import { AppIntegrationApplicationType } from "neword-core";
import { integrationFactory } from "../../integrations/integrationFactory";

const TopMenu = styled.div`
  z-index: 9999999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const IconCheckBoxWrapper = styled.div<{ active?: boolean }>`
  position: relative;
  width: 45px;
  height: 45px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-color);
  cursor: pointer;
  &:hover {
    border-color: var(--primary-purple);
  }
`;

const CheckWrapper = styled.div<{ active?: boolean }>`
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.active ? "var(--primary-purple)" : "transparent"};
  right: -5px;
  top: -5px;
  svg {
    fill: white;
    width: 6px;
    height: 6px;
  }
`;

interface MultiPlatformSelectorProps {
  filterPostType: (AppIntegrationApplicationType | null)[];
  setFilterPostType: React.Dispatch<
    React.SetStateAction<(AppIntegrationApplicationType | null)[]>
  >;
}

export const MultiPlatformSelector: React.FC<MultiPlatformSelectorProps> = ({
  filterPostType,
  setFilterPostType,
}) => {
  return (
    <TopMenu>
      {Object.entries(integrationFactory).map(([key, value]) => {
        if (!value.scheduleAble) return null;
        const integrationType = key as AppIntegrationApplicationType;
        const isActive = filterPostType.includes(integrationType);
        const Icon = value.icon;
        return (
          <IconCheckBoxWrapper
            key={key}
            active={isActive}
            onClick={() => {
              if (isActive) {
                setFilterPostType(
                  filterPostType.filter((p) => p !== integrationType)
                );
              } else {
                setFilterPostType([...filterPostType, integrationType]);
              }
            }}
          >
            <CheckWrapper active={isActive}>
              <CheckIcon />
            </CheckWrapper>
            <Icon />{" "}
          </IconCheckBoxWrapper>
        );
      })}
    </TopMenu>
  );
};
