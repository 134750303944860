// PostEventMenu.tsx
import React from "react";
import { Menu } from "primereact/menu";
import styled from "styled-components";
import { MenuItem } from "primereact/menuitem";
import { PostEntity, PostStatusType } from "neword-core";

const MenuStyled = styled(Menu)`
  .p-menuitem-icon {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
`;

interface MenuProps {
  post: PostEntity;
  onEdit: () => void;
  onApprove: () => void;
  onReject: () => void;
  onPublish: () => void;
  onView: () => void;
  navigateToIntegrations: () => void;
  onDelete: () => void;
  isPast: boolean;
}

const PostEventMenu: React.FC<MenuProps> = ({
  post,
  onEdit,
  onApprove,
  onReject,
  onPublish,
  onView,
  onDelete,
  navigateToIntegrations,
  isPast,
}) => {
  const menuItems: MenuItem[] = [
    {
      label: "אפשרויות מהירות",
      items: [
        {
          label: "עריכת פוסט",
          icon: "pi pi-pencil",
          command: onEdit,
        },
        post.status !== PostStatusType.APPROVED && !isPast
          ? {
              label: "אישור פוסט",
              icon: "pi pi-check",
              command: onApprove,
            }
          : {
              label: "ביטול פוסט",
              icon: "pi pi-times",
              command: onReject,
            },
        post.status !== PostStatusType.PUBLISHED
          ? {
              label: "פרסם עכשיו",
              icon: "pi pi-upload",
              command: onPublish,
            }
          : {
              label: "צפה בפוסט",
              icon: "pi pi-eye",
              command: onView,
            },
        {
          label: "הסתר מהלקוחות",
          icon: "pi pi-eye-slash",
        },
        {
          label: "מחק פוסט",
          icon: "pi pi-trash",
          command: onDelete,
        },
      ],
    },
    // Uncomment or add more items as needed
    // {
    //   label: "חבר אינטגרציה",
    //   items: [
    //     {
    //       label: "עבור לעמוד אינטגרציות",
    //       icon: "pi pi-external-link",
    //       command: navigateToIntegrations,
    //     },
    //   ],
    // },
  ];

  return <MenuStyled model={menuItems} />;
};

export default PostEventMenu;
