// BlogPostSharedSettings.tsx
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { Chips } from "primereact/chips";
import CustomChip from "../../components/CustomChip";
import GenericDescriptionInput from "../formComponentes/GenericDescriptionInput";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import DocChipInput from "../formComponentes/DocChipInput";
import {useTranslation} from "react-i18next";

function BioSharedSettings() {
  const { t } = useTranslation();
  return (
    <div className="w-full step-2-wt">
      <GenericDescriptionInput<DocumentEntity & { type: DocumentEntityType }>
        name="inputParams.cta"
        label={t("bio_shared_settings.cta_label")}
        placeholder={t("bio_shared_settings.cta_placeholder")}
        inputStyle={{ height: 60 }}
      />
      <GenericDescriptionInput<
        DocumentEntity & { type: DocumentEntityType.FLASH_SALE_SMS }
      >
        name="inputParams.additionalInformation"
        label={t("bio_shared_settings.additional_info_label")}
        placeholder={t("bio_shared_settings.additional_info_placeholder")}
        hideRephrase
      />
    </div>
  );
}

export default BioSharedSettings;
