// PostCalendarEvent.tsx
import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useRecoilState, useRecoilValue } from "recoil";
import styled, { css, keyframes } from "styled-components";
import { toast } from "react-toastify";
import { Divider } from "primereact/divider";
import { PostEntity, PostStatusType, PostEntityType } from "neword-core";
import PreviewPostModal from "../../post/PreviewPostModal";
import Preloader from "../../common/Preloader";
import PostEventMenu from "./PostEventMenu";
import PostEventContent from "./PostEventContent";
import PostEventHeader from "./PostEventHeader";
import postService from "../../../core/services/post.service";
import {
  lastAddedPostsIds,
  postsSummariesState,
} from "../../../state/postState";
import { Theme } from "../../../core/theme/theme";
import PostEventFooter from "./PostEventFooter";
import NoteEventContent from "./NoteEventContent";
import { use } from "i18next";
import { confirmDelete } from "../../modals/DeletePostModal";
import { t } from "i18next";

const popIn = keyframes`
   0% {
    opacity: 0;
    transform: scale(0.7);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  70% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
`;

const Wrapper = styled.div<{ isNew?: boolean }>`
  display: flex;
  position: relative;
  opacity: 1;
  transform: scale(1);
  transition: all 0.5s ease-out;
  z-index: 1;

  ${({ isNew }) =>
    isNew &&
    css`
      animation: ${popIn} 0.5s ease-out;
    `}

  &:hover .menu-container,
.menu-container:hover {
    z-index: 9;
    pointer-events: all;
    opacity: 1 !important;
  }

  .menu-container {
    transition-duration: 0.1s;
    pointer-events: none;
    opacity: 0;
    padding: 0 7px;
    position: absolute;
    z-index: 9;
    top: 0;
    width: max-content;
  }
`;

export const TileWrapper = styled.div<{ imageUrl?: string; note?: boolean }>`
  cursor: pointer;
  background: ${(props) => (props.note ? "white" : "white")};
  margin-top: 10px;
  border: ${(props) =>
    props.note
      ? "solid 1px var(--border-color);"
      : "solid 1px var(--border-color);"};
  color: ${(props) =>
    props.note ? "var(--title-color)" : "var(--title-color)"};
  display: flex;
  border-right: ${(props) => (props.note ? "auto" : "auto")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: ${(props) => `url(${props.imageUrl})`};
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  width: 100%; /* Allow the tile to take the full width */
  padding: 5px; /* Add some padding */
  box-sizing: border-box;
  position: relative;

  &:hover {
    border: ${(props) =>
      props.note ? "solid 1px var(--yellow)" : "solid 1px var(--title-color);"};
    .menu-container {
      pointer-events: all;
      opacity: 1;
    }
  }
`;

const PostCalendarEvent = ({
  post,
  clientView,
  monthView,
  isPast,
}: {
  post: PostEntity;
  clientView?: boolean;
  monthView?: boolean;
  isPast: boolean;
}) => {
  const tileRef = useRef<HTMLDivElement>(null);
  const { websiteId } = useParams();
  const navigate = useNavigate();

  const [posts, setPosts] = useRecoilState(
    postsSummariesState(websiteId as string)
  );
  const [lastAddedPosts, setLastAddedPosts] = useRecoilState(
    lastAddedPostsIds(websiteId as string)
  );
  const [showPreviewPostModal, setShowPreviewPostModal] = useState(false);
  const [isRightAligned, setIsRightAligned] = useState(false);

  useEffect(() => {
    const handlePositionCheck = () => {
      if (tileRef.current) {
        const rect = tileRef.current.getBoundingClientRect();
        setIsRightAligned(window.innerWidth - rect.right < 400);
      }
    };

    handlePositionCheck();
    window.addEventListener("resize", handlePositionCheck);
    return () => window.removeEventListener("resize", handlePositionCheck);
  }, []);

  useEffect(() => {
    if (lastAddedPosts.includes(post.id)) {
      setTimeout(() => {
        setLastAddedPosts([]);
      }, 2000);
    }
  }, [lastAddedPosts]);

  // Handler to update post status
  const updatePostStatus = (newStatus: PostStatusType) => {
    postService.updatePostStatus(post.id, newStatus).then((newPost) => {
      setPosts((oldPosts) =>
        oldPosts.map((p) => (p.id === post.id ? newPost : p))
      );
      const message =
        newStatus === PostStatusType.APPROVED
          ? "הפוסט אושר בהצלחה"
          : "הפוסט בוטל בהצלחה";
      toast.success(message);
    });
  };

  // Handler to publish post
  const publishPost = () => {
    const loadingToastId = toast.loading("מפרסם את הפוסט...");
    postService
      .publishPost(post.id)
      .then((newPost) => {
        setPosts((oldPosts) =>
          oldPosts.map((p) => (p.id === post.id ? newPost : p))
        );
        toast.dismiss(loadingToastId);
        toast.success("הפוסט פורסם בהצלחה");
      })
      .catch(() => {
        toast.dismiss(loadingToastId);
        toast.error("שגיאה בפרסום הפוסט");
      });
  };

  const deletePost = async () => {
    confirmDelete({} as any)
      .then(async (confirmed) => {
        if (confirmed) {
          await postService.deletePost(post.id).then(() => {
            toast.success(t("preview_post_modal.toast.post_deleted"));
            setPosts((oldPosts) => oldPosts.filter((p) => p.id !== post.id));
          });
        }
      })
      .catch((error) => {
        console.error("Error in confirmation dialog:", error);
      });
  };

  return (
    <Wrapper isNew={lastAddedPosts.includes(post.id)} className="w-full">
      {post.type !== PostEntityType.NOTE ? (
        <TileWrapper
          ref={tileRef}
          onClick={() => setShowPreviewPostModal(true)}
        >
          <PostEventHeader isPast={isPast} post={post} />
          <Divider className="m-0 mb-2" />
          <PostEventContent post={post} />
          {clientView && (
            <PostEventFooter
              onApprove={() => updatePostStatus(PostStatusType.APPROVED)}
              onReject={() => updatePostStatus(PostStatusType.REJECTED)}
              openPreview={() => setShowPreviewPostModal(true)}
              post={post}
            />
          )}
        </TileWrapper>
      ) : (
        <TileWrapper
          note
          ref={tileRef}
          onClick={() => setShowPreviewPostModal(true)}
        >
          <PostEventHeader isPast={isPast} post={post} hideTime />
          <Divider className="m-0 mb-2" />
          <NoteEventContent post={post} />
          {clientView && (
            <PostEventFooter
              onApprove={() => updatePostStatus(PostStatusType.APPROVED)}
              onReject={() => updatePostStatus(PostStatusType.REJECTED)}
              openPreview={() => setShowPreviewPostModal(true)}
              post={post}
            />
          )}
        </TileWrapper>
      )}

      <div
        className="menu-container"
        style={{
          left: isRightAligned ? "auto" : "100%",
          right: isRightAligned ? "100%" : "auto",
        }}
      >
        {!clientView && (
          <PostEventMenu
            isPast={isPast}
            post={post}
            onEdit={() => setShowPreviewPostModal(true)}
            onApprove={() => updatePostStatus(PostStatusType.APPROVED)}
            onReject={() => updatePostStatus(PostStatusType.REJECTED)}
            onPublish={publishPost}
            onDelete={deletePost}
            onView={() =>
              post.externalLink && window.open(post.externalLink, "_blank")
            }
            navigateToIntegrations={() =>
              navigate(`/websites/${websiteId}/integrations`)
            }
          />
        )}
      </div>

      {showPreviewPostModal && (
        <React.Suspense fallback={<Preloader />}>
          <PreviewPostModal
            post={post}
            onHide={() => setShowPreviewPostModal(false)}
          />
        </React.Suspense>
      )}
    </Wrapper>
  );
};

export default PostCalendarEvent;
