import { Package, PackageType } from "neword-core";
import { PayproSubscriptionDetails } from "../entities/payproSubscriptionDetails";
import { UsageType } from "../types/usageType";
import { BuySubscriptionResponse } from "./requests/buySubscription/buySubscriptionResponse";
import { UsageResponseData } from "./requests/usage/usageResponseData";
import { ServiceBase } from "./service.base";

class SubscriptionService extends ServiceBase {
  prefix = "/subscription";

  listSubscriptions() {
    return this.get<Package[]>("/");
  }

  getActiveSubscription() {
    return this.get<PayproSubscriptionDetails | undefined>("/paypro");
  }

  getUsage(usageType: UsageType) {
    return this.get<UsageResponseData>(`/usage/${usageType}`);
  }

  cancelSubscription() {
    return this.post<void>("/cancel");
  }

  buySubscription(type: PackageType, quantity: number, annual: boolean) {
    return this.post<BuySubscriptionResponse>(`/buy/${type}`, {
      quantity,
      annual,
    });
  }
}

export const subscriptionService = new SubscriptionService();
