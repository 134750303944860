import React from "react";
import { AppIntegrationApplicationType } from "neword-core";
import { ReactComponent as FacebookLogo } from "../../assets/Icons/facebook/FacebookLogo.svg";
import { ReactComponent as LinkedInLogo } from "../../assets/Icons/linkedin/LinkedInLogo.svg";
import { ReactComponent as XLogo } from "../../assets/Icons/XLogo.svg";
import { ReactComponent as InstagramLogo } from "../../assets/Icons/InstagramIcon.svg";
import { ReactComponent as TiktokLogo } from "../../assets/Icons/TiktokLogo.svg";
import { SocialIntegrationType } from "./types/socialIntegrationType";

import { ReactComponent as GoogleAnaliticsLogo } from "../assets/Icons/ColoredGoogle.svg";

import AnalyticsLogo from "../../assets/images/integrations/googleAnalytics-logo.png";

export const socialIntegrations: Partial<
  Record<AppIntegrationApplicationType, SocialIntegrationType>
> = {
  [AppIntegrationApplicationType.FACEBOOK]: {
    Icon: FacebookLogo,
    label: "social_integrations.facebook.label",
    text: "social_integrations.facebook.text",
  },
  [AppIntegrationApplicationType.LINKEDIN]: {
    Icon: LinkedInLogo,
    label: "social_integrations.linkedin.label",
    text: "social_integrations.linkedin.text",
  },
  [AppIntegrationApplicationType.LINKEDIN_COMPANY]: {
    Icon: LinkedInLogo,
    label: "social_integrations.linkedinCompany.label",
    text: "social_integrations.linkedinCompany.text",
  },
  [AppIntegrationApplicationType.X]: {
    Icon: XLogo,
    label: "social_integrations.twitter.label",
    text: "social_integrations.twitter.text",
  },
  [AppIntegrationApplicationType.INSTAGRAM]: {
    Icon: InstagramLogo,
    label: "social_integrations.instagram.label",
    text: "social_integrations.instagram.text",
  },

  [AppIntegrationApplicationType.TIKTOK]: {
    Icon: TiktokLogo,
    label: "social_integrations.tiktok.label",
    text: "",
  },
};
