import { Skeleton } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { websitesStateSelector } from "../../state/websitesState";
import AddWebsite from "./AddWebsite";
import WebsiteCard from "./WebsiteCard";
import { useNavigate } from "react-router";
import { userState } from "../../state/userState";
import Button from "../common/form/Button";
import GhostImg from "../../assets/images/ghost.png";
import { ReactComponent as PlusIcon } from "../../assets/Icons/Plus.svg";

import styled from "styled-components";
import {useTranslation} from "react-i18next";

const NotFoundWrapper = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  span {
    font-size: 18px;
    color: var(--text-color);
    letter-spacing: -0.5px;
  }
`;

const WebsitesList: React.FC = () => {
  const websitesSelector = useRecoilValue(websitesStateSelector);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="grid p-0">
      {!websitesSelector.length && (
        <NotFoundWrapper>
          <img src={GhostImg} alt="" width={300} />
          <span>{t('websites_list.no_websites')}</span>
          <Button
            onClick={() => navigate("/websites/new")}
            icon={<PlusIcon />}
            primary
          >
            {t('websites_list.add_new_website')}
          </Button>
        </NotFoundWrapper>
      )}
      {websitesSelector?.map((website) => (
        <WebsiteCard
          key={website.id}
          websiteId={website.id}
          websiteDomain={website.domain}
          websiteName={website.name}
        />
      ))}
    </div>
  );
};

export default WebsitesList;
