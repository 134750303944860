import React, { ReactElement, ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useRecoilValueLoadable } from "recoil";
import { userState } from "../state/userState";
import { validateUserAccess } from "./validateUserAccess";
import { UserRole } from "../core/types/userRole";
import Preloader from "../components/common/Preloader";
import MountUpgradeModal from "../components/core/MountUpgradeModal";

const Protected: React.FC<{
  children: ReactNode; // ReactNode for flexibility
  neededRole?: UserRole; // Optional
  fallback?: ReactNode; // ReactNode for flexibility in fallback
}> = ({ children, neededRole, fallback }) => {
  const userSelector = useRecoilValueLoadable(userState);
  const location = useLocation();

  switch (userSelector.state) {
    case "loading":
      return <Preloader />;
    case "hasError":
      return (
        <Navigate
          to={`/login?redirect=${encodeURIComponent(
            location.pathname + location.search
          )}`}
        />
      );
    case "hasValue":
      const user = userSelector.getValue()!!;
      if (!user.isActive) {
        return <Navigate to={`/complete-signup`} />;
      }

      if (validateUserAccess(user.role, neededRole)) {
        return (
          <>
            {children}{" "}
            <React.Suspense fallback={<Preloader />}>
              <MountUpgradeModal />
            </React.Suspense>
          </>
        ); // Ensure children are wrapped in valid React elements
      }
      return <>{fallback || null}</>; // Default to null if fallback is undefined
    default:
      return null; // Explicitly handle cases not covered
  }
};

export default Protected;
