import React from "react";
import styled from "styled-components";
import { Tooltip } from "primereact/tooltip";
import { PostEntity, PostEntityType, PostStatusType } from "neword-core";
import { ReactComponent as ErrorIcon } from "../../../assets/Icons/Error.svg";
import { ReactComponent as EditIcon } from "../../../assets/Icons/Edit.svg";
import { ReactComponent as ApprovedIcon } from "../../../assets/Icons/Approved.svg";
import { ReactComponent as RejectedIcon } from "../../../assets/Icons/Rejected.svg";
import Badge from "../../common/Badge";
import { PostCardIcons } from "../../post/postIconMappings";

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  width: 100%;
`;

const TimeWrapper = styled.div`
  font-size: 0.8em;
  font-weight: bold;
  color: var(--title-color);
`;

const DateWrapper = styled.div`
  font-size: 0.8em;
  font-weight: bold;
  color: var(--title-color);
`;

const PlatformsWrapper = styled.div`
  display: flex;
  gap: 5px;

  .note {
    background: var(--yellow-opacity);
    padding: 2px 6px;
    border-radius: 6px;
  }

  svg {
    width: 12px;
    height: 12px;
  }
`;

interface HeaderProps {
  post: PostEntity;
  hideTime?: boolean;
  isPast: boolean;
}

const PostEventHeader: React.FC<HeaderProps> = ({ post, hideTime, isPast }) => {
  // Formats time as HH:MM
  const formatTime = (date: Date) =>
    `${date.getHours().toString().padStart(2, "0")}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;

  // Formats date as DD/MM/YYYY. You can adjust the format as needed.
  const formatDate = (date: Date) =>
    `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;

  // Ensure scheduleDate is a Date instance
  const scheduleDate = new Date(post.scheduleDate);

  return (
    <TopWrapper className="mb-2">
      <div className="flex gap-10 align-items-center">
        {/* Render status badges based on post.status */}
        {post.status === PostStatusType.PUBLISH_FAILED && (
          <Badge
            bgColor="title"
            textColor="white"
            className="fail-icon"
            data-pr-tooltip="הפרסום נכשל, יש לבדוק שכל האינטגרציות מחוברות!"
            data-pr-position="top"
            icon={<ErrorIcon />}
          >
            הפרסום נכשל
          </Badge>
        )}
        {post.status === PostStatusType.PUBLISHED && (
          <Badge
            bgColor="purple"
            textColor="white"
            className="publish-icon"
            data-pr-tooltip="הפוסט פורסם"
            data-pr-position="top"
            icon={<ApprovedIcon />}
          >
            פורסם
          </Badge>
        )}
        {post.status === PostStatusType.APPROVED && (
          <Badge
            bgColor="success"
            textColor="title"
            className="approved-icon"
            data-pr-tooltip="הפוסט אושר"
            data-pr-position="top"
            icon={<ApprovedIcon />}
          >
            {post.type === PostEntityType.NOTE ? "הרעיון אושר" : "הפרסום אושר"}
          </Badge>
        )}
        {post.status === PostStatusType.PENDING && (
          <Badge
            bgColor="yellow"
            textColor="title"
            className="error-icon"
            data-pr-tooltip="יש לאשר את הפוסט על מנת שיתפרסם בפלטפורמה"
            data-pr-position="top"
            icon={<ErrorIcon />}
          >
            {post.type === PostEntityType.NOTE
              ? "הרעיון ממתין לאישור"
              : "הפוסט ממתין לאישור"}
          </Badge>
        )}
        {post.status === PostStatusType.DRAFT && (
          <Badge
            bgColor="text"
            textColor="white"
            className="error-icon"
            data-pr-tooltip="יש להוציא מטיוטה על מנת שיתפרסם בפלטפורמה"
            data-pr-position="top"
            icon={<EditIcon />}
          >
            טיוטה
          </Badge>
        )}
        {post.status === PostStatusType.REJECTED && (
          <Badge
            bgColor="danger"
            textColor="title"
            className="cancel-icon"
            data-pr-tooltip="הפוסט בוטל"
            data-pr-position="top"
            icon={<RejectedIcon />}
          >
            {post.type === PostEntityType.NOTE ? "הרעיון נדחה" : "הפרסום נדחה"}
          </Badge>
        )}
      </div>
      <div className="flex align-items-center gap-10">
        {!hideTime && <TimeWrapper>{formatTime(scheduleDate)}</TimeWrapper>}
        <PlatformsWrapper>
          {[PostCardIcons[post.type].icon].map((Icon, i, arr) => (
            <span
              key={i}
              className={post.type === PostEntityType.NOTE ? "note" : ""}
            >
              {Icon}
            </span>
          ))}
        </PlatformsWrapper>
      </div>
    </TopWrapper>
  );
};

export default PostEventHeader;
