// CalendarMenu.tsx
import React from "react";
import styled from "styled-components";
import { PostEntityType } from "neword-core";
import FilterPanel from "./FilterPanel";
import ViewToggle from "./ViewToggle";
import DateNavigator from "./DateNavigator";

interface CalendarMenuProps {
  setFilterPostType: React.Dispatch<React.SetStateAction<PostEntityType[]>>;
  filterPostType: PostEntityType[];
  toggleModal: () => void;
  hideToggle?: boolean;
  currentView: "month" | "week" | "day";
  setCurrentView: React.Dispatch<
    React.SetStateAction<"month" | "week" | "day">
  >;
  value: Date;
  setValue: React.Dispatch<React.SetStateAction<Date>>;
  clientView?: boolean;
}

const Wrapper = styled.div<{ notCalendar?: boolean }>`
  width: 100%;
  z-index: 9;
  position: sticky;
  top: 0;
  background-color: white;
  border-radius: 12px 12px 0 0;
  border-bottom: ${(props) =>
    props.notCalendar ? "solid 1px var(--border-color)" : "none"};
  border: solid 1px var(--border-color);
  gap: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const MainCalendarMenu: React.FC<CalendarMenuProps> = ({
  setFilterPostType,
  filterPostType,
  toggleModal,
  hideToggle,
  currentView,
  setCurrentView,
  value,
  setValue,
  clientView,
}) => {
  return (
    <Wrapper notCalendar>
      <FilterPanel
        toggleModal={toggleModal}
        hideToggle={hideToggle}
        filterPostType={filterPostType}
        setFilterPostType={setFilterPostType}
        currentView={currentView}
        setCurrentView={setCurrentView}
        datePicker={
          <DateNavigator
            value={value}
            setValue={setValue}
            currentView={currentView}
          />
        }
      />
    </Wrapper>
  );
};

export default MainCalendarMenu;
