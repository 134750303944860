import { Task } from "neword-core";
import { atom, selector } from "recoil";
import tasksService from "../core/services/tasks.service";

const taskSelector = selector<Task[]>({
  key: "taskSelector",
  get: () => tasksService.getAllTasks(),
});

export const taskState = atom<Task[]>({
  key: "taskState",
  default: taskSelector,
});
