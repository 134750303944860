import { ca } from "date-fns/locale";
import { PostEntityType } from "neword-core";
import { AppIntegrationApplicationType } from "neword-core";

export const postTypeToappIntegrationApplicationType = (
  postType: PostEntityType
): AppIntegrationApplicationType => {
  switch (postType) {
    case PostEntityType.FACEBOOK_POST:
      return AppIntegrationApplicationType.FACEBOOK;

    case PostEntityType.INSTAGRAM_POST:
    case PostEntityType.INSTAGRAM_STORY:
      return AppIntegrationApplicationType.INSTAGRAM;
    case PostEntityType.LINKEDIN_POST:
      return AppIntegrationApplicationType.LINKEDIN;
    case PostEntityType.LINKEDIN_COMPANY_POST:
      return AppIntegrationApplicationType.LINKEDIN_COMPANY;

    case PostEntityType.TIKTOK_POST:
      return AppIntegrationApplicationType.TIKTOK;
    case PostEntityType.INSTAGRAM_REEL:
      return AppIntegrationApplicationType.INSTAGRAM;
    case PostEntityType.FACEBOOK_REEL:
      return AppIntegrationApplicationType.FACEBOOK;
    default:
      return AppIntegrationApplicationType.FACEBOOK;
  }
};
