import { AttachmentMedia } from "neword-core";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18s";

export default (value: AttachmentMedia[]) => {
  let errorText = ""; // Separate videos and images
  const videos = value.filter((item) => item.metadata.type === "VIDEO");
  const images = value.filter((item) => item.metadata.type === "IMAGE");

  // Validate combined upload
  if (videos.length === 0 && images.length === 0) {
    errorText = i18n.t("media_validation.media_required_error");
  }
  if (value.length > 15) {
    // Increased to 15 items for more flexibility
    errorText = i18n.t("media_validation.media_limit_error");
  }

  // Validate Videos
  const videoErrors = videos
    .map((video) => {
      const { aspectRatio, resolution, frameRate, duration } =
        video.metadata as {
          type: "VIDEO";
          aspectRatio: string;
          resolution: string;
          frameRate: number;
          duration: number;
        };

      // Aspect Ratio: Allowing range around 9:16
      // const [widthRatio, heightRatio] = aspectRatio.split(":").map(Number);
      // const ratio = widthRatio / heightRatio;
      // if (ratio < 0.55 || ratio > 0.7) {
      //   // Approximate range around 9:16
      //   return "היחס רוחב-גובה של הווידאו צריך להיות בסביבה של 9:16";
      // }

      // // Resolution: Minimum 480x854
      // const [width, height] = resolution
      //   .split("x")
      //   .map((dim) => parseInt(dim.trim(), 10));
      // if (isNaN(width) || isNaN(height) || width < 480 || height < 854) {
      //   return "הרזולוציה של הווידאו צריכה להיות לפחות 480x854 פיקסלים";
      // }

      // // Frame Rate: Allow 20-60 FPS
      // if (frameRate < 20 || frameRate > 60) {
      //   return "קצב הפריימים של הווידאו צריך להיות בין 20 ל-60 FPS";
      // }

      // Duration: Between 1-120 seconds
      if (duration < 1 || duration > 120) {
        return i18n.t("media_validation.video_duration_media_error");
      }

      return null; // No error
    })
    .filter((error) => error !== null);

  // Validate Images
  const imageErrors = images
    .map((image) => {
      const { aspectRatio, resolution } = image.metadata;

      // Aspect Ratio: Allow a wider range
      // const [widthRatio, heightRatio] = aspectRatio.split(":").map(Number);
      // const ratio = widthRatio / heightRatio;
      // if (ratio < 0.75 || ratio > 2.0) {
      //   return "היחס רוחב-גובה של התמונה צריך להיות בין 3:4 ל-2:1";
      // }

      // Resolution: Minimum 300px on the shorter side
      // const [width, height] = resolution
      //   .split("x")
      //   .map((dim) => parseInt(dim.trim(), 10));
      // if (isNaN(width) || isNaN(height) || Math.min(width, height) < 300) {
      //   return "הרזולוציה של התמונה צריכה להיות לפחות 300 פיקסלים בצד הקצר";
      // }

      return null; // No error
    })
    .filter((error) => error !== null);

  // Combine all errors
  const allErrors = [...videoErrors, ...imageErrors];
  if (allErrors.length > 0) {
    errorText = allErrors.join(". ");
  }
  return errorText;
};
