import { Divider } from "primereact/divider";
import { InputSwitch } from "primereact/inputswitch";
import {
  Form,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import styled from "styled-components";
import Badge from "../../components/common/Badge";
import Card from "../../components/common/Card";
import CardTitle from "../../components/common/CardTitle";
import RadioGroup from "../../components/common/form/RadioGroup";
import PageTitle from "../../components/common/PageTitle";
import WebsiteTabsNav from "../../components/website/WebsiteTabsNav";
import { Writer } from "../../core/entities/writer";
import RadioGroupMulti from "../../components/common/form/RadioGroupMulti";
import { WriterTemplate } from "../../core/types/writerTemplates";
import { useRecoilState } from "recoil";
import { writerState } from "../../state/writerState";
import { useParams } from "react-router";
import { useCallback, useEffect } from "react";
import writerService from "../../core/services/writer.service";
import {useTranslation} from "react-i18next";

const WrapperPurple = styled.div`
  width: 90px;
  background: var(--text-color);

  border-radius: 6px;
  padding: 6px 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  transition-duration: 0.3s;
  .line {
    border-radius: 6px;
    width: 100%;
    height: 4px;
    background: white;
    opacity: 0.3;
  }
`;

const WrapperBlue = styled.div`
  margin-top: 5px;
  width: 70px;
  background: var(--lightblue);
  border-radius: 6px;
  padding: 6px 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  transition-duration: 0.3s;
  .line {
    border-radius: 6px;
    width: 100%;
    min-height: 4px;
    background: white;
    opacity: 0.3;
  }
  .number {
    font-size: 6px;
    font-weight: bold;
  }
`;

const WrapperYellow = styled.div`
  margin-top: 5px;
  width: 70px;
  background: var(--yellow);
  border-radius: 6px;
  padding: 6px 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  transition-duration: 0.3s;
  .line {
    border-radius: 6px;
    width: 100%;
    min-height: 4px;
    background: white;
    opacity: 0.3;
  }
  .number {
    font-size: 6px;
    font-weight: bold;
    color: white;
  }
`;

const WrapperPink = styled.div`
  margin-top: 5px;

  width: 80px;
  background: var(--border-color);
  border-radius: 6px;
  padding: 8px 6px;
  transition-duration: 0.3s;
  display: flex;
  gap: 5px;

  .line {
    border-radius: 6px;
    width: 100%;
    height: 4px;
    background: white;
    opacity: 0.3;
  }
`;

const CardStyle = styled(Card)`
  height: 100%;
  border: none;

  small {
    text-align: right;
  }
  .illus {
    width: 100px;
    margin-left: 20px;
  }

  /* &:hover {
    border-color: var(--title-color);
  } */

  &:hover ${WrapperBlue} {
    width: 80px;
  }

  &:hover ${WrapperPink} {
    width: 70px;
  }
`;

const RadioWrapper = styled.div`
  .singleradio {
    height: 100%;
    padding-left: 10px;
    background: white;
  }
`;

const Templates: React.FC = () => {
  const { websiteId } = useParams();
  const { t } = useTranslation();
  const [writer, setWriter] = useRecoilState(writerState(websiteId as string));
  const methods = useForm<Writer>({
    values: writer,
  });

  const templates = useWatch({
    control: methods.control,
    name: "templates",
  }) as string[];

  const onSubmit: SubmitHandler<Writer> = useCallback(async (data: Writer) => {
    try {
      setWriter(data);
      await writerService.updateWriter(writer.id, {
        ...data,
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    methods.handleSubmit(onSubmit)();
  }, [templates]);

  const newLocal = (
    <div className="illus">
      <WrapperPurple>
        <div className="line"></div>
      </WrapperPurple>
      <WrapperBlue>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </WrapperBlue>
      <WrapperPink>
        <div className="line"></div>
      </WrapperPink>
    </div>
  );
  return (
    <>
      <PageTitle
        title={t("templates.title")}
        subtitle={t("templates.subtitle")}
      />
      <FormProvider {...methods}>
        <RadioWrapper>
          <RadioGroupMulti<Writer>
            ColumnNumber="3"
            fieldName="templates"
            options={[
              {
                value: WriterTemplate.PILAR,
                render: (
                  <>
                    <CardStyle className="active">
                      <div className="flex ">
                        <div className="illus">
                          <WrapperPurple>
                            <div className="line"></div>
                          </WrapperPurple>
                          <WrapperBlue>
                            <div className="line"></div>
                            <div className="line"></div>
                          </WrapperBlue>
                          <WrapperBlue>
                            <div className="line"></div>
                          </WrapperBlue>
                          <WrapperPink>
                            <div className="line"></div>
                          </WrapperPink>
                        </div>
                        <div>
                          <div className="flex align-items-center mb-2 justify-content-between">
                            <CardTitle title={t("templates.card_titles.pilar_post_page")} className="mb-0" />
                          </div>
                          <small>
                            {t("templates.descriptions.pilar_post_page")}
                          </small>
                        </div>
                      </div>
                    </CardStyle>
                  </>
                ),
              },
              {
                value: WriterTemplate.LISTING,
                render: (
                  <>
                    <CardStyle>
                      <div className="flex">
                        <div className="illus">
                          <WrapperPurple>
                            <div className="line"></div>
                          </WrapperPurple>
                          <WrapperYellow>
                            <div className="flex">
                              <span className="ml-2 number">1.</span>
                              <div className="line"></div>
                            </div>
                            <div className="flex">
                              <span className="ml-2 number">2.</span>
                              <div className="line"></div>
                            </div>
                            <div className="flex">
                              <span className="ml-2 number">3.</span>
                              <div className="line"></div>
                            </div>
                          </WrapperYellow>
                          <WrapperPink>
                            <div className="line"></div>
                          </WrapperPink>
                        </div>
                        <div>
                          <div className="flex align-items-center mb-2 justify-content-between">
                            <CardTitle
                              title={t("templates.card_titles.product_listicle")}
                              className="mb-0"
                            />
                          </div>
                          <small>
                            {t("templates.descriptions.product_listicle")}
                          </small>
                        </div>
                      </div>
                    </CardStyle>
                  </>
                ),
              },
              {
                value: WriterTemplate.HOW_TO,
                render: (
                  <CardStyle>
                    <div className="flex ">
                      {newLocal}
                      <div>
                        <div className="flex align-items-center mb-2 justify-content-between">
                          <CardTitle title={t("templates.card_titles.how_to_post")} className="mb-0" />
                        </div>
                        <small>
                          {t("templates.descriptions.how_to_post")}
                        </small>
                      </div>
                    </div>
                  </CardStyle>
                ),
              },
              {
                value: WriterTemplate.TIPS,
                render: (
                  <CardStyle>
                    <div className="flex">
                      <div className="illus">
                        <WrapperPurple>
                          <div className="line"></div>
                        </WrapperPurple>
                        <WrapperYellow>
                          <div className="flex">
                            <span className="ml-2 number">1.</span>
                            <div className="line"></div>
                          </div>
                          <div className="flex">
                            <span className="ml-2 number">2.</span>
                            <div className="line"></div>
                          </div>
                          <div className="flex">
                            <span className="ml-2 number">3.</span>
                            <div className="line"></div>
                          </div>
                        </WrapperYellow>
                        <WrapperPink>
                          <div className="line"></div>
                        </WrapperPink>
                      </div>
                      <div>
                        <div className="flex align-items-center mb-2 justify-content-between">
                          <CardTitle title={t("templates.card_titles.tips_post")} className="mb-0" />
                        </div>
                        <small>
                          {t("templates.descriptions.tips_post")}
                        </small>
                      </div>
                    </div>
                  </CardStyle>
                ),
              },
              {
                value: WriterTemplate.MISTAKE_LIST,
                render: (
                  <CardStyle>
                    <div className="flex">
                      <div className="illus">
                        <WrapperPurple>
                          <div className="line"></div>
                        </WrapperPurple>
                        <WrapperYellow>
                          <div className="flex">
                            <span className="mr-2 number">1.</span>
                            <div className="line"></div>
                          </div>
                          <div className="flex">
                            <span className="mr-2 number">2.</span>
                            <div className="line"></div>
                          </div>
                          <div className="flex">
                            <span className="mr-2 number">3.</span>
                            <div className="line"></div>
                          </div>
                        </WrapperYellow>
                        <WrapperPink>
                          <div className="line"></div>
                        </WrapperPink>
                      </div>
                      <div>
                        <div className="flex align-items-center mb-2 justify-content-between">
                          <CardTitle title={t("templates.card_titles.common_mistakes")} className="mb-0" />
                        </div>
                        <small>
                          {t("templates.descriptions.common_mistakes")}
                        </small>
                      </div>
                    </div>
                  </CardStyle>
                ),
              },
              {
                value: WriterTemplate.BEGINNER_GUIDE,
                render: (
                  <CardStyle>
                    <div className="flex">
                      <div className="illus">
                        <WrapperPurple>
                          <div className="line"></div>
                        </WrapperPurple>
                        <WrapperBlue>
                          <div className="line"></div>
                        </WrapperBlue>
                        <WrapperYellow>
                          <div className="flex">
                            <span className="ml-2 number">1.</span>
                            <div className="line"></div>
                          </div>
                          <div className="flex">
                            <span className="ml-2 number">2.</span>
                            <div className="line"></div>
                          </div>
                        </WrapperYellow>
                        <WrapperPink>
                          <div className="line"></div>
                        </WrapperPink>
                      </div>
                      <div>
                        <div className="flex align-items-center mb-2 justify-content-between">
                          <CardTitle
                            title={t("templates.card_titles.beginners_guide")}
                            className="mb-0"
                          />
                        </div>
                        <small>
                          {t("templates.descriptions.beginners_guide")}
                        </small>
                      </div>
                    </div>
                  </CardStyle>
                ),
              },
              {
                value: WriterTemplate.DEFINITION,
                render: (
                  <CardStyle>
                    <div className="flex">
                      <div className="illus">
                        <WrapperPurple>
                          <div className="line"></div>
                        </WrapperPurple>
                        <WrapperBlue>
                          <div className="line"></div>
                        </WrapperBlue>
                        <WrapperBlue>
                          <div className="line"></div>
                        </WrapperBlue>
                        <WrapperBlue>
                          <div className="line"></div>
                        </WrapperBlue>

                        <WrapperPink>
                          <div className="line"></div>
                        </WrapperPink>
                      </div>
                      <div>
                        <div className="flex align-items-center mb-2 justify-content-between">
                          <CardTitle title={t("templates.card_titles.term_explainer")} className="mb-0" />
                        </div>
                        <small>
                          {t("templates.descriptions.term_explainer")}
                        </small>
                      </div>
                    </div>
                  </CardStyle>
                ),
              },
            ]}
          />
        </RadioWrapper>
      </FormProvider>
      <div className="grid">
        <div className="col-4"></div>
        <div className="col-4"></div>
        <div className="col-4"></div>
        <div className="col-4"></div>
        <div className="col-4"></div>
        <div className="col-4"></div>
        <div className="col-4"></div>
      </div>
    </>
  );
};

export default Templates;
