import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import { useState } from "react";
import { useParams } from "react-router";
import { useRecoilState } from "recoil";
import { FormProvider, useForm } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";

import Button from "./Button";
import FormStyle from "./FormStyle";
import writerService from "../../../core/services/writer.service";
import { toneState } from "../../../state/toneState";
import { WebsiteTone } from "../../../core/entities/tone";
import { useTranslation } from "react-i18next";

const Title = styled.h2`
  color: var(--Main-TitleColor, #0a2540);
  font-size: 1.125rem; /* 18px */
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.03rem;
  margin-top: 0.5rem;
`;

const Subtitle = styled.h3`
  margin-top: 0.5rem;
  font-size: 1rem; /* 16px */
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.02625rem;
  color: #666;
`;

const DialogStyled = styled(Dialog)`
  max-width: 700px;
  .p-dialog-header {
    background-color: #f7f9fc;
  }

  .p-dialog-content {
    padding: 1.5rem 1.5rem 0;
  }

  .p-dialog-footer {
    padding: 1rem 1.5rem;
  }

  @media screen and (max-width: 768px) {
    max-width: 90vw;
  }
`;

// A light style for the preview box
const PreviewBox = styled.div`
  background: #f9f9f9;
  border: 1px solid #ececec;
  border-radius: 8px;
  padding: 1rem;
  margin-top: 1rem;
  color: #333;
  font-size: 0.95rem;
  line-height: 1.4;
`;

interface Props {
  onHide: () => void;
  toneId: string;
}

const UpdateToneModal: React.FC<Props> = ({ onHide, toneId }) => {
  const { websiteId } = useParams();
  const { t } = useTranslation();
  const [tones, setTones] = useRecoilState(toneState(websiteId as string));
  const [isLoading, setIsLoading] = useState(false);

  // Find the tone we're editing, if any
  const currentTone = tones.find((tone) => tone.id === toneId);

  // We'll keep our form states here.
  const methods = useForm<WebsiteTone>({
    defaultValues: currentTone || { name: "", toneText: "" },
  });

  // On submit, handle the creation or update logic.
  // If you need real backend logic, please replace the static code below.
  const onSubmit = async (data: WebsiteTone) => {
    setIsLoading(true);
    if (toneId && currentTone) {
      // Update existing tone
      const newGeneratedTone = await writerService.updateTone(
        toneId,
        data,
        websiteId as string
      );

      setTones([...tones.filter((t) => t.id !== toneId), newGeneratedTone]);
    } else {
      // Create new tone
      const newTone = { ...data, id: Date.now().toString() };
      setTones((prev) => [...prev, newTone]);
    }
    onHide();
  };

  // Real-time watch for the tone text input.
  // This is used for live preview.
  const watchSummerizedToneText = methods.watch("summerizedToneText");

  return (
    <DialogStyled
      visible
      onHide={onHide}
      modal
      header={
        <>
          <Title>{t("form.create_tone_modal.title")}</Title>
          <Subtitle>{t("form.create_tone_modal.subtitle")}</Subtitle>
        </>
      }
      footer={
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            primary
            disabled={!methods.formState.isValid}
            onClick={methods.handleSubmit(onSubmit)}
            loading={isLoading}
          >
            {t("form.create_tone_modal.submit_button")}
          </Button>
        </div>
      }
    >
      <FormProvider {...methods}>
        <FormStyle>
          <div className="grid" style={{ marginBottom: "1rem" }}>
            <div className="col-12">
              <label htmlFor="toneName" style={{ fontWeight: 500 }}>
                {t("form.create_tone_modal.tone_name")}
              </label>
              <InputText
                id="toneName"
                placeholder={t("form.create_tone_modal.tone_name_placeholder")}
                className="w-full mt-2"
                {...methods.register("name", { required: true })}
              />
            </div>
          </div>
          <div className="grid" style={{ marginBottom: "1rem" }}>
            <div className="col-12">
              <label htmlFor="toneText" style={{ fontWeight: 500 }}>
                {t("form.create_tone_modal.tone_text")}
              </label>
              <InputTextarea
                id="toneText"
                placeholder={t("form.create_tone_modal.tone_text_placeholder")}
                rows={5}
                className="w-full mt-2"
                autoResize
                {...methods.register("toneText", { required: true })}
              />
            </div>
          </div>

          {/* Live preview of the typed tone text */}
          {watchSummerizedToneText?.trim() && (
            <PreviewBox>
              <strong>{t("form.create_tone_modal.tone_preview")}</strong>
              <p style={{ marginTop: "0.5rem" }}>{watchSummerizedToneText}</p>
            </PreviewBox>
          )}
        </FormStyle>
      </FormProvider>
    </DialogStyled>
  );
};

export default UpdateToneModal;
