import React from "react";
import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import { confirmable, createConfirmation } from "react-confirm";
import Button from "../common/form/Button";

// Styled components
const Title = styled.h2`
  color: var(--Main-TitleColor, #0a2540);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.03rem;
  margin-top: 0.5rem;
`;

const Subtitle = styled.h3`
  margin-top: 0.5rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.02625rem;
  color: #666;
`;

const DialogStyled = styled(Dialog)`
  width: 400px;
  .p-dialog-header {
    background-color: #f7f9fc;
  }

  .p-dialog-content {
    padding: 1.5rem 1.5rem 0;
  }

  .p-dialog-footer {
    padding: 1rem 1.5rem;
  }

  @media screen and (max-width: 768px) {
    max-width: 90vw;
  }
`;

const SmallText = styled.p`
  font-size: 12px;
  color: #666;
  margin-top: 10px;
  text-align: right;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

// Interface for props
interface Props {
  show: boolean;
  proceed: (value: boolean) => void;
}

const DeletePostConfirmModal: React.FC<Props> = ({ show, proceed }) => {
  if (!show) return null;

  return (
    <DialogStyled
      visible={show}
      onHide={() => proceed(false)}
      header={
        <>
          <Title>אישור מחיקה</Title>
          <Subtitle>האם אתה בטוח שברצונך למחוק את הפוסט?</Subtitle>
          <SmallText>* הפוסט לא ימחק מהרשתות החברתיות אלא רק מהמערכת</SmallText>
        </>
      }
      modal
    >
      <ButtonsContainer>
        <Button
          hoverColor="danger"
          borderColor="danger"
          style={{ color: "red" }}
          onClick={() => proceed(true)}
        >
          מחק
        </Button>
        <Button
          hoverColor="lightBlue"
          borderColor="lightBlue"
          onClick={() => proceed(false)}
        >
          ביטול
        </Button>
      </ButtonsContainer>
    </DialogStyled>
  );
};

export const confirmDelete = createConfirmation(
  confirmable(DeletePostConfirmModal)
);
