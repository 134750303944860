import { InputText } from "primereact/inputtext";
import styled from "styled-components";
import FormStyle from "../../components/common/form/FormStyle";
import { useCallback } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { userState } from "../../state/userState";
import { User } from "../../core/entities/user";
import userService from "../../core/services/user.service";
import { throttle } from "lodash";
import {useTranslation} from "react-i18next";

const Title = styled.h1`
  font-size: 48px;

  color: #0a2540;
  font-weight: 700;
  line-height: 100%; /* 3rem */
  letter-spacing: -0.1rem;
`;

const Subtitle = styled.h2`
  color: #9aa8b6;
  margin-top: 10px;
  margin-bottom: 60px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`;

const UserPreferences: React.FC = () => {
  const [user, setUser] = useRecoilState(userState);

  const { register, handleSubmit } = useForm<User>({
    defaultValues: user || {},
  });

  const onSubmit: SubmitHandler<User> = async (data: User) => {
    try {
      setUser(data);
      await userService.updateUser({
        ...data,
        company: undefined,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const submitDebounced = useCallback(throttle(onSubmit, 300), []);
  const { t } = useTranslation();

  return (
    <>
      <div className="grid">
        <div className="col-10">
          <Title>{t("top_menu.user_settings")}</Title>
          <Subtitle>{t("top_menu.manage_details")}</Subtitle>

          <FormStyle onChange={handleSubmit(submitDebounced)}>
            <div className="grid">
              <div className="col-6">
                <label htmlFor="username">{t("user_preferences.first_name")}</label>
                <InputText
                  {...register("firstName")}
                  placeholder={t("user_preferences.enter_first_name")}
                />
              </div>
              <div className="col-6">
                <label htmlFor="username">{t("user_preferences.last_name")}</label>
                <InputText
                  {...register("lastName")}
                  placeholder={t("user_preferences.enter_last_name")}
                />
              </div>
              {/* <div className="col-6">
                  <label htmlFor="username">Phone Number</label>
                  <div className="flex">
                    <Controller
                      name="phone"
                      control={control}
                      render={({ field }) => (
                        <PhoneSelector
                          onChange={field.onChange}
                          value={field.value}
                        />
                      )}
                    />
                  </div>
                  {user?.isActive && user.phone && (
                    <small id="email-varified" className="text-green-400">
                      Phone Verified
                    </small>
                  )}
                </div> */}
              <div className="col-6">
                <label htmlFor="username">{t("user_preferences.email")}</label>
                <InputText
                  disabled
                  {...register("email")}
                  placeholder="example@example.com"
                />
              </div>
              {/* <CardSubtitle subTitle={"Privacy settings"} />
                <div className="col-6">
                  <label htmlFor="password">New Password</label>
                  <InputText placeholder="New Password" />
                </div>
                <div className="col-6">
                  <label htmlFor="username">Validate Password</label>
                  <InputText placeholder="Validate Password" />
                </div> */}
            </div>
          </FormStyle>
        </div>
      </div>
    </>
  );
};

export default UserPreferences;
