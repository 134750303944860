import { Divider } from "primereact/divider";
import { InputTextarea } from "primereact/inputtextarea";
import { FieldValues, Path, useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import CardTitle from "../../common/CardTitle";
import MultyAttachmentInput from "../../common/form/MultyAttachmentInput";
import TiktokPreview from "../postPreviews/TiktokPreview";
import tiktokPostMediaValidation from "./mediaValdiation/tiktokPostMediaValidation";
import CircleBg from "../../../assets/images/bgcirclepurple.png";
import Card from "../../common/Card";
import { Message } from "primereact/message";
import { AttachmentMedia } from "neword-core";
import Button from "../../common/form/Button";
import VideoFramePicker from "../videoFramePicker";
import tiktokPostMediaWarning from "./mediaValdiation/tiktokPostMediaWarning";
import { useTranslation } from "react-i18next";

const InputTextareaStyled = styled(InputTextarea)`
  outline: none !important;
  min-height: fit-content;
  height: auto;
  width: 100%;
  border: none;
  resize: none; /* Optional: prevent manual resizing */
  &::placeholder {
    color: var(--text-color);
    opacity: 0.5;
  }

  &:hover {
    background: var(--light-bg);
  }
  &:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
    background: var(--light-bg);
    border-color: none !important;
  }
`;

const LabelStyled = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: var(--text-color);
  padding-bottom: 10px;
`;

const ImageWrapper = styled.div`
  background-size: cover;
  position: relative;
  overflow: hidden;
  background-color: var(--light-bg);
display: flex;
    justify-content: center;
    padding-top: 10px;
  
`;

function TiktokPostForm<FormDataType extends FieldValues>({
  name,
  index,
}: {
  name: Path<FormDataType>;
  index?: number;
}) {
  const {
    control,
    setValue,
    register,
    getValues,
    unregister,
    formState: { errors },
  } = useFormContext();

  const values = useWatch({ control, name: name }); // Ensure posts has a default value of an empty array
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [imageUrlError, setImageUrlError] = useState("");
  const { t } = useTranslation();
  const [mediaToEdit, setMediaToEdit] = useState<AttachmentMedia | null>(null);
  const [showFramePicker, setshowFramePicker] = useState<boolean>(false);

  // Watch the coverPhotoTimeStamp to trigger UI reaction if a cover photo is added
  const coverPhotoTimestamp = useWatch({
    control,
    name: `${name}.coverPhotoTimeStamp`,
  });

  // Function to automatically adjust textarea height
  const autoResizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };
  const warningMediaValidation = tiktokPostMediaWarning(values.imageUrls);

  useEffect(() => {
    autoResizeTextarea();

    const hasVideo = values?.imageUrls?.some(
      (m: AttachmentMedia) => m.metadata?.type === "VIDEO"
    );
    const coverPhotoTimestamp = getValues(`${name}.coverPhotoTimeStamp`);

    if (!hasVideo && coverPhotoTimestamp) {
      unregister(`${name}.coverPhotoTimeStamp`);
      unregister(`${name}.coverPhotoUrl`);
    }
  }, [values?.imageUrls, name, unregister, control._fields]); // Optimized dependencies

  return (
    <div className="grid h-full m-0">
      <div className="col-8 p-0 pl-4 pr-4 pt-4">
        <CardTitle title={t("tiktok_post_form.post_title")} />
        <Divider />
        <Card>
          <LabelStyled>{t("tiktok_post_form.post_content_label")}</LabelStyled>
          <InputTextareaStyled
            {...register(`${name}.message`)}
            className="w-90 pl-3"
            placeholder={t("tiktok_post_form.post_content_placeholder")}
            onInput={autoResizeTextarea}
            ref={(e) => {
              register(`${name}.message`).ref(e);
              textareaRef.current = e;
            }}
          />
          <Divider />
          <LabelStyled>{t("tiktok_post_form.add_media_label")}</LabelStyled>
          <MultyAttachmentInput
            name={`${name}.imageUrls`}
            validate={(value) => {
              const errorText = tiktokPostMediaValidation(value);
              setImageUrlError(errorText);
              if (errorText === "") {
                return true;
              }
              return errorText;
            }}
            onAddMedia={(media) => {
              setMediaToEdit(media);
            }}
          />
          {values.imageUrls.length === 1 &&
          values.imageUrls[0].metadata.type === "VIDEO" ? (
            <>
              <LabelStyled>בחירת קאבר</LabelStyled>
              <div className="flex gap-10">
                <Button
                  className="hov"
                  bgColor="bg"
                  onClick={() => {
                    setshowFramePicker(true);
                  }}
                >
                  בחירת פריים מהסרטון
                </Button>
                {/* UI Reaction: Show a success message when a cover photo has been added */}
                {coverPhotoTimestamp && (
                  <Message severity="success" text="נוסף תמונת קאבר" />
                )}
              </div>
            </>
          ) : (
            <></>
          )}
          {values.imageUrls.length > 0 && showFramePicker ? (
            <VideoFramePicker
              url={values.imageUrls[0].url}
              onProcess={(
                selectedTime: number,
                coverPhotoAttachment: AttachmentMedia
              ) => {
                setValue(name + ".coverPhotoTimeStamp", selectedTime);
                setValue(name + ".coverPhotoUrl", coverPhotoAttachment);
              }}
              imageSettings={{
                imageCropAspectRatio: 9 / 16, // Maintain TikTok's vertical aspect ratio
              }}
              imageWriterSettings={{
                targetSize: {
                  width: 1080, // TikTok preferred width
                  height: 1920, // TikTok preferred height
                },
              }}
              videoSettings={{
                framesPerSecond: 30, // TikTok-compatible frame rate
                videoBitrate: 2500000, // 2.5 Mbps for HD videos
              }}
              onClose={() => setshowFramePicker(false)}
            />
          ) : (
            <></>
          )}
        </Card>
        <div className="media p-2 mt-4">
          {imageUrlError !== "" && (
            <Message severity="error" text={imageUrlError} />
          )}
        </div>
        <div className="media p-2">
          {warningMediaValidation !== "" && (
            <Message severity="warn" text={warningMediaValidation} />
          )}
        </div>
      </div>
      <div className="col-4 p-0">
        <ImageWrapper className="">
          <TiktokPreview reel={true} name={name} />
        </ImageWrapper>
      </div>
    </div>
  );
}

export default TiktokPostForm;
