import { Avatar } from "primereact/avatar";
import { AvatarGroup } from "primereact/avatargroup"; //Optional for grouping
import { useRecoilValue } from "recoil";
import { userState, usersState } from "../../state/userState";
import styled, { keyframes } from "styled-components";
import React, { useMemo, useRef, useState } from "react";
//import { useRouter } from 'next/router';
import { Menu } from "primereact/menu";
import userService from "../../core/services/user.service";
import { ReactComponent as PlusIcon } from "../../assets/Icons/PlusBorder.svg";

import GWButton from "../common/form/Button";
import Link from "../common/Link";
import { Button } from "primereact/button";
import Announcement from "../common/Announcement";
import { announcementState } from "../../state/announcementState";
import WebsiteDropdownMenu from "../common/form/WebsitesDropdownMenu/WebsiteDropdownMenu";

import { ReactComponent as AddPerson } from "../../assets/Icons/AddPerson.svg";
import { ReactComponent as DownButton } from "../../assets/Icons/DownArrow.svg";
import { ReactComponent as GlobeIcon } from "../../assets/Icons/Globe.svg";
import { ReactComponent as TeamIcon } from "../../assets/Icons/Team.svg";

import IconButton from "../common/IconButton";
import { Tooltip } from "primereact/tooltip";
import { Divider } from "primereact/divider";
import UpgradePackage from "../modals/UpgradePackage";
import useNavigator from "../../hooks/useNavigator";
import Badge from "../common/Badge";
import Protected from "../../middleware/protected";
import { UserRole } from "../../core/types/userRole";
import { Color } from "../../core/theme/types/color";
import { useTranslation } from "react-i18next";
import { ReactComponent as ColoredIcon } from "../../assets/Logo/ColoredIcon.svg";
import { usePermissionCheck } from "../../hooks/usePermission";
import { PackageType } from "neword-core";
import { ReactComponent as UpgradeIcon } from "../../assets/Icons/Rocket.svg";

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  align-items: center;
  background: white;
  border-radius: 0px;
  min-height: 55px;
  border-bottom: 1px solid #e2e8f0;
`;

const AvatarStyled = styled(Avatar)`
  background-color: var(--yellow);
`;

const IconStyle = styled.div`
  transition-duration: 0.2s;
  position: relative;
  top: 1px;
  margin-right: 7px;
  svg {
    transform: rotate(180deg);
    width: 10px;
    width: 10px;
  }
  svg path {
    fill: black;
  }
`;
const ButtonAvatarStyled = styled(Button)`
  font-size: 14px;
  padding: 0;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: black !important;

  &:hover {
    ${IconStyle} {
      margin-top: 7px !important;
    }
  }
`;

const EmailText = styled.span`
  font-size: 14px;
  letter-spacing: -0.3px;
`;

const RoleText = styled.span`
  margin-top: 2px;
  font-size: 10px;
  letter-spacing: -0.3px;
`;

const AvatarContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: dashed 1px var(--border-color);
  border-radius: 50%; /* Makes the avatar circular */
  width: 2rem;
  height: 2rem;
  overflow: hidden;

  svg {
    width: 50%; /* Adjust icon size */
    height: 50%;
  }
`;

const rocketFloat = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(2px); /* adjust as needed to go “down” more */
  }
  100% {
    transform: translateY(0);
  }
`;

const ButtonStyled = styled(GWButton)``;

const ButtonStyledPack = styled(GWButton)`
  svg {
    display: inline-block;
    vertical-align: middle;
    margin-inline-start: 6px; /* small space to the left or right of text */
    position: relative;
    animation: ${rocketFloat} 2s ease-in-out infinite;
  }

  path {
    fill: var(--primary-purple);
  }
  &:hover {
    background: var(--light-bg);
  }
`;

const LogoWrapper = styled.div`
  svg {
    width: 22px;
    height: 26px;
  }
`;

const TopMenu: React.FC = () => {
  const user = useRecoilValue(userState);
  const { isPro } = usePermissionCheck();
  const navigate = useNavigator();
  const menuLeft = useRef<Menu>(null);
  const { t } = useTranslation();
  const [upgradePackageModal, setUpgradePackageModal] = useState(false);

  let items = [
    {
      template: () => {
        return (
          <button
            onClick={(e) => navigate("/")}
            className="w-full p-link flex align-items-center p-3"
          >
            <div className="flex flex-column">
              <EmailText>{user?.email}</EmailText>
              <RoleText>{user?.role}</RoleText>
            </div>
            <AvatarStyled
              label={user?.email.at(0)?.toLocaleUpperCase()}
              shape="circle"
              className="mr-2"
            />
          </button>
        );
      },
    },
    { separator: true },

    {
      label: t("top_menu.user_settings"),

      command: () => {
        navigate(`/user-preferences/account-settings`);
      },
    },
    {
      label: t("top_menu.plans_and_billing"),

      command: () => {
        navigate(`/user-preferences/subscription-manage`);
      },
    },

    // {
    //   label: "Add Website",
    //   icon: "pi pi-fw pi-plus",
    //   command: () => {
    //     navigate("/websites/new");
    //   },
    // },
    {
      template: () => {
        return (
          <>
            <Divider className="m-0"></Divider>
          </>
        );
      },
    },
    {
      label: t("top_menu.logout"),

      command: async () => {
        await userService.logout();
        document.location.href = "/login";
      },
    },
  ];

  const getColor = (role: UserRole): { bg: Color; text: Color } => {
    switch (role) {
      case UserRole.SUPER_ADMIN:
        return { bg: "purple", text: "bg" };

      case UserRole.ADMIN:
        return { bg: "pink", text: "bg" };

      case UserRole.EDITOR:
        return { bg: "yellow", text: "bg" };

      default:
        return { bg: "pink", text: "bg" };
    }
  };

  const announcementStateValue = useRecoilValue(announcementState);

  const teammates = useRecoilValue(usersState);

  return (
    <>
      <TopWrapper>
        <Protected
          neededRole={UserRole.EDITOR}
          fallback={
            <>
              <LogoWrapper>
                <ColoredIcon />
              </LogoWrapper>{" "}
              <div>אישור הגאנט החודשי</div>
            </>
          }
        >
          <div className="flex gap-10">
            <WebsiteDropdownMenu className="changeBisness-step" />
            <Link path="/websites/new" global>
              <ButtonStyled icon={<PlusIcon />} textColor="purple">
                {t("top_menu.add_new_business")}{" "}
              </ButtonStyled>
            </Link>
          </div>
        </Protected>
        <div>
          <div className="flex align-items-center">
            <Protected neededRole={UserRole.EDITOR}>
              {!isPro && (
                <ButtonStyledPack
                  onClick={() => setUpgradePackageModal(true)}
                  textColor="purple"
                  icon={<UpgradeIcon />}
                >
                  {t("subscription_manage.upgrade_button_text")}
                </ButtonStyledPack>
              )}

              {/* <Tooltip target=".add-person-icon" />
              <AvatarContainer
                path="/user-preferences/team"
                className="add-person-icon"
                data-pr-tooltip={t("top_menu.add_team_member")}
                data-pr-position="bottom"
              >
                <AddPerson />
              </AvatarContainer> */}
              {/* <AvatarGroup style={{ direction: "ltr" }} className="ml-4">
                {teammates.slice(0, 4).map((teammate, index) => (
                  <>
                    <Tooltip target={`.teammate-${index}`}>
                      <div className="flex">
                        <div className="ml-1">
                          <strong>{t("top_menu.team_member_name")} </strong>
                        </div>
                        {teammate.firstName + " " + teammate.lastName}
                      </div>
                      <div className="flex mt-2">
                        <div className="ml-1">
                          <strong>{t("top_menu.team_member_role")} </strong>
                        </div>
                        <Badge
                          bgColor={getColor(teammate.role).bg}
                          textColor={getColor(teammate.role).text}
                        >
                          {teammate.role}
                        </Badge>
                      </div>
                    </Tooltip>
                    <AvatarStyled
                      key={index}
                      label={teammate.firstName.at(0)?.toLocaleUpperCase()} // Display the first letter of the email
                      shape="circle"
                      className={`teammate-${index}`} // Unique class for tooltip targeting
                      data-pr-position="bottom" // Tooltip position
                    />
                  </>
                ))}
                {teammates.length > 4 && (
                  <Avatar
                    label={`+${teammates.length - 4}`}
                    shape="circle"
                    size="normal"
                  />
                )}
              </AvatarGroup> */}

              {/* <Tooltip target=".plugins-icon" />
              <IconButton
                icon={<DownloadIcon />}
                width={"17"}
                className="ml-3 plugins-icon"
                path={`/plugins`}
                data-pr-tooltip="הורד תוספים רלוונטיים ל-CMS שלך"
                data-pr-position="bottom"
              /> */}
              <Tooltip target=".teams-icon" />
              <IconButton
                icon={<TeamIcon />}
                width={"17"}
                className="ml-3 teams-icon"
                path={`/user-preferences/team`}
                data-pr-tooltip={t("top_menu.manage_team_members")}
                data-pr-position="bottom"
              />
              <Tooltip target=".websites-icon" />
              <IconButton
                global
                icon={<GlobeIcon />}
                width={"17"}
                className="ml-3 websites-icon"
                path="/websites"
                data-pr-tooltip={t("top_menu.all_businesses")}
                data-pr-position="bottom"
              />
            </Protected>

            <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
            <ButtonAvatarStyled
              iconPos="right"
              onClick={(event) => menuLeft.current?.toggle(event)}
              aria-controls="popup_menu_left"
              aria-haspopup
            >
              <AvatarStyled
                label={user?.firstName.at(0)?.toLocaleUpperCase()}
                shape="circle"
              />
              <IconStyle>
                <DownButton />
              </IconStyle>
            </ButtonAvatarStyled>
          </div>
        </div>
      </TopWrapper>
      {announcementStateValue && (
        <Announcement>
          <div className="flex align-items-center">
            {announcementStateValue}
          </div>
        </Announcement>
      )}

      {upgradePackageModal && (
        <UpgradePackage onHide={() => setUpgradePackageModal(false)} />
      )}
    </>
  );
};

export default TopMenu;
