import styled from "styled-components";
import { useRef, useState, useEffect } from "react";
import { ReactComponent as EmojiPickerIcon } from "../../../assets/Icons/EmojiPicker.svg";
import EmojiPicker from "emoji-picker-react";
import { useFormContext, Path } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TagInputsSection from "../../post/TagInputsSection";

const EditableDivStyled = styled.div`
  outline: none !important;
  min-height: 100px;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  &:empty:before {
    content: attr(
      data-placeholder
    ); /* Use the placeholder text from data attribute */
    color: #aaa; /* Placeholder text color */
    pointer-events: none; /* Ensure placeholder text doesn’t interfere with typing */
  }
`;

const Wrapper = styled.div<{ isFocused?: boolean }>`
  border: ${({ isFocused }) =>
    isFocused
      ? "solid 1px var(--title-color)"
      : "solid 1px var(--border-color)"}; /* used to be bordercolor!!!*/
  &:hover {
    border-color: var(--title-color);
  }
  border-radius: 6px;
  padding: 10px;
  transition: border-color 0.2s ease-in-out; /* Smooth transition for border change */
`;

const EmojiPickerWrapper = styled.div`
  position: relative;
  z-index: 9999;
  margin-bottom: -5px;
  svg {
    width: 20px;
    height: 20px;
    fill: #c2c2c2;
  }
`;

const EmojiPickerContainer = styled.div<{ show: boolean }>`
  position: absolute;
  top: 60px;
  right: -20px;
  display: ${({ show }) => (show ? "block" : "none")};
  z-index: 99999;
`;

const CharacterCounter = styled.div`
  text-align: right;
  font-size: 12px;
  color: ${({ count }: { count: number }) => (count > 2200 ? "red" : "#888")};
  margin-top: 20px;
`;

function TextEditor({
  name,
  haveTags,
}: {
  name: Path<any>;
  haveTags?: boolean;
}) {
  const { setValue, getValues } = useFormContext();
  const { t } = useTranslation();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);
  const editorRef = useRef<HTMLDivElement | null>(null);
  const emojiPickerRef = useRef<HTMLDivElement | null>(null);
  const [isFocused, setIsFocused] = useState(false); // Track focus state

  // Function to handle syncing input
  const handleInput = () => {
    if (editorRef.current) {
      const textContent = editorRef.current.innerText; // Ensure it's only text content

      // Update character count
      setCharacterCount(textContent.length);

      // Get the current object
      const currentObject = getValues(name);

      // Update only the message field in the object
      const updatedObject = { ...currentObject, message: textContent };

      // Set the updated object back into the form
      setValue(name, updatedObject);
    }
  };

  // Handle Emoji selection and update the div content
  const handleEmojiClick = (emojiObject: any) => {
    if (editorRef.current) {
      editorRef.current.innerText += emojiObject.emoji; // Append emoji to the div's text
      handleInput(); // Sync the div content with the form value
    }
    setShowEmojiPicker(false); // Close emoji picker after selecting an emoji
  };

  // Initialize the div with the message field value (for editing existing content)
  useEffect(() => {
    const initialValue = getValues(name); // Get the initial value for this form field

    if (
      initialValue &&
      typeof initialValue === "object" &&
      initialValue.message
    ) {
      if (editorRef.current) {
        editorRef.current.innerText = initialValue.message; // Set the initial content in the editable div
        setCharacterCount(initialValue.message.length); // Set initial character count
      }
    }
  }, [getValues, name]);

  // Close emoji picker when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target as Node)
      ) {
        setShowEmojiPicker(false);
      }
    };

    if (showEmojiPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showEmojiPicker]);

  return (
    <Wrapper isFocused={isFocused}>
      <EditableDivStyled
        data-placeholder={t(
          "publish_post_modal.text_editor.start_writing_post"
        )}
        contentEditable="true"
        ref={editorRef}
        onInput={handleInput} // Sync content with form on input
        onFocus={() => setIsFocused(true)} // Set focus state
        onBlur={() => setIsFocused(false)} // Reset focus state
      ></EditableDivStyled>
      <div className="flex justify-content-between align-items-center">
        <div className="flex gap-10 align-items-center">
          <EmojiPickerWrapper>
            <EmojiPickerIcon
              style={{ cursor: "pointer" }}
              onClick={() => setShowEmojiPicker((prev) => !prev)}
            />
            <EmojiPickerContainer ref={emojiPickerRef} show={showEmojiPicker}>
              <EmojiPicker onEmojiClick={handleEmojiClick} />
            </EmojiPickerContainer>
          </EmojiPickerWrapper>
          {haveTags && <TagInputsSection name={name} />}
        </div>
        <CharacterCounter count={characterCount}>
          {characterCount}/2200
        </CharacterCounter>
      </div>
    </Wrapper>
  );
}

export default TextEditor;
