import {
  createConfirmationCreater,
  createReactTreeMounter,
  createMountPoint,
} from "react-confirm";
import UpgradePackageConfirmable from "../modals/UpgradePackageConfirmable";

const mounter = createReactTreeMounter();

export const createConfirmation = createConfirmationCreater(mounter);
export const MountPoint = createMountPoint(mounter);

export const confirmUpgradePackage = createConfirmation(
  UpgradePackageConfirmable
);
