import { Features } from "neword-core";
import ImagePreview from "../../assets/images/analyticsPreview.png";
import { usePermissionCheck } from "../../hooks/usePermission";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const StyledImage = styled.img`
  width: 50vw;
`;
const InghitsPreview: React.FC = () => {
  const { checkFeaturePermission } = usePermissionCheck();
  const { t } = useTranslation();

  return (
    <Wrapper onClick={() => checkFeaturePermission(Features.INSIGHTS_PAGE)}>
      <StyledImage src={ImagePreview}></StyledImage>

      <h2>{t("insights_page.preview")}</h2>
    </Wrapper>
  );
};

export default InghitsPreview;
