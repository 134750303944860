import { Path, useController, useFormContext } from "react-hook-form";
import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Reganrate } from "../../../assets/Icons/Reganrate.svg";
import { ReactComponent as RephraseIcon } from "../../../assets/Icons/refresh.svg";

import { DocumentEntityType, DocumentEntity } from "neword-core";
import RephraseButton from "../../common/form/RephraseButton";
import {useTranslation} from "react-i18next";

const EditableInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const IconButton = styled.button`
  &.til svg {
    stroke: black;
    stroke-width: 6px;
  }
  cursor: pointer;

  background: white;
  color: white;
  align-items: center;
  border-radius: 8px;
  border: none;
  justify-content: center;
  height: 35px;
  width: 35px;
  svg {
    margin-top: 2px;
    width: 15px;
    height: 15px;
  }
  &:hover {
    svg path {
      fill: var(--primary-purple);
    }
    svg {
      fill: var(--primary-purple);
    }
    background: var(--light-bg);
    /* border: 1px solid var(--title-color, #e6e6e6); */
  }
`;
const EditableInput = styled.textarea<{ isEditing: boolean }>`
  border: ${({ isEditing }) => (isEditing ? "none" : "none")};
  background: transparent;
  outline: none;
  width: 100%;
  border-radius: 6px;
  height: auto;
  font-family: inherit;
  padding: ${({ isEditing }) => (isEditing ? "0px" : "none")};
  letter-spacing: -0.3px;
  resize: none;
  font-size: 16px;
  display: flex;
  color: var(--title-color);
  &:focus {
    outline: none;
  }
`;

const RegenerateButton = styled.button`
  background-color: var(--button-bg-color, #007bff);
  color: white;
  border: none;
  border-radius: 6px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    background-color: var(--button-hover-bg-color, #0056b3);
  }
`;

interface Props<T extends DocumentEntityType> {
  isEditing: boolean;
  blockIndex: number;
  handleBlur?: (blockIndex: number) => void;
  fieldName: Path<DocumentEntity>;
  style?: React.CSSProperties;
  onRegenerate?: () => void; // Function to regenerate the content
}
const RephraseButtonWrapper = styled.div<{ isEditing: boolean }>`
  border-radius: 8px;
  margin-top: ${({ isEditing }) => (isEditing ? "10px" : "-16px")};
  display: flex;
  cursor: pointer;
  opacity: ${({ isEditing }) => (isEditing ? "1" : "0")};
  pointer-events: none;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: 0.15s ease-in-out;
  span {
    color: var(--primary-purple);
  }
  svg {
    width: 10px;
  }
  svg path {
    width: 10px;
    fill: var(--primary-purple);
  }
`;

const ContentWrapper = styled.div<{ isEditing: boolean }>`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 8px;
  transition: 0.1s ease-in-out;
  align-items: flex-start;
  width: 100%;
  /* Fixed border and padding logic */
  border: ${({ isEditing }) =>
    isEditing ? "1px solid #e6e6e6" : "1px solid white"};
  padding: ${({ isEditing }) => (isEditing ? "15px" : "0")};
  background: ${({ isEditing }) => (isEditing ? "#f9f9f9" : "transparent")};

  &:hover {
    ${RephraseButtonWrapper} {
      opacity: 1;
      pointer-events: all;
      margin-top: 10px;
    }
  }
`;

function EditableTextArea<T extends DocumentEntityType>({
  blockIndex,
  isEditing,
  handleBlur,
  fieldName,
  style,
  onRegenerate,
}: Props<T>) {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { t } = useTranslation();

  const { control, watch } = useFormContext<DocumentEntity>();

  const { field } = useController<DocumentEntity>({
    control: control,
    name: fieldName,
  });
  const [height, setHeight] = useState(14);

  const fieldValue = watch(fieldName);

  useEffect(() => {
    if (inputRef.current) {
      setHeight(inputRef.current.scrollHeight);
    }
  }, [fieldValue]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      setHeight(inputRef.current.scrollHeight);
    }
  }, [isEditing]);

  return (
    <EditableInputContainer>
      <ContentWrapper isEditing={isEditing}>
        <EditableInput
          disabled={!isEditing}
          isEditing={isEditing}
          value={
            typeof field.value === "string" || typeof field.value === "number"
              ? field.value
              : ""
          }
          onBlur={() => {
            // handleBlur && handleBlur(blockIndex);
            field.onBlur();
          }}
          onChange={field.onChange}
          ref={inputRef}
          style={{ ...style, height }}
          autoFocus
        />

        <RephraseButton
          id={fieldName}
          onChange={field.onChange}
          content={field.value as string}
          prompt={t("common.refine_text")}
        >
          <RephraseButtonWrapper isEditing={isEditing}>
            <RephraseIcon className="ml-1" />
            <span>{t("common.recreate")}</span>
          </RephraseButtonWrapper>
        </RephraseButton>
        {/* </IconButton> */}
      </ContentWrapper>
    </EditableInputContainer>
  );
}

export default EditableTextArea;
