import styled from "styled-components";
import {useTranslation} from "react-i18next";

const SuggestedKeyword = styled.a`
  display: inline-flex;
  padding: 5px 10px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  gap: 20px;
  border-radius: 6px;
  border: 1px solid var(--Input-BorderColor, #e6e6e6);
  color: rgb(0, 0, 0);
  cursor: pointer;
  text-decoration: none;
  font-size: 12px;
  &:hover {
    border-color: var(--primary-title);
  }
`;

interface Props {
  onClickKeyword: (keyword: string) => void;
}

const ContentSuggestion: React.FC<Props> = ({ onClickKeyword }) => {
  const { t } = useTranslation();
  const suggestedContents = [
    {
      keyword: t("content_suggestion.product_promotion"),
      description: t("content_suggestion.product_promotion_description"),
    },
    {
      keyword: t("content_suggestion.branding_post"),
      description: t("content_suggestion.branding_post_description"),
    },
    {
      keyword: t("content_suggestion.product_announcement"),
      description: t("content_suggestion.product_announcement_description"),
    },
    {
      keyword: t("content_suggestion.promotion_campaign"),
      description: t("content_suggestion.promotion_campaign_description"),
    },
    {
      keyword: t("content_suggestion.review"),
      description: t("content_suggestion.review_description"),
    },
    {
      keyword: t("content_suggestion.event_announcement"),
      description: t("content_suggestion.event_announcement_description"),
    },
    {
      keyword: t("content_suggestion.seasonal_holiday"),
      description: t("content_suggestion.seasonal_holiday_description"),
    },
  ];
  const onClick = (content: { keyword: string; description: string }) => {
    onClickKeyword(content.description); // Pass only the keyword
  };

  return (
    <>
      {suggestedContents?.map((suggestedContent, index) => (
        <SuggestedKeyword key={index} onClick={() => onClick(suggestedContent)}>
          {suggestedContent.keyword}
        </SuggestedKeyword>
      ))}
    </>
  );
};

export default ContentSuggestion;
