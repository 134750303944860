import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import styled, { keyframes } from "styled-components";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import ModalHeader from "./ModalHeader";
import postService from "../../core/services/post.service";
import userService from "../../core/services/user.service";
import { User } from "../../core/entities/user";
import { toast } from "react-toastify";
import { ReactComponent as WhatsappIcon } from "../../assets/Icons/WhatsappIcon.svg";
import { ReactComponent as EmailIcon } from "../../assets/Icons/EmailIconFlat.svg";
import { ReactComponent as CopyIcon } from "../../assets/Icons/Copy.svg";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import Button from "../common/form/Button";
import PhoneSelector from "../common/form/phoneSelector/PhoneSelector";
import { useForm, SubmitHandler } from "react-hook-form";
import { Divider } from "@mui/material";
import { z } from "zod";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRecoilState } from "recoil";
import { guestUserState } from "../../state/userState";

// Animation for modal entrance
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
`;

const DialogStyled = styled(Dialog)`
  direction: rtl;
  .p-dialog-content {
    padding: 0;
    overflow: visible;
    animation: ${fadeIn} 0.3s ease-out;
  }
  .p-dialog-header {
    padding: 0 !important;
    border-bottom: 1px solid #eaeaea;
  }
  .p-dialog-footer {
    padding: 10px !important;
    border-top: 1px solid #eaeaea;
  }
`;

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SectionTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

const StyledButton = styled(Button)`
  padding: 0;
  width: 48%;
`;

// A styled container for sharing method buttons with hover and active effects
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 10px;
  Button {
    padding: 0;
  }
  .insideButton {
    min-width: 100%;
    transition-duration: 0.1s;
    padding: 30px;
    border: solid 1px var(--border-color);
    border-radius: 10px;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      border: solid 1px var(--title-color);
    }
    &.active {
      border: solid 1px var(--title-color);
    }
  }
  .whatsapp {
    fill: #24b65a;
  }
  .email {
    fill: red;
  }
`;

const StyledCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
`;

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  input {
    text-align: right;
  }
  direction: rtl;
`;

// Button for copying the link
const CopyButton = styled(Button)`
  border: solid 1px var(--title-color);
  svg path {
    fill: var(--primary-purple);
  }
  &:hover {
    border-color: var(--primary-purple);
  }
`;

interface NoteModalProps {
  onHide: () => void;
}

type ShareMethod = "email" | "whatsapp" | null;

interface EmailFormValues {
  guestEmail: string;
}

interface WhatsappFormValues {
  guestPhone: string;
}

interface EmailShareProps {
  emailForm: ReturnType<typeof useForm<EmailFormValues>>;
  onSubmit: () => void;
  handleCopyLink: () => void;
  loading: boolean;
  saveDetails: boolean;
  onToggleSaveDetails: () => void;
}

const EmailShare: React.FC<EmailShareProps> = ({
  emailForm,
  onSubmit,
  handleCopyLink,
  loading,
  saveDetails,
  onToggleSaveDetails,
}) => (
  <div className="w-full">
    <SectionTitle>כתובת מייל</SectionTitle>
    <StyledInputContainer>
      <InputText
        {...emailForm.register("guestEmail", {
          required: "כתובת מייל נדרשת",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "כתובת מייל לא תקינה",
          },
        })}
        placeholder="כתובת מייל"
        style={{ width: "100%", direction: "ltr" }}
        className={emailForm.formState.errors.guestEmail ? "p-invalid" : ""}
      />
      {emailForm.formState.errors.guestEmail && (
        <small className="p-error">
          {emailForm.formState.errors.guestEmail.message}
        </small>
      )}
    </StyledInputContainer>
    {/* Checkbox placed below the input and above the divider */}
    <StyledCheckboxWrapper>
      <Checkbox checked={saveDetails} onChange={onToggleSaveDetails} />
      <label>שמור פרטים לשיתופיים עתידיים</label>
    </StyledCheckboxWrapper>
    <Divider className="mt-4 mb-3" />
    <div className="flex justify-content-between mt-5">
      <CopyButton icon={<CopyIcon />} type="button" onClick={handleCopyLink}>
        העתקת קישור
      </CopyButton>
      <Button
        primary
        arrowPlacement="right"
        type="button"
        onClick={onSubmit}
        loading={loading}
      >
        שליחה
      </Button>
    </div>
  </div>
);

interface WhatsappShareProps {
  whatsappForm: ReturnType<typeof useForm<WhatsappFormValues>>;
  onSubmit: () => void;
  handleCopyLink: () => void;
  loading: boolean;
  saveDetails: boolean;
  onToggleSaveDetails: () => void;
}

const WhatsappShare: React.FC<WhatsappShareProps> = ({
  whatsappForm,
  onSubmit,
  handleCopyLink,
  loading,
  saveDetails,
  onToggleSaveDetails,
}) => (
  <div className="w-full">
    <SectionTitle>מספר טלפון</SectionTitle>
    <StyledInputContainer>
      <PhoneSelector
        value={whatsappForm.getValues("guestPhone")}
        onChange={(v) =>
          whatsappForm.setValue("guestPhone", v, {
            shouldValidate: true,
          })
        }
        error={whatsappForm.formState.errors.guestPhone?.message}
      />
    </StyledInputContainer>
    {/* Checkbox placed below the input and above the divider */}
    <StyledCheckboxWrapper>
      <Checkbox checked={saveDetails} onChange={onToggleSaveDetails} />
      <label>שמור פרטים לשיתופיים עתידיים</label>
    </StyledCheckboxWrapper>
    <Divider className="mt-4 mb-3" />
    <div className="flex justify-content-between mt-5">
      <CopyButton icon={<CopyIcon />} type="button" onClick={handleCopyLink}>
        העתקת קישור
      </CopyButton>
      <Button
        primary
        arrowPlacement="right"
        type="button"
        onClick={onSubmit}
        loading={loading}
      >
        שליחה
      </Button>
    </div>
  </div>
);

const ShareGantLinkModal: React.FC<NoteModalProps> = ({ onHide }) => {
  const { websiteId } = useParams();
  const { t } = useTranslation();
  const [guestUser, setGuestUser] = useRecoilState(
    guestUserState(websiteId ? websiteId : "")
  );
  const [gantLink, setGantLink] = useState<string>("null");
  const [saveDetails, setSaveDetails] = useState<boolean>(true);
  const [shareMethod, setShareMethod] = useState<ShareMethod>("whatsapp");
  const [loading, setLoading] = useState<boolean>(false);

  const emailForm = useForm<EmailFormValues>({
    defaultValues: { guestEmail: "" },
  });
  const whatsappForm = useForm<WhatsappFormValues>({
    defaultValues: { guestPhone: "" },
    resolver: zodResolver(
      z.object({
        guestPhone: z.string().refine(
          (value) => {
            try {
              if (!value) return false;

              const phoneNumber = parsePhoneNumber(value);
              return isValidPhoneNumber(value, phoneNumber.country);
            } catch (error) {
              return false;
            }
          },
          { message: "מספר טלפון לא תקין" }
        ),
      })
    ),
  });

  useEffect(() => {
    if (websiteId && guestUser) {
      postService.getClientGantLink(websiteId).then((link) => {
        setGantLink(link);
      });
      if (guestUser?.guestEmail) {
        emailForm.setValue("guestEmail", guestUser.guestEmail);
      }
      if (guestUser?.guestPhone) {
        whatsappForm.setValue("guestPhone", guestUser.guestPhone);
      }
    }
  }, [websiteId, guestUser]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    if (saveDetails && guestUser) {
      setGuestUser({ ...guestUser, firstName: newName });
    }
  };

  const onEmailSubmit: SubmitHandler<EmailFormValues> = async (data) => {
    if (websiteId) {
      setLoading(true);
      try {
        const updatedGuest =
          saveDetails && guestUser
            ? { ...guestUser, guestEmail: data.guestEmail }
            : guestUser;
        if (saveDetails && guestUser) setGuestUser(updatedGuest);
        const res = await postService.sendClientGantLink(
          websiteId,
          data.guestEmail,
          "email",
          saveDetails ? (updatedGuest ?? undefined) : undefined
        );
        res
          ? toast.success("המייל נשלח בהצלחה")
          : toast.error("שגיאה בשליחת המייל");
      } finally {
        setLoading(false);
        onHide();
      }
    }
  };

  const onWhatsappSubmit: SubmitHandler<WhatsappFormValues> = async (data) => {
    if (websiteId) {
      setLoading(true);
      try {
        const updatedGuest =
          saveDetails && guestUser
            ? { ...guestUser, guestPhone: data.guestPhone }
            : guestUser;
        if (saveDetails && guestUser) setGuestUser(updatedGuest);
        const res = await postService.sendClientGantLink(
          websiteId,
          data.guestPhone,
          "whatsapp",
          saveDetails ? (updatedGuest ?? undefined) : undefined
        );
        res
          ? toast.success("הודעה נשלחה בהצלחה בוואטסאפ")
          : toast.error("שגיאה בשליחת הודעת וואטסאפ");
      } finally {
        setLoading(false);
        onHide();
      }
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(gantLink);
    toast.success("קישור הועתק בהצלחה");
  };

  return (
    <DialogStyled
      header={
        <ModalHeader title="שיתוף גאנט ללקוח" downAbit OnClose={onHide} />
      }
      closable={false}
      visible
      position="center"
      style={{
        width: "30vw",
        margin: "0",
        boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
        borderRadius: "8px",
        maxHeight: "90vh",
      }}
      onHide={onHide}
      draggable={false}
      resizable={false}
    >
      <Wrapper>
        <SectionTitle>שם הלקוח</SectionTitle>
        <StyledInputContainer>
          <InputText
            readOnly={!saveDetails}
            value={guestUser?.firstName || ""}
            onChange={handleNameChange}
            style={{ width: "100%", direction: "rtl" }}
          />
        </StyledInputContainer>

        <ButtonContainer>
          <StyledButton
            type="button"
            onClick={() => setShareMethod("whatsapp")}
          >
            <div
              className={`flex insideButton ${shareMethod === "whatsapp" ? "active" : ""}`}
            >
              <WhatsappIcon className="whatsapp" />
              <span>שיתוף בוואטסאפ</span>
            </div>
          </StyledButton>
          <StyledButton type="button" onClick={() => setShareMethod("email")}>
            <div
              className={`flex insideButton ${shareMethod === "email" ? "active" : ""}`}
            >
              <EmailIcon className="email" />
              <span>שיתוף באימייל</span>
            </div>
          </StyledButton>
        </ButtonContainer>

        {shareMethod === "email" && (
          <EmailShare
            emailForm={emailForm}
            onSubmit={emailForm.handleSubmit(onEmailSubmit)}
            handleCopyLink={handleCopyLink}
            loading={loading}
            saveDetails={saveDetails}
            onToggleSaveDetails={() => setSaveDetails((prev) => !prev)}
          />
        )}
        {shareMethod === "whatsapp" && (
          <WhatsappShare
            whatsappForm={whatsappForm}
            onSubmit={whatsappForm.handleSubmit(onWhatsappSubmit)}
            handleCopyLink={handleCopyLink}
            loading={loading}
            saveDetails={saveDetails}
            onToggleSaveDetails={() => setSaveDetails((prev) => !prev)}
          />
        )}
      </Wrapper>
    </DialogStyled>
  );
};

export default ShareGantLinkModal;
