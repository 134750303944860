import { Divider } from "primereact/divider";
import { FieldValues, Path, useFormContext, useWatch } from "react-hook-form";
import styled, { css, keyframes } from "styled-components";
import IgPreview from "../postPreviews/IgPreview";
import { useRef, useEffect, useState } from "react";
import CardTitle from "../../common/CardTitle";
import TextEditor from "../../common/publishPostModal/TextEditor";
import Card from "../../common/Card";
import { Message } from "primereact/message";
import instagramPostMediaValidation from "./mediaValdiation/instagramPostMediaValidation";
import CircleBg from "../../../assets/images/bgcirclepurple.png";
import AttachmentInput from "../../common/form/AttachmentInput";
import { AttachmentMedia } from "neword-core";
import IgStoryPreview from "../postPreviews/IgStoryPreview";
import AttachmentInputFullMediaObject, {
  singleInputType,
} from "../../common/form/AttachmentsInputFullObject";
import { Theme } from "../../../core/theme/theme";
import { useTranslation } from "react-i18next";

const LabelStyled = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: var(--text-color);
  padding-bottom: 10px;
`;

const ImageWrapper = styled.div`
  background-size: cover;
  position: relative;
  background-color: var(--light-bg);
  margin-left: -1px;
  margin-top: -1px;
  &::before {
    content: "";
    position: absolute;
    top: 0x;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: url(${CircleBg});
    background-size: cover;
    opacity: 0.11; /* Adjust the opacity as needed */
    z-index: 1;
  }

  & > * {
    position: relative;
    z-index: 2;
  }
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }
`;
const highlightAnimation = keyframes`
  0% { border-color: ${Theme.colors.purple}; }
  50% { border-color: transparent; }
  100% { border-color: ${Theme.colors.purple}; }
`;

function InstagramStoryForm<FormDataType extends FieldValues>({
  name,
}: {
  name: Path<FormDataType>;
  index?: number;
}) {
  const { control } = useFormContext();
  const { t } = useTranslation();

  const { posts = [] } = useWatch({ control }); // Ensuring that posts has a default value

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [imageUrlError, setImageUrlError] = useState("");

  const autoResizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    autoResizeTextarea(); // Initial adjustment on mount or when post content changes
  }, [posts]);

  const [errorMsg, setErrorMsg] = useState("");

  return (
    <div className="grid m-0 h-full">
      <div className="col-7 pl-4 pr-4 pt-4">
        <CardTitle title={t("instagram_story_form.post_title")} />
        <Divider />
        <div className="post p-2 mb-2">
          <LabelStyled>{t("instagram_story_form.add_media_label")}</LabelStyled>
          <AttachmentInputFullMediaObject
            type={singleInputType.bigImage}
            isSquare
            includeVideos
            name={`${name}.imageUrls[0]`}
            //   validate={(value: AttachmentMedia[]) => {
            //     const errorText = instagramPostMediaValidation(value);
            //     setImageUrlError(errorText);
            //     if (errorText === "") {
            //       return true;
            //     }

            //     return errorText;
            //   }}
          />
        </div>
        <div className="media p-2 mt-0 ">
          {errorMsg !== "" && <Message severity="warn" text={errorMsg} />}
          {imageUrlError !== "" && (
            <Message severity="warn" text={imageUrlError} />
          )}
        </div>
      </div>
      <div className="col-5 p-0">
        <ImageWrapper className="p-4">
          <IgStoryPreview name={name} />
        </ImageWrapper>
      </div>
    </div>
  );
}

export default InstagramStoryForm;
