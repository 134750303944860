import React, { useEffect, useState } from "react";
import FormStyle from "../../../common/form/FormStyle";
import { InputTextarea } from "primereact/inputtextarea";
import styled from "styled-components";
import { Controller, useFormContext } from "react-hook-form";
import Button from "../../../common/form/Button";
import RephraseButton from "../../../common/form/RephraseButton";
import { AutoComplete } from "primereact/autocomplete";
import Card from "../../../common/Card";
import Badge from "../../../common/Badge";
import { scanWebsiteState } from "../../../../state/websitesState";
import { useRecoilValue } from "recoil";
import { CreateWebsiteRequestData } from "../../../../core/services/requests/createWebsite/createWebsiteRequestData";
import { Language } from "../../../../core/types/language";
import { useTranslation } from "react-i18next";
import { genConfig } from "react-nice-avatar";

const FormStyleStyled = styled(FormStyle)`
  width: 600px;
  margin-top: 60px;
`;

const BadgeStyled = styled(Badge)`
  position: absolute;
  top: -17px;
  right: 40px;
`;

const CardStyled = styled(Card)`
  position: relative;
`;

const useBusinessCategories = (): BusinessCategory[] => {
  const { t } = useTranslation();
  return [
    {
      category: t("business_categories.retail"),
      types: [
        {
          label: t("business_categories.retail_types.retail_clothing_toys"),
          value: "retail_clothing_toys",
        },
        {
          label: t("business_categories.retail_types.online_store"),
          value: "online_store",
        },
        {
          label: t("business_categories.retail_types.car_sales"),
          value: "car_sales",
        },
      ],
    },
    {
      category: t("business_categories.personal_services"),
      types: [
        {
          label: t(
            "business_categories.personal_services_types.service_provider"
          ),
          value: "service_provider",
        },
        {
          label: t(
            "business_categories.personal_services_types.medical_clinic"
          ),
          value: "medical_clinic",
        },
        {
          label: t(
            "business_categories.personal_services_types.repairs_renovations"
          ),
          value: "repairs_renovations",
        },
      ],
    },
    {
      category: t("business_categories.food_beverages"),
      types: [
        {
          label: t("business_categories.food_beverages_types.restaurant_cafe"),
          value: "restaurant_cafe",
        },
      ],
    },
    {
      category: t("business_categories.logistics_transportation"),
      types: [
        {
          label: t(
            "business_categories.logistics_transportation_types.logistics_business"
          ),
          value: "logistics_business",
        },
      ],
    },
    {
      category: t("business_categories.technology_innovation"),
      types: [
        {
          label: t(
            "business_categories.technology_innovation_types.tech_startup"
          ),
          value: "tech_startup",
        },
      ],
    },
    {
      category: t("business_categories.education_training"),
      types: [
        {
          label: t(
            "business_categories.education_training_types.educational_institution"
          ),
          value: "educational_institution",
        },
      ],
    },
    {
      category: t("business_categories.finance_consulting"),
      types: [
        {
          label: t(
            "business_categories.finance_consulting_types.financial_corporation"
          ),
          value: "financial_corporation",
        },
        {
          label: t(
            "business_categories.finance_consulting_types.business_consulting"
          ),
          value: "business_consulting",
        },
      ],
    },
    {
      category: t("business_categories.construction_real_estate"),
      types: [
        {
          label: t(
            "business_categories.construction_real_estate_types.construction_company"
          ),
          value: "construction_company",
        },
      ],
    },
    {
      category: t("business_categories.entertainment_events"),
      types: [
        {
          label: t(
            "business_categories.entertainment_events_types.event_company"
          ),
          value: "event_company",
        },
      ],
    },
    {
      category: t("business_categories.advertising_marketing"),
      types: [
        {
          label: t("business_categories.advertising_marketing_types.ad_agency"),
          value: "ad_agency",
        },
      ],
    },
    {
      category: t("business_categories.influencer"),
      types: [
        {
          label: t("business_categories.influencer_types.influencer"),
          value: "influencer",
        },
      ],
    },
  ];
};

interface BusinessType {
  label: string;
  value: string;
}

interface BusinessCategory {
  category: string;
  types: BusinessType[];
}

const BusinessExtraDetails: React.FC = () => {
  const methods = useFormContext<CreateWebsiteRequestData>();
  const { t } = useTranslation();
  const scanData = useRecoilValue(
    scanWebsiteState(methods.getValues("website.url"))
  );
  const businessCategories = useBusinessCategories();

  useEffect(() => {
    methods.setValue("website.description", scanData?.description ?? "");
    methods.setValue("writer.language", scanData?.language ?? Language.HEBREW);
    // methods.setValue(
    //   "audience",
    //   scanData?.audience.map((a) => ({ ...a, imageProps: genConfig() })) ?? []
    // );
  }, [scanData?.description, scanData?.language]);

  const [filteredBusinessTypes, setFilteredBusinessTypes] = useState<
    BusinessCategory[]
  >([]);

  const searchBusinessTypes = (event: any) => {
    const query = event.query.toLowerCase();
    const filteredGroups = businessCategories
      .map((category) => {
        const filteredItems = category.types.filter((item) =>
          item.label.toLowerCase().includes(query)
        );
        return { category: category.category, types: filteredItems };
      })
      .filter((category) => category.types.length > 0);

    setFilteredBusinessTypes(filteredGroups);
  };

  return (
    <>
      <FormStyleStyled>
        <div className="grid">
          <CardStyled className="grid w-full m-0 ">
            <BadgeStyled large bgColor="yellow" textColor="title">
              {t("business_extra_details.labels.business_details")}
            </BadgeStyled>
            <div className="col-12">
              <label htmlFor="username">
                {t("business_extra_details.labels.business_description")}
              </label>
              <InputTextarea
                {...methods.register("website.description", {
                  required: t(
                    "business_extra_details.messages.business_description_required"
                  ),
                })}
                placeholder={t(
                  "business_extra_details.placeholders.business_description"
                )}
              />
              <RephraseButton
                id="rephraseButton"
                onChange={(newText: string) =>
                  methods.setValue("website.description", newText)
                }
                content={methods.getValues("website.description")}
                prompt={
                  methods.getValues("website.description")
                    ? t("business_extra_details.prompts.rephrase")
                    : t("business_extra_details.prompts.generate")
                }
              >
                <span>
                  {methods.getValues("website.description")
                    ? t("business_extra_details.buttons.rephrase")
                    : t("business_extra_details.buttons.generate")}
                </span>
              </RephraseButton>
            </div>
            <div className="col-12">
              <label htmlFor="username">
                {t("business_extra_details.labels.business_category")}
              </label>
              <br />
              <Controller
                name="website.category"
                rules={{
                  required: t(
                    "business_extra_details.messages.business_category_required"
                  ),
                }}
                render={({ field }) => (
                  <AutoComplete
                    className="w-full"
                    id="businessType"
                    value={field.value}
                    field="label"
                    optionGroupLabel="category"
                    optionGroupChildren="types"
                    delay={0}
                    onChange={(e) => field.onChange(e.value)}
                    placeholder={t(
                      "business_extra_details.placeholders.business_category"
                    )}
                    suggestions={filteredBusinessTypes}
                    completeMethod={searchBusinessTypes}
                  />
                )}
              />
            </div>
          </CardStyled>
        </div>
      </FormStyleStyled>
    </>
  );
};

export default BusinessExtraDetails;
