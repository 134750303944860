import { useRecoilValue } from "recoil";
import { websitesStateSelector } from "../../state/websitesState";
import Link from "../../components/common/Link";
import {useTranslation} from "react-i18next";

const ChooseBusinessPage = () => {
  const websites = useRecoilValue(websitesStateSelector);
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t("choose_business_for_image_upload")}</h1>
      {websites.map((website) => {
        return (
          <div key={website.id}>
            <a href={`/websites/${website.id}/mobile/attachments`}>
              {website.name || website.domain}
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default ChooseBusinessPage;
